import React, { useContext, useEffect, useState } from "react";
// import { Modal } from "react-bootstrap";
import ContextState from "../../contextApi/ContextState";

import Modal from "@mui/material/Modal";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  PieChart,
  Pie,
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
  Cell,
  Rectangle,
  ComposedChart,
  AreaChart,
  Line,
  Sector,
  Area,
  LabelList,
} from "recharts";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  LinearProgress,
  Typography,
  Box,
  ButtonGroup,
  Button,
  Tooltip as MuiTooltip,
  linearProgressClasses,
} from "@mui/material";

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import RadialSeparators from "./RadialSeparators";

import noDataIMG from "../../../assets/images/no-data (1).png";

import allTicketsImg from "../../../assets/images/total-assessment-blue-3.png";
import allAcceptedImg from "../../../assets/images/high-quality.png";
import TotalMachinesImg from "../../../assets/images/machine-blue.png";
import TotalRegionImg from "../../../assets/images/region-blue.png";
import TotalDistrictImg from "../../../assets/images/district-blue.png";
import TotalCropsImg from "../../../assets/images/crop-blue.png";

import accepted_scans from "../../../assets/images/accepted_scan_1.png";
import rejected_scans from "../../../assets/images/rejected_scan_2.png";
import TotalMostScansImg from "../../../assets/images/diagram (1).png";
import TotalMostAcceptedScansImg from "../../../assets/images/diagram (2).png";
import AvgMostAcceptedScansImg from "../../../assets/images/scan-average-green.png";
import AvgMostScansImg from "../../../assets/images/scan-average-blue.png";
import FirstPageDataTable from "./FirstPageDataTable";
import { BeatLoader } from "react-spinners";
import Error from "../../error/Error";
import close from "../../../assets/images/close.svg";

const CropModal = ({
  showCropModal,
  setshowCropModal,
  color,
  topDivWidth,
  pageStyling,
  shadesOfBlue,
  isSmallScreen,
  islagelargeScreen,
  islageScreen,
  isMediumScreen,
  sendCropDataToURL,
  handelRefreshOfTheCropDataToURL,
  handelshowOverviewModalError,
  showOverviewModalError,
}) => {
  const {
    fetchApi,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    closeSnackbar,
    showError,
    showErrorPage,
    formatDate,
    formatInIndianRupees,
    controllerRef
  } = useContext(ContextState);

  // console.log("Crop Modal Data : ", sendCropDataToURL);

  const [RegionData, setRegionData] = useState(null);

  // data Table

  function dataPass(data) {
    return [...data];
  }

  // Custom bar component
  const CustomBar = (props) => {
    const { fill, x, y, width, height } = props;

    return (
      <Rectangle
        fill={fill}
        x={x}
        y={y}
        width={width}
        height={height}
        radius={[5, 5, 5, 5]}
      />
    );
  };

  const getPieChartCropData = (cropData) => {
    let showPie = [];
    // console.log(cropData, typeof cropData);
    // assuming cropData array
    cropData.forEach((element, index) =>
      showPie.push({
        name: element.crop,
        value: element.total,
        fill: shadesOfBlue[index],
      })
    );

    return showPie;
  };

  // cutsom tooltip

  const CustomTooltip = ({ active, payload, label }) => {
    // console.log("test_custom_tooltip------------->", payload, active, label);
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: color?.textWhiteColor,
            // margin: "8px",
            borderRadius: "5px",
            border: "1px solid",
            borderColor: "#e5e6e8",
          }}
        >
          {/* label for tooltip */}
          {label?.length > 1 ? (
            <p
              style={{
                margin: "0px 0px 0px 0px",
                fontSize: "0.75rem",
                borderBottom: "1px solid",
                borderColor: "#e5e6e8",
                padding: "4px 8px",
              }}
            >
              {label.length > 1 ? label : null}
            </p>
          ) : null}
          {/* {payload[0]?.payload?.name && (
            <p
              style={{
                margin: "0px 0px 0px 0px",
                fontSize: "0.75rem",
                borderBottom: "1px solid",
                borderColor: "#e5e6e8",
                padding: "4px 8px",
              }}
            >
              {payload[0]["payload"].name}
            </p>
          )} */}
          <div style={{ padding: "4px 0px" }}>
            <div style={{ padding: "4px 0" }}>
              {payload &&
                payload?.map((entry, index) => (
                  <div
                    key={`item-${index}`}
                    className="d-flex align-items-center gap-2 px-2 py-1"
                  >
                    <div
                      style={{
                        backgroundColor: entry?.color || entry?.payload?.fill,
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <div style={{ fontSize: "0.83rem", marginRight: "4px" }}>
                      {entry.name.toLowerCase() === "value"
                        ? "Total Scans :"
                        : entry.name}
                      :{/* Total Scans : */}
                    </div>
                    <div style={{ fontSize: "0.83rem", fontWeight: 600 }}>
                      {formatInIndianRupees(Math.abs(entry.value))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  // crop coustom chart
  const formatYAxis = (value) => {
    // Convert the value to a string with a locale-sensitive representation
    const xlablel = value < 0 ? -value : value;
    const formattedValue = xlablel.toLocaleString("en-IN");
    // Append "K" for thousands
    // console.log("formatXAxis: ", xlablel)
    if (xlablel >= 1000) {
      return `${Math.round(xlablel / 1000)}K`;
    }
    return formattedValue;
  };

  const [activeIndex, setActiveIndex] = useState(-1);
  const [opacity, setOpacity] = useState({ total: 1, accepted: 1 });
  const [monthAssementsOpacity, setMonthAssementsOpacity] = useState({
    total: 0.7,
    accepted: 0.7,
  });

  // Custom legend
  const customLegend = (props) => {
    const { payload } = props;
    return (
      <div
        className="d-flex justify-content-center gap-3 "
        style={{ cursor: "pointer" }}
      >
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{
              listStyleType: "none",
              // marginTop: "4px",
              border: "1px solid",
              padding: "3px 10px",
              borderRadius: "10px",
              borderColor: "#e5e6e8",
            }}
            onMouseEnter={() => handleMouseEnterLegend(entry)}
            onMouseLeave={() => handleMouseLeaveLegend(entry)}
          >
            <span
              style={{
                display: "inline-block",
                backgroundColor: entry.color,
                width: "10px",
                height: "10px",
                marginRight: "8px",
                borderRadius: "50%",
              }}
            ></span>
            {entry.value}
          </div>
        ))}
      </div>
    );
  };

  const handleMouseEnter = (index) => {
    // console.log(data, "test------>");
    setActiveIndex(index);
  };

  const handleMouseLeave = (data, index) => {
    setActiveIndex(-1);
  };

  const handleMouseEnterLegend = (o) => {
    // console.log("Mouse Enter ----->", o);
    const { dataKey } = o;
    setOpacity((prevOpacity) => ({
      ...prevOpacity,
      [dataKey]: 0.5,
    }));
  };

  const handleMouseLeaveLegend = (o) => {
    // console.log("Mouse Leave ----->", o);
    const { dataKey } = o;
    setOpacity((prevOpacity) => ({
      ...prevOpacity,
      [dataKey]: 0.3,
    }));
  };
  const handleMouseEnterLegendMonth = (o) => {
    // console.log("Mouse Enter ----->", o);
    const { dataKey } = o;
    setMonthAssementsOpacity((prevOpacity) => {
      const newOpacity = {};
      for (const key in prevOpacity) {
        newOpacity[key] = key === dataKey ? prevOpacity[key] : 0.1;
      }
      // console.log("newOpacity : ", newOpacity);
      return newOpacity;
    });
  };

  const handleMouseLeaveLegendMonth = (o) => {
    // console.log("Mouse Leave ----->", o);
    const { dataKey } = o;
    setMonthAssementsOpacity((prevOpacity) => {
      const newOpacity = {};
      for (const key in prevOpacity) {
        newOpacity[key] = key === dataKey ? prevOpacity[key] : 0.7;
      }
      // console.log("newOpacity : ", newOpacity);
      return newOpacity;
    });
  };
  const [loading, showLoading] = useState(true);

  useEffect(() => {
    document.title = "Region-name - NEO";
    // showLoading(true);
    let is_user_data_Mounted = true;
    const fetchDataAndUpdateFilters = async () => {
      // fetchFiltersDataApiCall
      const payload = {};

      if (sendCropDataToURL) {
        payload["crop"] = sendCropDataToURL.Crop;
      }

      setTimeout(async () => {
        const detailed_location_overview = await fetchApi(
          "get_detailed_crop_wise_data",
          "POST",
          payload,
          is_user_data_Mounted
        );
        // console.log("get_basic_details", detailed_location_overview);
        if (detailed_location_overview.statusCode === 200) {
          // showErrorPage(null, null);
          handelshowOverviewModalError(null, null);

          setRegionData(detailed_location_overview?.data || null);
          showLoading(false); // Moved here

          // console.log("data send : ", sendLocationDataToURL);
          // console.log(
          //   "detailed_location_overview?.data : ",
          //   detailed_location_overview?.data
          // );
        } else if (
          detailed_location_overview.statusCode === 500 ||
          detailed_location_overview.statusCode === 401
        ) {
          // console.log("working");
          // setUserData(null);
          // showErrorPage(
          //   detailed_location_overview.statusCode,
          //   detailed_location_overview.msg
          // );

          handelshowOverviewModalError(
            detailed_location_overview.statusCode,
            detailed_location_overview.msg
          );
        } else {
          // setUserData(null);
          // showErrorPage(detailed_location_overview.statusCode, detailed_location_overview.msg);
          console.log("Some Error Occured", detailed_location_overview);
          localStorage.clear();
          window.location.href = "/login";
        }
        // showLoading(false);
        return;
      }, 200);
    };

    fetchDataAndUpdateFilters();

    // Cleanup function
    return () => {
      is_user_data_Mounted = false;
      // On unmount, abort the previous request for fetchApi api
      if (controllerRef.current) {
        controllerRef.current.abort(); 
      }
    };
  }, []);

  function getFirstPageDataTableDataHeader(dataheaders, data) {
    // console.log(
    //   "getFirstPageDataTableData: ",
    //   RegionData.top_machine_wise_data
    // );
    const headers = [];
    const row = [];
    if (data) {
      const tableData = data[0] || [];
      // let headerskeys = Object.keys(tableData).filter(
      //   (item) => item.toLowerCase() !== "location"
      // );
      // let headerskeys = Object.keys(tableData);
      // console.log("table Data : ", tableData, headerskeys);
      // headerskeys.splice(1, 0, "location");
      dataheaders.forEach((item, index) => {
        if (item.label.toLowerCase().replace("_", " ") !== "accepted percent") {
          headers.push({
            field: item.label,
            headerName: item.header,
            width: item.label === "location" ? 250 : 150,
            flex: !isMediumScreen,
            editable: false,
            description: item.label,
            cellClassName: "wrapCell",
            renderCell: (params) => {
              // console.log("params-->: ", params);
              return (
                <Box
                  sx={{
                    // width: "10%", // Adjust width as needed
                    textAlign: "left",
                    //   marginLeft: "5px", // Add margin to separate text from progress bar
                    //   fontWeight: "bold", // Customize font weight if needed
                    fontFamily: "Open-Sans-Medium",
                    fontWeight: index ? "500" : "700",
                    fontSize: "1.05rem",
                    color: color.tableRowHeading,
                    textWrap: "nowrap",
                  }}
                >
                  {
                    <MuiTooltip
                      title={`${params.field} : ${params.value}`}
                      arrow
                    >
                      {(typeof params.value).toLowerCase() === "number"
                        ? formatInIndianRupees(params.value)
                        : params.value}
                    </MuiTooltip>
                  }
                </Box>
              );
            },
          });
        } else {
          headers.push({
            field: item.label,
            headerName: item.header.replace("_", " "),
            width: 300,
            flex: !isMediumScreen,
            editable: false,
            description: item.label,
            renderCell: (params) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  // padding: "0px 5px 0px 0px",
                }}
              >
                <MuiTooltip
                  title={`${Math.round(params.value)}% Scans Accepted`}
                  arrow
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      gap: "30px",
                      fontFamily: "Open-Sans-Medium",
                    }}
                  >
                    <Box
                      sx={{
                        width: "10%", // Adjust width as needed
                        color: color.tableRowHeading,
                        textAlign: "center",
                        fontSize: "1.15rem",
                        //   marginLeft: "5px", // Add margin to separate text from progress bar
                        //   fontWeight: "bold", // Customize font weight if needed
                      }}
                    >
                      {`${Math.round(params.value)}%`}
                    </Box>
                    <Box
                      sx={{
                        width: "90%",
                        m: "auto",
                        position: "relative",
                      }}
                    >
                      <LinearProgress
                        variant="determinate"
                        value={Math.round(params.value)}
                        style={{
                          height: "10px",
                          borderRadius: "8px",
                        }}
                        sx={{
                          backgroundColor: color.iconBackgroundgreen,
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: color.green,
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </MuiTooltip>
              </Box>
            ),
          });
        }
      });

      data.forEach((item, index) => {
        row.push({
          id: index,
          ...item,
        });
      });
    }

    // console.log("Headers: ", headers, row);

    return { TableDataHeaders: headers, TabelDataRows: row };
  }
  const [showTableData, setShowTableData] = useState({
    region: false,
    district: false,
    machine: false,
  });
  const { TableDataHeaders, TabelDataRows } = RegionData
    ? (showTableData.region &&
        getFirstPageDataTableDataHeader(
          RegionData.region_table_headers,
          RegionData.region_table_data
        )) ||
      (showTableData.district &&
        getFirstPageDataTableDataHeader(
          RegionData.district_table_headers,
          RegionData.district_table_data
        )) ||
      (showTableData.machine &&
        getFirstPageDataTableDataHeader(
          RegionData.machine_table_headers,
          RegionData.machine_table_data
        )) || {
        TableDataHeaders: [],
        TabelDataRows: [],
      }
    : { TableDataHeaders: [], TabelDataRows: [] };

  //   console.log(
  //     "TableDataHeaders, TabelDataRows  : ",
  //     TableDataHeaders,
  //     TabelDataRows
  //   );

  const handleButtonClick = (key) => {
    // console.log("Sate Change : ", key);
    setShowTableData((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, curr) => {
        acc[curr] = curr === key;
        return acc;
      }, {});
      return newState;
    });
  };

  useEffect(() => {
    if (RegionData?.region_table_data) {
      handleButtonClick("region");
    } else if (RegionData?.district_table_data) {
      handleButtonClick("district");
    } else if (RegionData?.machine_table_data) {
      handleButtonClick("machine");
    }
  }, [RegionData]);

  //   monthly scans
  // to show the -ive value for the bar graph
  // Function to transform positive value to negative
  const transformData = (data) => {
    // console.log("data: ", data);
    // return data?.map((item) => ({ ...item, accepted: -item.accepted }));
    return data?.map((item) => ({ ...item }));
  };

  function generateRangeArray(graph_data) {
    const maxTotal =
      Math.ceil(Math.max(...graph_data.map((item) => item.total)) / 100000) *
      100000;

    const step = 100000;
    const getarr = Array.from(
      { length: (2 * maxTotal) / step + 1 },
      (_, i) => -maxTotal + i * step
    );
    // console.log("maxTotal :", getarr);
    return getarr;
  }

  // data Table

  function dataPass(data) {
    return [...data];
  }
  const modalstyle = {
    position: "relative",
    zIndex: "1300",
    right: "0",
    /* bottom: 0; */
    top: "2%",
    left: "0",
    width: "90vw",
    margin: "  auto",
    overflowY: "auto",
    // height: "auto",
    borderRadius: "10px",
    minHeight: "95vh",
  };
  return (
    <>
      {
        <Modal
          style={{
            position: "fixed",
            overflowY: "scroll",
            height: "100%",
          }}
          open={showCropModal}
          // fullscreen='md-down'
          onClose={() => {
            // console.log("Model hide working : ");
            handelRefreshOfTheCropDataToURL();
            setshowCropModal(false);
          }}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          // BackdropProps={{ onClick: (event) => event.stopPropagation() }}
        >
          <Box sx={modalstyle}>
            {" "}
            <Typography
              style={{
                backgroundColor: color.primary,
                //   flex: "0 0 auto",
                //   position: "absolute",
                //   zIndex: 20,
                //   width: "100%",
                padding: "16px",
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                {!showOverviewModalError.status && (
                  <div>
                    {/* Custom Modal Styling */}
                    {/* Region name div */}
                    <div className="crad-Div pt-0">
                      <div
                        className="bar-chart-heading pt-0 ps-2"
                        style={{ color: color.taxtHeading }}
                      >
                        {sendCropDataToURL.Crop || "Crop Name"}
                      </div>
                    </div>
                  </div>
                )}

                <div
                  onClick={() => {
                    // console.log("Model hide working : ");
                    handelRefreshOfTheCropDataToURL();
                    setshowCropModal(false);
                  }}
                  className="ms-auto"
                >
                  <img
                    src={close}
                    alt="close"
                    style={{ width: "20px", cursor: "pointer" }}
                  />
                </div>
              </div>
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              style={{
                backgroundColor: color.primary,
                marginTop: "-20px",
                borderRadius: "5px",
                // height: RegionData ? modalHeight : "600px",
                // overflow: "hidden",
                // transition: "height 2s",
                padding: "16px",
              }}
            >
              {(!showOverviewModalError.status &&
                ((loading && (
                  <div className="showSpinner all-ticket-spinner">
                    <BeatLoader color="#2D97D4" />
                  </div>
                )) ||
                  (RegionData && !RegionData?.message && (
                    <>
                      {/* Top Cards */}
                      <div>
                        <div className="average-div d-flex flex-row flex-wrap align-items-center justify-content-start w-100 gap-0 ">
                          {/* top Div */}
                          {(sendCropDataToURL["Total Scans"] ||
                            sendCropDataToURL["Total Scans"] === 0) && (
                            <div className="top-div d-flex flex-row align-item-center flex-grow-1">
                              <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                <div
                                  style={{
                                    backgroundColor: color.iconBackgroundBlue,
                                    borderRadius:
                                      pageStyling.imageBackgroundDivRadius,
                                    height: pageStyling.iconBackgroundHeight,
                                    width: pageStyling.iconBackgroundWidth,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    src={allTicketsImg}
                                    alt="All Assesment img"
                                    className=""
                                    style={{
                                      width: pageStyling.IconWidth,
                                    }}
                                  />
                                </div>
                              </div>
                              {/* circular progress bar div desc */}
                              <div
                                className="top-div-circular-progress-desc flex-grow-1"
                                style={{
                                  color: color.taxtHeading,
                                }}
                              >
                                Total Scans
                                <p
                                  style={{
                                    color: color.tableRowHeading,
                                  }}
                                >
                                  {formatInIndianRupees(
                                    sendCropDataToURL["Total Scans"]
                                  )}
                                  {/* <span
                                  style={{
                                    color: color.taxtHeadingLight,
                                  }}
                                >
                                  (
                                  {formatInIndianRupees(
                                    data?.last_10_days_data?.top_data?.region_scan
                                  )}
                                  scans)
                                </span> */}
                                </p>
                              </div>
                            </div>
                          )}
                          {(sendCropDataToURL["Total Accepted"] ||
                            sendCropDataToURL["Total Accepted"] === 0) && (
                            <div className="top-div d-flex flex-row align-item-center flex-grow-1">
                              <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                <div
                                  style={{
                                    backgroundColor: color.iconBackgroundBlue,
                                    borderRadius:
                                      pageStyling.imageBackgroundDivRadius,
                                    height: pageStyling.iconBackgroundHeight,
                                    width: pageStyling.iconBackgroundWidth,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    src={allTicketsImg}
                                    alt="All Assesment img"
                                    className=""
                                    style={{
                                      width: pageStyling.IconWidth,
                                    }}
                                  />
                                </div>
                              </div>
                              {/* circular progress bar div desc */}
                              <div
                                className="top-div-circular-progress-desc flex-grow-1"
                                style={{
                                  color: color.taxtHeading,
                                }}
                              >
                                Accepted Scans
                                <p
                                  style={{
                                    color: color.tableRowHeading,
                                  }}
                                >
                                  {formatInIndianRupees(
                                    sendCropDataToURL["Total Accepted"]
                                  )}
                                  {/* <span
                                  style={{
                                    color: color.taxtHeadingLight,
                                  }}
                                >
                                  (
                                  {formatInIndianRupees(
                                    data?.last_10_days_data?.top_data?.region_scan
                                  )}
                                  scans)
                                </span> */}
                                </p>
                              </div>
                            </div>
                          )}

                          {sendCropDataToURL["Top Region"] && (
                            <div className="top-div d-flex flex-row align-item-center flex-grow-1">
                              <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                <div
                                  style={{
                                    backgroundColor: color.iconBackgroundBlue,
                                    borderRadius:
                                      pageStyling.imageBackgroundDivRadius,
                                    height: pageStyling.iconBackgroundHeight,
                                    width: pageStyling.iconBackgroundWidth,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="ms-auto barChartGraphDesc"
                                >
                                  <img
                                    src={TotalDistrictImg}
                                    alt="All Assesment img"
                                    style={{
                                      width: pageStyling.IconWidth,
                                    }}
                                  />
                                </div>
                              </div>
                              {/* circular progress bar div desc */}

                              <div
                                className="top-div-circular-progress-desc flex-grow-1"
                                style={{
                                  color: color.taxtHeading,
                                }}
                              >
                                Top Region
                                <p
                                  style={{
                                    color: color.tableRowHeading,
                                  }}
                                >
                                  {sendCropDataToURL["Top Region"]}
                                </p>
                              </div>
                            </div>
                          )}
                          {/* vertical seperator line */}
                          {/* <div className="barChartDivDescVerticalSeperator"></div> */}
                          {/* middel Div */}
                          {sendCropDataToURL["Top District"] && (
                            <div className="top-div d-flex flex-row align-item-center flex-grow-1">
                              <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                <div
                                  style={{
                                    backgroundColor: color.iconBackgroundBlue,
                                    borderRadius:
                                      pageStyling.imageBackgroundDivRadius,
                                    height: pageStyling.iconBackgroundHeight,
                                    width: pageStyling.iconBackgroundWidth,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="ms-auto barChartGraphDesc"
                                >
                                  <img
                                    src={TotalDistrictImg}
                                    alt="All Assesment img"
                                    style={{
                                      width: pageStyling.IconWidth,
                                    }}
                                  />
                                </div>
                              </div>
                              {/* circular progress bar div desc */}

                              <div
                                className="top-div-circular-progress-desc flex-grow-1"
                                style={{
                                  color: color.taxtHeading,
                                }}
                              >
                                Top District
                                <p
                                  style={{
                                    color: color.tableRowHeading,
                                  }}
                                >
                                  {sendCropDataToURL["Top District"]}
                                </p>
                              </div>
                            </div>
                          )}
                          {/* Divider line */}

                          {/* <div className="barChartDivDescVerticalSeperator"></div> */}
                          {/* bottom Div */}
                          {sendCropDataToURL["Top Location"] && (
                            <div className="top-div d-flex flex-row align-item-center flex-grow-1">
                              <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                <div
                                  style={{
                                    backgroundColor: color.iconBackgroundBlue,
                                    borderRadius:
                                      pageStyling.imageBackgroundDivRadius,
                                    height: pageStyling.iconBackgroundHeight,
                                    width: pageStyling.iconBackgroundWidth,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="ms-auto barChartGraphDesc"
                                >
                                  <img
                                    src={TotalMachinesImg}
                                    alt="All Assesment img"
                                    style={{
                                      width: pageStyling.IconWidth,
                                    }}
                                  />
                                </div>
                              </div>
                              {/* circular progress bar div desc */}
                              <div
                                className="top-div-circular-progress-desc flex-grow-1"
                                style={{
                                  color: color.taxtHeading,
                                }}
                              >
                                Top Location
                                <p
                                  style={{
                                    color: color.tableRowHeading,
                                  }}
                                >
                                  {sendCropDataToURL["Top Location"]}

                                  {/* <span
                                  style={{
                                    color: color.taxtHeadingLight,
                                  }}
                                >
                                  (
                                  {formatInIndianRupees(
                                    data?.last_month_data["Top machine"].scan
                                  )}{" "}
                                  scans)
                                </span> */}
                                </p>
                              </div>
                            </div>
                          )}

                          {/* crop data */}
                          {false &&
                            (RegionData.top_crop ||
                              RegionData.top_crop === 0) && (
                              <div className="top-div d-flex flex-row align-item-center ">
                                <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                  <div
                                    style={{
                                      backgroundColor: color.iconBackgroundBlue,
                                      borderRadius:
                                        pageStyling.imageBackgroundDivRadius,
                                      height: pageStyling.iconBackgroundHeight,
                                      width: pageStyling.iconBackgroundWidth,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    className="ms-auto barChartGraphDesc"
                                  >
                                    <img
                                      src={TotalCropsImg}
                                      alt="All Assesment img"
                                      style={{
                                        width: pageStyling.IconWidth,
                                      }}
                                    />
                                  </div>
                                </div>
                                {/* circular progress bar div desc */}
                                <div
                                  className="top-div-circular-progress-desc flex-grow-1"
                                  style={{
                                    color: color.taxtHeading,
                                  }}
                                >
                                  Top crop
                                  <p
                                    style={{
                                      color: color.tableRowHeading,
                                    }}
                                  >
                                    {RegionData.top_crop}

                                    {/* <span
                                  style={{
                                    color: color.taxtHeadingLight,
                                  }}
                                >
                                  (
                                  {formatInIndianRupees(
                                    data?.last_month_data["Top machine"].scan
                                  )}{" "}
                                  scans)
                                </span> */}
                                  </p>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>

                      {/* total and accepted bar graph */}
                      <div className="m-top-bottom mt-1">
                        <div className="card-Div ">
                          <div
                            className="bar-chart-heading pt-2 ps-2"
                            style={{ color: color.taxtHeading }}
                          >
                            Monthly Assessment
                          </div>
                          {/* <div className=" d-flex justify-content-center"> */}
                          <div
                            className=" total-scans-bar-chart-main-div"
                            // style={{ maxWidth: "1400px" }}
                          >
                            {/* total $ accepted bar chart */}
                            <div className="barChartDiv m-auto w-100 overflow-auto style-scrollbar">
                              <div style={{ minWidth: "900px" }}>
                                <ResponsiveContainer width="100%" height={350}>
                                  <AreaChart
                                    data={transformData(
                                      RegionData?.graph_data || []
                                    )}
                                    margin={{
                                      top: 10,
                                      right: 0,
                                      left: 5,
                                      bottom: 10,
                                    }}
                                  >
                                    <defs>
                                      <linearGradient
                                        id="colorAccepted"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                      >
                                        <stop
                                          offset="0%"
                                          stopColor={color.green}
                                          stopOpacity={1}
                                        />
                                        <stop
                                          offset="100%"
                                          stopColor={color.green}
                                          stopOpacity={0.05}
                                        />
                                      </linearGradient>
                                      <linearGradient
                                        id="colorTotal"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                      >
                                        <stop
                                          offset="0%"
                                          stopColor={color.secondary}
                                          stopOpacity={1}
                                        />
                                        <stop
                                          offset="100%"
                                          stopColor={color.secondary}
                                          stopOpacity={0.05}
                                        />
                                      </linearGradient>
                                    </defs>
                                    <CartesianGrid
                                      strokeDasharray="0"
                                      vertical={false}
                                      stroke="#4c4e6614"
                                    />
                                    <XAxis
                                      dataKey="date"
                                      angle={0}
                                      tick={{
                                        fill: color.taxtHeading,
                                        fontSize: 12,
                                      }}
                                      axisLine={false}
                                      tickLine={{ display: "none" }}
                                      dx={0}
                                      dy={15}
                                    />
                                    <YAxis
                                      domain={["auto", "auto"]}
                                      tick={{
                                        fill: color.taxtHeading,
                                        fontSize: 11,
                                      }}
                                      axisLine={false}
                                      label={{
                                        value: "Scans",
                                        angle: -90,
                                        position: "insideLeft",
                                      }}
                                      tickFormatter={formatYAxis}
                                      tickLine={{ display: "none" }}
                                    />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend
                                      layout="horizontal"
                                      verticalAlign="top"
                                      align="right"
                                      iconSize={10}
                                      iconType="circle"
                                      content={(props) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                          }}
                                        >
                                          {props.payload.map((entry, index) => (
                                            <div
                                              key={`item-${index}`}
                                              style={{
                                                marginRight: 10,
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                              onMouseEnter={() =>
                                                handleMouseEnterLegendMonth(
                                                  entry
                                                )
                                              }
                                              onMouseLeave={() =>
                                                handleMouseLeaveLegendMonth(
                                                  entry
                                                )
                                              }
                                            >
                                              <div
                                                style={{
                                                  width: 10,
                                                  height: 10,
                                                  borderRadius: "25%",
                                                  backgroundColor:
                                                    entry.value.toLowerCase() ===
                                                    "accepted"
                                                      ? color.green
                                                      : color.secondary,
                                                  marginRight: 5,
                                                }}
                                              />
                                              <span
                                                style={{
                                                  color:
                                                    entry.value.toLowerCase() ===
                                                    "accepted"
                                                      ? color.green
                                                      : color.secondary,
                                                }}
                                              >
                                                {(entry.value.toLowerCase() ===
                                                  "total" &&
                                                  entry.value + " Scans") ||
                                                  (entry.value.toLowerCase() ===
                                                    "accepted" &&
                                                    entry.value + " Scans") ||
                                                  entry.value}
                                              </span>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    />
                                    {RegionData.show_accepted && (
                                      <Area
                                        type="monotone"
                                        dataKey="accepted"
                                        stroke={color.green}
                                        fill="url(#colorAccepted)"
                                        stackId="stack"
                                        fillOpacity={
                                          monthAssementsOpacity.accepted
                                        }
                                        activeDot={{ r: 5 }}
                                      />
                                    )}
                                    <Area
                                      type="monotone"
                                      dataKey="total"
                                      stroke={color.secondary}
                                      fill="url(#colorTotal)"
                                      stackId="stack"
                                      fillOpacity={monthAssementsOpacity.total}
                                      activeDot={{ r: 5 }}
                                    />
                                  </AreaChart>
                                </ResponsiveContainer>
                              </div>
                            </div>
                            <div className="barChartDivDesc">
                              <div
                                className="d-flex flex-row justify-content-evenly"
                                style={{ height: "100%" }}
                              >
                                {/* total scans */}
                                <p className="m-0">
                                  <div className="card-Div bar-chart-desc-div">
                                    {/* <img
                          src={TotalMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}

                                    <div className=" card-div-icon-div m-auto">
                                      <div
                                        style={{
                                          backgroundColor:
                                            color.iconBackgroundBlue,
                                          borderRadius:
                                            pageStyling.imageBackgroundDivRadius,
                                          height:
                                            pageStyling.monthlyScanDivDescIconBackgroundHeight,
                                          width:
                                            pageStyling.monthlyScanDivDescIconBackgroundWidth,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        className="ms-auto "
                                      >
                                        <img
                                          src={TotalMostScansImg}
                                          alt="All Assesment img"
                                          style={{
                                            width:
                                              pageStyling.monthlyScanDivDescIconWidth,
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <span className="bar-chart-num">
                                      {RegionData?.month_max_total}
                                    </span>
                                    <p className="bar-chart-heading ">
                                      Most Scans
                                    </p>
                                  </div>
                                </p>
                                <div className="barChartDivDescVerticalSeperator"></div>
                                {/* Avg Total Scans */}
                                <p className="m-0">
                                  <div className="card-Div bar-chart-desc-div">
                                    {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                          /> */}

                                    <div className=" card-div-icon-div m-auto">
                                      <div
                                        style={{
                                          backgroundColor:
                                            color.iconBackgroundBlue,
                                          borderRadius:
                                            pageStyling.imageBackgroundDivRadius,
                                          height:
                                            pageStyling.monthlyScanDivDescIconBackgroundHeight,
                                          width:
                                            pageStyling.monthlyScanDivDescIconBackgroundWidth,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        className="ms-auto "
                                      >
                                        <img
                                          // src={TotalMostScansImg}
                                          src={AvgMostScansImg}
                                          alt="All Assesment img"
                                          style={{
                                            width:
                                              pageStyling.monthlyScanDivDescIconWidth,
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <span className="bar-chart-num">
                                      {formatInIndianRupees(
                                        RegionData?.avg_scans_last_12_month
                                      )}
                                    </span>
                                    <p className="bar-chart-heading ">
                                      Avg Total Scans
                                    </p>
                                  </div>
                                </p>
                              </div>
                              {RegionData?.show_accepted && (
                                <>
                                  {/* horizontal line seprator */}
                                  <div className="barChartDivDescHorizontalSeperator" />
                                  {/* Most accepted scans  */}
                                  <div
                                    className="d-flex flex-row justify-content-evenly"
                                    style={{ height: "100%" }}
                                  >
                                    <p className="m-0">
                                      <div className="card-Div bar-chart-desc-div">
                                        {/* <img
                              src={TotalMostAcceptedScansImg}
                              alt="TotalMostScansImg"
                              className="barChartGraphDesc"
                              /> */}

                                        <div className=" card-div-icon-div m-auto">
                                          <div
                                            style={{
                                              backgroundColor:
                                                color.iconBackgroundgreen,
                                              borderRadius:
                                                pageStyling.imageBackgroundDivRadius,
                                              height:
                                                pageStyling.monthlyScanDivDescIconBackgroundHeight,
                                              width:
                                                pageStyling.monthlyScanDivDescIconBackgroundWidth,
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            className="ms-auto "
                                          >
                                            <img
                                              // src={TotalMostScansImg}
                                              src={TotalMostAcceptedScansImg}
                                              alt="All Assesment img"
                                              style={{
                                                width:
                                                  pageStyling.monthlyScanDivDescIconWidth,
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <span className="bar-chart-num">
                                          {RegionData?.month_max_accepted}
                                        </span>
                                        <p className="bar-chart-heading ">
                                          Most Accepted Scans
                                        </p>
                                      </div>
                                    </p>
                                    <div className="barChartDivDescVerticalSeperator"></div>
                                    <p className="m-0">
                                      <div className="card-Div bar-chart-desc-div">
                                        {/* <img
                              alt="TotalMostScansImg"
                              className="barChartGraphDesc"
                              /> */}

                                        <div className=" card-div-icon-div m-auto">
                                          <div
                                            style={{
                                              backgroundColor:
                                                color.iconBackgroundgreen,
                                              borderRadius:
                                                pageStyling.imageBackgroundDivRadius,
                                              height:
                                                pageStyling.monthlyScanDivDescIconBackgroundHeight,
                                              width:
                                                pageStyling.monthlyScanDivDescIconBackgroundWidth,
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            className="ms-auto "
                                          >
                                            <img
                                              src={AvgMostAcceptedScansImg}
                                              // src={TotalMostScansImg}
                                              alt="All Assesment img"
                                              style={{
                                                width:
                                                  pageStyling.monthlyScanDivDescIconWidth,
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <span className="bar-chart-num">
                                          {formatInIndianRupees(
                                            RegionData?.avg_accepted_scans_last_12_month
                                          )}
                                        </span>
                                        <p className="bar-chart-heading ">
                                          Avg Accepted Scans
                                        </p>
                                      </div>
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                      {/* accepted radial bar chart && crop assesment*/}
                      {false && (
                        <div
                          className={` cropAssesmentMainDiv m-top-bottom ${
                            islageScreen && "flex-wrap"
                          }`}
                        >
                          {/* Crops Pie chart */}
                          {false && (
                            <div
                              className="flex-grow-1"
                              style={{
                                minWidth: isSmallScreen ? "250px" : "450px",
                              }}
                            >
                              <div className="card-Div d-flex flex-column h-100">
                                <div>
                                  <div
                                    className="bar-chart-heading pt-2 ps-2"
                                    style={{ color: color.taxtHeading }}
                                  >
                                    Crop Wise Assesment
                                  </div>
                                  {/* total and accepted bar graph bar */}
                                  <div
                                    className={`d-flex ${
                                      isSmallScreen ? "flex-column" : "flex-row"
                                    }`}
                                  >
                                    <div className="flex-grow-1">
                                      <div className="card-Div border-none shadow-none">
                                        <div className="overflow-auto style-scrollbar">
                                          {/* <div
                            className="last-10-days-bar-graph "
                            style={{ width: "250px", minWidth: "fit-content" }}
                          > */}
                                          <div
                                            className=""
                                            style={{
                                              width: "100%",
                                              // maxWidth: "600px",
                                              overflow: "visible",
                                            }}
                                          >
                                            <ResponsiveContainer
                                              width="100%"
                                              height={350}
                                              sx={{ overflow: "visible" }}
                                            >
                                              <ComposedChart
                                                data={getPieChartCropData(
                                                  RegionData?.crops_data
                                                )}
                                                //   width={500}
                                                //   height={400}
                                                layout="vertical"
                                                margin={{
                                                  top: 20,
                                                  right: 15,
                                                  bottom: 0,
                                                  left: 0,
                                                }}
                                              >
                                                <CartesianGrid
                                                  horizontal={false}
                                                  strokeDasharray="10"
                                                  //   stroke="#4c4e6614"
                                                  stroke={color.navBorderBottom}
                                                  // stroke="none" // Set stroke to "none" to remove grid lines
                                                />
                                                <XAxis
                                                  type="number"
                                                  //   domain={["auto", "auto"]}
                                                  interval={0}
                                                  angle={
                                                    isSmallScreen ? -20 : 0
                                                  }
                                                  // textAnchor="end"
                                                  tick={{
                                                    fill: color.taxtHeading,
                                                    fontSize: 12,
                                                  }}
                                                  axisLine={false}
                                                  //   tickLine={{ display: "none" }}
                                                  tickLine={false}
                                                  // tickFormatter={(value) => (value < 0 ? -value : value)} // Ensure positive ticks
                                                  tickFormatter={formatYAxis} // Ensure positive ticks
                                                />
                                                <YAxis
                                                  dataKey="name"
                                                  type="category"
                                                  //   scale="band"
                                                  tick={{
                                                    fill: color.taxtHeading,
                                                    fontSize: 11,
                                                  }}
                                                  axisLine={false}
                                                  //   label={{
                                                  //     value: "Scans",
                                                  //     angle: -90,
                                                  //     position: "insideLeft",
                                                  //   }}
                                                  tickLine={{ display: "none" }}
                                                />
                                                <Tooltip
                                                  content={<CustomTooltip />}
                                                  cursor={false}
                                                />

                                                <Bar
                                                  dataKey="value"
                                                  //   fill={color.secondary}
                                                  stackId="stack"
                                                  barSize={25}
                                                  radius={[0, 5, 5, 0]}
                                                  minPointSize={20}
                                                  // onMouseEnter={handleMouseEnter}
                                                  // onMouseLeave={handleMouseLeave}
                                                >
                                                  {getPieChartCropData(
                                                    RegionData?.crops_data
                                                  )?.map((entry, index) => (
                                                    <Cell
                                                      key={`cell-${index}`}
                                                      fill={
                                                        index === activeIndex
                                                          ? color.iconBackgroundBlue
                                                          : entry.fill
                                                      }
                                                      fillOpacity={
                                                        entry.value === 0
                                                          ? 0
                                                          : opacity.accepted
                                                      }
                                                      onMouseEnter={() =>
                                                        handleMouseEnter(index)
                                                      }
                                                      onMouseLeave={
                                                        handleMouseLeave
                                                      }
                                                    />
                                                  ))}
                                                  {/* <LabelList
                                      dataKey="name"
                                      position="top"
                                      content={(props) => {
                                        const { x, y, width, value } = props;
                                        console.log("value :", value)
                                        return (
                                          <text
                                            // x={x + width / 2}
                                            x={x + width / 2}
                                            y={y + 15}
                                            fill={color.green}
                                            textAnchor="middle"
                                            dominantBaseline="middle"
                                          >
                                            {value}
                                          </text>
                                        );
                                      }}
                                    /> */}
                                                </Bar>
                                                {/* {RegionData.show_accepted && (
                              <Line
                                type="natural"
                                dataKey="accepted"
                                stroke={color.green}
                                strokeWidth={4}
                                dot={{
                                  stroke: color.green,
                                  strokeWidth: 2,
                                  r: 6,
                                  opacity: opacity.total,
                                }}
                                activeDot={{
                                  stroke: color.textWhiteColor,
                                  strokeWidth: 2,
                                  r: 6,
                                }}
                                strokeOpacity={opacity.total}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                              />
                            )} */}
                                              </ComposedChart>
                                            </ResponsiveContainer>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="card-Div border-none shadow-none">
                                      <div className="carousel-region-div my-4 mb-5 w-100">
                                        <div className="d-flex align-items-center flex-column flex-grow-1">
                                          <div className="crop-bar-graph-desc">
                                            {getPieChartCropData(
                                              RegionData?.crops_data
                                            ).map((cropDesc, index) => {
                                              //   console.log("regionDesc : ", cropDesc);

                                              return (
                                                <div
                                                  className="top-div d-flex flex-row align-item-center "
                                                  style={{
                                                    height: "auto",
                                                    minWidth: "150px",
                                                  }}
                                                >
                                                  {/* <div className="card-div-icon-div top-div-circular-progress d-flex align-items-start justify-content-center mx-2"> */}
                                                  <div
                                                    style={{
                                                      backgroundColor:
                                                        cropDesc.fill,
                                                      borderRadius:
                                                        pageStyling.imageBackgroundDivRadius,
                                                      height: "15px",
                                                      width: "15px",
                                                      margin: "3px 10px",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {/* <img
                                          src={allTicketsImg}
                                          alt="All Assesment img"
                                          className=""
                                          style={{
                                            width: pageStyling.IconWidth,
                                          }}
                                        /> */}
                                                  </div>
                                                  {/* </div> */}
                                                  {/* circular progress bar div desc */}
                                                  <div
                                                    className="top-div-circular-progress-desc flex-grow-1"
                                                    style={{
                                                      color: color.taxtHeading,
                                                    }}
                                                  >
                                                    {cropDesc.name}
                                                    <p
                                                      style={{
                                                        color:
                                                          color.tableRowHeading,
                                                      }}
                                                    >
                                                      {formatInIndianRupees(
                                                        cropDesc.value
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="flex-grow-1">
                            <div
                              className={`d-flex flex-row   w-100 ${
                                isSmallScreen && "flex-wrap"
                              }`}
                              style={{ gap: "20px" }}
                            >
                              {/* accepted radial bar chart */}
                              {RegionData.show_accepted && (
                                <div className="w-100">
                                  <div className="card-Div m-0 h-100">
                                    <div>
                                      {/* <div
                  className="bar-chart-heading pt-2 ps-2"
                  style={{ color: color.taxtHeading }}
                >
                  Accepted Assesments
                </div> */}

                                      {/* <div className="d-flex flex-column  gap-2 ">
                  <p
                    className="bar-chart-heading m-0 p-0 d-flex align-items-center text-wrap  "
                    style={{
                      fontSize: "1rem",
                      color: color.taxtHeadingLight,
                    }}
                  ></p>
                </div>

                <div
                  className="bar-chart-heading pt-2 ps-2"
                  style={{ color: color.taxtHeading }}
                >
                  Total Scans
                  <p style={{ fontSize: "0.95rem", marginTop: "5px" }}>
                    <span
                      className="bar-chart-num"
                      style={{
                        marginRight: "5px",
                        color: color.taxtHeading,
                      }}
                    >
                      {formatInIndianRupees(RegionData?.total_assessments)}
                    </span>
                  </p>
                </div> */}
                                      {/* circular progress bar */}

                                      <div
                                        id="chart"
                                        className="d-flex align-items-center justify-content-center position-relative h-100 mt-2"
                                        style={{
                                          minWidth: isSmallScreen
                                            ? "240px"
                                            : "500px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="d-flex flex-column gap-0 align-items-center justify-content-center position-relative">
                                          <CircularProgressbarWithChildren
                                            value={
                                              sendCropDataToURL[
                                                "Accepted Percent"
                                              ]
                                            }
                                            text={`${sendCropDataToURL[
                                              "Accepted Percent"
                                            ].toFixed(2)}%`}
                                            circleRatio={0.7}
                                            strokeWidth={10}
                                            // styles={buildStyles({
                                            //   strokeLinecap: "butt",
                                            // })}
                                            styles={{
                                              trail: {
                                                strokeLinecap: "butt",
                                                transform: "rotate(-126deg)",
                                                transformOrigin:
                                                  "center center",
                                                stroke: color.iconBackgroundred,
                                              },
                                              path: {
                                                strokeLinecap: "butt",
                                                transform: "rotate(-126deg)",
                                                transformOrigin:
                                                  "center center",
                                                stroke: color.green,
                                              },
                                              text: {
                                                fill: color.tableRowHeading,
                                                fontWeight: 800,
                                                fontSize: "1.05rem",
                                                dominantBaseline: "middle", // Align text vertically center
                                                textAnchor: "middle", // Align text horizontally center
                                              },
                                            }}
                                          >
                                            <RadialSeparators
                                              count={100}
                                              style={{
                                                background: "#fff",
                                                width: "3px",
                                                // This needs to be equal to props.strokeWidth
                                                height: `${10}%`,
                                              }}
                                            />
                                          </CircularProgressbarWithChildren>
                                          {/* <div
                        className="bar-chart-heading"
                        style={{
                          position: "absolute",
                          bottom: "20px",
                          fontSize: "1.35rem",
                          fontWeight: 600,
                        }}
                      >
                        Accepted Scans
                      </div> */}
                                        </div>
                                      </div>
                                      {/* accepted and rejected */}
                                      <div>
                                        {/* total assements */}
                                        <div
                                          className="d-flex  flex-row flex-wrap z-3 "
                                          style={{ marginTop: "-40px" }}
                                        >
                                          {/* accepted scans */}

                                          <div
                                            className="col-5"
                                            style={{
                                              padding: "0px 0px 0px 8px",
                                            }}
                                          >
                                            <div className="d-flex flex-column  w-100">
                                              {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}
                                              <div className="d-flex flex-column  gap-2 ">
                                                <div className="card-div-icon-div ">
                                                  <div
                                                    style={{
                                                      backgroundColor:
                                                        color.iconBackgroundgreen,
                                                      borderRadius:
                                                        pageStyling.imageBackgroundDivRadius,
                                                      height:
                                                        pageStyling.iconBackgroundHeight,
                                                      width:
                                                        pageStyling.iconBackgroundWidth,
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                    className="me-auto "
                                                  >
                                                    <img
                                                      src={accepted_scans}
                                                      alt="Accepted img"
                                                      // style={{ width: "33px" }}
                                                    />
                                                  </div>
                                                </div>
                                                <p
                                                  className="bar-chart-heading m-0 p-0 d-flex align-items-center text-wrap  "
                                                  style={{
                                                    fontSize: "1rem",
                                                    color:
                                                      color.taxtHeadingLight,
                                                  }}
                                                >
                                                  Accepted Scans
                                                </p>
                                              </div>
                                              <span
                                                className="bar-chart-num"
                                                style={{
                                                  fontSize: "1.5rem",
                                                  color: color.taxtHeading,
                                                }}
                                              >
                                                {sendCropDataToURL[
                                                  "Accepted Percent"
                                                ].toFixed(2)}
                                                %
                                              </span>
                                              <span
                                                className="bar-chart-num mt-1"
                                                style={{
                                                  fontSize: "1rem",
                                                  color:
                                                    color?.taxtHeadingLight,
                                                }}
                                              >
                                                {formatInIndianRupees(
                                                  sendCropDataToURL[
                                                    "Total Accepted"
                                                  ]
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          {/* seperator */}
                                          <div className="d-flex flex-column col-2 align-items-center justify-content-center">
                                            {" "}
                                            <div
                                              style={{
                                                width: "1px",
                                                backgroundColor: "#4c4e6433",
                                                margin: "auto 10px",
                                                height: "40px",
                                                zIndex: "20",
                                              }}
                                            ></div>
                                            <div
                                              style={{
                                                borderRadius: "50%",
                                                backgroundColor: "#4c4e6433",
                                                padding: ".4rem .45rem",
                                                margin: "5px 0px",
                                                fontSize: "0.7rem",
                                              }}
                                            >
                                              <p className="mb-0">VS</p>
                                            </div>
                                            <div
                                              style={{
                                                width: "1px",
                                                backgroundColor: "#4c4e6433",
                                                margin: "auto 10px",
                                                height: "40px",
                                              }}
                                            ></div>
                                          </div>
                                          {/* rejected scans */}
                                          <div className="col-5 shadow-none pe-2">
                                            <div className="d-flex flex-column align-items-end w-100">
                                              {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}
                                              <div className="d-flex flex-column  gap-2 align-items-center">
                                                <div className="card-div-icon-div ms-auto">
                                                  <div
                                                    style={{
                                                      backgroundColor:
                                                        color.iconBackgroundred,
                                                      borderRadius:
                                                        pageStyling.imageBackgroundDivRadius,
                                                      height:
                                                        pageStyling.iconBackgroundHeight,
                                                      width:
                                                        pageStyling.iconBackgroundWidth,
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                    className="ms-auto "
                                                  >
                                                    <img
                                                      src={rejected_scans}
                                                      alt="rejected_scans img"
                                                      // style={{ width: "33px" }}
                                                    />
                                                  </div>
                                                </div>

                                                <p
                                                  className="bar-chart-heading m-0 p-0 text-wrap text-end  "
                                                  style={{
                                                    fontSize: "1rem",
                                                    color:
                                                      color.taxtHeadingLight,
                                                  }}
                                                >
                                                  Rejected Scans
                                                </p>
                                              </div>
                                              <span
                                                className="bar-chart-num"
                                                style={{
                                                  fontSize: "1.5rem",
                                                }}
                                              >
                                                {(
                                                  100 -
                                                  sendCropDataToURL[
                                                    "Accepted Percent"
                                                  ]
                                                ).toFixed(2)}
                                                %
                                              </span>
                                              <span
                                                className="bar-chart-num mt-1"
                                                style={{
                                                  fontSize: "1rem",
                                                  color: color.taxtHeadingLight,
                                                }}
                                              >
                                                {formatInIndianRupees(
                                                  sendCropDataToURL[
                                                    "Total Accepted"
                                                  ] -
                                                    sendCropDataToURL[
                                                      "Accepted Percent"
                                                    ]
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        {/* progress bar accepeted scans && rejected scans */}
                                        {/* <div style={{ padding: "20px 5px 10px 5px" }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={RegionData?.percentage_accepted}
                      colorAccept={color?.secondary}
                      colorReject={color?.green}
                              /> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {/* data tables */}
                      {(showTableData?.district ||
                        showTableData?.machine ||
                        showTableData?.region) && (
                        //   <div className="m-top-bottom mb-2" style={{ marginTop: "33px" }}>
                        <div
                          className="m-top-bottom"
                          style={{ marginTop: "-10px" }}
                        >
                          {/* {console.log("showTableData : ", showTableData)} */}
                          <div
                            className="card-Div px-0 pb-0"
                            style={{
                              backgroundColor: color.primary,
                              border: "none",
                              boxShadow: "none",
                            }}
                          >
                            <div
                              className="bar-chart-heading pt-2 ps-0 mb-3"
                              style={{ color: color.taxtHeading }}
                            >
                              {/* Data Table */}
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "start",
                                  // "& > *": {
                                  //   m: 1,
                                  // },
                                }}
                              >
                                <ButtonGroup
                                  variant="outlined"
                                  aria-label="Basic button group"
                                  sx={{
                                    display: "flex",
                                    gap: "8px", // Add the gap between buttons
                                    "& .MuiButton-outlined": {
                                      color: color.tableRowHeading,
                                      border: "none",
                                      fontFamily: "Open-Sans-Medium",
                                      // borderColor: color.secondary,
                                      borderRadius: "5px",
                                      "&:hover": {
                                        color: color.secondary,
                                        borderColor: color.secondary,
                                        backgroundColor:
                                          color.iconBackgroundBlue,
                                      },
                                    },
                                  }}
                                >
                                  {RegionData?.region_table_data && (
                                    <Button
                                      onClick={() =>
                                        handleButtonClick("region")
                                      }
                                      sx={{
                                        backgroundColor: showTableData.region
                                          ? color.iconBackgroundBlue
                                          : "white",
                                        fontSize: "0.85rem",
                                        padding: "4px 10px",
                                        //   color: showTableData.district ? "white" : "red",
                                        textTransform: "capitalize",
                                        "&:hover": {
                                          border: "none",
                                          backgroundColor: showTableData.region
                                            ? "darkred"
                                            : color.iconBackgroundBlue,
                                        },
                                      }}
                                    >
                                      Regions
                                    </Button>
                                  )}
                                  {RegionData?.district_table_data && (
                                    <Button
                                      onClick={() =>
                                        handleButtonClick("district")
                                      }
                                      sx={{
                                        backgroundColor: showTableData.district
                                          ? color.iconBackgroundBlue
                                          : "white",
                                        fontSize: "0.85rem",
                                        padding: "5px 10px",
                                        //   color: showTableData.machine ? "white" : "red",
                                        textTransform: "capitalize",
                                        "&:hover": {
                                          border: "none",
                                          backgroundColor:
                                            showTableData.district
                                              ? "darkred"
                                              : color.iconBackgroundBlue,
                                        },
                                      }}
                                    >
                                      Districts
                                    </Button>
                                  )}
                                  {RegionData?.machine_table_data && (
                                    <Button
                                      onClick={() =>
                                        handleButtonClick("machine")
                                      }
                                      sx={{
                                        backgroundColor: showTableData.machine
                                          ? color.iconBackgroundBlue
                                          : "white",
                                        fontSize: "0.85rem",
                                        padding: "5px 10px",
                                        //   color: showTableData.machine ? "white" : "red",
                                        textTransform: "capitalize",
                                        "&:hover": {
                                          border: "none",
                                          backgroundColor: showTableData.machine
                                            ? "darkred"
                                            : color.iconBackgroundBlue,
                                        },
                                      }}
                                    >
                                      Machines
                                    </Button>
                                  )}
                                </ButtonGroup>
                              </Box>
                            </div>
                            <FirstPageDataTable
                              columns={TableDataHeaders}
                              rows={TabelDataRows}
                              RegionData={RegionData}
                              color={color}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )) || (
                    <div className="container no-ticket-found-div text-center count_heading_div">
                      <div className="d-flex align-items-center justify-content-center flex-column">
                        {/* <i
                  className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"
                  style={{ color: color.secondary }}
                ></i> */}
                        <img src={noDataIMG} />
                        <p>No Data Found</p>
                      </div>
                    </div>
                  ))) ||
                (showOverviewModalError.status && (
                  <Error
                    code={showOverviewModalError.status}
                    msg={showOverviewModalError.msg}
                  />
                )) || <Error code={500} />}
            </Typography>
          </Box>
        </Modal>
      }
    </>
  );
};

export default CropModal;

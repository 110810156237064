import React, { useContext, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import "../../assets/styles/CardModal.css";
import ContextState from "../contextApi/ContextState";
import { useSelector } from "react-redux";
import ModalError from "../error/ModalError";
import { Box, FormControl, Tooltip, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Modal } from "@material-ui/core";
import { MenuItem } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import FilesUpload from "../RaiseTicketForm/FilesUpload";
import { formatDateString } from "../modals/VisitDetails";
import close from "../../assets/images/close.svg";
import { BeatLoader } from "react-spinners";
import { RiAddBoxFill } from "react-icons/ri";
export default function VisitGeneralPage({
  data,
  reloadGeneralPage,
  reloadAllTicketsData,
  refreshBtn,
  setRefreshBtn,
  setRefreshLoading,
}) {
  const modalstyle = {
    position: "relative",
    zIndex: "1111",
    right: "0",
    top: "3%",
    left: "0",
    margin: "  auto",
    overflowY: "auto",
    height: "95vh",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    maxWidth: "1150px",
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const { visit_id } = data;

  // const [stateO, setStateO] = useState(false)

  // state for button spinner
  const [loading, setLoading] = useState(false);

  const [reloadData, setReloadData] = useState(false);

  // state for each visit details
  const [visit_info, setVisit_info] = useState(null);

  // options list for  support_persion & parts_replaced
  const [backend_lists, setBackend_list] = useState({});

  const [edit_details, setEditDetails] = useState({
    location: "",
    support_person: [],
    total_expenses: "",
    parts_replaced: [],
    distance: null,
    remarks: "",
    start_date: null,
    end_date: null,
    attachments: [],
    product_serial_data: [],
    isAddSerial: false,
  });

  // Temp state to hold the current entry
  const [tempSerialData, setTempSerialData] = useState({
    hardware: "",
    old_serial: "",
    new_serial: "",
  });

  // Handle input change for temp state
  const handleTempInputChange = (e) => {
    const { name, value } = e.target;
    setTempSerialData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Function to add the temp data to main product_serial_data array
  const addSerialData = () => {
    setEditDetails((prev) => ({
      ...prev,
      product_serial_data: [
        ...(prev?.product_serial_data || []),
        tempSerialData,
      ],
    }));

    // Clear tempSerialData after adding
    setTempSerialData({
      hardware: "",
      old_serial: "",
      new_serial: "",
    });
    setEditDetails((prev) => ({
      ...prev,
      isAddSerial: false,
    }));
  };

  // To delete theentry
  const handleDeleteElement = (index) => {
    setEditDetails((prev) => ({
      ...prev, // Keep other state properties
      product_serial_data: prev.product_serial_data.filter(
        (_, i) => i !== index
      ),
    }));
  };

  // console.log("edit_details------>", edit_details);
  const {
    fetchApi,
    formatInIndianRupees,
    openSnackbar,
    showModalError,
    showModalErrorPage,
    modalControllerRef,
  } = useContext(ContextState);

  const filterData = useSelector(
    (state) => state?.userFilters?.data?.data || []
  );

  const [loadingModalVisiblity, setLoadingModalVisiblity] = useState(false);
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };
  // fetching of each visit_ticket_info by visit_id
  let is_visit_data_mounted;
  const fetch_visit_ticket_info = async (is_visit_data_mounted) => {
    try {
      const payload = {
        visit_id: visit_id,
        info_type: "general",
      };

      if (refreshBtn) {
        setRefreshLoading(true);
      }

      const visit_ticket_data = await fetchApi(
        "get_visit_ticket_info",
        "POST",
        payload,
        true,
        1
      );

      if (visit_ticket_data.statusCode === 200) {
        showModalErrorPage(null, null);
        // setVisit_info(visit_ticket_data?.data.gen_details || {});
        setVisit_info((prevState) => ({
          ...prevState,
          gen_details: visit_ticket_data?.data.gen_details,
          hardware_serial_data: visit_ticket_data?.data.hardware_serial_data,
          attachments: visit_ticket_data?.data?.attachments,
        }));
      } else if (
        visit_ticket_data.statusCode === 500 ||
        visit_ticket_data.statusCode === 401
      ) {
        showModalErrorPage(visit_ticket_data.statusCode, visit_ticket_data.msg);
      } else {
        showModalErrorPage(visit_ticket_data.statusCode, visit_ticket_data.msg);
      }
    } catch (error) {
      if (modalControllerRef.current) {
        openSnackbar(
          "Your data is on its way. Thank you for your patience.",
          "warning"
        );
      } else {
        openSnackbar("Some Error Occured!", "error");
      }
    } finally {
      showLoadingModal(false);
      setRefreshLoading(false);
      setRefreshBtn(false);
    }
  };

  useEffect(() => {
    // console.log("bjdcj");
    let isMounted = true;
    showLoadingModal(true);
    fetch_visit_ticket_info(isMounted);

    return () => {
      isMounted = false;
    };
  }, [reloadData, reloadGeneralPage]);

  // modal state
  const [showVisitDetailsModal, setShowVisitDetailsModal] = useState(false);
  const handleCloseVisitDetailsModal = (e, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setShowVisitDetailsModal(false);
  };

  const deleteSerialData = async (serial_id) => {
    try {
      const payload = {
        serial_id: serial_id,
        ticket_id: visit_info?.gen_details?.ticket_id,
      };

      const jsonData = await fetchApi(
        "delete_serial_id",
        "POST",
        payload,
        true,
        1
      );

      if (jsonData.statusCode === 200) {
        openSnackbar("Successfully deleted serial key data!", "success");
        handleReloadData();
      } else {
        openSnackbar("Internal Server Error!", "error");
      }
    } catch {
      openSnackbar("Internal Server Error!", "error");
    }
  };
  // handle save changes button
  const handle_edit_Details = () => {
    // console.log("edit btn clicked");
    setEditDetails({
      support_person: visit_info?.gen_details?.support_person?.map(
        (support_persons) => support_persons.name
      ),

      location: visit_info?.gen_details?.machine_code?.location,
      start_date: dayjs(formatDateString(visit_info?.gen_details?.start_date)),
      end_date: dayjs(formatDateString(visit_info?.gen_details?.end_date)),
      parts_replaced: visit_info?.gen_details?.parts_replaced?.map(
        (items) => items.part_replaced
      ),
      total_expenses: visit_info?.gen_details?.total_expense,
      remarks: visit_info?.gen_details?.remarks || "",
      distance: visit_info?.gen_details?.distance,
      attachments: [],
      ticket_id: visit_info?.gen_details?.ticket_id,
      product_serial_data: [],
      isAddSerial: false,
    });

    setBackend_list({
      support_persons_list: Object.values(filterData?.assignee_list)?.map(
        ({ name }) => name
      ),

      parts_replaced_list: Object.values(filterData?.hardware_list),
    });

    setShowVisitDetailsModal(!showVisitDetailsModal);

    // console.log("----->showVisitDetailsModal", showVisitDetailsModal);
  };

  // handle Date Change
  const handleDateChange = (name, value) => {
    const formattedDate = dayjs(value).format("YYYY-MM-DD");
    const parsedDate = dayjs(formattedDate); // Parse the formatted date back to a dayjs object

    setEditDetails((prevState) => ({
      ...prevState,
      [name]: parsedDate,
    }));
  };

  // handling onChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // to get ids for hardware while adding serial keys
  const transformProductSerialData = () => {
    const hardwareMapping = filterData?.hardware_list || {};
    console.log("hardware lsit dict", hardwareMapping);

    const transformedData = edit_details?.product_serial_data?.map((item) => {
      const hardwareId = Object.keys(hardwareMapping).find(
        (key) => hardwareMapping[key] === item.hardware
      );

      return {
        id: hardwareId || null,
        old: item.old_serial,
        new: item.new_serial,
      };
    });

    return transformedData;
  };

  // handle get machine code by location
  const get_support_persons_list = (persons_list) => {
    let updated_persons_list = [];
    for (let index = 0; index < persons_list?.length; index++) {
      let coming_name = persons_list[index];
      for (
        let index1 = 0;
        index1 < filterData?.assignee_list?.length;
        index1++
      ) {
        const element = filterData?.assignee_list?.[index1];
        const name = element["name"];
        const id = element["id"];
        if (name === coming_name) {
          updated_persons_list.push(id);
        }
      }
    }
    return updated_persons_list;
  };

  const get_parts_replaced_list = (check_parts) => {
    const ids = [];
    for (const key in filterData?.hardware_list) {
      if (
        Object.prototype.hasOwnProperty.call(filterData?.hardware_list, key)
      ) {
        const value = filterData?.hardware_list[key];
        if (check_parts.includes(value)) {
          ids.push(parseInt(key));
        }
      }
    }
    return ids;
  };

  // saving edit changes
  const saving_edit_changes = async () => {
    // state for loading spinner while loading

    const urlToFileArrayBuffer = async (fileUrl) => {
      try {
        // Fetch the file content as a blob
        const response = await fetch(fileUrl);
        const blob = await response.blob();

        // Read the blob as an array buffer
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.onerror = () => {
            reject(new Error("Failed to read file"));
          };
          reader.readAsArrayBuffer(blob);
        });
      } catch (error) {
        console.error("Error fetching file:", error);
        return null;
      }
    };

    const attachmentsData = await Promise.all(
      edit_details?.attachments.map(async (data) => {
        // console.log(data);
        // Fetch array buffer from URL
        const arrayBuffer = await urlToFileArrayBuffer(data.objectURL);
        // const base64String = btoa(String.fromCharCode.apply(null, arrayBuffer));
        const uint8Array = new Uint8Array(arrayBuffer);

        // console.log(arrayBuffer);
        // Convert array buffer to Blob
        const blob = new Blob([arrayBuffer]);
        // console.log("blob-", blob);
        // Return object with file name and FormData
        return {
          file_name: data.name,
          file_data: Array.from(uint8Array),
        };
      })
    );

    const payload = {
      visit_id: visit_info?.gen_details?.visit_id,
      ticket_id: visit_info?.gen_details?.ticket_id,
      start_date: formatDateString(edit_details?.start_date),
      end_date: formatDateString(edit_details?.end_date),
      total_expense: edit_details.total_expenses
        ? edit_details.total_expenses
        : null,
      distance: edit_details.distance ? edit_details.distance : null,
      remarks: edit_details.remarks,
      machine_code: visit_info?.gen_details?.machine_code?.code,
      support_person: get_support_persons_list(edit_details.support_person),
      parts_replaced: get_parts_replaced_list(edit_details.parts_replaced),
      attachment_data: attachmentsData,
      product_serial_data:
        edit_details?.product_serial_data?.length > 0
          ? transformProductSerialData()
          : [],
    };

    // for checking the date

    // if (!payload.start_date || !payload.end_date) {
    //   openSnackbar("Please select both start date and end date.");
    //   return;
    // }

    // // Check if end date is not before start date
    // if (dayjs(payload.end_date).isBefore(payload.start_date)) {
    //   openSnackbar("End date cannot be before start date.");
    //   return;
    // }
    setLoading(true);
    const response = await fetchApi(
      "edit_support_visit",
      "POST",
      payload,
      true,
      1
    );

    try {
      if (response.statusCode === 200) {
        // console.log("ticket edited succesfully");
        openSnackbar("visit details edited succesfully", "success");
        handleCloseVisitDetailsModal();
        handleReloadData();
        reloadAllTicketsData();
      } else {
        // console.error(
        //   "Some error occured while editing visit details",
        //   response.statusCode
        // );
        openSnackbar("some error occured while saving details", "error");
      }
    } catch (error) {
      // console.log("some error occured!");
      openSnackbar("some error occured while saving details", "error");
    }

    setLoading(false);
  };
  // to refresh page
  const handleReloadData = () => {
    setReloadData((prevState) => !prevState);
  };

  return (
    <>
      {(loadingModalVisiblity && (
        <div
          className="showSpinner "
          style={{ position: "relative", height: "88vh", width: "100%" }}
        >
          <BeatLoader color="#2D97D4" />
        </div>
      )) ||
        (!showModalError.status && visit_info && (
          <div className="general_page_main_details mt-3">
            <Box
              className="basic_details"
              component="form"
              sx={{
                // "& .MuiTextField-root": { m: 0, width: "45ch" },
                "@media (max-width: 2400px)": {
                  "& .MuiTextField-root": { width: "32%" },
                },
                "@media (max-width: 1145px)": {
                  "& .MuiTextField-root": { width: "48%" },
                },
                "@media (max-width: 768px)": {
                  "& .MuiTextField-root": { width: "48%" },
                },
                "@media (max-width: 600px)": {
                  "& .MuiTextField-root": { width: "100%" },
                },
              }}
              noValidate
              autoComplete="off"
            >
              {visit_info &&
                // key !== "remarks" &&
                Object.entries(visit_info?.gen_details).map(
                  ([key, value]) =>
                    key !== "remarks" && (
                      <div key={key} className="basic_details_elements">
                        <label
                          htmlFor={`outlined-read-only-input-${key}`}
                          className="basic_details_elements_label"
                        >
                          {key.replace(/_/g, " ")}{" "}
                          {/* Improve label readability */}
                        </label>

                        <div
                          id={`outlined-read-only-input-${key}`}
                          className="basic_details_elements_content"
                        >
                          {Array.isArray(value) ? (
                            // Handle arrays
                            value.length > 0 ? (
                              <span>
                                {key === "support_person"
                                  ? value.map((item) => item.name).join(", ")
                                  : key === "parts_replaced"
                                  ? value
                                      .map((item) => item.part_replaced)
                                      .join(", ")
                                  : ""}
                              </span>
                            ) : (
                              <span>-</span>
                            )
                          ) : key === "machine_code" ? (
                            // Format machine_code object
                            <span>{`${value.location} (${value.code})`}</span>
                          ) : (key === "start_date" ||
                              key === "end_date" ||
                              key === "creation_time") &&
                            value ? (
                            // Date formatting for specific fields
                            <span>
                              {new Date(value).toLocaleString("en-US", {
                                weekday: "short", // "Sat"
                                day: "2-digit", // "30"
                                month: "short", // "Mar"
                                year: "numeric", // "2024"
                              })}
                            </span>
                          ) : key === "total_expense" && value ? (
                            <span>Rs. {formatInIndianRupees(value)} </span>
                          ) : key === "distance" && value ? (
                            <span>{formatInIndianRupees(value)} Km</span>
                          ) : (
                            // Handle simple values
                            <span>{value || "-"}</span>
                          )}
                        </div>
                      </div>
                    )
                )}
            </Box>
            {/* remarks */}
            <div className=" mt-4">
              <label
                htmlFor={`outlined-read-only-input-remarks`}
                className="basic_details_elements_label"
              >
                Remarks
              </label>
              <div
                id={`outlined-read-only-input-remarks`}
                className="basic_details_elements_content"
              >
                {visit_info?.gen_details?.remarks}
              </div>
            </div>

            {/* Render the list of elements */}
            {(visit_info?.hardware_serial_data?.length > 0 && (
              <div
                className={`serial-keys-container my-4 ms-0 ${
                  isSmallScreen ? "" : "mx-5"
                }`}
              >
                <header className="pb-2 previous-comments-header">
                  Product Serial Keys
                </header>
                <div className="mt-2 data-variation-elements-container">
                  {visit_info?.hardware_serial_data?.map((element, index) => (
                    <div
                      key={element.id}
                      className="data-variation-elements d-flex justify-content-center"
                    >
                      <div
                        className="
                        "
                        style={{ color: "rgba(76, 78, 100, 0.8)" }}
                      >
                        <strong>Hardware:</strong> {element.hardware},{" "}
                        <strong>Old Serial:</strong> {element.old_serial},{" "}
                        <strong>New Serial:</strong> {element.new_serial}
                      </div>
                      <div className="ms-auto">
                        <div
                          className="dropdown ms-2  "
                          style={{ cursor: "pointer" }}
                        >
                          <Tooltip
                            data-html="true"
                            title={"Click to Delete this serial key data"}
                            arrow={true}
                          >
                            <i
                              className="fa-solid fa-ellipsis-vertical  "
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ padding: "0px 3px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            ></i>
                          </Tooltip>
                          {/* Centered dropdown */}
                          {/* </button> */}
                          <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                            <li>
                              <a
                                className="dropdown-item text-center delete-ticket-btn"
                                href="#"
                                onClick={(e) => {
                                  // e.stopPropagation();
                                  deleteSerialData(element.id);
                                }}
                              >
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )) || <></>}

            {/* Edit button */}
            <div className="edit_basic_details">
              <button
                type="button"
                className="btn btn-outline-primary markResolved_button"
                // disabled={visit_info["Status"] === "Resolved"}
                onClick={handle_edit_Details}
              >
                Edit Details
              </button>
            </div>

            <Modal
              open={showVisitDetailsModal}
              onClose={handleCloseVisitDetailsModal}
              style={{
                position: "fixed",
                // overflowY: "scroll",
                height: "100%",
                margin: "auto 20px",
              }}
            >
              <Box sx={modalstyle}>
                <Typography
                  className="d-flex justify-content-between p-3"
                  style={{ borderBottom: "1px solid rgb(199, 199, 199)" }}
                >
                  <h5 className="mb-0"> Edit Visit Details</h5>{" "}
                  <div
                    onClick={() => {
                      handleCloseVisitDetailsModal();
                    }}
                  >
                    <img
                      src={close}
                      alt="close"
                      style={{ width: "20px", cursor: "pointer" }}
                    />
                  </div>
                </Typography>

                <Typography className="p-3">
                  <div className="main_visit_modal_container">
                    <div className="visit_details_form ">
                      <div className="d-flex flex-column flex-lg-row align-items-center mb-lg-3">
                        <div className="p-2 w-100 modal_elements ">
                          <Autocomplete
                            multiple
                            name="support_person"
                            id="tags-outlined"
                            value={edit_details?.support_person || ""}
                            options={backend_lists?.support_persons_list || []}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            onChange={(e, selectedOptions) => {
                              setEditDetails((prev) => ({
                                ...prev,
                                support_person: selectedOptions.map(
                                  (option) => option
                                ),
                              }));
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Support Persons*"
                                placeholder="Support Persons"
                                sx={{
                                  "& .MuiButtonBase-input.Mui-disabled": {
                                    opacity: "0.6",
                                  },
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="p-2 w-100">
                          <FormControl
                            // sx={{ m: 1, width: "fullwidth" }}
                            size="medium"
                            fullWidth
                          >
                            <InputLabel id="location-label">
                              Location*
                            </InputLabel>
                            <Select
                              labelid="location-label"
                              value={edit_details?.location || ""}
                              // onChange={handleInputChange}
                              label="Location*"
                              name="location"
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                                },
                              }}
                            >
                              <MenuItem
                                key={
                                  visit_info?.gen_details?.machine_code
                                    ?.location
                                }
                                value={
                                  visit_info?.gen_details?.machine_code
                                    ?.location
                                }
                              >
                                {
                                  visit_info?.gen_details?.machine_code
                                    ?.location
                                }{" "}
                                - {visit_info?.gen_details?.machine_code?.code}
                                {/* ({get_machine_code(location)}) */}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      <div className="d-flex flex-column flex-lg-row mb-lg-3">
                        <div className="d-flex flex-row align-items-center w-100 w-lg-50 p-2">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            sx={{}}
                          >
                            <DemoContainer
                              components={["DatePicker"]}
                              sx={{
                                overflow: "unset",
                                padding: "0px",
                              }}
                            >
                              <DatePicker
                                label="Start Date*"
                                maxDate={
                                  edit_details?.end_date
                                    ? dayjs(edit_details?.end_date)
                                    : dayjs()
                                }
                                value={edit_details.start_date}
                                onChange={(date) =>
                                  handleDateChange("start_date", date)
                                }
                                className="min_width_unset"
                                sx={{
                                  "& .MuiStack-root>.MuiTextField-root": {
                                    minWidth: "unset",
                                  },
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                                  },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>

                          <i className="bi bi-arrow-left-right"></i>

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={["DatePicker"]}
                              sx={{ overflow: "unset", padding: "0px" }}
                            >
                              <DatePicker
                                className="min_width_unset"
                                label="End Date*"
                                minDate={dayjs(edit_details?.start_date)}
                                maxDate={dayjs()}
                                value={edit_details.end_date}
                                onChange={(date) =>
                                  handleDateChange("end_date", date)
                                }
                                sx={{
                                  "& .MuiStack-root>.MuiTextField-root": {
                                    minWidth: "unset",
                                  },
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                                  },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>

                        <div className="p-2 w-100 w-lg-50 modal_elements">
                          <Autocomplete
                            multiple
                            name="parts_replaced"
                            id="tags-outlined"
                            value={edit_details?.parts_replaced || ""}
                            options={backend_lists?.parts_replaced_list || []}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            onChange={(e, selectedOptions) => {
                              setEditDetails((prev) => ({
                                ...prev,
                                parts_replaced: selectedOptions?.map(
                                  (option) => option
                                ),
                              }));
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Parts Replaced*"
                                placeholder="Parts Replaced"
                              />
                            )}
                          />
                        </div>
                      </div>

                      {/* distance && expenses section */}
                      <div className="d-flex flex-column flex-lg-row mb-lg-3">
                        {/* distance */}
                        <div className="p-2 w-100">
                          {" "}
                          <TextField
                            value={edit_details.distance}
                            name="distance"
                            fullWidth
                            id="outlined-basic"
                            label="Distance (km)"
                            variant="outlined"
                            type="number"
                            onChange={handleInputChange}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                              },
                            }}
                          />
                        </div>
                        <div className="p-2 w-100">
                          {" "}
                          <TextField
                            value={edit_details.total_expenses}
                            name="total_expenses"
                            fullWidth
                            id="outlined-basic"
                            label="Total Expenses*"
                            variant="outlined"
                            type="number"
                            onChange={handleInputChange}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                              },
                            }}
                          />
                        </div>
                      </div>

                      {/* parts replaced & remarks desp */}

                      <div className="d-flex flex-column flex-lg-row mb-lg-3">
                        <div className="p-2 w-100">
                          <FormControl className="w-100">
                            <TextField
                              name="remarks"
                              label="Remarks*"
                              id="outlined-textarea"
                              onChange={handleInputChange}
                              rows={4}
                              multiline
                              value={edit_details.remarks}
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                                },
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>

                      {/* serial part starts from here  */}
                      <div
                        className="m-1" // You can keep the same class for styling
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          data-html="true"
                          title={
                            "Click here to add serial no of hardware parts"
                          }
                          arrow={true}
                        >
                          <RiAddBoxFill
                            onClick={() => {
                              setEditDetails((prev) => ({
                                ...prev,
                                isAddSerial: !prev.isAddSerial,
                              }));
                            }}
                            className="add-icon-hover"
                            style={{
                              color: "#6c757d", // Change color based on edit state
                              fontSize: "30px", // Adjust size as needed
                              cursor: "pointer",
                              transition: "color 0.2s ease-in-out",
                            }}
                          />
                        </Tooltip>
                        <span style={{ marginLeft: "5px" }}>Add Serial No</span>{" "}
                      </div>

                      {(edit_details?.isAddSerial && (
                        <div>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: `${
                                isLargeScreen ? "1fr" : "1fr 1fr 1fr"
                              }`,
                              marginTop: "10px",
                              gap: "2px", // Add some space between columns
                            }}
                          >
                            {/* Hardware Autocomplete */}
                            <div className="p-2 w-100">
                              <Autocomplete
                                name="hardware"
                                id="tags-outlined"
                                value={tempSerialData.hardware}
                                options={
                                  backend_lists?.parts_replaced_list || []
                                }
                                getOptionLabel={(option) => option}
                                filterSelectedOptions
                                onChange={(e, selectedOption) => {
                                  setTempSerialData((prev) => ({
                                    ...prev,
                                    hardware: selectedOption,
                                  }));
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Hardware*"
                                    placeholder="Select Hardware"
                                    fullWidth
                                    sx={{
                                      "& .MuiButtonBase-input.Mui-disabled": {
                                        opacity: "0.6",
                                      },
                                    }}
                                  />
                                )}
                              />
                            </div>

                            {/* Old V39 Serial No */}
                            <div className="p-2 w-100">
                              <TextField
                                value={tempSerialData.old_serial}
                                name="old_serial"
                                placeholder="Enter Old Serial No"
                                fullWidth
                                id="outlined-basic"
                                label="Old Serial No*"
                                variant="outlined"
                                onChange={handleTempInputChange}
                                sx={{
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                                  },
                                }}
                              />
                            </div>

                            {/* New V39 Serial No */}
                            <div className="p-2 w-100">
                              <TextField
                                value={tempSerialData.new_serial}
                                name="new_serial"
                                placeholder="Enter New Serial No"
                                fullWidth
                                id="outlined-basic"
                                label="New Serial No*"
                                variant="outlined"
                                onChange={handleTempInputChange}
                                sx={{
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                                  },
                                }}
                              />
                            </div>
                          </div>

                          {/* Button to add tempSerialData to product_serial_data */}
                          <div className="px-2 py-2">
                            <button
                              variant="contained"
                              className="btn btn-outline-primary markResolved_button m-1"
                              onClick={addSerialData}
                              disabled={
                                !tempSerialData.hardware ||
                                !tempSerialData.old_serial ||
                                !tempSerialData.new_serial
                              }
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      )) || <></>}

                      {/* Render the list of elements */}
                      {(edit_details?.product_serial_data?.length > 0 && (
                        <div className="serial-keys-container">
                          <header className="pb-2 previous-comments-header">
                            Product Serial Keys
                          </header>
                          <div className="mt-2 data-variation-elements-container">
                            {edit_details?.product_serial_data?.map(
                              (element, index) => (
                                <div
                                  key={index}
                                  className="data-variation-elements"
                                  style={{ color: "rgba(76, 78, 100, 0.7)" }}
                                >
                                  <strong>Hardware:</strong> {element.hardware},{" "}
                                  <strong>Old Serial:</strong>{" "}
                                  {element.old_serial},{" "}
                                  <strong>New Serial:</strong>{" "}
                                  {element.new_serial}
                                  <span
                                    className="px-2 cross-btn-data-variation"
                                    variant="outlined"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleDeleteElement(index)}
                                  >
                                    x
                                  </span>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )) || <></>}
                      {/* here serial part ended */}

                      {/* attachments */}
                      <div className="p-2 mb-2">
                        <p>* Service report and Machine images are mandatory</p>
                        <FilesUpload
                          genFields={edit_details}
                          setGenFields={setEditDetails}
                          isSmallScreen={isSmallScreen}
                        />
                      </div>
                    </div>
                  </div>
                </Typography>
                <Typography className="float-end p-3">
                  {/* <Button
            variant="secondary"
            onClick={handleCloseVisitDetailsModal}
          >
            Close
          </Button> */}

                  <LoadingButton
                    size="small"
                    onClick={saving_edit_changes}
                    disabled={
                      // ticketStatus === "resolved" ||
                      edit_details.location === "" ||
                      edit_details?.total_expenses === "" ||
                      // edit_details?.attachments.length === 0 ||
                      edit_details?.start_date === null ||
                      edit_details?.end_date === null ||
                      edit_details?.support_person.length === 0 ||
                      edit_details?.parts_replaced.length === 0 ||
                      edit_details?.remarks === ""
                    }
                    endIcon={<SendIcon />}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                    style={{ height: "36px" }}
                  >
                    <span>Save Changes</span>
                  </LoadingButton>
                </Typography>
              </Box>
            </Modal>
          </div>
        )) ||
        (showModalError.status && <ModalError />)}
    </>
  );
}

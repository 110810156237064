import React, { useContext, useEffect, useState } from "react";
import "../../../assets/styles/PreFirstPage.css";
import "../../../assets/styles/Location.css";
import ContextState from "../../contextApi/ContextState";
import TotalMachinesImg from "../../../assets/images/machine-blue.png";
import TotalRegionImg from "../../../assets/images/region-blue.png";
import TotalDistrictImg from "../../../assets/images/district-blue.png";
import TotalCropsImg from "../../../assets/images/crop-blue.png";
import FCI_Icon from "../../../assets/images/Food_Corporation_of_India.svg.png";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const pageStyling = {
  imageBackgroundDivRadius: "25%",
  topDivwidth: "33px",

  IconWidth: "26px",
  iconBackgroundWidth: "60px",
  iconBackgroundHeight: "50px",
};

const color = {
  primary: "#f7f7f9", // website background
  // secondary: "#8884d8",
  // secondary: "#826af9", // blue color in dashboard
  // secondary: "#0098e9", // blue color in dashboard
  secondary: "#666bff", // blue color in dashboard
  green: "#82ca9d", // green color in dashboard
  red: "#ff4d49d9", // red color in dashboard
  navBorderBottom: "#4c4e6422",
  taxtHeadingLight: "#4c4e6499", // normal text heading light color
  taxtHeading: "#4c4e64e6", // normal text heading dark color (76,78,100,0.97)
  tableRowHeading: "#4c4e64bf", // (76,78,100,0.87)
  iconBackgroundBlue: "#666bff39",
  iconBackgroundWhite: "#ffffffe6",
  iconBackgroundgreen: "#82ca9d39",
  iconBackgroundred: "#ff4d4939",
  textWhiteColor: "#ffffff",
  textDescBackgroundRegionDiv: "#5b61e5",
  locationTextHeading: "#4c4e64fc", //location heeading color
};

const PreFirstPage = () => {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.userDetail);
  
  const navigateToDashboard = () => {
    navigate('/FCI');
  };

  return (
    <div className="cards-parent1">
      <div className="card-firstPage">
        <div className="img-div">
          <img
            src={FCI_Icon}
            className="mx-2 fci-icon-width1"
            alt="FCI Icon img"
          />
        </div>
        <div className="text-content-client">
          <div className="inside-text-content">
            <p className="main-client-title-heading px-2">
              {userDetails?.data?.title}
            </p>
            {/* Top monthly region, district, machine */}
            <div className="main-stats">
              {/* top Div */}

              <div className="top-div top-monthly-div d-flex flex-row align-item-center flex-grow-1">
                <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                  <div
                    style={{
                      backgroundColor: color.iconBackgroundBlue,
                      borderRadius: pageStyling.imageBackgroundDivRadius,
                      height: pageStyling.iconBackgroundHeight,
                      width: pageStyling.iconBackgroundWidth,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={TotalRegionImg}
                      alt="All Assesment img"
                      className=""
                      style={{
                        width: pageStyling.IconWidth,
                      }}
                    />
                  </div>
                </div>
                {/* circular progress bar div desc */}
                <div
                  className="top-div-circular-progress-desc flex-grow-1"
                  style={{
                    color: color.taxtHeadingLight,
                  }}
                >
                  <p
                    style={{
                      color: color.tableRowHeading,
                    }}
                  >
                    {userDetails?.data?.total_regions}
                  </p>
                  Total Regions
                </div>
              </div>

              {/* vertical seperator line */}
              {/* middel Div */}

              <div className=" top-div top-monthly-div d-flex flex-row align-item-center flex-grow-1">
                <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                  <div
                    style={{
                      backgroundColor: color.iconBackgroundBlue,
                      borderRadius: pageStyling.imageBackgroundDivRadius,
                      height: pageStyling.iconBackgroundHeight,
                      width: pageStyling.iconBackgroundWidth,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="ms-auto barChartGraphDesc"
                  >
                    <img
                      src={TotalDistrictImg}
                      alt="All Assesment img"
                      style={{
                        width: pageStyling.IconWidth,
                      }}
                    />
                  </div>
                </div>
                {/* circular progress bar div desc */}

                <div
                  className="top-div-circular-progress-desc flex-grow-1"
                  style={{
                    color: color.taxtHeadingLight,
                  }}
                >
                  <p
                    style={{
                      color: color.tableRowHeading,
                    }}
                  >
                    {userDetails?.data?.total_districts}
                  </p>
                  Total Districts
                </div>
              </div>

              {/* Divider line */}

              {/* <div className="barChartDivDescVerticalSeperator"></div> */}
              {/* bottom Div */}
              <div className=" top-div top-monthly-div d-flex flex-row align-item-center flex-grow-1">
                <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                  <div
                    style={{
                      backgroundColor: color.iconBackgroundBlue,
                      borderRadius: pageStyling.imageBackgroundDivRadius,
                      height: pageStyling.iconBackgroundHeight,
                      width: pageStyling.iconBackgroundWidth,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="ms-auto barChartGraphDesc"
                  >
                    <img
                      src={TotalMachinesImg}
                      alt="All Assesment img"
                      style={{
                        width: pageStyling.IconWidth,
                      }}
                    />
                  </div>
                </div>
                {/* circular progress bar div desc */}
                <div
                  className="top-div-circular-progress-desc flex-grow-1"
                  style={{
                    color: color.taxtHeadingLight,
                  }}
                >
                  <p
                    style={{
                      color: color.tableRowHeading,
                    }}
                  >
                    {userDetails?.data?.total_machines}
                  </p>
                  Total Machines
                </div>
              </div>

              {/* crop data */}

              <div className=" top-div top-monthly-div d-flex flex-row align-item-center flex-grow-1">
                <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                  <div
                    style={{
                      backgroundColor: color.iconBackgroundBlue,
                      borderRadius: pageStyling.imageBackgroundDivRadius,
                      height: pageStyling.iconBackgroundHeight,
                      width: pageStyling.iconBackgroundWidth,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="ms-auto barChartGraphDesc"
                  >
                    <img
                      src={TotalCropsImg}
                      alt="All Assesment img"
                      style={{
                        width: pageStyling.IconWidth,
                      }}
                    />
                  </div>
                </div>
                {/* circular progress bar div desc */}
                <div
                  className="top-div-circular-progress-desc flex-grow-1"
                  style={{
                    color: color.taxtHeadingLight,
                  }}
                >
                  <p
                    style={{
                      color: color.tableRowHeading,
                    }}
                  >
                    {userDetails?.data?.total_crops}
                  </p>
                  Total Crops
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="more-details">
          <Tooltip
            data-html="true"
            title={"Click to view detailed data"}
            arrow={true}
          >

            <button
              style={{ color: color.taxtHeadingLight }}
              className="location_moredetails_btn ms-auto align-self-center "
              onClick={navigateToDashboard}
            >
              More<i class="fa-solid fa-chevron-right ps-1"></i>
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default PreFirstPage;

import React, { useContext, useEffect, useState } from "react";
import Calender from "../../assets/images/calender.jpg";
import running from "../../assets/images/running.svg";
import Person from "../../assets/images/person.jpg";
import attcahments_icons from "../.././assets/images/paperclip.svg";
import rupees_icon from "../../assets/images/currency-rupee.svg";
import parts_replaced_icons from "../../assets/images/gear-fill.svg";
import remarks_icon from "../../assets/images/chat-dots.svg";
import "../../App.css";
import "../../assets/styles/CardModal.css";
import VisitDetailModal from "./VisitDetailModal";
import ContextState from "../contextApi/ContextState";
import { Tooltip } from "@mui/material";

const ShowVisits = ({
  distance,
  end_date,
  issue_type,
  sub_issue_type,
  machine_code,
  parts_replaced,
  remarks,
  start_date,
  support_person,
  ticketId,
  total_expense,
  visit_id,
  handleDeleteModal,
  reloadData,
  attchementsCount,
}) => {
  const { formatInIndianRupees } = useContext(ContextState);
  // console.log("from sghow visits-->", visit_id);
  // state for visit Modals
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setModalIsOpen(false);
  };

  return (
    <div className="card">
      <div className="card-body py-3 px-3" onClick={openModal}>
        <div className="position-relative d-flex flex-row align-items-center">
          <h3 className="ticketid  fs-4 mb-0 ">
            <svg className="ticket-icon" viewBox="0 0 576 512">
              <path
                fill="#362b2b"
                d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z"
              />
            </svg>
            {/* {ticketId} */}
            {visit_id}
          </h3>
          <div className="machine_btns me-2">#{ticketId}</div>
          <div className="">
            <div className="dropdown ">
              {/* <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              > */}
              <Tooltip
                data-html="true"
                title={"Click here to delete this visit detail"}
                arrow={true}
              >
                <i
                  className="fa-solid fa-ellipsis-vertical "
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ padding: "0px 3px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                ></i>
              </Tooltip>
              {/* Centered dropdown */}
              {/* </button> */}
              <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                <li>
                  <a
                    className="dropdown-item text-center delete-ticket-btn"
                    href="#"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteModal(true, visit_id, ticketId);
                    }}
                  >
                    Delete Card
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Issue Type */}

        <div className="d-flex align-items-center error_type  pb-0 mb-0">
          <p className=" ps-0 mb-0">{issue_type}</p>
          {sub_issue_type && (
            <p className="mb-0">
              <span>
                <i className="bi bi-dot"></i>
              </span>
              {sub_issue_type}
            </p>
          )}
        </div>

        <div className="d-flex gap-2 machine_no my-2 mt-1">
          <p className="machine_btns">{machine_code}</p>
        </div>
        <div className="lower-part">
          <div className="d-flex align-items-center mb-2">
            <img className="imported_img " src={Person} alt="" />
            {/* <i className="bi bi-person-fill imported_img"></i> */}
            <div className="d-flex flex-row flex-wrap ps-2">
              {support_person?.length > 0
                ? support_person.map((each_person, index) => (
                    <React.Fragment key={index}>
                      <p className="mb-0 generated_by ps-0">
                        {index > 0 && <i className="bi bi-dot mx-1"></i>}
                        {each_person ? each_person : ""}
                      </p>
                    </React.Fragment>
                  ))
                : "Not mentioned name"}
            </div>
          </div>

          {/* distance ,expenses and  attachmenst */}

          <div className="d-flex flex-wrap mb-2">
            {/* Distance */}
            <div className="d-flex align-items-center me-3">
              <img className="imported_img " src={running} alt="" />

              <p className=" generated_by  ps-2">
                {distance
                  ? `${formatInIndianRupees(distance)} km`
                  : "Not Mentioned"}
              </p>
            </div>
            {/* Total_expense */}{" "}
            <div className="d-flex align-items-center me-3">
              <img className="imported_img " src={rupees_icon} alt="" />
              <p className=" generated_by  ps-1">
                {total_expense
                  ? formatInIndianRupees(total_expense)
                  : "Not Mentioned"}
              </p>
            </div>
            {/* count of attachments */}
            <div className="d-flex align-items-center me-3">
              <img className="imported_img " src={attcahments_icons} alt="" />
              <p className=" generated_by  ps-1">{attchementsCount}</p>
            </div>
          </div>

          {/* Parts Replaced */}
          <div className="d-flex align-items-start  mb-2">
            <img
              className="imported_img"
              // key={"parts_replaced_icons"}
              src={parts_replaced_icons}
              alt="parts_icon"
            />
            {/* <i className="bi bi-card-text imported_img"></i> */}
            <div className="d-flex flex-row flex-wrap ps-2">
              {parts_replaced.length > 0 ? (
                parts_replaced.map((parts, index) => (
                  <p className={`m-0  error_description`}>
                    <React.Fragment key={index}>
                      {index > 0 && <i className="bi bi-dot"></i>}
                      {parts}
                    </React.Fragment>
                  </p>
                ))
              ) : (
                <p className="mb-0 ms-2">Not Mentioned</p>
              )}
            </div>
          </div>

          {/* Issue type */}
          <div className="d-flex align-items-center  mb-2">
            <img className="imported_img " src={remarks_icon} alt="" />
            {/* <i className="bi bi-card-text imported_img"></i> */}
            <p className="error_description ps-2">{remarks}</p>
          </div>

          {/* Start  Date && end Date */}
          <div className="d-flex flex-wrap align-items-center  ">
            <div className="d-flex me-auto flex-row ">
              <img className="imported_img " src={Calender} alt="" />
              {/* <i className="bi bi-calendar3 imported_img"></i> */}
              <p className=" created_date mt-0 ps-2 d-flex flex-column justify-content-end">
                <span className="">{start_date.slice(0, -12)}</span>
                <label className="text-start">Start Date</label>
              </p>
            </div>
            {end_date && (
              <div className="d-flex  flex-row justify-content-end">
                <img className="imported_img " src={Calender} alt="" />
                {/* <i className="bi bi-calendar3 imported_img"></i> */}
                <p className=" created_date mt-0 ps-2 d-flex flex-column justify-content-end">
                  <span className="">{end_date.slice(0, -12)}</span>
                  <label className="text-start">Resolved Date</label>
                </p>
              </div>
            )}
          </div>
        </div>
        {/* <div className="underline1 pt-2"></div> */}
      </div>
      {/* Render the modal */}
      {/* <div className="individualdatamodal"> */}
      {/* <CardDetailModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        data={{
          ticketId,
          status,
        }}
        reloadData={reloadData}
      /> */}
      {modalIsOpen && <VisitDetailModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        data={{ visit_id }}
        reloadData={reloadData}
      />}
    </div>
  );
};

export default ShowVisits;

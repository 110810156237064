import React, { useState } from "react";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import India from "@svg-maps/india";
import "../../../assets/styles/MapChart.css";
import useMediaQuery from "@mui/material/useMediaQuery"; // conditional renderring of media query

// map desc images
import LocationGreenIMG from "../../../assets/images/location_green.png";
import LocationBlueIMG from "../../../assets/images/location_blue.png";
import LocationlightBlueIMG from "../../../assets/images/location_light_blue.png";
import LocationSemilightBlueIMG from "../../../assets/images/location_semilight_blue.png";

const MapChart = ({ color, shadesOfBlue, machineData }) => {
  // media qery
  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const [selectedState, setSelectedState] = useState(null);
  const [tooltipData, setTooltipData] = useState({
    visible: false,
    x: 0,
    y: 0,
    stateName: "",
    count: null,
  });
  // console.log("Machine Data : ", isSmallScreen, tooltipData);

  const onLocationClick = (event) => {
    const clickedStateName = event.target.getAttribute("name");
    if (clickedStateName === "Andaman and Nicobar Islands") {
      // Prevent interaction
      return;
    }
    const data = machineData.find((item) => item.state === clickedStateName);
    setSelectedState(data || { state: clickedStateName, count: null });
  };

  const onLocationMouseOver = (event) => {
    const hoveredStateName = event.target.getAttribute("name");
    if (hoveredStateName === "Andaman and Nicobar Islands") {
      // Prevent showing tooltip or any other interactions
      return;
    }
    const data =
      machineData.find((item) => item.state === hoveredStateName) || {};
    setTooltipData({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      stateName: hoveredStateName,
      machines: data ? data.machines : null,
      region: data.region ? data.region : null,
      districts: data.districts ? data.districts : null,
    });
    // console.log(tooltipData, "------->");
  };

  const onLocationMouseOut = () => {
    setTooltipData((prev) => ({ ...prev, visible: false, stateName: "" }));
  };

  const getColorClass = (count) => {
    if (count === 0) return "shade8";
    else if (count <= 10) return "shade6";
    else if (count <= 20) return "shade5";
    else if (count <= 30) return "shade4";
    else if (count <= 40) return "shade3";
    else if (count <= 50) return "shade2";
    else if (count > 50) return "shade1";
  };

  // console.log(shadesOfBlue);

  // range for count

  const range_details = [
    { range: "0 - 10", shade: shadesOfBlue[5] },
    { range: "11 - 20", shade: shadesOfBlue[4] },
    { range: "21 - 30", shade: shadesOfBlue[3] },
    { range: "31 - 40", shade: shadesOfBlue[2] },
    { range: "41 - 50", shade: shadesOfBlue[1] },
    { range: "Greater than 50", shade: shadesOfBlue[0] },
  ];
  return (
    shadesOfBlue &&
    machineData && (
      <div className="d-flex  card-Div flex-column " style={{ height: "99%" }}>
        <style>
          {`
            .defaultShade {
              fill: ${shadesOfBlue[-1] || "#f0f0f0"};
            }
            ${shadesOfBlue
              .map((color, colorIndex) => {
                return ".shade" + colorIndex + "{ fill: " + color + "}";
              })
              .join("\n")}
          `}
        </style>
        {/* region list */}
        <div
          className="bar-chart-heading pt-2 ps-2 "
          style={{ color: color.taxtHeading }}
        >
          Machine Units
        </div>
        <div className="machine-unit-div w-100 flex-grow-1">
          {/* region div */}
          <div className="d-flex machine-unit-region-div">
            <div
              className="card-Div w-100 ms-0 ps-0"
              style={{
                // backgroundColor: "#f3f7fd80",
                // border: "1px solid #8080800f",
                minWidth: isSmallScreen ? "90px" : "200px",
                boxShadow: "none",
              }}
            >
              <div className="map-location-desc-div">
                <div className="d-flex flex-column w-auto gap-1">
                  {machineData.map((each_ro, each_ro_index) => {
                    if (each_ro.region) {
                      return (
                        <>
                          <div
                            className=" m-0 p-0 d-flex flex-row map-location-data-div align-items-end"
                            key={`each_ro_index-${each_ro_index}`}
                            style={{
                              fontWeight:
                                tooltipData?.stateName === each_ro.state
                                  ? "bold"
                                  : "normal",
                            }}
                          >
                            <div
                              className="rounded-circle"
                              style={{
                                // backgroundColor: color.secondary,
                                height: "28px",
                                width: "30px",
                                margin: "auto 1px",
                                marginLeft: "-2px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {(tooltipData?.stateName === each_ro.state && (
                                <img
                                  src={LocationBlueIMG}
                                  alt="TotalMostScansImg"
                                  className="barChartGraphDesc"
                                  style={{
                                    height: "auto",
                                    width: "18px",
                                  }}
                                />
                              )) || (
                                <img
                                  src={LocationSemilightBlueIMG}
                                  alt="TotalMostScansImg"
                                  className="barChartGraphDesc"
                                  style={{
                                    height: "auto",
                                    width: "18px",
                                  }}
                                />
                              )}
                            </div>
                            <p className="m-0 p-0 me-auto">{each_ro.region}</p>
                            <span className="m-0 p-0 ">{each_ro.machines}</span>
                          </div>
                        </>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* map */}
          <div className="flex-grow-1 d-flex align-items-center justify-content-center m-auto">
            <div className="p-3 map-div-div flex-grow-1">
              <SVGMap
                map={India}
                onLocationClick={onLocationClick}
                onLocationMouseOver={onLocationMouseOver}
                onLocationMouseOut={onLocationMouseOut}
                className="svg-map"
                locationClassName={(location) => {
                  if (location.name === "Andaman and Nicobar Islands") {
                    return "hidden";
                  }
                  const data = machineData.find(
                    (item) => item.state === location.name
                  );
                  return getColorClass(
                    data && data.machines ? data.machines : 0
                  );
                }}
              />

              {/* Tooltip when hover on states */}
              {tooltipData.visible && (
                <div
                  style={{
                    position: "absolute",
                    // top: isSmallScreen
                    //   ? tooltipData.y - 150 + "px"
                    //   : tooltipData.y - 360 + "px",
                    // left: isSmallScreen
                    //   ? tooltipData.x - 400 + "px"
                    //   : tooltipData.x - 360 + "px",
                    transform: `translate(${
                      isSmallSmallScreen
                        ? tooltipData.x - 100
                        : tooltipData.x - 480
                    }px, ${
                      isSmallSmallScreen
                        ? tooltipData.y - 580
                        : tooltipData.y - 640
                    }px)`,
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    color: "#fff",
                    padding: "15px",
                    borderRadius: "6px",
                    zIndex: 999,
                    fontSize: "12px",
                  }}
                >
                  {tooltipData.stateName && (
                    <p className="m-0 mb-1 tooltip_Heading text-nowrap">
                      {tooltipData.stateName}
                    </p>
                  )}
                  {tooltipData.region && (
                    <p className="m-0 mb-1 text-nowrap">
                      Region : {tooltipData.region}
                    </p>
                  )}
                  {tooltipData.districts && (
                    <p className=" m-0 mb-1 text-nowrap">
                      Districts : {tooltipData.districts}
                    </p>
                  )}
                  {tooltipData.machines && (
                    <p className="m-0 text-nowrap">
                      Machines : {tooltipData.machines}
                    </p>
                  )}
                </div>
              )}

              {/* Data showing when click on state */}
              {/* {selectedState && (
      <div className="p-4">
        {selectedState.state && (
          <h3 className="mb-0">{selectedState.state}</h3>
        )}

        {selectedState.region && (
          <p className="mb-0">Region :{selectedState.region}</p>
        )}
        {selectedState.districts && (
          <p className="mb-0">District :{selectedState.districts}</p>
        )}
        {selectedState.machines && (
          <p className="mb-0">Machine Count: {selectedState.machines}</p>
        )}
      </div>
    )} */}

              {/* <div className="map-div-desc">
                <p className="m-0 card-Div-heading map-div-desc-heading">
                  Machine Count
                </p>
                {range_details.map((each_data, machineCountIndex) => (
                  <div
                    className="d-flex align-items-center text-nowrap  map-div-desc-heading-desc"
                    key={`machineCountIndex-${machineCountIndex}`}
                  >
                    <div
                      style={{
                        backgroundColor: each_data.shade,
                        height: "6px",
                        width: "6px",
                        borderRadius: "25%",
                      }}
                    ></div>
                    <p className="m-0 range_Desp p-0 ms-1">
                      {" "}
                      {each_data.range}
                    </p>
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default MapChart;

import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Rectangle,
  ComposedChart,
  Line,
  Sector,
} from "recharts";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  LinearProgress,
  Typography,
  Box,
  ButtonGroup,
  Button,
  Tooltip as MuiTooltip,
  linearProgressClasses,
} from "@mui/material";

import { useOutletContext } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RadialSeparators from "./RadialSeparators";
import "../../../assets/styles/FirstPage.css";

import MapChart from "./MapChart";
import allTicketsImg from "../../../assets/images/total-assessment-blue-3.png";
import TotalMachinesImg from "../../../assets/images/machine-blue.png";
import TotalRegionImg from "../../../assets/images/region-blue.png";
import TotalDistrictImg from "../../../assets/images/district-blue.png";
import TotalCropsImg from "../../../assets/images/crop-blue.png";

import socket from "../../socket/Socket";

import accepted_scans from "../../../assets/images/accepted_scan_1.png";
import rejected_scans from "../../../assets/images/rejected_scan_2.png";
import TotalMostScansImg from "../../../assets/images/diagram (1).png";
import TotalMostAcceptedScansImg from "../../../assets/images/diagram (2).png";
import AvgMostAcceptedScansImg from "../../../assets/images/scan-average-green.png";
import AvgMostScansImg from "../../../assets/images/scan-average-blue.png";

import noDataIMG from "../../../assets/images/no-data (1).png";

import useMediaQuery from "@mui/material/useMediaQuery"; // conditional renderring of media query

// colored icons
// import allTicketsImg from "../../../assets/images/data-analytics (1).png";
// import allAcceptedImg from "../../../assets/images/high-quality (1).png";
// import TotalMachinesImg from "../../../assets/images/computer.png";
// import TotalCropsImg from "../../../assets/images/wheat (2).png";
// import CustomLocationDataMainTable from "./CustomLocationDataMainTable";

// import AvgMostAcceptedScansImg from "../../../assets/images/average (1).png";
// import AvgMostScansImg from "../../../assets/images/average (2).png";

// import AvgMostAcceptedScansImg from "../../../assets/images/NewAcceptedAverage.png";
// import AvgMostScansImg from "../../../assets/images/NewTotalAverage.png";

import RegionCrousel from "./RegionCrousel";
import ContextState from "../../contextApi/ContextState";
import { BeatLoader } from "react-spinners";
import { styled } from "@mui/material/styles";

// progress bar for accepted scans && Reject scans

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, colorAccept, colorReject }) => ({
    height: 12,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colorAccept,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colorReject,
      borderRadius: 0,
      borderRight: "2px solid white",
    },
  })
);

const Overview = () => {
  const { fetchApi, showErrorPage, formatInIndianRupees, controllerRef } =
    useContext(ContextState);

  // user Details from redux
  const userData = useSelector((state) => state.userDetail);

  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");
  // // console.log("islagelargeScreen: ", islagelargeScreen);
  const outletContext = useOutletContext();
  // // console.log("context value : ", outletContext);

  const data = outletContext.overviewData;
  // // console.log("Overview data : ", data);
  const handelOverviewData = outletContext.handelOverviewData;

  // bule shades for pie chart
  const shadesOfBlue = outletContext.shadesOfBlue;

  // color
  const color = outletContext.color;
  const getPieChartCropData = (cropData) => {
    let showPie = [];
    // // console.log(cropData, typeof cropData);
    // assuming cropData array
    cropData.forEach((element, index) =>
      showPie.push({
        name: element.crop,
        value: element.total,
        fill: shadesOfBlue[index],
      })
    );

    return showPie;
  };

  // useEffect(() => {
  //   console.log("--> ", showError);
  // }, [showError]);

  const getPieChartDescData = (
    CropTableData,
    cropDataHeaders,
    showAccept = true
  ) => {
    const funcToLocalString = (value) => value.toLocaleString("en-IN");
    const headers = cropDataHeaders.map((header, index) => ({
      id: header.label,
      label: header.header,
      align: index ? "center" : "left",
      minWidth: header.label === "percent" ? 200 : 80,
      format: funcToLocalString,
    }));
    const rows = CropTableData;

    return { headers, rows };
  };
  const { headers, rows } = data
    ? getPieChartDescData(
        data.crops_data || [],
        data.crops_data_header || [],
        false
      )
    : { headers: [], rows: [] };

  const gettableData = (dataValue) => {
    let headers = ["name", "calories", "fat", "carbs", "protein"];
    headers = headers.map((header, index) => ({
      id: header,
      label: header,
      align: index ? "right" : "left",
      minWidth: index ? "250" : "70",
    }));
    function createData(name, calories, fat, carbs, protein) {
      return { name, calories, fat, carbs, protein };
    }

    const rows = [
      createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
      createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
      createData("Eclair", 262, 16.0, 24, 6.0),
      createData("Cupcake", 305, 3.7, 67, 4.3),
      createData("Gingerbread", 356, 16.0, 49, 3.9),
      createData("Gingerbread", 356, 16.0, 49, 3.9),
    ];

    return { tableheaders: headers, tableRows: rows };
  };

  const getRegiontableData = () => {
    const regionData = data?.top_region_wise_data || [];
    // // console.log("Region data : ", regionData, Object.keys(regionData[0]));
    const funcToLocalString = (value) => value.toLocaleString("en-IN");
    let tableRegionheaders =
      regionData.length !== 0
        ? Object.keys(regionData[0] || {}).map((header, index) => ({
            id: header,
            label: header,
            align: index ? "right" : "left",
            minWidth: index ? "250" : "70",
            format: funcToLocalString,
          }))
        : [];
    let tableRegionRows = regionData;
    return {
      tableRegionheaders: tableRegionheaders,
      tableRegionRows: tableRegionRows,
    };
  };
  const getDistricttableData = () => {
    const districtData = data?.top_district_wise_data || [];
    // // console.log("Region data : ", districtData, Object.keys(districtData[0]));
    const funcToLocalString = (value) => value?.toLocaleString("en-IN");

    let tableDistrictheaders =
      data?.top_district_header && data?.top_district_header?.length !== 0
        ? data?.top_district_header?.map((headerData, index) => ({
            id: headerData.label,
            label: headerData.header,
            align: index ? "right" : "left",
            minWidth: index ? "100px" : "70px",
            format: funcToLocalString,
          }))
        : [];
    let tableDistrictRows = districtData;

    // console.log(
    //   "getDistricttableData : ",
    //   tableDistrictheaders,
    //   tableDistrictRows
    // );
    return {
      tableDistrictheaders: tableDistrictheaders,
      tableDistrictRows: tableDistrictRows,
    };
  };
  const getMachinetableData = () => {
    const machineData = data?.top_machine_wise_data || [];
    const funcToLocalString = (value) => value.toLocaleString("en-IN");

    // console.log(
    //   "Region data : ",
    //   machineData,
    //   Object.keys(machineData[0] || {})
    // );
    // let tableMachineheaders =
    //   machineData?.length !== 0
    //     ? Object.keys(machineData[0]).filter(
    //         (item) => item.toLowerCase() !== "location"
    //       )
    //     : [];
    // if (tableMachineheaders && tableMachineheaders.length !== 0) {
    //   tableMachineheaders.splice(1, 0, "location");
    // }
    let tableMachineheaders =
      data?.top_machine_header?.map((headerData, index) => ({
        id: headerData.label,
        label: headerData.header,
        align: ["matt", "location"].includes(headerData.label.toLowerCase())
          ? "left"
          : "right",
        minWidth: index ? "100px" : "70px",
        format: funcToLocalString,
      })) || [];
    let tableMachineRows = machineData;
    // console.log(
    //   "tableMachineheaders : ",
    //   tableMachineheaders,
    //   tableMachineRows
    // );
    return {
      tableMachineheaders: tableMachineheaders,
      tableMachineRows: tableMachineRows,
    };
  };

  const { tableheaders, tableRows } = data
    ? gettableData()
    : { tableheaders: [], tableRows: [] };
  const { tableRegionheaders, tableRegionRows } = data
    ? getRegiontableData()
    : { tableRegionheaders: [], tableRegionRows: [] };
  const { tableDistrictheaders, tableDistrictRows } = data
    ? getDistricttableData()
    : { tableDistrictheaders: [], tableDistrictRows: [] };
  const { tableMachineheaders, tableMachineRows } = data
    ? getMachinetableData()
    : { tableMachineheaders: [], tableMachineRows: [] };

  // to show the -ive value for the bar graph
  // Function to transform positive value to negative
  const transformData = (data) => {
    // console.log("data: ", data);
    return data?.map((item) => ({ ...item, accepted: -item.accepted }));
  };

  // data Table

  function dataPass(data) {
    return [...data];
  }

  // Custom bar component
  const CustomBar = (props) => {
    const { fill, x, y, width, height } = props;

    return (
      <Rectangle
        fill={fill}
        x={x}
        y={y}
        width={width}
        height={height}
        radius={[5, 5, 5, 5]}
      />
    );
  };

  const topDivWidth = {
    normal: isSmallScreen ? "280px" : "290px",
    isSmallScreen: "150px",
    isSmallSmallScreen: "250px",
  };

  const pageStyling = {
    imageBackgroundDivRadius: "25%",
    topDivwidth: "33px",

    IconWidth: "23px",
    iconBackgroundWidth: "45px",
    iconBackgroundHeight: "45px",

    monthlyScanDivDescIconWidth: "20px",
    monthlyScanDivDescIconBackgroundWidth: "40px",
    monthlyScanDivDescIconBackgroundHeight: "40px",
  };

  const top3rdScans = data?.weekly_scans
    ? [...data?.weekly_scans]?.sort((a, b) => b.scans - a.scans) || []
    : [];

  const [loading, showLoading] = useState(true);
  const [test, setTest] = useState(false);

  useEffect(() => {
    document.title = "Home - NEO";
    showLoading(true);
    let is_user_data_Mounted = true;
    const fetchDataAndUpdateFilters = async () => {
      //     // fetchFiltersDataApiCall
      setTimeout(async () => {
        const all_filter_data = await fetchApi(
          "dashboard_overview",
          "POST",
          {},
          is_user_data_Mounted
        );
        // console.log("get_basic_details", all_filter_data);
        if (all_filter_data.statusCode === 200) {
          // showErrorPage(null, null);
          showErrorPage(null, null);
          outletContext.handelOverviewData(all_filter_data?.data || null);
          setTest((prevTest) => !prevTest);
          showLoading(false); // Moved here

          // console.log("all_filter_data?.data : ", all_filter_data?.data);
        } else if (
          all_filter_data.statusCode === 500 ||
          all_filter_data.statusCode === 401
        ) {
          // setUserData(null);
          showLoading(false); // Moved here
          outletContext.handelshowFirstPageError(
            all_filter_data.statusCode,
            all_filter_data?.msg
          );

          // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
        } else {
          // setUserData(null);
          // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
          // console.log("Some Error Occured", all_filter_data);
          localStorage.clear();
          window.location.href = "/login";
        }
        return;
      }, 10);

      // test Data
      // outletContext.handelOverviewData(jsonData);
    };

    // current page
    const curr_page = "general_overview";

    // Initial event 'initial_check' whenever the component is opened for the first time
    if (data) {
      // console.log(
      //   "data is already present, so doing initial check for overview page..."
      // );
      socket.emit("initial_check", { curr_page });
    }

    // every 60 seconds, i will be checking if there's any data update
    const intervalId = setInterval(() => {
      // console.log("checking for updates...")
      socket.emit("check_updates", { curr_page });
    }, 45000);

    // Listen for the 'update_<page>' event from the server
    socket.on(`update_${curr_page}`, (value) => {
      if (value === true) {
        console.log(`Reload the data for ${curr_page}...`);
        fetchDataAndUpdateFilters();
      } else {
        console.log(`Don't reload the data for ${curr_page}...`);
      }
    });

    if (!data) {
      fetchDataAndUpdateFilters();
    } else {
      showLoading(false);
    }

    // Cleanup function
    return () => {
      is_user_data_Mounted = false;
      // On unmount, abort the previous request for fetchApi api
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      clearInterval(intervalId);
      socket.off(`update_${curr_page}`);
    };
  }, []);

  const formatYAxis = (value) => {
    // Convert the value to a string with a locale-sensitive representation
    const xlablel = value < 0 ? -value : value;
    const formattedValue = xlablel.toLocaleString("en-IN");
    // Append "K" for thousands
    // console.log("formatXAxis: ", xlablel)
    if (xlablel >= 1000) {
      return `${Math.round(xlablel / 1000)}K`;
    }
    return formattedValue;
  };

  const [activeIndex, setActiveIndex] = useState(-1);
  const [opacity, setOpacity] = useState({ total: 1, accepted: 1 });
  const [monthAssementsOpacity, setMonthAssementsOpacity] = useState({
    total: 1,
    accepted: 1,
  });

  const handleMouseEnter = (data, index) => {
    // console.log(data, "test------>");
    setActiveIndex(index);
  };

  const handleMouseLeave = (data, index) => {
    setActiveIndex(-1);
  };

  const handleMouseEnterLegend = (o) => {
    // console.log("Mouse Enter ----->", o);
    const { dataKey } = o;
    setOpacity((prevOpacity) => ({
      ...prevOpacity,
      [dataKey]: 0.1,
    }));
  };

  const handleMouseLeaveLegend = (o) => {
    // console.log("Mouse Leave ----->", o);
    const { dataKey } = o;
    setOpacity((prevOpacity) => ({
      ...prevOpacity,
      [dataKey]: 1,
    }));
  };
  const handleMouseEnterLegendMonth = (o) => {
    // console.log("Mouse Enter ----->", o);
    const { dataKey } = o;
    setMonthAssementsOpacity((prevOpacity) => ({
      ...prevOpacity,
      [dataKey]: 0.1,
    }));
  };

  const handleMouseLeaveLegendMonth = (o) => {
    // console.log("Mouse Leave ----->", o);
    const { dataKey } = o;
    setMonthAssementsOpacity((prevOpacity) => ({
      ...prevOpacity,
      [dataKey]: 1,
    }));
  };

  // progresss circular for accepted and rejected

  const [circularOpacity, setCircularOpacity] = useState({
    accepted: 1,
    rejected: 1,
  });

  const handleMouseEnterCircluarProgress = (data) => {
    // console.log("Opacity------> Enter", data);
    // console.log("Opacity------> Enter", circularOpacity);
    setCircularOpacity((prevOpacity) => ({
      ...prevOpacity,
      [data]: 0.5,
    }));
  };
  const handleMouseLeaveCircluarProgress = (data) => {
    // console.log("Opacity------> Leave", data);
    // console.log("Opacity------> Enter", circularOpacity);
    setCircularOpacity((prevOpacity) => ({
      ...prevOpacity,
      [data]: 1,
    }));
  };

  // crop-pie-chart

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text
          x={cx}
          y={cy - 15}
          dy={8}
          textAnchor="middle"
          fill={color.taxtHeading}
          style={{ fontSize: "1.55rem", fontWeight: "bold" }}
        >
          {payload.total}
        </text>
        <text
          x={cx}
          y={cy + 15}
          dy={8}
          textAnchor="middle"
          fill={color.taxtHeading}
          style={{ fontSize: "17px" }}
        >
          {payload.crop}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 13}
          fill={fill}
        />
        {/* <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        /> */}
        {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
        {/* <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`PV ${value}`}</text> */}
        {/* <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text> */}
      </g>
    );
  };

  const [activePieIndex, setactivePieIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const onPieEnter = useCallback(
    (_, index) => {
      setactivePieIndex(index);
      setIsHovered(true);
    },
    [setactivePieIndex]
  );

  const onPieLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  useEffect(() => {
    if (!isHovered) {
      const interval = setInterval(() => {
        setactivePieIndex(
          (prevIndex) =>
            (prevIndex + 1) % data?.last_10_days_data?.cropwise_chart.length
        );
      }, 3000); // Change interval as needed

      return () => clearInterval(interval);
    }
  }, [isHovered, data]);

  // useEffect(() => {
  //   let interval;
  //   if (isPlay) {
  //     interval = setInterval(() => {
  //       const nextSlideIndex = (activePieIndex + 1) % 7;
  //       setactivePieIndex(nextSlideIndex);
  //       console.log(nextSlideIndex, "nxt");
  //     }, 3000);
  //     console.log("active", activePieIndex);
  //   }
  //   // Cleanup interval on component unmount
  //   return () => clearInterval(interval);
  // }, [activeIndex, isPlay]);

  // cutsom tooltip

  const CustomTooltip = ({ active, payload, label }) => {
    // console.log("test_custom_tooltip------------->", payload, active, label);
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: color?.textWhiteColor,
            // margin: "8px",
            borderRadius: "5px",
            border: "1px solid",
            borderColor: "#e5e6e8",
          }}
        >
          {/* label for tooltip */}
          {label?.length > 1 ? (
            <p
              style={{
                margin: "0px 0px 0px 0px",
                fontSize: "0.75rem",
                borderBottom: "1px solid",
                borderColor: "#e5e6e8",
                padding: "4px 8px",
              }}
            >
              {label.length > 1 ? label : null}
            </p>
          ) : null}
          {payload[0]?.payload?.label && (
            <p
              style={{
                margin: "0px 0px 0px 0px",
                fontSize: "0.75rem",
                borderBottom: "1px solid",
                borderColor: "#e5e6e8",
                padding: "4px 8px",
              }}
            >
              {payload[0]["payload"].label}
            </p>
          )}
          <div style={{ padding: "4px 0px" }}>
            <div style={{ padding: "4px 0" }}>
              {payload &&
                payload?.map((entry, index) => (
                  <div
                    key={`CustomTooltip-item-${index}`}
                    className="d-flex align-items-center gap-2 px-2 py-1"
                  >
                    <div
                      style={{
                        backgroundColor: entry?.color || entry?.payload?.fill,
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <div style={{ fontSize: "0.83rem", marginRight: "4px" }}>
                      {entry.name}:
                    </div>
                    <div style={{ fontSize: "0.83rem", fontWeight: 600 }}>
                      {formatInIndianRupees(Math.abs(entry.value))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  // Custom legend
  const customLegend = (props) => {
    const { payload } = props;
    return (
      <div
        className="d-flex justify-content-center gap-3 "
        style={{ cursor: "pointer" }}
      >
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{
              listStyleType: "none",
              // marginTop: "4px",
              border: "1px solid",
              padding: "3px 10px",
              borderRadius: "10px",
              borderColor: "#e5e6e8",
            }}
            onMouseEnter={() => handleMouseEnterLegend(entry)}
            onMouseLeave={() => handleMouseLeaveLegend(entry)}
          >
            <span
              style={{
                display: "inline-block",
                backgroundColor: entry.color,
                width: "10px",
                height: "10px",
                marginRight: "8px",
                borderRadius: "50%",
              }}
            ></span>
            {(entry.value.toLowerCase() === "total" &&
              entry.value + " Scans") ||
              (entry.value.toLowerCase() === "accepted" &&
                entry.value + " Scans") ||
              entry.value}
          </div>
        ))}
      </div>
    );
  };

  function generateRangeArray(graph_data) {
    const graphMax = Math.max(...graph_data.map((item) => item.total));
    const step = Math.pow(10, graphMax.toString().length - 1);
    let maxTotal = Math.ceil(graphMax / step) * step;

    const getarr = Array.from(
      { length: ((data.show_accepted ? 2 : 1) * maxTotal) / step + 1 },
      (_, i) => (data.show_accepted ? -1 : 0) * maxTotal + i * step
    );
    // console.log("maxTotal :", getarr, graphMax, step);
    return getarr;
  }

  const renderCustomLegend = (props) => {
    const { payload } = props;
    return (
      <div
        className="d-flex justify-content-end gap-3 "
        style={{ cursor: "pointer" }}
      >
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{
              listStyleType: "none",
              // marginTop: "4px",
              // border: "1px solid",
              padding: "0px 0px",
              borderRadius: "10px",
              borderColor: "#e5e6e8",
              color: entry.color,
            }}
            onMouseEnter={() => handleMouseEnterLegendMonth(entry)}
            onMouseLeave={() => handleMouseLeaveLegendMonth(entry)}
          >
            <span
              style={{
                display: "inline-block",
                backgroundColor: entry.color,
                width: "10px",
                height: "10px",
                marginRight: "8px",
                borderRadius: "25%",
              }}
            ></span>
            {(entry.value.toLowerCase() === "total" &&
              entry.value + " Scans") ||
              (entry.value.toLowerCase() === "accepted" &&
                entry.value + " Scans") ||
              entry.value}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {(loading && (
        <div
          className="showSpinner all-ticket-spinner"
          style={{ position: "relative", height: "85vh" }}
        >
          <BeatLoader color="#2D97D4" />
        </div>
      )) ||
        (data && !data?.message && (
          <>
            {/* Top Cards  */}
            {(data.total_assessments ||
              data.total_assessments === 0 ||
              data.total_regions ||
              data.total_regions === 0 ||
              data.total_districts ||
              data.total_districts === 0 ||
              data.total_machines ||
              data.total_machines === 0 ||
              data.total_crops ||
              data.total_crops === 0) && (
              <div
                className={`${
                  userData?.data?.access_level !== "institution"
                    ? "card-div-element"
                    : "grid-card-div-element"
                }  m-top-bottom mt-3`}
              >
                {/* Total Assesment */}
                {(data.total_assessments || data.total_assessments === 0) && (
                  <div
                    className={`card-Div div-overview-container`}
                    style={{
                      backgroundColor: color.secondary,
                      minWidth: topDivWidth.normal,
                    }}
                  >
                    {/* all ticket icon & heading */}
                    <div className="d-flex align-items-center top-div-margin-bottom">
                      <div
                        className="ps-2  d-flex flex-row  w-100 align-items-start"
                        style={{ color: color.taxtHeading }}
                      >
                        <span
                          className="card-Div-heading w-100"
                          style={{ color: "#ffffff" }}
                        >
                          Total Scans
                        </span>
                        <div className="card-div-icon-div ms-auto">
                          <div
                            style={{
                              backgroundColor: color.iconBackgroundWhite,
                              borderRadius:
                                pageStyling.imageBackgroundDivRadius,
                              height: pageStyling.iconBackgroundHeight,
                              width: pageStyling.iconBackgroundWidth,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="ms-auto "
                          >
                            <img
                              src={allTicketsImg}
                              alt="All Assesment img"
                              style={{ width: pageStyling.IconWidth }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Total Counts of All Tickets */}
                    <div className="total-count-div mb-0  d-flex flex-column align-items-start justify-content-center ps-2">
                      <span
                        key={test}
                        className="fs-2 rotatingText-adjective"
                        style={{ color: "#ffffffe0" }}
                      >
                        {formatInIndianRupees(data.total_assessments)}
                        {/* {formatInIndianRupees(test)} */}
                      </span>
                    </div>
                    {/* image */}
                    {/* <div className="card-div-icon-div ">
                  <img src={allTicketsImg} alt="All Assesment img" />
                </div> */}
                  </div>
                )}

                {/* Total Region */}
                {(data.total_regions || data.total_regions === 0) && (
                  <div
                    className={`card-Div div-overview-container `}
                    style={{
                      backgroundColor: "#ffffff",
                      minWidth: topDivWidth.normal,
                    }}
                  >
                    {/* all ticket icon & heading */}
                    <div className="d-flex align-items-center ">
                      <div
                        className="ps-2  d-flex flex-row  w-100 align-items-start"
                        style={{ color: color.taxtHeading }}
                      >
                        <span
                          className="card-Div-heading w-100"
                          style={{ color: color.taxtHeading }}
                        >
                          Total Regions
                        </span>
                        <div className="card-div-icon-div ms-auto">
                          <div
                            style={{
                              backgroundColor: color.iconBackgroundBlue,
                              borderRadius:
                                pageStyling.imageBackgroundDivRadius,
                              height: pageStyling.iconBackgroundHeight,
                              width: pageStyling.iconBackgroundWidth,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="ms-auto"
                          >
                            <img
                              src={TotalRegionImg}
                              alt="All Assesment img"
                              style={{ width: pageStyling.IconWidth }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Total Counts of All Tickets */}
                    <div className="total-count-div mb-0  d-flex flex-column align-items-start justify-content-center ps-2">
                      <span
                        className="fs-2"
                        style={{ color: color.taxtHeading }}
                      >
                        {formatInIndianRupees(data.total_regions)}
                      </span>
                    </div>
                    {/* image */}
                    {/* <div className="card-div-icon-div ">
                  <div
                    style={{
                      backgroundColor: color.secondary,
                      borderRadius: pageStyling.imageBackgroundDivRadius,
                      height: "55px",
                      width: "55px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={allAcceptedImg} alt="All Assesment img" />
                  </div>
                </div> */}
                  </div>
                )}

                {/* Total District */}
                {(data.total_districts || data.total_districts === 0) && (
                  <div
                    className={`card-Div div-overview-container `}
                    style={{
                      backgroundColor: "#ffffff",
                      minWidth: topDivWidth.normal,
                    }}
                  >
                    {/* all ticket icon & heading */}
                    <div className="d-flex align-items-center ">
                      <div
                        className="ps-2  d-flex flex-row  w-100 align-items-start"
                        style={{ color: color.taxtHeading }}
                      >
                        <span
                          className="card-Div-heading w-100"
                          style={{ color: color.taxtHeading }}
                        >
                          Total Districts
                        </span>
                        <div className="card-div-icon-div ms-auto">
                          <div
                            style={{
                              backgroundColor: color.iconBackgroundBlue,
                              borderRadius:
                                pageStyling.imageBackgroundDivRadius,
                              height: pageStyling.iconBackgroundHeight,
                              width: pageStyling.iconBackgroundWidth,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="ms-auto"
                          >
                            <img
                              src={TotalDistrictImg}
                              alt="All Assesment img"
                              style={{ width: pageStyling.IconWidth }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Total Counts of All Tickets */}
                    <div className="total-count-div mb-0  d-flex flex-column align-items-start justify-content-center ps-2">
                      <span
                        className="fs-2"
                        style={{ color: color.taxtHeading }}
                      >
                        {formatInIndianRupees(data?.total_districts)}
                      </span>
                    </div>
                    {/* image */}
                    <div className="card-div-icon-div ">
                      {/* <img src={allAcceptedImg} alt="All Assesment img" /> */}
                    </div>
                  </div>
                )}

                {/* Active Machines Location  */}
                {(data.total_machines || data.total_machines === 0) && (
                  <div
                    className={`card-Div div-overview-container `}
                    style={{
                      backgroundColor: "#ffffff",
                      minWidth: topDivWidth.normal,
                    }}
                  >
                    {/* all ticket icon & heading */}
                    <div className="d-flex align-items-center ">
                      {/* <div
                    className="ps-2 card-Div-heading me-auto"
                    style={{ color: color.taxtHeading }}
                  >
                    Total Machines
                  </div> */}

                      <div
                        className="ps-2  d-flex flex-row  w-100 align-items-start"
                        style={{ color: color.taxtHeading }}
                      >
                        <span
                          className="card-Div-heading w-100"
                          style={{ color: color.taxtHeading }}
                        >
                          Total Machines
                          {/* Total District */}
                        </span>
                        <div className="card-div-icon-div ms-auto">
                          <div
                            style={{
                              backgroundColor: color.iconBackgroundBlue,
                              borderRadius:
                                pageStyling.imageBackgroundDivRadius,
                              height: pageStyling.iconBackgroundHeight,
                              width: pageStyling.iconBackgroundWidth,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="ms-auto"
                          >
                            <img
                              src={TotalMachinesImg}
                              alt="All Assesment img"
                              style={{ width: pageStyling.IconWidth }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Total Counts of All Tickets */}
                    <div className="total-count-div mb-0  d-flex flex-column align-items-start justify-content-center ps-2">
                      <span
                        className="fs-2"
                        style={{ color: color.taxtHeading }}
                      >
                        {formatInIndianRupees(data?.total_machines)}
                      </span>
                    </div>
                    {/* image */}
                    {/* <div className="card-div-icon-div ">
                  <img src={TotalMachinesImg} alt="All Assesment img" />
                </div> */}
                  </div>
                )}
                {/* Total Crop */}
                {userData?.data?.access_level !== "institution" &&
                  (data.total_crops || data.total_crops === 0) && (
                    <div
                      className={`card-Div div-overview-container `}
                      style={{
                        backgroundColor: "#ffffff",
                        minWidth: topDivWidth.normal,
                      }}
                    >
                      {/* all ticket icon & heading */}
                      <div className="d-flex align-items-center ">
                        {/* <div
                    className="ps-2 card-Div-heading me-auto"
                    style={{ color: color.taxtHeading }}
                  >
                    Total Crops
                  </div> */}

                        <div
                          className="ps-2  d-flex flex-row  w-100 align-items-start"
                          style={{ color: color.taxtHeading }}
                        >
                          <span
                            className="card-Div-heading w-100"
                            style={{ color: color.taxtHeading }}
                          >
                            {/* Total Machines */}
                            Total Crops
                            {/* Total District */}
                          </span>
                          <div className="card-div-icon-div ms-auto">
                            <div
                              style={{
                                backgroundColor: color.iconBackgroundBlue,
                                borderRadius:
                                  pageStyling.imageBackgroundDivRadius,
                                height: pageStyling.iconBackgroundHeight,
                                width: pageStyling.iconBackgroundWidth,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              className="ms-auto"
                            >
                              <img
                                src={TotalCropsImg}
                                alt="All Assesment img"
                                style={{ width: pageStyling.IconWidth }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Total Counts of All Tickets */}
                      <div className="total-count-div mb-0  d-flex flex-column align-items-start justify-content-center ps-2">
                        <span
                          className="fs-2"
                          style={{ color: color.taxtHeading }}
                        >
                          {formatInIndianRupees(data.total_crops)}
                        </span>
                      </div>
                      {/* image */}
                      {/* <div className="card-div-icon-div ">
                  <img src={TotalCropsImg} alt="All Assesment img" />
                </div> */}
                    </div>
                  )}
              </div>
            )}
            {/* total and accepted bar graph */}
            {data?.graph_data && (
              <div className="m-top-bottom">
                <div className="card-Div ">
                  <div
                    className="bar-chart-heading pt-2 ps-2"
                    style={{ color: color.taxtHeading }}
                  >
                    Monthly Assessment
                  </div>
                  {/* <div className=" d-flex justify-content-center"> */}
                  <div
                    className=" total-scans-bar-chart-main-div"
                    // style={{ maxWidth: "1400px" }}
                  >
                    {/* total $ accepted bar chart */}
                    <div className="barChartDiv m-auto w-100 overflow-auto style-scrollbar">
                      <div style={{ minWidth: "800px" }}>
                        <ResponsiveContainer width="100%" height={350}>
                          <BarChart
                            // width={1000}
                            // height={300}

                            data={transformData(data?.graph_data || [])}
                            stackOffset="sign"
                            margin={{
                              top: 10,
                              right: 0,
                              left: 5,
                              bottom: 5,
                            }}
                            // barGap={3}
                            // barCategoryGap={55}
                          >
                            <CartesianGrid
                              strokeDasharray="0"
                              vertical={false}
                              stroke="#4c4e6614"
                              // stroke="none" // Set stroke to "none" to remove grid lines
                            />
                            <XAxis
                              dataKey="date"
                              interval={0}
                              angle={isSmallScreen ? -20 : 0}
                              // textAnchor="end"
                              tick={{ fill: color.taxtHeading, fontSize: 12 }}
                              axisLine={false}
                              tickLine={{ display: "none" }}
                              // tickFormatter={(value) => (value < 0 ? -value : value)} // Ensure positive ticks
                            />
                            <YAxis
                              domain={["auto", "auto"]}
                              tick={{ fill: color.taxtHeading, fontSize: 11 }}
                              axisLine={false}
                              label={{
                                value: "Scans",
                                angle: -90,
                                position: "insideLeft",
                              }}
                              tickFormatter={formatYAxis} // Ensure positive ticks
                              tickLine={{ display: "none" }}
                              ticks={
                                data.show_accepted
                                  ? generateRangeArray(data?.graph_data || [])
                                  : []
                              }
                              // ticks={yAxisTicks}
                              // scale={"linear"}
                            />
                            {/* <Tooltip
                          formatter={(value, name) => {
                            // If the name is 'accepted' (negative values), display the absolute value
                            if (name === "accepted") {
                              return Math.abs(value);
                            }
                            // Otherwise, return the original value
                            return value;
                          }}
                        /> */}
                            <Tooltip
                              content={<CustomTooltip />}
                              cursor={{ fill: color.primary }}
                            />
                            <Legend
                              layout="horizontal"
                              verticalAlign="top"
                              align="right"
                              // height={30}
                              iconSize={10}
                              iconType="circle"
                              // height={30}
                              wrapperStyle={{ cursor: "pointer" }}
                              // onMouseEnter={handleMouseEnterLegendMonth}
                              // onMouseLeave={handleMouseLeaveLegendMonth}
                              content={renderCustomLegend} // Use custom legend here
                            />
                            <ReferenceLine y={0} stroke="#ccc" />
                            <Bar
                              dataKey="total"
                              fill={color.secondary}
                              stackId="stack"
                              barSize={20}
                              radius={[5, 5, 0, 0]}
                              // shape={<CustomScansBar/>}
                              // shape={(props) => {
                              //   const { height, ...rest } = props;
                              //   const minHeight = 50; // Minimum height for the bar
                              //   const adjustedHeight =
                              //     height < minHeight ? minHeight : height; // Ensure minimum height
                              //   return <rect {...rest} height={adjustedHeight} />;
                              // }}
                              minPointSize={15}
                              fillOpacity={monthAssementsOpacity.accepted}
                            />
                            {data.show_accepted && (
                              <Bar
                                dataKey="accepted"
                                fill={color.green}
                                stackId="stack"
                                // shape={<CustomBarBottom />}
                                // shape={<CustomScansBar  />}
                                // shape={(props) => {
                                //   const { height, ...rest } = props;
                                //   const minHeight = 50; // Minimum height for the bar
                                //   const adjustedHeight =
                                //     height < minHeight ? minHeight : height; // Ensure minimum height
                                //   return <rect {...rest} height={adjustedHeight} />;
                                // }}
                                // barCategoryGap={10}
                                minPointSize={-10}
                                radius={[5, 5, 0, 0]}
                                fillOpacity={monthAssementsOpacity.total}
                              />
                            )}
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                    <div className="barChartDivDesc">
                      <div
                        className="d-flex flex-row justify-content-evenly"
                        style={{ height: "100%" }}
                      >
                        {/* total scans */}
                        <p className="m-0">
                          <div className="card-Div bar-chart-desc-div">
                            {/* <img
                          src={TotalMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}

                            <div className=" card-div-icon-div m-auto">
                              <div
                                style={{
                                  backgroundColor: color.iconBackgroundBlue,
                                  borderRadius:
                                    pageStyling.imageBackgroundDivRadius,
                                  height:
                                    pageStyling.monthlyScanDivDescIconBackgroundHeight,
                                  width:
                                    pageStyling.monthlyScanDivDescIconBackgroundWidth,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                className="ms-auto "
                              >
                                <img
                                  src={TotalMostScansImg}
                                  alt="All Assesment img"
                                  style={{
                                    width:
                                      pageStyling.monthlyScanDivDescIconWidth,
                                  }}
                                />
                              </div>
                            </div>
                            <span className="bar-chart-num">
                              {data.month_max_total}
                            </span>
                            <p className="bar-chart-heading ">Most Scans</p>
                          </div>
                        </p>
                        <div className="barChartDivDescVerticalSeperator"></div>
                        {/* Avg Total Scans */}
                        <p className="m-0">
                          <div className="card-Div bar-chart-desc-div">
                            {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                          /> */}

                            <div className=" card-div-icon-div m-auto">
                              <div
                                style={{
                                  backgroundColor: color.iconBackgroundBlue,
                                  borderRadius:
                                    pageStyling.imageBackgroundDivRadius,
                                  height:
                                    pageStyling.monthlyScanDivDescIconBackgroundHeight,
                                  width:
                                    pageStyling.monthlyScanDivDescIconBackgroundWidth,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                className="ms-auto "
                              >
                                <img
                                  // src={TotalMostScansImg}
                                  src={AvgMostScansImg}
                                  alt="All Assesment img"
                                  style={{
                                    width:
                                      pageStyling.monthlyScanDivDescIconWidth,
                                  }}
                                />
                              </div>
                            </div>
                            <span className="bar-chart-num">
                              {formatInIndianRupees(
                                data.avg_scans_last_12_month
                              )}
                            </span>
                            <p className="bar-chart-heading ">
                              Avg Total Scans
                            </p>
                          </div>
                        </p>
                      </div>
                      {data.show_accepted && (
                        <>
                          {/* horizontal line seprator */}
                          <div className="barChartDivDescHorizontalSeperator" />
                          {/* Most accepted scans  */}
                          <div
                            className="d-flex flex-row justify-content-evenly"
                            style={{ height: "100%" }}
                          >
                            <p className="m-0">
                              <div className="card-Div bar-chart-desc-div">
                                {/* <img
                              src={TotalMostAcceptedScansImg}
                              alt="TotalMostScansImg"
                              className="barChartGraphDesc"
                              /> */}

                                <div className=" card-div-icon-div m-auto">
                                  <div
                                    style={{
                                      backgroundColor:
                                        color.iconBackgroundgreen,
                                      borderRadius:
                                        pageStyling.imageBackgroundDivRadius,
                                      height:
                                        pageStyling.monthlyScanDivDescIconBackgroundHeight,
                                      width:
                                        pageStyling.monthlyScanDivDescIconBackgroundWidth,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    className="ms-auto "
                                  >
                                    <img
                                      // src={TotalMostScansImg}
                                      src={TotalMostAcceptedScansImg}
                                      alt="All Assesment img"
                                      style={{
                                        width:
                                          pageStyling.monthlyScanDivDescIconWidth,
                                      }}
                                    />
                                  </div>
                                </div>
                                <span className="bar-chart-num">
                                  {data.month_max_accepted}
                                </span>
                                <p className="bar-chart-heading ">
                                  Most Accepted Scans
                                </p>
                              </div>
                            </p>
                            <div className="barChartDivDescVerticalSeperator"></div>
                            <p className="m-0">
                              <div className="card-Div bar-chart-desc-div">
                                {/* <img
                              alt="TotalMostScansImg"
                              className="barChartGraphDesc"
                              /> */}

                                <div className=" card-div-icon-div m-auto">
                                  <div
                                    style={{
                                      backgroundColor:
                                        color.iconBackgroundgreen,
                                      borderRadius:
                                        pageStyling.imageBackgroundDivRadius,
                                      height:
                                        pageStyling.monthlyScanDivDescIconBackgroundHeight,
                                      width:
                                        pageStyling.monthlyScanDivDescIconBackgroundWidth,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    className="ms-auto "
                                  >
                                    <img
                                      src={AvgMostAcceptedScansImg}
                                      // src={TotalMostScansImg}
                                      alt="All Assesment img"
                                      style={{
                                        width:
                                          pageStyling.monthlyScanDivDescIconWidth,
                                      }}
                                    />
                                  </div>
                                </div>
                                <span className="bar-chart-num">
                                  {formatInIndianRupees(
                                    data.avg_accepted_scans_last_12_month
                                  )}
                                </span>
                                <p className="bar-chart-heading ">
                                  Avg Accepted Scans
                                </p>
                              </div>
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            )}
            {/* accepted radial bar chart && crop assesment*/}
            <div
              className={` cropAssesmentMainDiv m-top-bottom ${
                islagelargeScreen && "flex-wrap"
              }`}
              style={{ marginBottom: "30px" }}
            >
              {/* Crops Pie chart */}
              {data?.crops_data && (
                <div
                  className="flex-grow-1"
                  style={{
                    minWidth: isSmallScreen
                      ? "250px"
                      : data.show_accepted
                      ? "450px"
                      : "55%",
                  }}
                >
                  <div className="card-Div d-flex flex-column h-100 ">
                    <div className="h-100">
                      <div
                        className={`bar-chart-heading pt-2 ps-2  ${
                          isMediumScreen ? "pb-0" : "pb-2"
                        }`}
                        style={{ color: color.taxtHeading }}
                      >
                        Crop Wise Assessment
                      </div>
                      <div
                        className={`d-flex flex-colums align-item-center w-100 m-auto`}
                      >
                        <div
                          className="pie-chart-main-div flex-grow-1"
                          // style={{ margin: "0px !important" }}
                        >
                          {/* pie chart */}
                          <div className="pie-chart-div ">
                            <PieChart width={400} height={400}>
                              <Pie
                                data={getPieChartCropData(
                                  data.crops_data || []
                                )}
                                dataKey="value"
                                cx={200}
                                cy={200}
                                outerRadius={140}
                                // innerRadius={90}
                                fill="#8884d8"
                                startAngle={90}
                                endAngle={-270}
                              />
                              <Tooltip content={<CustomTooltip />} />
                            </PieChart>
                          </div>
                          {/* pie desc table */}
                          <div className="pie-chart-desc-div m-auto overflow-hidden">
                            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                              <TableContainer
                                sx={{
                                  borderRadius: "15px",
                                  overflow: "auto",
                                  // border: "2px solid" + color.primary,
                                }}
                              >
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      {headers.map((column, headerIndex) => (
                                        <TableCell
                                          key={`table-header-${headerIndex}-${headerIndex}`}
                                          align={column.align}
                                          style={{
                                            minWidth: column.minWidth,
                                            // backgroundColor: "#F3F7FD",
                                            padding: "5px",
                                            paddingLeft: headerIndex
                                              ? "5px"
                                              : "15px",
                                            color: color.tableRowHeading,
                                            fontSize: "0.9125rem",
                                          }}
                                          className="bar-chart-heading"
                                        >
                                          {column.label}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {rows.map((row, rowIndex) => {
                                      return (
                                        <MuiTooltip
                                          key={`table-${rowIndex}`}
                                          title={row["crop"]}
                                          placement="top"
                                          arrow
                                        >
                                          <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                          >
                                            {headers.map(
                                              (column, rowDivIndex) => {
                                                const value = row[column.id];
                                                return (
                                                  <TableCell
                                                    key={`table-row-${rowDivIndex}`}
                                                    align={column.align}
                                                    style={{
                                                      padding: "12px 15px",
                                                      color: color.taxtHeading,
                                                      fontWeight:
                                                        column.id === "crop"
                                                          ? "600"
                                                          : "400",
                                                      border: "none",
                                                      fontSize: "1rem",
                                                    }}
                                                  >
                                                    {column.id === "percent" ? (
                                                      <Box
                                                        sx={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <MuiTooltip
                                                          title={`${Math.round(
                                                            value
                                                          )}% Scans Accepted`}
                                                          arrow
                                                        >
                                                          <Box
                                                            sx={{
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <Box
                                                              sx={{
                                                                width: "90%",
                                                                m: "auto",
                                                                position:
                                                                  "relative",
                                                              }}
                                                            >
                                                              <LinearProgress
                                                                variant="determinate"
                                                                value={Math.round(
                                                                  value
                                                                )}
                                                                style={{
                                                                  height:
                                                                    "13px",
                                                                  borderRadius:
                                                                    "8px",
                                                                }}
                                                                sx={{
                                                                  backgroundColor:
                                                                    color.primary,
                                                                  "& .MuiLinearProgress-bar":
                                                                    {
                                                                      backgroundColor:
                                                                        color.green,
                                                                    },
                                                                }}
                                                              />
                                                            </Box>
                                                            <Box
                                                              sx={{
                                                                width: "10%", // Adjust width as needed
                                                                textAlign:
                                                                  "center",
                                                                marginLeft:
                                                                  "5px", // Add margin to separate text from progress bar
                                                                // fontWeight: "bold", // Customize font weight if needed
                                                              }}
                                                            >
                                                              {`${Math.round(
                                                                value
                                                              )}%`}
                                                            </Box>
                                                          </Box>
                                                        </MuiTooltip>
                                                      </Box>
                                                    ) : column.format &&
                                                      typeof value ===
                                                        "number" ? (
                                                      column.format(value)
                                                    ) : (
                                                      (column.label ===
                                                        "crop" && (
                                                        <div className="text-center d-flex justify-content-start align-items-center">
                                                          <div
                                                            style={{
                                                              backgroundColor:
                                                                shadesOfBlue[
                                                                  rowIndex %
                                                                    shadesOfBlue.length
                                                                ],
                                                              height: "15px",
                                                              width: "15px",
                                                              borderRadius:
                                                                pageStyling.imageBackgroundDivRadius,
                                                              margin:
                                                                "0px 10px",
                                                            }}
                                                          ></div>
                                                          <p
                                                            className="p-0 m-0"
                                                            style={{
                                                              color:
                                                                color.tableRowHeading,
                                                            }}
                                                          >
                                                            {value}
                                                          </p>
                                                        </div>
                                                      )) ||
                                                      value
                                                    )}
                                                  </TableCell>
                                                );
                                              }
                                            )}
                                          </TableRow>
                                        </MuiTooltip>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Paper>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* weekdays && Accepted Scans */}
              {(data.weekly_scans ||
                (data?.show_accepted &&
                  data?.percentage_accepted &&
                  data?.total_accepted)) && (
                <div className="flex-grow-1">
                  <div
                    className={`d-flex flex-row   w-100 ${
                      isSmallScreen && "flex-wrap"
                    }`}
                    style={{ gap: "20px" }}
                  >
                    {/* wekkdays scans */}
                    {data.weekly_scans && (
                      <div className="w-100">
                        {/* right Card */}
                        <div className={`card-Div h-100 w-100}`}>
                          <div
                            className=""
                            style={{
                              minWidth: isMediumScreen ? "auto" : "450px",
                            }}
                          >
                            <div
                              className="bar-chart-heading pt-2 ps-2"
                              style={{ color: color.taxtHeading }}
                            >
                              Week Days Assessment
                            </div>
                            <div
                              style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                                minWidth: isSmallScreen ? "auto" : "275px",
                                width: "auto",
                              }}
                            >
                              <ResponsiveContainer height={300}>
                                <BarChart
                                  data={dataPass(data.weekly_scans || [])}
                                >
                                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                  {/* <XAxis dataKey="name" axisLine={false} tickLine={false} /> */}
                                  <XAxis
                                    dataKey="name"
                                    interval={0}
                                    // textAnchor="end"
                                    tick={{
                                      fill: color.taxtHeading,
                                      fontSize: 18,
                                    }}
                                    axisLine={false}
                                    tickLine={{ display: "none" }}
                                    tickMargin={12}
                                  />
                                  <YAxis hide />
                                  <Tooltip
                                    content={<CustomTooltip />}
                                    cursor={{ fill: color.primary }}
                                  />

                                  <Bar
                                    dataKey="scans"
                                    fill="#8884d8"
                                    barSize={30}
                                    shape={<CustomBar />}
                                  >
                                    {data?.weekly_scans?.map((entry, index) => (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={
                                          top3rdScans.length &&
                                          entry.scans >= top3rdScans[2].scans
                                            ? color.secondary
                                            : color.iconBackgroundBlue
                                        }
                                      />
                                    ))}
                                  </Bar>
                                </BarChart>
                              </ResponsiveContainer>
                            </div>

                            <div className="top-div d-flex flex-row align-item-center ps-2 h-auto">
                              {/* <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center">
                    <div
                      style={{
                        backgroundColor: color.iconBackgroundBlue,
                        borderRadius: pageStyling.imageBackgroundDivRadius,
                        height:
                          pageStyling.iconBackgroundHeight,
                        width:
                          pageStyling.iconBackgroundWidth,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="ms-auto barChartGraphDesc"
                    >
                      <img
                        src={TotalRegionImg}
                        alt="All Assesment img"
                        className="barChartGraphDesc"
                        style={{
                          width: pageStyling.IconWidth,
                        }}
                      />
                    </div>
                  </div> */}
                              {/* circular progress bar div desc */}
                              <div
                                className="top-div-circular-progress-desc flex-grow-1"
                                style={{
                                  color: color.taxtHeadingLight,
                                  fontSize: isSmallSmallScreen
                                    ? "0.6125rem"
                                    : "0.9125rem",
                                  marginTop: "10px",
                                }}
                              >
                                <p
                                  style={{
                                    color: color.tableRowHeading,
                                    fontSize: "1rem",
                                    padding: "0px",
                                    margin: "0px",
                                  }}
                                >
                                  Most Assessment Day
                                </p>
                                Total{" "}
                                {formatInIndianRupees(
                                  data?.most_scans_weekday?.scans
                                )}{" "}
                                Scans on {` ${data?.most_scans_weekday?.day}`}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* accepted radial bar chart */}
                    {data?.show_accepted &&
                      data?.percentage_accepted &&
                      data?.total_accepted && (
                        <div className="w-100">
                          <div className="card-Div m-0 h-100">
                            <div>
                              {/* <div
                  className="bar-chart-heading pt-2 ps-2"
                  style={{ color: color.taxtHeading }}
                >
                  Accepted Assesments
                </div> */}

                              {/* <div className="d-flex flex-column  gap-2 ">
                  <p
                    className="bar-chart-heading m-0 p-0 d-flex align-items-center text-wrap  "
                    style={{
                      fontSize: "1rem",
                      color: color.taxtHeadingLight,
                    }}
                  ></p>
                </div>

                <div
                  className="bar-chart-heading pt-2 ps-2"
                  style={{ color: color.taxtHeading }}
                >
                  Total Scans
                  <p style={{ fontSize: "0.95rem", marginTop: "5px" }}>
                    <span
                      className="bar-chart-num"
                      style={{
                        marginRight: "5px",
                        color: color.taxtHeading,
                      }}
                    >
                      {formatInIndianRupees(data?.total_assessments)}
                    </span>
                  </p>
                </div> */}
                              {/* circular progress bar */}

                              <div
                                id="chart"
                                className="d-flex align-items-center justify-content-center position-relative h-100 mt-2"
                                style={{
                                  minWidth: isSmallScreen ? "240px" : "330px",
                                  width: "100%",
                                }}
                              >
                                <div className="d-flex flex-column gap-0 align-items-center justify-content-center position-relative">
                                  <CircularProgressbarWithChildren
                                    value={data.percentage_accepted}
                                    text={`${data.percentage_accepted.toFixed(
                                      2
                                    )}%`}
                                    circleRatio={0.7}
                                    strokeWidth={10}
                                    // styles={buildStyles({
                                    //   strokeLinecap: "butt",
                                    // })}
                                    styles={{
                                      trail: {
                                        strokeLinecap: "butt",
                                        transform: "rotate(-126deg)",
                                        transformOrigin: "center center",
                                        stroke: color.iconBackgroundred,
                                        // transition: "transform 5s",
                                        // opacity: circularOpacity?.rejected,
                                      },
                                      path: {
                                        strokeLinecap: "butt",
                                        transform: "rotate(-126deg)",
                                        transformOrigin: "center center",
                                        stroke: color.green,
                                        // opacity: circularOpacity.accepted,
                                      },
                                      text: {
                                        fill: color.tableRowHeading,
                                        fontWeight: 800,
                                        fontSize: "1.05rem",
                                        dominantBaseline: "middle", // Align text vertically center
                                        textAnchor: "middle", // Align text horizontally center
                                      },
                                    }}
                                  >
                                    <RadialSeparators
                                      count={100}
                                      style={{
                                        background: "#fff",
                                        width: "3px",
                                        // This needs to be equal to props.strokeWidth
                                        height: `${10}%`,
                                      }}
                                    />
                                  </CircularProgressbarWithChildren>
                                  {/* <div
                        className="bar-chart-heading"
                        style={{
                          position: "absolute",
                          bottom: "20px",
                          fontSize: "1.35rem",
                          fontWeight: 600,
                        }}
                      >
                        Accepted Scans
                      </div> */}
                                </div>
                              </div>
                              {/* accepted and rejected */}
                              <div>
                                {/* total assements */}
                                <div
                                  className="d-flex  flex-row flex-wrap z-3 "
                                  style={{ marginTop: "-40px" }}
                                >
                                  {/* accepted scans */}

                                  <div
                                    className="col-5"
                                    style={{ padding: "0px 0px 0px 8px" }}
                                    //   onMouseEnter={() => {
                                    //     handleMouseEnterCircluarProgress("accepted");
                                    //   }}
                                    //   onMouseLeave={() => {
                                    //     handleMouseLeaveCircluarProgress("accepted");
                                    //   }}
                                  >
                                    <div className="d-flex flex-column  w-100">
                                      {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}
                                      <div className="d-flex flex-column  gap-2 ">
                                        <div className="card-div-icon-div ">
                                          <div
                                            style={{
                                              backgroundColor:
                                                color.iconBackgroundgreen,
                                              borderRadius:
                                                pageStyling.imageBackgroundDivRadius,
                                              height:
                                                pageStyling.iconBackgroundHeight,
                                              width:
                                                pageStyling.iconBackgroundWidth,
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            className="me-auto "
                                            // onMouseLeave={}
                                          >
                                            <img
                                              src={accepted_scans}
                                              alt="Accepted img"
                                              // style={{ width: "33px" }}
                                            />
                                          </div>
                                        </div>
                                        <p
                                          className="bar-chart-heading m-0 p-0 d-flex align-items-center text-wrap  "
                                          style={{
                                            fontSize: "1rem",
                                            color: color.taxtHeadingLight,
                                          }}
                                        >
                                          Accepted Scans
                                        </p>
                                      </div>
                                      <span
                                        className="bar-chart-num"
                                        style={{
                                          fontSize: "1.5rem",
                                          color: color.taxtHeading,
                                        }}
                                      >
                                        {(data?.percentage_accepted).toFixed(2)}
                                        %
                                      </span>
                                      <span
                                        className="bar-chart-num mt-1"
                                        style={{
                                          fontSize: "1rem",
                                          color: color?.taxtHeadingLight,
                                        }}
                                      >
                                        {formatInIndianRupees(
                                          data?.total_accepted
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  {/* seperator */}
                                  <div className="d-flex flex-column col-2 align-items-center justify-content-center">
                                    {" "}
                                    <div
                                      style={{
                                        width: "1px",
                                        backgroundColor: "#4c4e6433",
                                        margin: "auto 10px",
                                        height: "40px",
                                        zIndex: "20",
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        borderRadius: "50%",
                                        backgroundColor: "#4c4e6433",
                                        padding: ".4rem .45rem",
                                        margin: "5px 0px",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      <p className="mb-0">VS</p>
                                    </div>
                                    <div
                                      style={{
                                        width: "1px",
                                        backgroundColor: "#4c4e6433",
                                        margin: "auto 10px",
                                        height: "40px",
                                      }}
                                    ></div>
                                  </div>
                                  {/* rejected scans */}
                                  <div
                                    className="col-5 shadow-none pe-2"
                                    // onMouseEnter={() => {
                                    //   handleMouseEnterCircluarProgress("rejected");
                                    // }}
                                    // onMouseLeave={() => {
                                    //   handleMouseLeaveCircluarProgress("rejected");
                                    // }}
                                  >
                                    <div className="d-flex flex-column align-items-end w-100">
                                      {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}
                                      <div className="d-flex flex-column  gap-2 align-items-center">
                                        <div className="card-div-icon-div ms-auto">
                                          <div
                                            style={{
                                              backgroundColor:
                                                color.iconBackgroundred,
                                              borderRadius:
                                                pageStyling.imageBackgroundDivRadius,
                                              height:
                                                pageStyling.iconBackgroundHeight,
                                              width:
                                                pageStyling.iconBackgroundWidth,
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            className="ms-auto "
                                          >
                                            <img
                                              src={rejected_scans}
                                              alt="rejected_scans img"
                                              // style={{ width: "33px" }}
                                            />
                                          </div>
                                        </div>

                                        <p
                                          className="bar-chart-heading m-0 p-0 text-wrap text-end  "
                                          style={{
                                            fontSize: "1rem",
                                            color: color.taxtHeadingLight,
                                          }}
                                        >
                                          Rejected Scans
                                        </p>
                                      </div>
                                      <span
                                        className="bar-chart-num"
                                        style={{
                                          fontSize: "1.5rem",
                                        }}
                                      >
                                        {(
                                          100 - data?.percentage_accepted
                                        ).toFixed(2)}
                                        %
                                      </span>
                                      <span
                                        className="bar-chart-num mt-1"
                                        style={{
                                          fontSize: "1rem",
                                          color: color.taxtHeadingLight,
                                        }}
                                      >
                                        {formatInIndianRupees(
                                          data.total_rejected
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {/* progress bar accepeted scans && rejected scans */}
                                {/* <div style={{ padding: "20px 5px 10px 5px" }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={data?.percentage_accepted}
                      colorAccept={color?.secondary}
                      colorReject={color?.green}
                              /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>

            {/* Monthly and Daily Avg count with weekly scans */}
            {false && (
              <div className="MonthDailyWeeklyScans m-top-bottom ">
                <div className="">
                  <div className="card-Div h-100 ">
                    <div className="d-flex flex-column ">
                      {/* Last month heading */}
                      <div
                        className="bar-chart-heading pt-2 ps-2"
                        style={{ color: color.taxtHeading }}
                      >
                        Last Month Assessment
                        <p style={{ fontSize: "0.95rem", marginTop: "5px" }}>
                          Total
                          <span
                            className="bar-chart-num"
                            style={{
                              margin: "0px 5px",
                              color: color.taxtHeading,
                            }}
                          >
                            {data?.last_month_data &&
                            data?.last_month_data["Total Assessments"]
                              ? formatInIndianRupees(
                                  data?.last_month_data["Total Assessments"]
                                    ?.total
                                )
                              : ""}
                          </span>
                          Assesments
                        </p>
                      </div>
                      {/* left card */}
                      <div className="d-flex flex-row flex-wrap">
                        {/* accepted radial bar */}
                        {data?.show_accepted &&
                          data?.last_month_data &&
                          data?.last_month_data["Total Assessments"] && (
                            <div
                              id="chart"
                              className="d-flex align-items-center justify-content-center position-relative "
                              style={{
                                minWidth: "250px",
                                margin: "auto",
                                padding: "10px",
                              }}
                            >
                              <div style={{ width: "250px", height: "auto" }}>
                                <CircularProgressbarWithChildren
                                  value={
                                    data?.last_month_data["Total Assessments"][
                                      "accepted percent"
                                    ]
                                  }
                                  text={`${data?.last_month_data["Total Assessments"]["accepted percent"]}%`}
                                  circleRatio={0.7}
                                  strokeWidth={12}
                                  // styles={buildStyles({
                                  //   strokeLinecap: "butt",
                                  // })}
                                  styles={{
                                    trail: {
                                      strokeLinecap: "butt",
                                      transform: "rotate(-126deg)",
                                      transformOrigin: "center center",
                                    },
                                    path: {
                                      strokeLinecap: "butt",
                                      transform: "rotate(-126deg)",
                                      transformOrigin: "center center",
                                      stroke: color.green,
                                    },
                                    text: {
                                      fill: color.tableRowHeading,
                                      fontWeight: 800,
                                      fontSize: "15px",
                                      dominantBaseline: "middle", // Align text vertically center
                                      textAnchor: "middle", // Align text horizontally center
                                    },
                                  }}
                                />
                                <div
                                  className="bar-chart-heading"
                                  style={{
                                    position: "absolute",
                                    bottom: "5px",
                                    left: "70px",
                                    fontSize: "1rem",
                                    fontWeight: 600,
                                    color: color.taxtHeadingLight,
                                  }}
                                >
                                  Accepted Scans
                                  <p
                                    style={{
                                      fontSize: "0.75rem",
                                      textAlign: "center",
                                    }}
                                  >
                                    {data?.last_month_data &&
                                    data?.last_month_data["Total Assessments"]
                                      ? formatInIndianRupees(
                                          data?.last_month_data[
                                            "Total Assessments"
                                          ]?.accepted
                                        )
                                      : ""}
                                    <span
                                      style={{
                                        fontSize: "0.65rem",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      /
                                      {data?.last_month_data &&
                                      data?.last_month_data["Total Assessments"]
                                        ? formatInIndianRupees(
                                            data?.last_month_data[
                                              "Total Assessments"
                                            ]?.total
                                          )
                                        : ""}
                                    </span>
                                  </p>
                                  <p></p>
                                </div>
                              </div>
                            </div>
                          )}

                        {/* right card */}
                        <div className=" flex-grow-1">
                          <div className="d-flex flex-column justify-content-center align-items-center ">
                            {/* top and accepted scans */}
                            <div
                              className="d-flex  flex-row z-3 w-100"
                              style={{ marginTop: "0px" }}
                            >
                              {data?.last_month_data &&
                                data?.last_month_data["Top Scan Date"] && (
                                  <div className="card-Div flex-grow-1 shadow-none ">
                                    <p className="m-0">
                                      <div className="bar-chart-desc-div w-100">
                                        {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}
                                        <div className="card-div-icon-div ">
                                          <div
                                            style={{
                                              backgroundColor:
                                                color.iconBackgroundBlue,
                                              borderRadius:
                                                pageStyling.imageBackgroundDivRadius,
                                            }}
                                          >
                                            <img
                                              src={allTicketsImg}
                                              alt="All Assesment img"
                                            />
                                          </div>
                                        </div>
                                        <span
                                          className="bar-chart-num mt-0"
                                          // style={{
                                          //   fontSize: "1.75rem",
                                          // }}
                                        >
                                          {formatInIndianRupees(
                                            data?.last_month_data[
                                              "Top Scan Date"
                                            ].scan
                                          )}
                                        </span>
                                        <p
                                          className="p-0 m-0"
                                          style={{
                                            color: color.taxtHeadingLight,
                                            fontWeight: 700,
                                          }}
                                        >
                                          {
                                            data?.last_month_data[
                                              "Top Scan Date"
                                            ].date
                                          }
                                        </p>
                                        <p
                                          className="bar-chart-heading m-0 p-0 "
                                          style={{
                                            color: color.taxtHeading,
                                            fontSize: "0.85rem",
                                          }}
                                        >
                                          Top Assesments
                                        </p>
                                      </div>
                                    </p>
                                  </div>
                                )}
                              {data?.last_month_data &&
                                data?.last_month_data["Top Scan Date"] && (
                                  <>
                                    <div className="barChartDivDescVerticalSeperator"></div>
                                    <div className="card-Div flex-grow-1 shadow-none ">
                                      <p className="m-0">
                                        <div className=" bar-chart-desc-div w-100">
                                          {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}
                                          <div className="card-div-icon-div ">
                                            <div
                                              style={{
                                                backgroundColor:
                                                  color.iconBackgroundBlue,
                                                borderRadius:
                                                  pageStyling.imageBackgroundDivRadius,
                                              }}
                                            >
                                              <img
                                                src={TotalCropsImg}
                                                alt="All Assesment img"
                                              />
                                            </div>
                                          </div>
                                          <span
                                            className="bar-chart-num mt-0"
                                            // style={{
                                            //   fontSize: "1.75rem",
                                            // }}
                                          >
                                            {formatInIndianRupees(
                                              data?.last_month_data[
                                                "Top Performing Crop"
                                              ].scan
                                            )}
                                          </span>
                                          <p
                                            className="p-0 m-0"
                                            style={{
                                              color: color.taxtHeadingLight,
                                              fontWeight: 700,
                                            }}
                                          >
                                            {
                                              data?.last_month_data[
                                                "Top Performing Crop"
                                              ].crop
                                            }
                                          </p>
                                          <p
                                            className="bar-chart-heading m-0 p-0 "
                                            style={{
                                              color: color.taxtHeading,
                                              fontSize: "0.85rem",
                                            }}
                                          >
                                            Top Performing Crop
                                          </p>
                                        </div>
                                      </p>
                                    </div>
                                  </>
                                )}
                            </div>
                            {/* list of active region , district , machine, active Crop */}
                            {data?.last_month_data && (
                              <div style={{ width: "80%", maxWidth: "500px" }}>
                                {[
                                  "Active Regions",
                                  "Active Districts",
                                  "Active Machines",
                                ].map(
                                  (values, index) =>
                                    data?.last_month_data[values] && (
                                      <div
                                        className="d-flex flex-row justify-content-between monlthly-active-div"
                                        style={{
                                          color: color.taxtHeadingLight,
                                        }}
                                        key={`monlthly-active-div-${index}`}
                                      >
                                        <p className="p-0 m-0">{values}</p>
                                        <span
                                          style={{
                                            backgroundColor: color.primary,
                                            color: color.tableRowHeading,
                                          }}
                                        >
                                          {data?.last_month_data[values]}
                                        </span>
                                      </div>
                                    )
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Top monthly region, district, machine */}
                        {data?.last_month_data && (
                          <div className="average-div d-flex flex-row flex-wrap align-items-center justify-content-between w-100 gap-0 mt-1">
                            {/* top Div */}
                            {data?.last_month_data["Top region"] && (
                              <div className="top-div d-flex flex-row align-item-center ">
                                <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                  <div
                                    style={{
                                      backgroundColor: color.iconBackgroundBlue,
                                      borderRadius:
                                        pageStyling.imageBackgroundDivRadius,
                                      height: pageStyling.iconBackgroundHeight,
                                      width: pageStyling.iconBackgroundWidth,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={TotalRegionImg}
                                      alt="All Assesment img"
                                      className=""
                                      style={{
                                        width: pageStyling.IconWidth,
                                      }}
                                    />
                                  </div>
                                </div>
                                {/* circular progress bar div desc */}
                                <div
                                  className="top-div-circular-progress-desc flex-grow-1"
                                  style={{
                                    color: color.taxtHeading,
                                  }}
                                >
                                  <p
                                    style={{
                                      color: color.tableRowHeading,
                                    }}
                                  >
                                    {data?.last_month_data["Top region"].region}
                                    <span
                                      style={{
                                        color: color.taxtHeadingLight,
                                      }}
                                    >
                                      (
                                      {formatInIndianRupees(
                                        data?.last_month_data["Top region"].scan
                                      )}
                                      scans)
                                    </span>
                                  </p>
                                  Top region
                                </div>
                              </div>
                            )}

                            {/* vertical seperator line */}
                            {/* <div className="barChartDivDescVerticalSeperator"></div> */}
                            {/* middel Div */}
                            {data?.last_month_data["Top district"] && (
                              <div className="top-div d-flex flex-row align-item-center ">
                                <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                  <div
                                    style={{
                                      backgroundColor: color.iconBackgroundBlue,
                                      borderRadius:
                                        pageStyling.imageBackgroundDivRadius,
                                      height: pageStyling.iconBackgroundHeight,
                                      width: pageStyling.iconBackgroundWidth,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    className="ms-auto barChartGraphDesc"
                                  >
                                    <img
                                      src={TotalDistrictImg}
                                      alt="All Assesment img"
                                      style={{
                                        width: pageStyling.IconWidth,
                                      }}
                                    />
                                  </div>
                                </div>
                                {/* circular progress bar div desc */}

                                <div
                                  className="top-div-circular-progress-desc flex-grow-1"
                                  style={{
                                    color: color.taxtHeading,
                                  }}
                                >
                                  <p
                                    style={{
                                      color: color.tableRowHeading,
                                    }}
                                  >
                                    {
                                      data?.last_month_data["Top district"]
                                        .district
                                    }
                                    <span
                                      style={{
                                        color: color.taxtHeadingLight,
                                      }}
                                    >
                                      (
                                      {formatInIndianRupees(
                                        data?.last_month_data["Top district"]
                                          .scan
                                      )}{" "}
                                      scans)
                                    </span>
                                  </p>
                                  Top District
                                </div>
                              </div>
                            )}
                            {/* Divider line */}

                            {/* <div className="barChartDivDescVerticalSeperator"></div> */}
                            {/* bottom Div */}
                            {data?.last_month_data["Top machine"] && (
                              <div className="top-div d-flex flex-row align-item-center ">
                                <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                  <div
                                    style={{
                                      backgroundColor: color.iconBackgroundBlue,
                                      borderRadius:
                                        pageStyling.imageBackgroundDivRadius,
                                      height: pageStyling.iconBackgroundHeight,
                                      width: pageStyling.iconBackgroundWidth,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    className="ms-auto barChartGraphDesc"
                                  >
                                    <img
                                      src={TotalMachinesImg}
                                      alt="All Assesment img"
                                      style={{
                                        width: pageStyling.IconWidth,
                                      }}
                                    />
                                  </div>
                                </div>
                                {/* circular progress bar div desc */}
                                <div
                                  className="top-div-circular-progress-desc flex-grow-1"
                                  style={{
                                    color: color.taxtHeading,
                                  }}
                                >
                                  <p
                                    style={{
                                      color: color.tableRowHeading,
                                    }}
                                  >
                                    {
                                      data?.last_month_data["Top machine"]
                                        .machine
                                    }
                                    <span
                                      style={{
                                        color: color.taxtHeadingLight,
                                      }}
                                    >
                                      (
                                      {formatInIndianRupees(
                                        data?.last_month_data["Top machine"]
                                          .scan
                                      )}{" "}
                                      scans)
                                    </span>
                                  </p>
                                  Top Machine
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Last 10 days */}
            {data?.last_10_days_data && (
              <div className=" m-top-bottom ">
                <div className="">
                  <div className="card-Div h-100 ">
                    <div className="d-flex flex-column w-100">
                      {/* Last month heading */}
                      <div
                        className="bar-chart-heading pt-2 ps-2"
                        style={{ color: color.taxtHeading }}
                      >
                        Last 10 Days Assessment
                        {/* <p
                      style={{
                        fontSize: "0.8rem",
                        marginTop: "5px",
                        marginBottom: "0px",
                      }}
                    >
                      <span
                        className="bar-chart-num"
                        style={{
                          marginRight: "5px",
                          color: color.taxtHeading,
                        }}
                      >
                        {formatInIndianRupees(
                          data?.last_10_days_data?.active_data?.total
                        )}
                      </span>
                      Total Assesments
                    </p> */}
                      </div>

                      {/* tota/accepted scans & active location & active Crop & active RO/DO/Machine */}
                      <div
                        className={`d-flex flex-row ${
                          islageScreen && "flex-wrap"
                        }`}
                      >
                        <div
                          className={`flex-grow-1 ${
                            islageScreen ? "border-bottom w-100" : "border-end"
                          }`}
                        >
                          <div className="d-flex flex-column overflow-hidden">
                            {/* tota/accepted scans & active location & active Crop & active RO/DO/Machine */}
                            <div className="last-10-days-desc overflow-auto w-100 ">
                              <div
                                className="d-flex flex-row z-3 overflow-visible"
                                style={{
                                  marginTop: "0px",
                                  minWidth: "fit-content",
                                  width: "50px",
                                }}
                              >
                                {/* Total Scans */}
                                {data?.last_10_days_data?.active_data?.total !==
                                  undefined &&
                                  data?.last_10_days_data?.active_data
                                    ?.total !== null && (
                                    <div className="card-Div  shadow-none overflow-hidden">
                                      <p className="m-0 top-10-days-div">
                                        <div className=" last10days-desc-div w-100">
                                          {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}
                                          <span
                                            key={test}
                                            className="bar-chart-num rotatingText-adjective"
                                            style={{
                                              fontSize: "1.75rem",
                                            }}
                                          >
                                            {formatInIndianRupees(
                                              data?.last_10_days_data
                                                ?.active_data?.total
                                            )}
                                          </span>
                                          <p
                                            className="bar-chart-heading m-0 p-0"
                                            style={{
                                              fontSize: "1rem",
                                              color: color.taxtHeading,
                                            }}
                                          >
                                            Total Scans
                                          </p>
                                        </div>
                                      </p>
                                    </div>
                                  )}
                                {data?.last_10_days_data?.active_data
                                  ?.accepted !== undefined &&
                                  data?.last_10_days_data?.active_data
                                    ?.accepted !== null &&
                                  data.show_accepted && (
                                    <>
                                      <div className="barChartDivDescVerticalSeperator"></div>
                                      {/* Accepted Scans */}
                                      <div className="card-Div  shadow-none ">
                                        <p className="m-0 top-10-days-div">
                                          <div className=" last10days-desc-div w-100">
                                            {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}
                                            <span
                                              key={test}
                                              className="bar-chart-num rotatingText-adjective"
                                              style={{
                                                fontSize: "1.75rem",
                                              }}
                                            >
                                              {formatInIndianRupees(
                                                data?.last_10_days_data
                                                  ?.active_data?.accepted
                                              )}
                                            </span>
                                            <p
                                              className="bar-chart-heading m-0 p-0"
                                              style={{
                                                fontSize: "1rem",
                                                color: color.taxtHeading,
                                              }}
                                            >
                                              Accepted Scans
                                            </p>
                                          </div>
                                        </p>
                                      </div>
                                    </>
                                  )}
                                {data?.last_10_days_data?.active_data
                                  ?.active_regions !== undefined &&
                                  data?.last_10_days_data?.active_data
                                    ?.active_regions !== null && (
                                    <>
                                      <div className="barChartDivDescVerticalSeperator"></div>
                                      {/* Accepted Region */}
                                      <div className="card-Div  shadow-none ">
                                        <p className="m-0 top-10-days-div">
                                          <div className=" last10days-desc-div w-100">
                                            {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}
                                            <span
                                              key={test}
                                              className="bar-chart-num rotatingText-adjective"
                                              style={{
                                                fontSize: "1.75rem",
                                              }}
                                            >
                                              {formatInIndianRupees(
                                                data?.last_10_days_data
                                                  ?.active_data?.active_regions
                                              )}
                                            </span>
                                            <p
                                              className="bar-chart-heading m-0 p-0 "
                                              style={{
                                                fontSize: "1rem",
                                                color: color.taxtHeading,
                                              }}
                                            >
                                              Active Regions
                                            </p>
                                          </div>
                                        </p>
                                      </div>
                                    </>
                                  )}
                                {data?.last_10_days_data?.active_data
                                  ?.active_districts !== undefined &&
                                  data?.last_10_days_data?.active_data
                                    ?.active_districts !== null && (
                                    <>
                                      <div className="barChartDivDescVerticalSeperator"></div>
                                      {/* Accepted District */}
                                      <div className="card-Div  shadow-none ">
                                        <p className="m-0 top-10-days-div">
                                          <div className=" last10days-desc-div w-100">
                                            {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}
                                            <span
                                              key={test}
                                              className="bar-chart-num rotatingText-adjective"
                                              style={{
                                                fontSize: "1.75rem",
                                              }}
                                            >
                                              {formatInIndianRupees(
                                                data?.last_10_days_data
                                                  ?.active_data
                                                  ?.active_districts
                                              )}
                                            </span>
                                            <p
                                              className="bar-chart-heading m-0 p-0"
                                              style={{
                                                fontSize: "1rem",
                                                color: color.taxtHeading,
                                              }}
                                            >
                                              Active Districts
                                            </p>
                                          </div>
                                        </p>
                                      </div>
                                    </>
                                  )}
                                {data?.last_10_days_data?.active_data
                                  ?.active_machines !== undefined &&
                                  data?.last_10_days_data?.active_data
                                    ?.active_machines !== null && (
                                    <>
                                      <div className="barChartDivDescVerticalSeperator"></div>
                                      {/* Accepted Locations */}
                                      <div className="card-Div  shadow-none ">
                                        <p className="m-0 top-10-days-div">
                                          <div className=" last10days-desc-div w-100">
                                            {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}
                                            <span
                                              key={test}
                                              className="bar-chart-num rotatingText-adjective"
                                              style={{
                                                fontSize: "1.75rem",
                                              }}
                                            >
                                              {formatInIndianRupees(
                                                data?.last_10_days_data
                                                  ?.active_data?.active_machines
                                              )}
                                            </span>
                                            <p
                                              className="bar-chart-heading m-0 p-0"
                                              style={{
                                                fontSize: "1rem",
                                                color: color.taxtHeading,
                                              }}
                                            >
                                              Active Locations
                                            </p>
                                          </div>
                                        </p>
                                      </div>
                                    </>
                                  )}
                                {data?.last_10_days_data?.active_data
                                  ?.active_crops !== undefined &&
                                  data?.last_10_days_data?.active_data
                                    ?.active_crops !== null && (
                                    <>
                                      <div className="barChartDivDescVerticalSeperator"></div>
                                      {/* Accepted Crops */}
                                      <div className="card-Div  shadow-none ">
                                        <p className="m-0 top-10-days-div">
                                          <div className=" last10days-desc-div w-100">
                                            {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}
                                            <span
                                              key={test}
                                              className="bar-chart-num rotatingText-adjective"
                                              style={{
                                                fontSize: "1.75rem",
                                              }}
                                            >
                                              {formatInIndianRupees(
                                                data?.last_10_days_data
                                                  ?.active_data?.active_crops
                                              )}
                                            </span>
                                            <p
                                              className="bar-chart-heading m-0 p-0"
                                              style={{
                                                fontSize: "1rem",
                                                color: color.taxtHeading,
                                              }}
                                            >
                                              Active Crops
                                            </p>
                                          </div>
                                        </p>
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                            {/* total and accepted bar graph bar */}
                            <div className="overflow-auto style-scrollbar">
                              {/* <div
                            className="last-10-days-bar-graph "
                            style={{ width: "250px", minWidth: "fit-content" }}
                          > */}
                              <div
                                className=""
                                style={{
                                  width: "100%",
                                  minWidth: "700px",
                                  overflow: "visible",
                                }}
                              >
                                {/* <ResponsiveContainer width="100%" height={300} sx={{overflow:"visible"}}> */}
                                {
                                  <ResponsiveContainer
                                    width="100%"
                                    height={300}
                                  >
                                    <ComposedChart
                                      data={
                                        data?.last_10_days_data
                                          ?.daywise_graph || []
                                      }
                                      margin={{
                                        top: 20,
                                        right: 20,
                                        bottom: 20,
                                        left: 20,
                                      }}
                                    >
                                      <CartesianGrid
                                        vertical={false}
                                        strokeDasharray="10"
                                        // stroke="#4c4e6614"
                                        stroke={color.navBorderBottom}
                                        // stroke="none" // Set stroke to "none" to remove grid lines
                                      />
                                      <XAxis
                                        dataKey="day"
                                        interval={0}
                                        angle={isSmallScreen ? -20 : 0}
                                        // textAnchor="end"
                                        tick={{
                                          fill: color.taxtHeading,
                                          fontSize: 12,
                                        }}
                                        axisLine={false}
                                        tickLine={{ display: "none" }}
                                        // tickFormatter={(value) => (value < 0 ? -value : value)} // Ensure positive ticks
                                      />
                                      <YAxis
                                        domain={["auto", "auto"]}
                                        tick={{
                                          fill: color.taxtHeading,
                                          fontSize: 11,
                                        }}
                                        axisLine={false}
                                        label={{
                                          value: "Scans",
                                          angle: -90,
                                          position: "insideLeft",
                                        }}
                                        tickLine={{ display: "none" }}
                                        tickFormatter={formatYAxis} // Ensure positive ticks
                                      />
                                      <Tooltip content={<CustomTooltip />} />

                                      <Legend
                                        layout="horizontal"
                                        verticalAlign="bottom"
                                        align="center"
                                        margin={{
                                          top: 10,
                                          left: 10,
                                          right: 10,
                                          bottom: 10,
                                        }}
                                        wrapperStyle={{
                                          position: "absolute",
                                          bottom: "8px",
                                          left: "50%",
                                          transform: "translateX(-50%)",
                                        }}
                                        iconSize={10}
                                        iconType="circle"
                                        // height={30}
                                        content={customLegend}
                                        // onMouseEnter={handleMouseEnterLegend}
                                        // onMouseLeave={handleMouseLeaveLegend}
                                      />
                                      <Bar
                                        dataKey="total"
                                        fill={color.secondary}
                                        stackId="stack"
                                        barSize={20}
                                        radius={[5, 5, 0, 0]}
                                        // onMouseEnter={handleMouseEnter}
                                        // onMouseLeave={handleMouseLeave}
                                      >
                                        {data?.last_10_days_data?.daywise_graph?.map(
                                          (entry, index) => (
                                            <Cell
                                              key={`10-days-bar-cell-${index}`}
                                              fill={
                                                index === activeIndex
                                                  ? color.iconBackgroundBlue
                                                  : color.secondary
                                              }
                                              fillOpacity={opacity.accepted}
                                              onMouseEnter={() =>
                                                handleMouseEnter(data, index)
                                              }
                                              onMouseLeave={handleMouseLeave}
                                            />
                                          )
                                        )}
                                      </Bar>
                                      {data.show_accepted && (
                                        <Line
                                          type="natural"
                                          dataKey="accepted"
                                          stroke={color.green}
                                          strokeWidth={4}
                                          dot={{
                                            stroke: color.green,
                                            strokeWidth: 2,
                                            r: 6,
                                            opacity: opacity.total,
                                          }}
                                          activeDot={{
                                            stroke: color.textWhiteColor,
                                            strokeWidth: 2,
                                            r: 6,
                                          }}
                                          strokeOpacity={opacity.total}
                                          onMouseEnter={handleMouseEnter}
                                          onMouseLeave={handleMouseLeave}
                                        />
                                      )}
                                    </ComposedChart>
                                  </ResponsiveContainer>
                                }
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <div className="card-Div border-none shadow-none p-0 my-0">
                            {/* cropwise assesmenty heading */}
                            <div
                              className="bar-chart-heading ps-2"
                              style={{ color: color.taxtHeading }}
                            >
                              Crop Wise Assessment
                              {/* <p style={{ fontSize: "0.8rem", marginTop: "5px" }}>
                            <span
                              className="bar-chart-num"
                              style={{
                                marginRight: "5px",
                                color: color.taxtHeading,
                              }}
                            >
                              {formatInIndianRupees(
                                data?.last_10_days_data?.active_data?.total
                              )}
                            </span>
                            Total Assesments
                          </p> */}
                            </div>
                            <div
                              className="pie-chart-div d-flex align-items-center justify-content-center"
                              style={{ marginBottom: "-40px" }}
                            >
                              <PieChart width={400} height={400}>
                                <Pie
                                  activeIndex={activePieIndex}
                                  activeShape={renderActiveShape}
                                  data={
                                    data?.last_10_days_data?.cropwise_chart ||
                                    []
                                  }
                                  cx={200}
                                  cy={175}
                                  innerRadius={100}
                                  outerRadius={130}
                                  fill={color.secondary}
                                  dataKey="total"
                                  startAngle={90}
                                  endAngle={-270}
                                  onMouseEnter={onPieEnter}
                                  onMouseLeave={onPieLeave}
                                >
                                  {data?.last_10_days_data?.cropwise_chart.map(
                                    (entry, index) => (
                                      <Cell
                                        key={`pie-cell-${index}`}
                                        fill={
                                          shadesOfBlue[
                                            index % shadesOfBlue.length
                                          ]
                                        }
                                      />
                                    )
                                  )}
                                </Pie>
                              </PieChart>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Top monthly region, district, machine */}
                      <div>
                        <div className="average-div d-flex flex-row flex-wrap align-items-center justify-content-start w-100 gap-0 mt-1">
                          {/* top Div */}
                          {data?.last_10_days_data?.top_data?.region && (
                            <div className="top-div top-monthly-div d-flex flex-row align-item-center flex-grow-1">
                              <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                <div
                                  style={{
                                    backgroundColor: color.iconBackgroundBlue,
                                    borderRadius:
                                      pageStyling.imageBackgroundDivRadius,
                                    height: pageStyling.iconBackgroundHeight,
                                    width: pageStyling.iconBackgroundWidth,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    src={TotalRegionImg}
                                    alt="All Assesment img"
                                    className=""
                                    style={{
                                      width: pageStyling.IconWidth,
                                    }}
                                  />
                                </div>
                              </div>
                              {/* circular progress bar div desc */}
                              <div
                                className="top-div-circular-progress-desc flex-grow-1"
                                style={{
                                  color: color.taxtHeading,
                                }}
                              >
                                <p
                                  style={{
                                    color: color.tableRowHeading,
                                  }}
                                >
                                  {data?.last_10_days_data?.top_data?.region}
                                  {/* <span
                                  style={{
                                    color: color.taxtHeadingLight,
                                  }}
                                >
                                  (
                                  {formatInIndianRupees(
                                    data?.last_10_days_data?.top_data?.region_scan
                                  )}
                                  scans)
                                </span> */}
                                </p>
                                Top region
                              </div>
                            </div>
                          )}

                          {/* vertical seperator line */}
                          {/* <div className="barChartDivDescVerticalSeperator"></div> */}
                          {/* middel Div */}
                          {data?.last_10_days_data?.top_data?.district && (
                            <div className=" top-div top-monthly-div d-flex flex-row align-item-center flex-grow-1">
                              <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                <div
                                  style={{
                                    backgroundColor: color.iconBackgroundBlue,
                                    borderRadius:
                                      pageStyling.imageBackgroundDivRadius,
                                    height: pageStyling.iconBackgroundHeight,
                                    width: pageStyling.iconBackgroundWidth,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="ms-auto barChartGraphDesc"
                                >
                                  <img
                                    src={TotalDistrictImg}
                                    alt="All Assesment img"
                                    style={{
                                      width: pageStyling.IconWidth,
                                    }}
                                  />
                                </div>
                              </div>
                              {/* circular progress bar div desc */}

                              <div
                                className="top-div-circular-progress-desc flex-grow-1"
                                style={{
                                  color: color.taxtHeading,
                                }}
                              >
                                <p
                                  style={{
                                    color: color.tableRowHeading,
                                  }}
                                >
                                  {data?.last_10_days_data?.top_data?.district}
                                  {/* <span
                                  style={{
                                    color: color.taxtHeadingLight,
                                  }}
                                >
                                  (
                                  {formatInIndianRupees(
                                    data?.last_month_data["Top district"].scan
                                  )}{" "}
                                  scans)
                                </span> */}
                                </p>
                                Top District
                              </div>
                            </div>
                          )}
                          {/* Divider line */}

                          {/* <div className="barChartDivDescVerticalSeperator"></div> */}
                          {/* bottom Div */}
                          {data?.last_10_days_data?.top_data?.machine && (
                            <div className=" top-div top-monthly-div d-flex flex-row align-item-center flex-grow-1">
                              <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                <div
                                  style={{
                                    backgroundColor: color.iconBackgroundBlue,
                                    borderRadius:
                                      pageStyling.imageBackgroundDivRadius,
                                    height: pageStyling.iconBackgroundHeight,
                                    width: pageStyling.iconBackgroundWidth,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="ms-auto barChartGraphDesc"
                                >
                                  <img
                                    src={TotalMachinesImg}
                                    alt="All Assesment img"
                                    style={{
                                      width: pageStyling.IconWidth,
                                    }}
                                  />
                                </div>
                              </div>
                              {/* circular progress bar div desc */}
                              <div
                                className="top-div-circular-progress-desc flex-grow-1"
                                style={{
                                  color: color.taxtHeading,
                                }}
                              >
                                <p
                                  style={{
                                    color: color.tableRowHeading,
                                  }}
                                >
                                  {data?.last_10_days_data?.top_data?.machine}

                                  {/* <span
                                  style={{
                                    color: color.taxtHeadingLight,
                                  }}
                                >
                                  (
                                  {formatInIndianRupees(
                                    data?.last_month_data["Top machine"].scan
                                  )}{" "}
                                  scans)
                                </span> */}
                                </p>
                                Top Location
                              </div>
                            </div>
                          )}

                          {/* crop data */}
                          {data?.last_10_days_data?.top_data?.crop && (
                            <div className=" top-div top-monthly-div d-flex flex-row align-item-center flex-grow-1">
                              <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center mx-2">
                                <div
                                  style={{
                                    backgroundColor: color.iconBackgroundBlue,
                                    borderRadius:
                                      pageStyling.imageBackgroundDivRadius,
                                    height: pageStyling.iconBackgroundHeight,
                                    width: pageStyling.iconBackgroundWidth,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="ms-auto barChartGraphDesc"
                                >
                                  <img
                                    src={TotalCropsImg}
                                    alt="All Assesment img"
                                    style={{
                                      width: pageStyling.IconWidth,
                                    }}
                                  />
                                </div>
                              </div>
                              {/* circular progress bar div desc */}
                              <div
                                className="top-div-circular-progress-desc flex-grow-1"
                                style={{
                                  color: color.taxtHeading,
                                }}
                              >
                                <p
                                  style={{
                                    color: color.tableRowHeading,
                                  }}
                                >
                                  {data?.last_10_days_data?.top_data?.crop}

                                  {/* <span
                                  style={{
                                    color: color.taxtHeadingLight,
                                  }}
                                >
                                  (
                                  {formatInIndianRupees(
                                    data?.last_month_data["Top machine"].scan
                                  )}{" "}
                                  scans)
                                </span> */}
                                </p>
                                Top crop
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* map */}
            {data.machine_data && data.region_crausal_data && (
              <div
                className="map-region-main-div "
                style={{
                  gridTemplateColumns:
                    (data.machine_data || data.machine_data.length !== 0) &&
                    (data.region_crausal_data ||
                      data.region_crausal_data.length !== 0)
                      ? "0.7fr 1fr"
                      : "1fr",
                }}
              >
                {data.machine_data && data.machine_data.length !== 0 && (
                  <div className="flex-grow-1">
                    <MapChart
                      color={color}
                      shadesOfBlue={shadesOfBlue}
                      machineData={data.machine_data || null}
                    />
                  </div>
                )}
                {/* region analysis */}
                {data.region_crausal_data &&
                  data.region_crausal_data.length !== 0 && (
                    <div className="">
                      {/* right Card */}
                      <div
                        className="card-Div"
                        style={{
                          backgroundColor: color.secondary,
                          height: "99%",
                        }}
                      >
                        <RegionCrousel
                          test={test}
                          color={color}
                          data={data.region_crausal_data || []}
                          showAccepted={data.show_accepted}
                          pageStyling={pageStyling}
                        />
                      </div>
                    </div>
                  )}
              </div>
            )}

            {/* Region and District table */}
            {(data?.top_district_wise_data || data?.top_machine_wise_data) && (
              <div className="d-flex m-top-bottom flex-wrap gap-20 mb-5 ">
                {/* <div className="flex-grow-1  ">
              <div className="card-Div h-100 p-0">
                <div className="top-table-div">
                  <div
                    className="bar-chart-heading pt-3 ps-3"
                    style={{ color: color.taxtHeading }}
                  >
                    Top Region Scorers
                  </div>
                  <SimpleTable
                    headers={tableRegionheaders}
                    rows={tableRegionRows}
                    color={color}
                  />
                </div>
              </div>
            </div> */}

                {data?.top_district_wise_data &&
                  data?.top_district_wise_data?.length !== 0 &&
                  data?.top_district_header && (
                    <div
                      className={`static-header-chart-div flex-grow-1 ${
                        isSmallScreen ? "w-100" : "w-auto"
                      }`}
                    >
                      <div className="card-Div h-100 p-0 ">
                        <div className="top-table-div ">
                          <div
                            className="bar-chart-heading pt-3 ps-3"
                            style={{
                              color: color.taxtHeading,
                              lineHeight: "1.2",
                            }}
                          >
                            Top District Scorers
                          </div>
                          <div
                            className="ps-3"
                            style={{
                              color: "#838485",
                              fontSize: "14px",
                              textTransform: "none",
                              marginBottom: "4px",
                            }}
                          >
                            Top districts with highest procurement
                          </div>
                          <Paper
                            sx={{
                              width: "100%",
                              overflow: "auto",
                              border: "none",
                              boxShadow: "none",
                            }}
                          >
                            <TableContainer
                              sx={{
                                borderRadius: "15px",
                                overflow: "auto",
                                // border: "2px solid" + color.primary,
                                border: "none",
                                boxShadow: "none",
                              }}
                            >
                              <Table
                                stickyHeader
                                aria-label="sticky table"
                                style={{ overflow: "auto" }}
                              >
                                <TableHead>
                                  <TableRow>
                                    {tableDistrictheaders?.map(
                                      (column, headerIndex) =>
                                        column.id !== "percent" && (
                                          <TableCell
                                            key={`table-header-${headerIndex}-${headerIndex}`}
                                            align={column.align}
                                            style={{
                                              minWidth: column.minWidth,
                                              color: color.tableRowHeading,
                                              padding: "7px",
                                              paddingLeft:
                                                headerIndex === 0
                                                  ? "20px"
                                                  : "10px",
                                              paddingRight:
                                                headerIndex ===
                                                headers.length - 1
                                                  ? "20px"
                                                  : "10px",
                                              fontWeight: 400,
                                              fontSize: "0.9rem",
                                              lineHeight: "1.959rem",
                                              letterSpacing: "0.17px",
                                            }}
                                            className="bar-chart-heading"
                                          >
                                            {column.label.replace("_", " ")}
                                          </TableCell>
                                        )
                                    )}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {tableDistrictRows?.map((row, rowIndex) => (
                                    <MuiTooltip
                                      key={`table-${rowIndex}`}
                                      title={row["crop"]}
                                      placement="top"
                                      arrow
                                    >
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                      >
                                        {tableDistrictheaders.map(
                                          (column, rowDivIndex) => {
                                            const value = row[column.id];
                                            return (
                                              <TableCell
                                                key={`table-row-${rowDivIndex}`}
                                                align={column.align}
                                                sx={{
                                                  minWidth: column.minWidth,
                                                  borderBottom: "none",
                                                  padding: "10px",
                                                  color:
                                                    column.label === "name"
                                                      ? color.tableRowHeading
                                                      : color.taxtHeading,
                                                  paddingLeft:
                                                    rowDivIndex === 0
                                                      ? "20px"
                                                      : "10px",
                                                  paddingRight:
                                                    rowDivIndex ===
                                                    tableDistrictheaders.length -
                                                      1
                                                      ? "20px"
                                                      : "10px",
                                                  fontWeight:
                                                    rowDivIndex === 0
                                                      ? 600
                                                      : 500,
                                                  fontSize: "1rem",
                                                  lineHeight: 1.429,
                                                  letterSpacing: "0.15px",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {column.format(value)}
                                              </TableCell>
                                            );
                                          }
                                        )}
                                      </TableRow>
                                    </MuiTooltip>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Paper>
                        </div>
                      </div>
                    </div>
                  )}
                {data?.top_machine_wise_data &&
                  data?.top_machine_wise_data?.length !== 0 &&
                  data?.top_machine_header && (
                    <div
                      className={` static-header-chart-div flex-grow-1 ${
                        isSmallScreen ? "w-100" : "w-auto"
                      }`}
                    >
                      <div className="card-Div h-100 p-0 ">
                        <div className="top-table-div ">
                          <div
                            className="bar-chart-heading pt-3 ps-3"
                            style={{
                              color: color.taxtHeading,
                              lineHeight: "1.2",
                            }}
                          >
                            Top Machine Scorers
                          </div>
                          <div
                            className="ps-3"
                            style={{
                              color: "#838485",
                              fontSize: "14px",
                              textTransform: "none",
                              marginBottom: "4px",
                            }}
                          >
                            Top machines with highest procurement
                          </div>
                          {/* <div className="overflow-auto">
                    <SimpleTable
                      headers={tableMachineheaders}
                      rows={tableMachineRows}
                      color={color}
                    />
                  </div> */}

                          <Paper
                            sx={{
                              width: "100%",
                              overflow: "auto",
                              border: "none",
                              boxShadow: "none",
                            }}
                          >
                            <TableContainer
                              sx={{
                                borderRadius: "15px",
                                overflow: "auto",
                                // border: "2px solid" + color.primary,
                                border: "none",
                                boxShadow: "none",
                              }}
                            >
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    {tableMachineheaders?.map(
                                      (column, headerIndex) =>
                                        column.id !== "percent" && (
                                          <TableCell
                                            key={`top-table-header-${headerIndex}-${headerIndex}`}
                                            align={column.align}
                                            style={{
                                              minWidth: column.minWidth,
                                              color: color.tableRowHeading,
                                              padding: "7px",
                                              paddingLeft:
                                                headerIndex === 0
                                                  ? "20px"
                                                  : "10px",
                                              paddingRight:
                                                headerIndex ===
                                                headers.length - 1
                                                  ? "20px"
                                                  : "10px",
                                              fontWeight: 400,
                                              lineHeight: "1.959rem",
                                              fontSize: "0.9rem",
                                              letterSpacing: "0.17px",
                                            }}
                                            className="bar-chart-heading"
                                          >
                                            {column.label.replace("_", " ")}
                                          </TableCell>
                                        )
                                    )}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {tableMachineRows?.map((row, rowIndex) => (
                                    <MuiTooltip
                                      key={`table-${rowIndex}`}
                                      title={row["crop"]}
                                      placement="top"
                                      arrow
                                    >
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                      >
                                        {tableMachineheaders.map(
                                          (column, rowDivIndex) => {
                                            const value = row[column.id];
                                            return (
                                              <TableCell
                                                key={`table-row-${rowDivIndex}`}
                                                align={column.align}
                                                sx={{
                                                  minWidth: column.minWidth,
                                                  borderBottom: "none",
                                                  padding: "10px",
                                                  color:
                                                    column.label === "name"
                                                      ? color.tableRowHeading
                                                      : color.taxtHeading,
                                                  paddingLeft:
                                                    rowDivIndex === 0
                                                      ? "20px"
                                                      : "10px",
                                                  paddingRight:
                                                    rowDivIndex ===
                                                    tableMachineheaders.length -
                                                      1
                                                      ? "20px"
                                                      : "10px",
                                                  fontWeight:
                                                    rowDivIndex === 0
                                                      ? 600
                                                      : 500,
                                                  fontSize: "1rem",
                                                  lineHeight: 1.429,
                                                  letterSpacing: "0.15px",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {column.format(value)}
                                              </TableCell>
                                            );
                                          }
                                        )}
                                      </TableRow>
                                    </MuiTooltip>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Paper>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )}
          </>
        )) || (
          <div className="container no-ticket-found-div text-center count_heading_div">
            <div className="d-flex align-items-center justify-content-center flex-column">
              {/* <i
                className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"
                style={{ color: color.secondary }}
              ></i> */}
              <img src={noDataIMG} />
              <p>No Data Found</p>
            </div>
          </div>
        )}
    </>
  );
};

export default Overview;

import React, { useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import close from "../../assets/images/close.svg";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import ContextState from "../contextApi/ContextState";

const EditDataVariationModal = ({
  editDataVariation,
  handleClose,
  handleReloadData,
}) => {
  const crop_details = useSelector((state) => state.userFilters?.data.data);
  const { fetchApi, openSnackbar, modalControllerRef } =
    useContext(ContextState);
  const [modalState, setModalState] = useState({
    crop: "",
    parameter: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editDataVariation) {
      setModalState({
        crop: editDataVariation?.crop || "",
        parameter: editDataVariation?.parameter || "",
      });
    }
    return () => {};
  }, [editDataVariation]);

  const crop_list = Object.keys(crop_details?.param_data || {});
  const para_list = Object.keys(
    crop_details?.param_data?.[modalState?.crop]?.client_params || {}
  );

  const getCropId = (cropName) => {
    for (const [key, value] of Object.entries(crop_details?.param_data)) {
      if (value.matt_crop === cropName) {
        return value.crop_id;
      }
    }
    return null;
  };

  const getParamId = (cropName, paramName) => {
    const cropData = crop_details.param_data[cropName];
    if (cropData && cropData.client_params[paramName]) {
      return cropData.client_params[paramName];
    }
    return null;
  };

  const fetching_edit_param_variation = async () => {
    try {
      setLoading(true);
      const payload = {
        ticket_id: editDataVariation?.ticket_id,
        id: editDataVariation?.id,
        crop_id: getCropId(modalState.crop),
        param_id: getParamId(modalState.crop, modalState.parameter),
      };

      const response = await fetchApi(
        "edit_param_variation",
        "POST",
        payload,
        true
      );

      if (response.statusCode === 200) {
        openSnackbar(response?.data?.message);
        handleReloadData();
        handleClose();
      } else {
        openSnackbar(response?.msg);
      }
    } catch (error) {
      if (modalControllerRef.current) {
        openSnackbar(
          "Your data is on its way. Thank you for your patience.",
          "warning"
        );
      } else {
        openSnackbar("Some Error Occured!", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={editDataVariation?.isOpen}
      onClose={handleClose}
      style={{
        position: "fixed",
        height: "100%",
      }}
    >
      <Box
        style={{
          position: "relative",
          zIndex: "1111",
          right: "0",
          height: "auto",
          top: "10%",
          margin: "auto",
          overflowY: "auto",
          borderRadius: "10px",
          backgroundColor: "#ffffff",
          width: "85vw",
          maxWidth: "880px",
        }}
      >
        {/* Modal Header */}
        <div
          style={{
            padding: "16px 24px",
            borderBottom: "1px solid #f5f5f5",
          }}
        >
          <div className="d-flex justify-content-between">
            <h5 className="m-0">Edit Data Variation Details</h5>
            <div
              onClick={() => {
                handleClose();
              }}
            >
              <img
                src={close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
        {/* Modal Header ends... */}

        {/* Modal Body Starts .... */}
        <div className="modal_body px-2 mt-2">
          <div className="d-flex flex-column flex-sm-row">
            <div className="p-2 w-100">
              <FormControl fullWidth>
                <InputLabel id="crop-select-label">Crop</InputLabel>
                <Select
                  labelId="crop-select-label"
                  id="crop-select"
                  name="crop"
                  value={modalState.crop}
                  label="Crop"
                  onChange={(e) => {
                    setModalState((prev) => ({
                      ...prev,
                      crop: e.target.value,
                      parameter: "",
                    }));
                  }}
                >
                  {crop_list.map((each_crop) => (
                    <MenuItem key={each_crop} value={each_crop}>
                      {each_crop}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="p-2 w-100">
              <FormControl fullWidth>
                <InputLabel id="parameter-select-label">Parameter</InputLabel>
                <Select
                  labelId="parameter-select-label"
                  id="parameter-select"
                  name="parameter"
                  value={modalState.parameter}
                  label="Parameter"
                  onChange={(e) => {
                    setModalState((prev) => ({
                      ...prev,
                      parameter: e.target.value,
                    }));
                  }}
                >
                  {para_list.map((para) => (
                    <MenuItem key={para} value={para}>
                      {para}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        {/* Modal Body ends .... */}
        {/* Modal Footer starts .... */}
        <div className="footer py-2 pb-3 px-3 d-flex justify-content-end">
          <LoadingButton
            size="large"
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
            onClick={fetching_edit_param_variation}
            disabled={
              modalState.crop === "" || modalState.parameter === ""
                ? true
                : false
            }
          >
            <span>Submit</span>
          </LoadingButton>
        </div>
        {/* Modal Footer ends .... */}
      </Box>
    </Modal>
  );
};

export default EditDataVariationModal;

import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";

import "./App.css";
import { BeatLoader } from "react-spinners";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import ErrorBoundary from "./ErrorBoundary";

import { lazy, Suspense } from "react";

// import LineChartAnalysis from "./Components/User/LineChartAnalysis";
import PrivateRoutes from "./Components/utils/PrivateRoutes";
import Error from "./Components/error/Error";
import AllTickets from "./Components/AllTickets";
import AllVisits from "./Components/AllVisits";
import Login from "./Components/auth/Login";
import UserDashboard from "./Components/User/UserDashboard";
import Signup from "./Components/auth/Signup";
import Navigation from "./Components/navigation/Navigation";
import ContextState from "./Components/contextApi/ContextState";
import ForgotPassword from "./Components/auth/ForgotPassword";
// import RaiseTicketWrapper from "./Components/MultipageForm/RaiseTicketWrapper";
import RaiseTicketForm from "./Components/RaiseTicketForm/RaiseTicketForm";
import UserProfile from "./Components/User/UserProfile";
import Navbar from "./Components/Navbar";
import ShowTicket from "./Components/ShowTicket";
import SideBar from "./Components/SideBar";
import VisitLineChartAnalysis from "./Components/User/Vists_Analytics/VisitLineChartAnalysis";
import LocationWiseVisitData from "./Components/User/Vists_Analytics/LocationWiseVisitData";
import VisitsUserDashboard from "./Components/User/Vists_Analytics/VisitsUserDashboard";
import DetailedVisitData from "./Components/User/Vists_Analytics/DetailedVisitData";
import LineChartAnalysis from "./Components/User/LineChartAnalysis";
import TableFormTicketData from "./Components/User/TableFormTicketData";
import IssueWiseTableView from "./Components/User/IssueWiseTableView";
import { useDispatch, useSelector } from "react-redux";
import { addOnlineStatus } from "./Components/features/filtersSlice/filterSlice";

function App() {
  const {
    lodingVisiblity,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    closeSnackbar,
    autoHide,
    openSnackbar,
  } = useContext(ContextState);
  // console.log("Access token secret", process.env)

  const onlineStatus = useSelector((state) => state.onlineStatus.online);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = (setvalue) => {
    setOpen(setvalue);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // check website is online or not

  function handleIsOnline() {
    const data = {
      isOnline: true,
      timeStamp: new Date().toISOString(),
    };
    dispatch(addOnlineStatus(data));
    openSnackbar("Back to online", "success");
  }
  function handleIsOffline() {
    const data = {
      isOnline: false,
      timeStamp: new Date().toISOString(),
    };
    openSnackbar("You're Offline. Check your internet connection. ", "warning");
    dispatch(addOnlineStatus(data));
  }

  useEffect(() => {
    window.addEventListener("online", handleIsOnline);
    window.addEventListener("offline", handleIsOffline);

    return () => {
      // clearInterval(interval);
      window.removeEventListener("online", handleIsOnline);
      window.removeEventListener("offline", handleIsOffline);
    };
  }, [onlineStatus]);
  return (
    <>
      <Suspense
        fallback={
          <div className="showSpinner">
            <BeatLoader color="#2D97D4" />
          </div>
        }
      >
        <Router>
          <SideBar
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
          />
          {/* <Navbar /> */}
          <Box sx={{ width: 500 }}>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={closeSnackbar}
              message={snackbarMessage}
              severity={snackbarSeverity}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={closeSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              <Alert
                onClose={closeSnackbar}
                severity={snackbarSeverity}
                variant="filled"
                sx={{
                  width: "100%",
                  ".MuiAlert-action": {
                    display: onlineStatus ? "" : "none",
                  },
                }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>
          {/* <div className="main-container "> */}
          {/* When user need to login after 7 days */}
          {/* {showError.status === 401 &&
            showError.msg === "Expired JWT token" && (
              <div className="session-expired-div">
              <div>
              <h3>User session expired</h3>
              <p>You have been Logged out. Please log in again.</p>
              <p className="d-flex mb-0 align-items-end justify-content-end">
              <button
              type="button"
              className="btn btn-primary py-2 rounded-pill"
              onClick={() => {
                        localStorage.clear();
                        showErrorPage(null, null);
                        window.location.href = "login";
                      }}
                    >
                    Login
                    </button>
                  </p>
                </div>
              </div>
            )} */}
          {/* Beatloader visiblity */}
          {/* {lodingVisiblity && (
              <div className="showSpinner">
                <BeatLoader color="#2D97D4" />
              </div>
            )} */}
          {/* <Navigation /> */}
          {
            // <div className="main-inner-container ">
            //   <Routes>
            //     <Route element={<PrivateRoutes />}>
            //       {/* Dashboard route */}
            //       <Route
            //         element={<UserDashboard />}
            //         path="/ticketsoverview"
            //         exact
            //       />
            //       <Route element={<UserProfile />} path="/" exact />
            //       <Route
            //         element={<RaiseTicketForm />}
            //         path="/raiseticket"
            //         exact
            //       />
            //       <Route
            //         element={<LineChartAnalysis />}
            //         path="/tickettrends"
            //         exact
            //       />
            //       <Route
            //         element={<TableFormTicketData />}
            //         path="/locationinsights"
            //         exact
            //       />
            //       <Route
            //         element={<IssueWiseTableView />}
            //         path="/issuewisedata"
            //         exact
            //       />
            // <Route
            //   element={<VisitsUserDashboard />}
            //   path="/visitsoverview"
            //   exact
            // />
            // <Route
            //   element={<VisitLineChartAnalysis />}
            //   path="/visitstrends"
            //   exact
            // />
            // <Route
            //   element={<LocationWiseVisitData />}
            //   path="/visitsinsights"
            //   exact
            // />
            // <Route
            //   element={<DetailedVisitData />}
            //   path="/detailedinsights"
            //   exact
            // />
            //       <Route element={<AllTickets />} path="/allticket" exact />
            //       <Route element={<AllVisits />} path="/allvisits" exact />
            //       <Route element={<ShowTicket />} path="/show" exact />
            //       <Route element={<Error />} path="/error" exact />
            //     </Route>
            //     <Route path="/login" element={<Login />} />
            //     <Route path="/signup" element={<Signup />} />
            //     <Route path="/forgotpassword" element={<ForgotPassword />} />
            //     <Route path="*" element={<Error code={404} />} />
            //   </Routes>
            // </div>
          }
          {/* </div> */}
        </Router>
      </Suspense>
    </>
  );
}

export default App;

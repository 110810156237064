import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  //   todos: [{ id: 1, text: "Hello World" }],
  userFilters: {
    data: null,
    lastDataFetchTimestamp: null,
  },
  createTicketDetail: {
    data: null,
    lastDataFetchTimestamp: null,
  },
  userDetail: {
    data: null,
  },
  // userDetail: {
  //   data: {
  //     Institution: "FCI",
  //     "Total Districts": 73,
  //     "Total Machines": 399,
  //     "Total Regions": 10,
  //     user_type: "support admin",
  //     Username: "Tushar Kankhedia",
  //   },
  // },

  onlineStatus: {
    online: true,
    timeStamp: null,
  },
};

//  reducer for update of state
export const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    addFilters: (state, action) => {
      state.userFilters.data = action.payload;
      state.userFilters.lastDataFetchTimestamp = Date.now();
      // console.log("Filter state updated ", action.payload, Date.now());
    },
    addTicketDetail: (state, action) => {
      state.createTicketDetail.data = action.payload;
      state.createTicketDetail.lastDataFetchTimestamp = Date.now();
      // console.log("Filter state updated ", action.payload, Date.now());
    },
    addUserDetail: (state, action) => {
      state.userDetail.data = action.payload;
      // console.log("Filter state updated ", action.payload, Date.now());
    },

    addOnlineStatus: (state, action) => {
      // console.log(state, action, "------->");
      state.onlineStatus.online = action.payload.isOnline;
      state.onlineStatus.timeStamp = action.payload.timeStamp;
    },
  },
});

// exporting the functionallity to update the state
// for each component
export const { addFilters, addTicketDetail, addUserDetail, addOnlineStatus } =
  filterSlice.actions;

// to give awareness to store
export default filterSlice.reducer;

import React, { useContext, useEffect, useState } from "react";
import "../../../assets/styles/LineChartAnalysis.css";
import "../../../assets/styles/UserDashboard.css";
import Dropdown from "../../filters/Dropdown";
import AllFilters from "../../filters/AllFilters";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ToolTip from "react-bootstrap/Tooltip";
import { BeatLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { addFilters } from "../../features/filtersSlice/filterSlice";
import ContextState from "../../contextApi/ContextState";
import Error from "../../error/Error";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  FaTicketAlt,
  FaChartLine,
  FaCheckCircle,
  FaChartBar,
  FaMapMarkedAlt,
  FaBuilding,
  FaCogs,
} from "react-icons/fa";
import GroupSizesColors from "../ButtonGroup";
import LocationDataMainTable from "./LocationDataMainTable";

const icons = [
  FaMapMarkedAlt,
  FaTicketAlt,
  FaTicketAlt,
  FaTicketAlt,
  FaBuilding,
  FaCogs,
  FaTicketAlt,
];

const issueTyepLogoBGcolor = [
  "#FF7F50",
  "#f79009",
  "#f79009",
  "#ff0000",
  "#FF5733",
  "#3498db",
  "#f79009",
];

const renderTooltip = (props) => (
  <ToolTip id="button-tooltip" {...props}>
    Visit Analytics Data categorized by <strong> Region, District </strong> and{" "}
    <strong>Machine </strong>
  </ToolTip>
);

const LocationWiseVisitData = () => {
  const buttons = [
    { label: "Region", clicked: true },
    { label: "District", clicked: false },
    { label: "Machine", clicked: false },
  ];

  const [clickedButtonIndex, setClickedButtonIndex] = useState(0);
  const [tableData, setTableData] = useState({
    Region: true,
    District: false,
    Machine: false,
  });

  // function to handle group button click
  const handleButtonClick = (index, label) => {
    setClickedButtonIndex(index);
    setTableData((prevState) => {
      const updatedState = {};
      for (const key in prevState) {
        updatedState[key] = key === label;
      }
      return updatedState;
    });
  };

  //   console.log("tableData", tableData);

  function generateMonthsList(fromDate, toDate) {
    const startDate = new Date(fromDate + "-01");
    const endDate = new Date(toDate + "-01");

    const monthsList = [];
    let currentDate = startDate;

    // Loop until currentDate is less than or equal to endDate
    while (currentDate <= endDate) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // getMonth() returns zero-based month index
      const formattedMonth = month < 10 ? "0" + month : month.toString(); // Format month with leading zero if needed
      const formattedDate = year + "-" + formattedMonth;
      monthsList.push(formattedDate);

      // Move currentDate to the next month
      currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    }

    return monthsList;
  }
  // Set initial width
  const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.92);

  // Update chart width on window resize
  useEffect(() => {
    const handleResize = () => {
      const newWidth =
        window.innerWidth > 1100 ? window.innerWidth * 0.92 : 1100;
      setChartWidth(newWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // ************* filters required data ***********
  const {
    fetchApi,
    showLoading,
    showError,
    showErrorPage,
    formatDate,
    formatInIndianRupees,
    controllerRef,
  } = useContext(ContextState);
  const dispatch = useDispatch();
  const userFilters = useSelector((state) => state.userFilters); // user filter from redux

  // blur all tickets
  const [blurTickets, setblurTickets] = useState("");

  const handelPageBlur = (blur_ticket) => {
    if (blur_ticket) {
      setblurTickets("all-ticket-content-blur");
    } else {
      setblurTickets("");
    }
  };

  // loding page when data to be fetched
  const [loading, setLoading] = useState(true);
  const [allFiltersDisplay, setallFilterDisplay] = useState(false);
  // filters
  const [filters, setFilters] = useState({
    // status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    // time: [],
    machineNumber: [],
    // hourlyStartDate: [],
    // hourlyEndDate: [],
    // DailyStartDate: [],
    // DailyEndDate: [],
    // MonthlyStartDate: [],
    // MonthlyEndDate: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    crop_variation: [],
    params_variation: [],
    // moreFilters: [],
    ticketPerPage: [],
    assignee: [],
    commitId: [],
    modelNo: [],
  });
  const [showAllfilters, setshowAllFilters] = useState({
    // status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    // time: [],
    machineNumber: [],
    // hourlyStartDate: [],
    // hourlyEndDate: [],
    // DailyStartDate: [],
    // DailyEndDate: [],
    // MonthlyStartDate: [],
    // MonthlyEndDate: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    crop_variation: [],
    params_variation: [],
    // moreFilters: [],
    ticketPerPage: [],
    assignee: [],
    commitId: [],
    modelNo: [],
  });
  const [filterCount, setFilterCount] = useState({
    // status: 0,
    issueType: 0,
    subIssueType: 0,
    RO: 0,
    DO: 0,
    // time: 0,
    machineNumber: 0,
    // hourlyStartDate: 0,
    // hourlyEndDate: 0,
    // DailyStartDate: 0,
    // DailyEndDate: 0,
    // MonthlyStartDate: 0,
    // MonthlyEndDate: 0,
    createdStartDate: 0,
    createdEndDate: 0,
    resolvedStartDate: 0,
    resolvedEndDate: 0,
    crop_variation: 0,
    params_variation: 0,
    // moreFilters: 0,
    ticketPerPage: 0,
    assignee: 0,
    commitId: 0,
    modelNo: 0,
  });
  // Function to update filters
  const updateFilters = (Filters, clickedPageNumber = 0, updateURL = true) => {
    // console.log("update filter 0 -->", Filters, clickedPageNumber);
    Filters = applyCoustomChecksFilter(Filters);
    setFilters(Filters);
    // setCurrentPage(clickedPageNumber);
    if (updateURL) {
      updateURLParams(Filters, clickedPageNumber);
      // console.log("Url Is Updating ");
    }
    // else {
    // // console.log("Url Is Not Updating ");
    // }
    updateFilterCount(Filters);
    if (getUserFilters?.issues) {
      applyIssueTypeFilters(Filters);
    }
    showAllFilters(Filters);
    fetchData(Filters);
  };

  const updateFilterCount = (farr) => {
    for (const key in farr) {
      const count = farr[key].length;
      filterCount[key] = count;
    }
    setFilterCount(filterCount);
  };

  const updateURLParams = (params, page) => {
    const urlSearchParams = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      // console.log(key, params[key]);
      if (params[key]) {
        urlSearchParams.set(key, params[key]);
      } else {
        urlSearchParams.delete(key);
      }
    });
    // console.log("url send to update : ", params);
    // urlSearchParams.set("currentPage", parseInt(page) + 1);
    // urlSearchParams.set("cardsPerPage", cardsPerPage);
    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;

    if (window.location.pathname === "/FCI/visitsinsights" && !Data) {
      // Check if the state already exists
      // console.log("Replace state Wroking");
      const currentState = window.history.state;
      if (!currentState || !currentState.filters) {
        // Replace the initial state if no filters exist
        window.history.replaceState(
          {
            filters: params,
          },
          "",
          newUrl
        );
      }
    } else {
      // Push state for subsequent changes
      window.history.pushState(
        {
          filters: params,
        },
        "",
        newUrl
      );
    }
  };

  // this will populate our dashboard page
  const [Data, setData] = useState(null); // page data
  const fetchData = async (F, Page = 0) => {
    setData(null);
    // console.log("data set to empty List - 3");

    setLoading(true);
    showLoading(true);
    // data from backend
    // total number of cards
    // cards filter show how many pages perpage (cardsPerPage : 15)

    const payload = {};
    // payload["page_num"] = parseInt(Page) + 1;
    if (F["createdStartDate"] && F["createdStartDate"].length) {
      payload["visit_start_from"] = F["createdStartDate"][0];
    }
    if (F["createdEndDate"] && F["createdEndDate"].length) {
      payload["visit_start_to"] = F["createdEndDate"][0];
    }
    if (F["resolvedStartDate"] && F["resolvedStartDate"].length) {
      payload["visit_end_from"] = F["resolvedStartDate"][0];
    }
    if (F["resolvedEndDate"] && F["resolvedEndDate"].length) {
      payload["visit_end_to"] = F["resolvedEndDate"][0];
    }

    //  filters for dashboard
    // if (F["time"]?.length !== 0) {
    //   payload["frequency"] = F["time"][0];
    // } else {
    //   payload["frequency"] = "daily";
    // }
    // if (F["hourlyStartDate"]?.length && F["time"].includes("hourly")) {
    //   payload["from_date"] = F["hourlyStartDate"][0];
    // }
    // if (F["hourlyEndDate"]?.length && F["time"].includes("hourly")) {
    //   payload["to_date"] = F["hourlyEndDate"][0];
    // }
    // if (F["DailyStartDate"]?.length && F["time"].includes("daily")) {
    //   payload["from_date"] = F["DailyStartDate"][0];
    // }
    // if (F["DailyEndDate"]?.length && F["time"].includes("daily")) {
    //   payload["to_date"] = F["DailyEndDate"][0];
    // }
    // if (F["MonthlyStartDate"]?.length && F["time"].includes("monthly")) {
    //   payload["from_date"] = F["MonthlyStartDate"][0];
    // }
    // if (F["MonthlyEndDate"]?.length && F["time"].includes("monthly")) {
    //   payload["to_date"] = F["MonthlyEndDate"][0];
    // }
    // if (
    //   F["MonthlyStartDate"]?.length &&
    //   F["time"].includes("monthly") &&
    //   F["MonthlyEndDate"]?.length &&
    //   F["time"].includes("monthly")
    // ) {
    //   payload["months_list"] = generateMonthsList(
    //     F["MonthlyStartDate"],
    //     F["MonthlyEndDate"]
    //   );
    // }

    if (F["issueType"] && F["issueType"].length) {
      payload["issue"] = F["issueType"];
    }
    if (F["subIssueType"] && F["subIssueType"].length) {
      payload["sub_issue"] = F["subIssueType"];
    }
    if (F["status"] && F["status"].length) {
      payload["status"] = F["status"].map((e) => e.toLowerCase());
    }
    if (F["machineNumber"] && F["machineNumber"].length) {
      payload["machines"] = F["machineNumber"];
    }
    if (F["DO"] && F["DO"].length) {
      payload["districts"] = F["DO"];
    }
    if (F["RO"] && F["RO"].length) {
      payload["regions"] = F["RO"];
    }
    // if (F["sort"] && F["sort"].length) {
    //   const sortDictManual = {
    //     "Creation time- Ascending": "creation_time:ASC",
    //     "Creation time- Descending": "creation_time:DESC",
    //     "Updated time- Descending": "last_updated:DESC",
    //   };
    //   const sortArr =
    //     (sortDictManual[filtersOptions["sortDict"][0]] &&
    //       F["sort"].map((item) => filtersOptions["sortDict"][item])) ||
    //     sortDictManual[F["sort"][0]];
    //   payload["Sort"] = sortArr;
    // }
    if (F["crop_variation"] && F["crop_variation"].length) {
      payload["crop_variation"] = F["crop_variation"];
    }
    if (F["params_variation"] && F["params_variation"].length) {
      payload["params_variation"] = F["params_variation"];
    }
    if (F["assignee"] && F["assignee"].length) {
      payload["support_person"] = F["assignee"];
    }
    if (F["commitId"] && F["commitId"].length) {
      payload["commit_id"] = F["commitId"];
    }
    if (F["modelNo"] && F["modelNo"].length) {
      payload["model_no"] = F["modelNo"];
    }
    // if (F["moreFilters"] && F["moreFilters"].length) {
    //   F["moreFilters"].forEach((item) => {
    //     payload[item] = true;
    //   });
    // }
    if (F["searchBox"] && F["searchBox"].length && F["searchBox"][0] !== "") {
      payload["ticket_id"] = F["searchBox"][0];
    }
    if (
      F["ticketPerPage"] &&
      F["ticketPerPage"].length &&
      F["ticketPerPage"][0] !== ""
    ) {
      payload["tickets_limit"] = F["ticketPerPage"][0];
    }

    let is_all_ticket_data_Mounted = true;
    const all_tickets_data = await fetchApi(
      "get_visit_locations_data",
      "POST",
      payload,
      is_all_ticket_data_Mounted
    );
    // console.log("data send : ", payload, all_tickets_data?.data);

    // setData({

    // })

    if (all_tickets_data.statusCode === 200) {
      showErrorPage(null, null);
      setData(all_tickets_data?.data || null);
      // settotalCardsCount(all_tickets_data?.data?.total_count || 1);

      // if (all_tickets_data?.data?.tickets_limit === "All") {
      //   setCardsPerPage(all_tickets_data?.data?.total_count || 1);
      // } else {
      //   setCardsPerPage(all_tickets_data?.data?.tickets_limit || 1);
      // }
    } else if (
      all_tickets_data.statusCode === 500 ||
      all_tickets_data.statusCode === 401
    ) {
      setData(null);
      // console.log(all_tickets_data, "---------=========>");
      showErrorPage(all_tickets_data?.statusCode, all_tickets_data?.msg);
    } else {
      setData(null);
      // showErrorPage(all_tickets_data.statusCode, all_tickets_data.msg);
      localStorage.clear();
      window.location.href = "/login";
    }

    setLoading(false);
    showLoading(false);
    // setTimeout(() => {
    // }, 100);

    return () => {
      is_all_ticket_data_Mounted = false;
    };
  };

  const handleFilterChange = (filter, filterType) => {
    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };
      const filterList = currentFilters[filterType];

      if (
        filterList &&
        (filterType === "searchBox" || filterType === "visitSearchBox")
      ) {
        if (filter !== "") currentFilters[filterType] = [filter];
        else currentFilters[filterType] = [];
      } else if (filterList && filterList.includes(filter)) {
        currentFilters[filterType] = filterList.filter(
          (prevFilter) => prevFilter !== filter
        );
      } else if (
        filterList &&
        (filterType === "createdStartDate" ||
          filterType === "createdEndDate" ||
          filterType === "resolvedStartDate" ||
          filterType === "resolvedEndDate" ||
          filterType === "totalExpense")
      ) {
        currentFilters[filterType] = [filter];
      } else if (
        (filterList && filterType === "sort") ||
        filterType === "ticketPerPage"
      ) {
        currentFilters[filterType] = [filter];
      } else {
        currentFilters[filterType] = [...(filterList || []), filter];
      }
      if (filterType === "RO") {
        applyROFilters(currentFilters);
      } else if (filterType === "machineNumber") {
        applyMachineNumberFilters(currentFilters);
      } else if (filterType === "DO") {
        applyDOFilters(currentFilters);
      }
      // applyIssueTypeFilters(currentFilters);
      updateFilterCount(currentFilters);
      // applyCropVariationFilters(currentFilters);
      // console.log(
      //   "update filter 1 -->",
      //   prevFilters,
      //   currentFilters,
      //   filter,
      //   filterType
      // );
      return currentFilters;
    });
  };

  const handleFilterReset = (filtername) => {
    // const filters = { ...filters };
    if (Object.keys(filters || {}).includes(filtername)) {
      filters[filtername] = [];
    }
    // if (filtername === "moreFilters") {
    //   filters["searchBox"] = [];
    //   updateFilters["ticketPerPage"] = [];
    // }
    if (filtername === "issueType") {
      filters["subIssueType"] = [];
    }
    if (filtername === "dateSelector") {
      filters["resolvedStartDate"] = [];
      filters["resolvedEndDate"] = [];
      filters["createdStartDate"] = [];
      filters["createdEndDate"] = [];
    }
    if (filtername === "time") {
      filters["DailyStartDate"] = [];
      filters["DailyEndDate"] = [];
      filters["MonthlyStartDate"] = [];
      filters["MonthlyEndDate"] = [];
      filters["hourlyStartDate"] = [];
      filters["hourlyEndDate"] = [];
    }
    // console.log("working", filtername, filtername === "dateSelector", filters);
    setFilters(filters);
    // console.log(filtername);
    if (filtername === "RO") {
      applyROFilters(filters);
    } else if (filtername === "machineNumber") {
      applyMachineNumberFilters(filters);
    } else if (filtername === "DO") {
      applyDOFilters(filters);
    }
    // console.log(filters, "updateFilterCount");
    applyIssueTypeFilters(filters);
    updateFilterCount(filters);
  };

  const handleSaveChanges = (filtername) => {
    // console.log("sendFilters->  ", filtername, filters[filtername]);
    const filterAfterCoustomChange = applyCoustomChecksFilter(filters);
    updateFilters(filterAfterCoustomChange, 0);
    setshowAllFilters(filterAfterCoustomChange);
  };

  const handelResetAllBtn = () => {
    // console.log("working");
    for (const key in filters) {
      filters[key] = [];
    }
    // console.log("updated filter ", filters);
    updateFilters(filters, 0);

    setfiltersOptions((prevState) => ({
      ...prevState,
      DO: filtersOptionsCopy["DO"],
      machineNumber: filtersOptionsCopy["machineNumber"],
      RO: filtersOptionsCopy["RO"],
    }));
  };

  const handelDeleteFilterBtn = (filterType, subfilter) => {
    // console.log("working", filterType, subfilter);

    // Check if the filterKey exists in the filters state
    if (filters.hasOwnProperty(filterType)) {
      // Remove the specified filterValue from the filterKey array
      filters[filterType] = filters[filterType].filter(
        (filter) => filter !== subfilter
      );
    }
    if (filterType === "status" && subfilter === "Resolved") {
      filters["resolvedEndDate"] = [];
      filters["resolvedStartDate"] = [];
    }
    if (filterType === "issueType") {
      filters["subIssueType"] = [];
      if (subfilter === "Data Variation") {
        filters["crop_variation"] = [];
        filters["params_variation"] = [];
        filters["modelNo"] = [];
        filters["commitId"] = [];
      }
    }
    if (filterType === "crop_variation") {
      filters["params_variation"] = [];
    }

    if (
      filterType === "hourlyStartDate" ||
      filterType === "hourlyEndDate" ||
      filterType === "DailyStartDate" ||
      filterType === "DailyEndDate" ||
      filterType === "MonthlyStartDate" ||
      filterType === "MonthlyEndDate" ||
      filterType === "time"
    ) {
      if (filterType !== "time") {
        filters["time"] = [];
      }
      filters["DailyStartDate"] = [];
      filters["DailyEndDate"] = [];
      filters["MonthlyStartDate"] = [];
      filters["MonthlyEndDate"] = [];
      filters["hourlyStartDate"] = [];
      filters["hourlyEndDate"] = [];
    }
    if (filterType === "RO") {
      applyROFilters(filters);
    } else if (filterType === "machineNumber") {
      applyMachineNumberFilters(filters);
    } else if (filterType === "DO") {
      applyDOFilters(filters);
    }
    updateFilters(filters, 0);
    // console.log("Deleted single filter --->", filters);
  };

  function showAllFilters(FiltersDict) {
    for (const i in FiltersDict) {
      if (FiltersDict[i].length !== 0) {
        // console.log("show filter");
        setallFilterDisplay(true);
        return;
      }
    }
    setallFilterDisplay(false);
  }

  const [getUserFilters, setgetUserFilters] = useState(null);

  // filters to show

  const [filtersOptions, setfiltersOptions] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    DO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    commitId: [],
    modelNo: [],
    // moreFilters: ["visit_required", "internet_issue"],
    // time: ["hourly", "daily", "monthly"],
    ticketPerPage: [],
  });
  const [filtersOptionsCopy, setfiltersOptionsCopy] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    commitId: [],
    modelNo: [],
    // moreFilters: [],
  });
  // filters sorting according to selected filter
  const getFilterResponse = async (response) => {
    // setLoading(true);
    // showLoading(true);
    try {
      // calling refresh token whenever loading the page
      // console.log("get_user_filters--->", response);
      if (response.statusCode === 200) {
        // console.log("get_user_filters Data Fetched Successfully");
        const data = response.data;
        // console.log("data", new Date().toLocaleString(), data);
        setgetUserFilters(data);

        // Extract RO# keys from  filters_data
        const roKeys =
          data && data.access_level === "institution"
            ? Object.keys(data?.filters_data || {})
            : [];

        // Extract DO# keys from RO objects in filters_data
        const doKeys =
          data && data.access_level === "institution"
            ? Object.values(data?.filters_data || {}).flatMap((Do) =>
                Object.keys(Do)
              )
            : data && data.access_level === "region"
            ? Object.keys(data.filters_data)
            : [];

        // Populate machineNumber array with unique machine numbers
        const uniquedoKeys = Array.from(new Set(doKeys));

        // Extract machine numbers from DO keys
        const machineNumbervalue =
          data && data.access_level === "institution"
            ? Object.values(data?.filters_data || {})
                .flatMap((ro) => Object.values(ro))
                .flatMap((doData) => doData)
                .flatMap((machine) => machine)
                .concat(Object.values(data?.ro_machines || {}))
            : data && data.access_level === "region"
            ? Object.values(data?.filters_data || {})
                .flatMap((doList) => {
                  return Object.values(doList);
                })
                .flat()
            : data && data.access_level === "district"
            ? data.filters_data
            : [];

        // Populate machineNumber array with unique machine numbers
        const uniqueMachineNumbers = Array.from(new Set(machineNumbervalue));

        // ********** not required ************
        // populate issuetype in filter option
        const issueTypes = Object.keys(data.issues[0]);
        // populate subissuetype in filter option
        const subIssueTypes = Object.values(data.issues[0]).flatMap((issue) =>
          issue.sub_issue.map((subIssue) => subIssue[1])
        );
        // console.log(
        //   "Sub issues : ",
        //   subIssueTypes,
        //   typeof subIssueTypes,
        //   subIssueTypes.length
        // );
        // populating sort in filters Option
        // const sortArr =
        //   (data && data.sort_list.map((item) => item.value)) || [];
        // const sortDictArr =
        //   (data &&
        //     data.sort_list.reduce((acc, item) => {
        //       acc[item.value] = item.id;
        //       return acc;
        //     }, {})) ||
        //   [];

        const assigneeType =
          (data && data.assignee_list?.map((item) => item.name)) || [];
        // // console.log("assigneeType", assigneeType);
        let commitIdArr = data && Object.values(data.commit_id_list || {});

        commitIdArr = commitIdArr.map((commitIds, index) => {
          return commitIds.slice(0, 5);
        });
        const modelNoArr = data && Object.values(data.model_no_list || {});
        const cropvariationTypes = data ? Object.keys(data.param_data) : [];

        const dataParamsTypes =
          Array.from(
            new Set(
              data &&
                Object.values(data.param_data).flatMap((item) =>
                  Object.keys(item.client_params)
                )
            )
          ) || [];

        // console.log("cropvariationTypes", cropvariationTypes, dataParamsTypes);
        // const statusTypes = Array.from(
        //   new Set(Object.values(data?.states_list || {}).flat())
        // );
        const issueTypeArr = Object.keys(data?.states_list || {}).filter(
          (item) => {
            return item.toLowerCase() === "hardware";
          }
        );

        // console.log("cropvariationTypes", cropvariationTypes, dataParamsTypes);
        // const statusTypes = data.status_list;

        setfiltersOptions((eachFilterToShow) => ({
          ...eachFilterToShow,
          RO: roKeys,
          DO: uniquedoKeys,
          machineNumber: uniqueMachineNumbers,
          issueType: issueTypes,
          subIssueType: subIssueTypes,
          crop_variation: cropvariationTypes,
          assignee: assigneeType,
          modelNo: modelNoArr,
          commitId: commitIdArr,
          params_variation: dataParamsTypes,
          issueType: issueTypeArr,
          // status: statusTypes,
        }));
        setfiltersOptionsCopy((eachFilterToShow) => ({
          ...eachFilterToShow,
          RO: roKeys,
          DO: uniquedoKeys,
          machineNumber: uniqueMachineNumbers,
          issueType: issueTypes,
          subIssueType: subIssueTypes,
          assignee: assigneeType,
          modelNo: modelNoArr,
          commitId: commitIdArr,
          crop_variation: cropvariationTypes,
          params_variation: dataParamsTypes,
          issueType: issueTypeArr,
          // status: statusTypes,
        }));
      } else {
        // Handle non-successful responses
        console.error("Error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const applyROFilters = (filtersDict) => {
    const roFilters = filtersDict.RO;

    // Extract all DO keys from selected RO filters
    const filteredDO = roFilters.flatMap((ro) =>
      getUserFilters.filters_data[ro]
        ? Object.keys(getUserFilters?.filters_data[ro] || {})
        : []
    );

    // console.log("filteredDO", filteredDO, roFilters);

    // Extract all machine numbers from selected RO filters and DO keys
    const filteredMachineNumbers = roFilters.flatMap((ro) =>
      getUserFilters.filters_data[ro]
        ? Object.values(getUserFilters?.filters_data[ro] || {})
            .flatMap((doValues) => doValues)
            .concat(
              getUserFilters?.ro_machines[ro]
                ? getUserFilters?.ro_machines[ro]
                : []
            )
        : []
    );

    // Update filtersOptions with unique filtered DO values and machine numbers
    const uniqueDO = Array.from(new Set(filteredDO));
    const uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));

    if (roFilters.length === 0) {
      const newFilter = { ...filters };
      newFilter["DO"] = [];
      newFilter["RO"] = [];
      newFilter["machineNumber"] = [];
      // setFilters(newFilter);
      // updateFilterCount(newFilter);
      // showAllFilters(newFilter);
      setfiltersOptions((prevState) => ({
        ...prevState,
        DO: filtersOptionsCopy["DO"],
        machineNumber: filtersOptionsCopy["machineNumber"],
      }));
    } else {
      const newFilter = { ...filters };
      newFilter["DO"] = [];
      newFilter["machineNumber"] = [];
      // console.log("uniqueMachineNumber --> ", uniqueMachineNumbers);
      // setFilters(newFilter);
      // updateFilterCount(newFilter);
      // showAllFilters(newFilter);

      setfiltersOptions((prevState) => ({
        ...prevState,
        DO: uniqueDO,
        machineNumber: uniqueMachineNumbers,
      }));
    }
  };

  const applyDOFilters = (filtersDict) => {
    const doFilters = filtersDict.DO;
    const roFilters = filtersDict.RO;
    if (getUserFilters.access_level === "institution") {
      // Find corresponding RO keys for selected DO keys
      const roKeys = [];
      Object.entries(getUserFilters?.filters_data || {}).forEach(
        ([roKey, doValues]) => {
          Object.keys(doValues).forEach((doKey) => {
            if (doFilters.includes(doKey)) {
              roKeys.push(roKey);
            }
          });
        }
      );

      // Extract all machine numbers from selected DO keys
      const filteredMachineNumbers = doFilters.flatMap((doKey) =>
        Object.values(getUserFilters.filters_data)
          .filter((ro) => ro[doKey])
          .flatMap((ro) => ro[doKey])
      );
      // Update filtersOptions with unique filtered machine numbers and corresponding RO keys
      const uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));
      const uniqueRO = Array.from(new Set(roKeys));
      if (doFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: filtersOptionsCopy["machineNumber"],
          RO: filtersOptionsCopy["RO"],
        }));
      } else {
        //filters["machineNumber"] = uniqueMachineNumbers;
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: uniqueMachineNumbers,
          RO: roFilters.length === 0 ? uniqueRO : prevState.RO,
        }));
      }
    } else if (getUserFilters.access_level === "region") {
      // Extract machine numbers for selected DO keys
      const filteredMachineNumbers = doFilters.reduce((acc, doKey) => {
        if (getUserFilters.filters_data[doKey]) {
          acc.push(...getUserFilters.filters_data[doKey]);
        }
        return acc;
      }, []);

      if (doFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: filtersOptionsCopy["machineNumber"],
        }));
      } else {
        // Update filtersOptions machineNumber with unique filtered machine numbers
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: Array.from(new Set(filteredMachineNumbers)),
        }));
      }
    }
  };

  const applyMachineNumberFilters = (filtersDict) => {
    const roFilters = filtersDict.RO;
    const doFilters = filtersDict.DO;
    const machineNumberFilters = filtersDict.machineNumber;
    if (getUserFilters.access_level === "institution") {
      // Extract corresponding RO and DO keys for selected machine numbers
      const filteredRODO = Object.entries(
        getUserFilters?.filters_data || {}
      ).reduce((acc, [ro, roData]) => {
        Object.entries(roData || {}).forEach(([doKey, machines]) => {
          const matchingMachines = machines.filter((machine) =>
            machineNumberFilters.includes(machine)
          );
          if (matchingMachines.length > 0) {
            acc.push({ RO: ro, DO: doKey });
          }
        });
        return acc;
      }, []);

      // Extract unique RO and DO keys
      const uniqueRO = Array.from(
        new Set(filteredRODO.map((entry) => entry.RO))
      );
      const uniqueDO = Array.from(
        new Set(filteredRODO.map((entry) => entry.DO))
      );

      // Update filtersOptions with unique RO and DO keys
      if (machineNumberFilters.length === 0) {
        // console.log(
        //   "machineNumberFilters.length",
        //   machineNumberFilters.length,
        //   filtersOptions
        // );
        setfiltersOptions((prevState) => ({
          ...prevState,
          RO: filtersOptionsCopy["RO"],
          DO: filtersOptionsCopy["DO"],
        }));
      } else {
        setfiltersOptions((prevState) => ({
          ...prevState,
          RO: roFilters.length === 0 ? uniqueRO : prevState.RO,
          DO: doFilters.length === 0 ? uniqueDO : prevState.DO,
        }));
      }
    } else if (getUserFilters.access_level === "region") {
      // Extract DO keys for selected machine numbers
      const filteredDOKeys = Object.keys(getUserFilters.filters_data).filter(
        (doKey) => {
          const machineNumbers = getUserFilters.filters_data[doKey];
          return machineNumbers.some((number) =>
            machineNumberFilters.includes(number)
          );
        }
      );

      if (machineNumberFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          DO: filtersOptionsCopy["DO"],
        }));
      } else {
        // Update filtersOptions DO with unique filtered DO keys
        setfiltersOptions((prevState) => ({
          ...prevState,
          DO:
            doFilters.length === 0
              ? Array.from(new Set(filteredDOKeys))
              : prevState.RO,
        }));
      }
    }
  };

  // const applyIssueTypeFilters = (filterDict) => {
  // Function to update filtersOptions based on selected issueType
  const applyIssueTypeFilters = (filterDict) => {
    // Filter subIssueType values based on the selected issueType
    if (filterDict?.issueType) {
      if (filterDict.issueType.length) {
        const filteredSubIssueType =
          getUserFilters?.issues?.reduce((acc, issue) => {
            const selectedIssueTypes = filterDict.issueType;
            for (const issueType of selectedIssueTypes) {
              if (issue[issueType]) {
                acc.push(
                  ...issue[issueType].sub_issue?.map((subIssue) => subIssue[1])
                );
              }
            }
            return acc;
          }, []) || [];

        // Variable to store status options
        const statusOptions = [];

        // Iterate over statesList and update statusOptions
        // Object.keys(getUserFilters?.states_list || {}).forEach((key) => {
        //   if (filterDict.issueType.includes(key)) {
        //     statusOptions.push(...(getUserFilters?.states_list[key] || []));
        //   }
        // });

        // console.log(
        //   "statusOptions.push(...statesList[key]) --> ",
        //   new Set([...filters.status, ...statusOptions])
        // );
        // Update filtersOptions state with the filtered subIssueType values
        setfiltersOptions((prevState) => ({
          ...prevState,
          subIssueType: Array.from(
            new Set([...(filters.subIssueType || []), ...filteredSubIssueType])
          ),
          // status: Array.from(new Set([...filters.status, ...statusOptions])),
        }));
      } else {
        setfiltersOptions((prevState) => ({
          ...prevState,
          subIssueType: filtersOptionsCopy.subIssueType,
          // status: filtersOptionsCopy.status,
        }));
      }
    }
  };

  const applyCropVariationFilters = (filterDict) => {
    // Get the list of param_data keys from filters.crop_variation
    const selectedCrops = filterDict.crop_variation || [];

    // Filter client_params keys based on selectedCrops
    const clientParamsKeysArray = Object.entries(
      getUserFilters?.param_data || {}
    )
      .filter(([key]) => selectedCrops.includes(key)) // Filter based on selectedCrops
      .flatMap(([key, value]) => Object.keys(value.client_params));

    // Update filtersOptions.params_variation
    const updatedParamsVariation = Array.from(
      new Set([...(filters.params_variation || []), ...clientParamsKeysArray])
    );

    // console.log("updatedParamsVariation", updatedParamsVariation);
    if (selectedCrops.length) {
      setfiltersOptions((prevState) => ({
        ...prevState,
        params_variation: updatedParamsVariation,
      }));
    } else {
      setfiltersOptions((prevState) => ({
        ...prevState,
        params_variation: filtersOptionsCopy["params_variation"],
      }));
    }
  };

  const applyCoustomChecksFilter = (filterDict) => {
    // if (
    //   filterDict["status"] &&
    //   !filterDict["status"].some(
    //     (status) => status.toLowerCase() === "resolved"
    //   )
    // ) {
    //   filterDict["resolvedEndDate"] = [];
    //   filterDict["resolvedStartDate"] = [];
    // }

    if (
      filterDict?.issueType &&
      !filterDict?.issueType?.includes("Data Variation")
    ) {
      filterDict["params_variation"] = [];
      filterDict["crop_variation"] = [];
    }

    if (filterDict?.issueType && filterDict?.issueType?.length === 0) {
      filterDict.subIssueType = [];
      filterDict.status = [];
    }

    if (
      filterDict?.crop_variation &&
      filterDict?.crop_variation?.length === 0
    ) {
      filterDict.params_variation = [];
    }

    return filterDict;
  };

  useEffect(() => {
    document.title = "Location Insights - NEO";
    showLoading(true);
    setLoading(true);
    setData(null);
    // console.log("data set to empty List - 1");
    const params = new URLSearchParams(window.location.search);
    // console.log("prams -->", params);
    // const statusFilterData =
    //   params.get("status") !== "" && params.get("status") !== null
    //     ? params.get("status").split(",")
    //     : [];
    const issueTypeFilterData =
      params.get("issueType") !== "" && params.get("issueType") !== null
        ? params.get("issueType").split(",")
        : [];
    const ROFilterData =
      params.get("RO") !== "" && params.get("RO") !== null
        ? params.get("RO").split(",")
        : [];
    const DOFilterData =
      params.get("DO") !== "" && params.get("DO") !== null
        ? params.get("DO").split(",")
        : [];
    const machineNumberFilterData =
      params.get("machineNumber") !== "" && params.get("machineNumber") !== null
        ? params.get("machineNumber").split(",")
        : [];
    // const timeFilterData =
    //   params.get("time") !== "" && params.get("time") !== null
    //     ? params.get("time").split(",")
    //     : [];
    // const hourlyStartDateFilterData =
    //   params.get("hourlyStartDate") !== "" &&
    //   params.get("hourlyStartDate") !== null
    //     ? params.get("hourlyStartDate").split(",")
    //     : [];
    // const hourlyEndDateFilterData =
    //   params.get("hourlyEndDate") !== "" && params.get("hourlyEndDate") !== null
    //     ? params.get("hourlyEndDate").split(",")
    //     : [];
    // const DailyStartDateFilterData =
    //   params.get("DailyStartDate") !== "" &&
    //   params.get("DailyStartDate") !== null
    //     ? params.get("DailyStartDate").split(",")
    //     : [];
    // const DailyEndDateFilterData =
    //   params.get("DailyEndDate") !== "" && params.get("DailyEndDate") !== null
    //     ? params.get("DailyEndDate").split(",")
    //     : [];
    // const MonthlyStartDateFilterData =
    //   params.get("MonthlyStartDate") !== "" &&
    //   params.get("MonthlyStartDate") !== null
    //     ? params.get("MonthlyStartDate").split(",")
    //     : [];
    // const MonthlyEndDateFilterData =
    //   params.get("MonthlyEndDate") !== "" &&
    //   params.get("MonthlyEndDate") !== null
    //     ? params.get("MonthlyEndDate").split(",")
    //     : [];
    // console.log("machineNumberFilterData", machineNumberFilterData);
    const createdStartDateFilterData =
      params.get("createdStartDate") !== "" &&
      params.get("createdStartDate") !== null
        ? params.get("createdStartDate").split(",")
        : [];
    const createdEndDateFilterData =
      params.get("createdEndDate") !== "" &&
      params.get("createdEndDate") !== null
        ? params.get("createdEndDate").split(",")
        : [];
    const resolvedStartDateDateFilterData =
      params.get("resolvedStartDate") !== "" &&
      params.get("resolvedStartDate") !== null
        ? params.get("resolvedStartDate").split(",")
        : [];
    const resolvedEndDateFilterData =
      params.get("resolvedEndDate") !== "" &&
      params.get("resolvedEndDate") !== null
        ? params.get("resolvedEndDate").split(",")
        : [];
    const assigneeFilterData =
      params.get("assignee") !== "" && params.get("assignee") !== null
        ? params.get("assignee").split(",")
        : [];
    const commitIdFilterData =
      params.get("commitId") !== "" && params.get("commitId") !== null
        ? params.get("commitId").split(",")
        : [];
    const modelNoFilterData =
      params.get("modelNo") !== "" && params.get("modelNo") !== null
        ? params.get("modelNo").split(",")
        : [];
    const subIssueTypeFilterData =
      params.get("subIssueType") !== "" && params.get("subIssueType") !== null
        ? params.get("subIssueType").split(",")
        : [];
    // const sortFilterData =
    //   params.get("sort") !== "" && params.get("sort") !== null
    //     ? params.get("sort").split(",")
    //     : [];
    // const searchBoxFilterData =
    //   params.get("searchBox") !== "" && params.get("searchBox") !== null
    //     ? params.get("searchBox").split(",")
    //     : [];
    // const Page =
    //   params.get("currentPage") !== "" && params.get("currentPage") !== null
    //     ? params.get("currentPage")
    //     : 1;
    // const ticketPerPageFilterData =
    //   params.get("ticketPerPage") !== "" && params.get("ticketPerPage") !== null
    //     ? [params.get("ticketPerPage")]
    //     : [];
    // console.log("ticketPerPageFilterData", ticketPerPageFilterData);
    // if (
    //   ticketPerPageFilterData.length ||
    //   ticketPerPageFilterData[0] === "20" ||
    //   ticketPerPageFilterData[0] === "50" ||
    //   ticketPerPageFilterData[0] === "100"
    // ) {
    //   setCardsPerPage(parseInt(ticketPerPageFilterData[0]));
    // }
    // console.log("searchBoxFilterData: ", searchBoxFilterData);
    const cropVariationFilterData =
      params.get("crop_variation") !== "" &&
      params.get("crop_variation") !== null
        ? params.get("crop_variation").split(",")
        : [];
    const paramVariationFilterData =
      params.get("params_variation") !== "" &&
      params.get("params_variation") !== null
        ? params.get("params_variation").split(",")
        : [];

    // const moreFiltersFilterData =
    //   params.get("moreFilters") !== "" && params.get("moreFilters") !== null
    //     ? params.get("moreFilters").split(",")
    //     : [];
    // console.log("moreFiltersFilterData", moreFiltersFilterData);
    // machineNumber: [],
    // createdStartDate: [],
    // createdEndDate: [],
    // resolvedStartDate: [],
    // resolvedEndDate: [],
    // sort: [],
    // crop_variation: [],
    // params_variation: [],

    // console.log("------------->", Page);
    const initalFilters = {
      // status: statusFilterData,
      issueType: issueTypeFilterData,
      RO: ROFilterData,
      DO: DOFilterData,
      machineNumber: machineNumberFilterData,
      // time: timeFilterData,
      // hourlyStartDate: hourlyStartDateFilterData,
      // hourlyEndDate: hourlyEndDateFilterData,
      // DailyStartDate: DailyStartDateFilterData,
      // DailyEndDate: DailyEndDateFilterData,
      // MonthlyStartDate: MonthlyStartDateFilterData,
      // MonthlyEndDate: MonthlyEndDateFilterData,
      createdStartDate: createdStartDateFilterData,
      createdEndDate: createdEndDateFilterData,
      resolvedStartDate: resolvedStartDateDateFilterData,
      resolvedEndDate: resolvedEndDateFilterData,
      crop_variation: cropVariationFilterData,
      params_variation: paramVariationFilterData,
      // moreFilters: moreFiltersFilterData,
      // searchBox: searchBoxFilterData,
      // ticketPerPage: ticketPerPageFilterData,
      assignee: assigneeFilterData,
      commitId: commitIdFilterData,
      subIssueType: subIssueTypeFilterData,
    };
    // console.log("insitial filter --->", initalFilters);

    let is_all_filter_data_Mounted = true;
    const fetchDataAndUpdateFilters = async (
      initalFilters,
      Page,
      updateURL = true
    ) => {
      const currentTime = Date.now();
      const lastDataFetchTimestamp = userFilters?.lastDataFetchTimestamp;

      if (
        !lastDataFetchTimestamp ||
        currentTime - lastDataFetchTimestamp >= 30 * 60 * 1000
      ) {
        // fetchFiltersDataApiCall
        const all_filter_data = await fetchApi(
          "get_user_filters",
          "POST",
          {},
          is_all_filter_data_Mounted
        );
        // console.log("all_filter_data in All Ticket : ", all_filter_data);

        if (all_filter_data.statusCode === 200) {
          dispatch(addFilters(all_filter_data));
          getFilterResponse(all_filter_data);
          showErrorPage(null, null);
        } else if (
          all_filter_data.statusCode === 500 ||
          all_filter_data.statusCode === 401
        ) {
          setData(null);
          showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
        } else {
          setData(null);
          // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
          // console.log("Some Error Occured", all_filter_data);
          localStorage.clear();
          window.location.href = "/login";
        }
      } else {
        // console.log("Data fetch from redux ************", userFilters?.data);
        getFilterResponse(userFilters?.data);
      }
      updateFilters(initalFilters, 0, updateURL);
      setshowAllFilters(initalFilters);
      // showLoading(false);
    };
    fetchDataAndUpdateFilters(initalFilters, 0);
    // console.log("data set to empty List - 2");

    // setTimeout(() => {
    //   setLoading(false);
    //   showLoading(false);
    // }, 2000);

    const handlePopState = () => {
      // Your logic to handle popstate event
      // console.log("mount", "User navigated back or forward");
      const previousPageURL = window.history.state || null;
      // console.log("Previous page URL:", previousPageURL);
      if (previousPageURL && previousPageURL.filters) {
        // showLoading(true);
        fetchDataAndUpdateFilters(previousPageURL.filters, 0, false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      is_all_filter_data_Mounted = false;
      // On unmount, abort the previous request for fetchApi api
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      window.removeEventListener("popstate", handlePopState);
      // console.log("unmount");
    };
  }, []);

  // const itemValues = [Data['total_created'], Data['total_resolved']];

  return (
    <>
      {(!showError.status && (
        <>
          <Dropdown
            blurTickets={handelPageBlur}
            filters={filters}
            filterCount={filterCount}
            handleFilterChange={handleFilterChange}
            handleSaveChanges={handleSaveChanges}
            handleFilterReset={handleFilterReset}
            getUserFilters={getUserFilters}
            getFilterResponse={getFilterResponse}
            filtersOptions={filtersOptions}
            showResolvedDate={true}
            hideStartDate={true}
            dateStr={["Visit Date", "Visit Date"]}
            updateDateFilterName={"Visit Date"}
            assigneeStr={"Support Person"}
          />
          {allFiltersDisplay ? (
            <AllFilters
              filters={showAllfilters}
              handelResetAllBtn={handelResetAllBtn}
              handelDeleteFilterBtn={handelDeleteFilterBtn}
              getUserFilters={getUserFilters}
              showVisitstr={true}
            />
          ) : (
            ""
          )}
          <div
            className={`${blurTickets} all-ticket-content ${
              allFiltersDisplay ? "m-top-0 p-top-0" : " "
            }`}
          >
            {(loading && (
              // {lodingVisiblity && (
              <div className="showSpinner all-ticket-spinner">
                <BeatLoader color="#2D97D4" />
              </div>
            )) ||
              (Data && (
                <div className="container pt-2 ">
                  <div className="upper-heading-container p-0">
                    <div className="info-part">
                      <span className="px-2 mb-1 info-heading">
                        Location Insights
                      </span>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <i
                          className="bi bi-info-circle icon-color px-0"
                          id="info-icon"
                          style={{ fontSize: "15px", lineHeight: "24px" }}
                        ></i>
                      </OverlayTrigger>
                    </div>

                    <div className="show-range ms-auto">{Data?.title}</div>
                  </div>
                  {/* Line chart analysis */}
                  <div className="issue-type-row-div overflow-x-hidden p-0">
                    {(Data &&
                      Object.keys(Data || {}).map((item, index) => {
                        if (
                          item === "visit_count" ||
                          item === "region_count" ||
                          item === "district_count" ||
                          item === "machine_count"
                        ) {
                          const value = Data[item];
                          const color = issueTyepLogoBGcolor[index];
                          const Component = icons[index];
                          // console.log("**************", value, color);

                          return (
                            <div className="card-Div" key={index}>
                              <div className="d-flex align-items-center ">
                                <div className="ps-2 card-Div-heading me-auto">
                                  {item.split("_").join(" ")}
                                </div>
                                <div
                                  className="user-dashboard-circle-icon "
                                  style={{
                                    backgroundColor: color,
                                  }}
                                >
                                  {<Component className="fa-2xl" />}
                                </div>
                              </div>
                              <div className="total-count-div d-flex flex-column align-items-start justify-content-center ps-2">
                                <span className="fs-2 anaylatics_title">
                                  {formatInIndianRupees(value)}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      })) ||
                      "No data to show"}
                  </div>

                  <div className="group-buttons mb-3 p-0">
                    <GroupSizesColors
                      buttons={buttons}
                      clickedButtonIndex={clickedButtonIndex}
                      handleButtonClick={handleButtonClick}
                    />
                  </div>

                  <div className="columngrouptable p-0">
                    <LocationDataMainTable
                      tableStatus={tableData}
                      data={Data}
                    />
                  </div>
                </div>
              )) || (
                <div className="container no-ticket-found-div text-center count_heading_div">
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
                    <p>No Data Found</p>
                  </div>
                </div>
              )}
          </div>
        </>
      )) ||
        (showError.status && <Error />) || <Error code={500} />}
    </>
  );
};

export default LocationWiseVisitData;

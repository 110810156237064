import React, { useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import ContextState from "../contextApi/ContextState";
import close from "../../assets/images/close.svg";

const ScanDetailsEditModal = ({
  modalState,
  handleClose,
  machineData,
  ticketId,
  handleReloadData,
  allScanDetails,
}) => {
  const filterData = useSelector(
    (state) => state?.userFilters?.data?.data || []
  );

  const { fetchApi, url, openSnackbar } = useContext(ContextState);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({
    machine: "",
    crop: "",
    scanid: "",
    results: [],
    newcrop: "",
  });

  // const paramValues = getparam_id(editData.results);
  // console.log("paramValues:", paramValues);

  // console.log("editdata results", editData?.results);
  // console.log(filterData.param_data[editData?.newcrop]?.client_params);

  // console.log(editData?.results);
  const handleSaveEditChanges = async () => {
    try {
      // Construct the payload
      const payload = {
        ticket_id: modalState?.ticket_id,
        scan_id: editData?.scanid,
        old_scan_id: modalState?.selected_scan_id,
        crop_id: filterData?.param_data[editData?.newcrop]?.crop_id,
        old_crop_id:
          filterData?.param_data[
            allScanDetails?.[modalState?.selected_scan_id]?.crop
          ]?.crop_id,
        old_machine:
          allScanDetails?.[modalState?.selected_scan_id]?.machine_code,
        machine_code: editData?.machine,
        param_data: getparam_id(editData?.results),
      };

      setLoading(true);

      // console.log("payload final:---", payload);

      const response = await fetchApi(
        "edit_scan_details",
        "POST",
        payload,
        true,
        1
      );

      // Check if the response is successful
      if (response.statusCode === 200) {
        // Reload the React component
        // console.log("ticket edited succesfully");
        openSnackbar("Scan details added succesfully", "success");
        handleClose();
        handleReloadData();
      } else {
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      openSnackbar("Some error occured while editing scan details", "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (allScanDetails && modalState) {
      setEditData((prev) => ({
        ...prev,
        machine:
          allScanDetails?.[modalState?.selected_scan_id]?.machine_code || "",
        crop: allScanDetails?.[modalState?.selected_scan_id]?.crop || "",
        scanid: modalState?.selected_scan_id || "",
        // results: allScanDetails?.[modalState?.selected_scan_id]?.results || [],
        newcrop: allScanDetails?.[modalState?.selected_scan_id]?.crop || "",
      }));
    }

    // console.log("rendering");
  }, [allScanDetails, modalState]);

  useEffect(() => {
    if (editData?.crop !== editData?.newcrop) {
      // Reset results when crop is changed
      const newResults = Object.keys(
        filterData?.param_data?.[editData?.newcrop]?.client_params || {}
      ).map((param) => [param, null, null]);
      setEditData((prev) => ({
        ...prev,
        results: newResults,
      }));
    } else {
      const oldResults =
        allScanDetails?.[modalState?.selected_scan_id]?.results || [];
      setEditData((prev) => ({
        ...prev,
        results: oldResults,
      }));
    }
    return () => {
      // On unmount, abort the previous request for fetchApi api
    };
  }, [editData?.newcrop]);

  // useEffect(() => {
  //   console.log(editData.newcrop, editData?.crop, editData?.results);
  // });

  const handleParamChange = (paramkey, value, type) => {
    // console.log(value, "--------->");
    const updatedResults = [...editData.results];

    // updatedResults[index][type === "matt" ? 1 : 2] = value;

    updatedResults.find((item) => item[0] === paramkey)[
      type === "matt" ? 1 : 2
    ] = value;

    // console.log("--->", updatedResults);
    // Update the editData state with the modified results array
    setEditData((prevEditData) => ({
      ...prevEditData,
      results: updatedResults,
    }));
  };
  function getparam_id(data) {
    const newcrop = editData.newcrop;
    const clientParams = filterData.param_data[newcrop]?.client_params;

    if (!clientParams) return [];

    return data.map(([paramName, value1, value2]) => {
      const paramId = Object.entries(clientParams).find(
        ([paramKey]) => paramName === paramKey
      )?.[1];
      return {
        param_id: paramId,
        matt_result: value1 ? parseFloat(value1) : null,
        manual_result: value2 ? parseFloat(value2) : null,
      };
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prev) => ({ ...prev, [name]: value }));

    // Reset results when crop is changed
    // setEditData((prev) => ({
    //   ...prev,
    //   results: Object.keys(
    //     filterData?.param_data?.[value]?.client_params || {}
    //   ).map((param) => [param, null, null]),
    // }));
  };

  // console.log(editData);
  const modalstyle = {
    position: "relative",
    zIndex: "90",
    right: "0",
    top: "5%",
    left: "0",
    margin: "  auto",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    overflowY: "auto",
    width: "85vw",
    maxWidth: "1250px",
  };
  return (
    <Modal
      style={{
        position: "fixed",
        height: "100%",
        overflowY: "scroll",
        margin: "0px 0px 0px 10px",
      }}
      open={modalState?.isOpen}
      onClose={handleClose}
    >
      <Box sx={modalstyle}>
        <Typography
          style={{
            padding: "16px",

            borderBottom: "1px solid rgb(199, 199, 199)",
          }}
          className="d-flex justify-content-between"
        >
          <h5 className="mb-0">Edit Scan Details</h5>
          <div
            onClick={() => {
              // console.log("Model hide working : ");
              handleClose();
            }}
          >
            <img
              src={close}
              alt="close"
              style={{ width: "20px", cursor: "pointer" }}
            />
          </div>
        </Typography>
        <Typography>
          {editData && modalState?.selected_scan_id && (
            <div className="p-4 pb-0">
              <div>
                <FormControl className="w-100 mt-2 mb-2 " size="medium">
                  <InputLabel id="machine-label">Machine</InputLabel>
                  <Select
                    labelId="machine-label"
                    value={editData?.machine || ""}
                    name="machine"
                    onChange={handleChange}
                    label="Machine"
                  >
                    {machineData?.length &&
                      machineData.map((item, index) => (
                        <MenuItem key={index} value={item["code"]}>
                          {item["code"]} ({item?.location})
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <TextField
                  id="outlined-multiline-flexible"
                  label="Scan Id"
                  value={editData?.scanid}
                  name="scanid"
                  onChange={handleChange}
                  multiline
                  maxRows={4}
                  required
                  className="w-100 my-2"
                />

                <FormControl className="w-100 my-2" size="medium">
                  <InputLabel id="crop-label">Crop</InputLabel>
                  <Select
                    labelId="crop-label"
                    value={editData?.newcrop || ""}
                    name="newcrop"
                    onChange={handleChange}
                    label="Crop"
                    disabled
                  >
                    {filterData?.param_data &&
                      Object.keys(filterData.param_data).map((crop, index) => (
                        <MenuItem key={index} value={crop}>
                          {crop}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {filterData &&
                  editData?.newcrop &&
                  filterData.param_data[editData.newcrop] && (
                    <div className="crop-details-container">
                      <div className="row mb-3 heading-parameters">
                        <div className="col-6 ">Parameters</div>
                        <div className="col-3 ">Matt</div>
                        <div className="col-3 ">Manual</div>
                      </div>
                      {editData?.newcrop &&
                        Object.entries(
                          filterData.param_data[editData.newcrop][
                            "client_params"
                          ]
                        ).map(([paramKey, paramValue], index) => (
                          <div
                            key={paramValue}
                            className="param-column row mb-2"
                          >
                            <div className="param-name col-6">{paramKey}</div>
                            <div className="col-3">
                              <input
                                className="form-control"
                                type="number"
                                // value={editData?.results?.[index]?.[1] || ""}
                                value={
                                  editData?.results.find(
                                    (item) => item[0] === paramKey
                                  )?.[1] || null
                                }
                                placeholder=""
                                onChange={(e) =>
                                  handleParamChange(
                                    paramKey,
                                    e.target.value,
                                    "matt"
                                  )
                                }
                              />
                            </div>

                            <div className="col-3">
                              <input
                                className="form-control"
                                type="number"
                                value={
                                  editData?.results.find(
                                    (item) => item[0] === paramKey
                                  )?.[2] || null
                                }
                                placeholder=""
                                onChange={(e) =>
                                  handleParamChange(
                                    paramKey,
                                    e.target.value,
                                    "manual"
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
              </div>
            </div>
          )}
        </Typography>
        <Typography className="float-end p-3">
          {/* <Button variant="secondary" onClick={handleClose}>Close</Button> */}

          {/* <Button variant="primary" onClick={handleChangesinDB}>Save Changes</Button> */}
          <LoadingButton
            size="large"
            onClick={handleSaveEditChanges}
            endIcon={<SendIcon />}
            loading={loading}
            // disabled={
            //   scanDetailsList.length === 0 &&
            //   elementList.length === 0 &&
            //   modalDetailsList.length === 0
            // }
            loadingPosition="end"
            variant="contained"
          >
            <span>Submit</span>
          </LoadingButton>
        </Typography>
      </Box>
    </Modal>
  );
};

export default ScanDetailsEditModal;

import React, { useContext, useEffect, useRef, useState } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import "../../assets/styles/LineChartAnalysis.css";
// import "../../assets/styles/UserDashboard.css";
import Dropdown from "../filters/Dropdown";
import AllFilters from "../filters/AllFilters";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ToolTip from "react-bootstrap/Tooltip";
import { BeatLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { addFilters } from "../features/filtersSlice/filterSlice";
import ContextState from "../contextApi/ContextState";
import Error from "../error/Error";
import * as XLSX from "xlsx";
import {
  LinearProgress,
  linearProgressClasses,
  Tooltip as MuiTooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { FaTicketAlt, FaCheckCircle } from "react-icons/fa";
import { useMediaQuery } from "@material-ui/core";
import TotalMachinesImg from "../../assets/images/machine-blue.png";
import TotalRegionImg from "../../assets/images/region-blue.png";
import TotalDistrictImg from "../../assets/images/district-blue.png";
import { createPortal } from "react-dom";
import { CiExport } from "react-icons/ci";
import { current } from "@reduxjs/toolkit";

const icons = [, FaTicketAlt, FaCheckCircle];

const LineChartAnalysis = ({ isSideBarOpen }) => {
  const BorderLinearProgress = styled(LinearProgress)(
    ({ theme, colorAccept, colorReject, value }) => ({
      height: 8,
      borderRadius: 5,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: colorReject,
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: colorAccept,
        borderRadius: 0,
        opacity: value > 50 ? 1 : 0.75,
        // borderRight: "2px solid white",
      },
    })
  );
  const [showHeading, setShowHeading] = useState(false);
  const issueTyepLogoBGcolor = [, "#f79009", "#10b981"];
  function generateMonthsList(fromDate, toDate) {
    const startDate = new Date(fromDate + "-01"); // Convert fromDate to Date object
    const endDate = new Date(toDate + "-01"); // Convert toDate to Date object

    const monthsList = [];
    let currentDate = startDate;

    // Loop until currentDate is less than or equal to endDate
    while (currentDate <= endDate) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // getMonth() returns zero-based month index
      const formattedMonth = month < 10 ? "0" + month : month.toString(); // Format month with leading zero if needed
      const formattedDate = year + "-" + formattedMonth;
      monthsList.push(formattedDate);

      // Move currentDate to the next month
      currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    }

    return monthsList;
  }
  // Set initial width
  const chartContainerRef = useRef(null);

  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");
  const [chartWidth, setChartWidth] = useState(1700);
  const pageStyling = {
    imageBackgroundDivRadius: "25%",
    topDivwidth: "33px",

    IconWidth: "25px",
    iconBackgroundWidth: "45px",
    iconBackgroundHeight: "45px",
  };
  const color = {
    primary: "#f7f7f9", // website background
    // secondary: "#8884d8",
    // secondary: "#826af9", // blue color in dashboard
    // secondary: "#0098e9", // blue color in dashboard
    secondary: "#666bff", // blue color in dashboard
    green: "#82ca9d", // green color in dashboard
    red: "#ff4d49d9", // red color in dashboard
    navBorderBottom: "#4c4e6422",
    taxtHeadingLight: "#4c4e6499", // normal text heading light color
    taxtHeading: "#4c4e64e6", // normal text heading dark color (76,78,100,0.97)
    tableRowHeading: "#4c4e64de", // (76,78,100,0.87)
    iconBackgroundBlue: "#666bff39",
    iconBackgroundWhite: "#ffffffe6",
    iconBackgroundgreen: "#82ca9d39",
    iconBackgroundred: "#ff4d4939",
    textWhiteColor: "#ffffff",
    textDescBackgroundRegionDiv: "#5b61e5",
    locationTextHeading: "#4c4e64fc", //location heeading color
    option_1: "#8884d8",
    option_1_bg: "#8884d830",
    option_2: "#82ca9d",
    option_2_bg: "#82ca9d30",
    option_3: "#fdb528d9",
    option_3_bg: "#fdb52830",
    option_4: "#26c6f9d9",
    option_4_bg: "#26c6f930",
    option_5: "#ff8042",
    option_5_bg: "#ff804230",
    option_6: "#36A2EB",
    option_6_bg: "#36A2EB30",
    option_7: "#FF66B2",
    option_7_bg: "#FF66B230",
    option_8: "#4BC0C0",
    option_8_bg: "#4BC0C030",
    upper_limit: "#ff4d49d9",
    lower_limit: "#ff4d4966",
  };

  const [showError, setShowError] = useState({
    status: null,
    msg: null,
  });
  const showErrorPage = (status, msg) => {
    showError.status = status;
    showError.msg = msg;
    setShowError(showError);
  };

  useEffect(() => {
    const updateChartWidth = () => {
      if (chartContainerRef.current) {
        const containerWidth = chartContainerRef.current.clientWidth;
        setChartWidth(containerWidth <= 1100 ? 1100 : containerWidth * 0.95);
      }
    };

    updateChartWidth();

    window.addEventListener("resize", updateChartWidth);

    return () => {
      window.removeEventListener("resize", updateChartWidth);
    };
  }, []);

  const renderTooltip = (props) => (
    <ToolTip id="button-tooltip" {...props}>
      Comparison of Parameter <strong>{Data?.param_name} </strong> for the Crop{" "}
      <strong> {Data?.crop} </strong> Across Selected Options
    </ToolTip>
  );

  // ************* filters required data ***********
  const {
    fetchApi,
    showLoading,
    formatDate,
    formatInIndianRupees,
    controllerRef,
  } = useContext(ContextState);
  const dispatch = useDispatch();
  const userFilters = useSelector((state) => state.userFilters); // user filter from redux

  // blur all tickets
  const [blurTickets, setblurTickets] = useState("");

  const handelPageBlur = (blur_ticket) => {
    if (blur_ticket) {
      setblurTickets("all-ticket-content-blur");
    } else {
      setblurTickets("");
    }
  };

  // loding page when data to be fetched
  const [loading, setLoading] = useState(true);
  const [allFiltersDisplay, setallFilterDisplay] = useState(false);
  // filters
  const [filters, setFilters] = useState({
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    time: [],
    machineNumber: [],
    hourlyStartDate: [],
    hourlyEndDate: [],
    DailyStartDate: [],
    DailyEndDate: [],
    MonthlyStartDate: [],
    MonthlyEndDate: [],
    weeklyStartDate: [],
    weeklyEndDate: [],
    crop_variation: [],
    params_variation: [],
    moreFilters: [],
    searchBox: [],
    ticketPerPage: [],
    assignee: [],
    commitId: [],
    modelNo: [],
    compare: [],
    option: [],
  });
  const [showAllfilters, setshowAllFilters] = useState({
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    time: [],
    machineNumber: [],
    hourlyStartDate: [],
    hourlyEndDate: [],
    DailyStartDate: [],
    DailyEndDate: [],
    MonthlyStartDate: [],
    MonthlyEndDate: [],
    crop_variation: [],
    params_variation: [],
    moreFilters: [],
    searchBox: [],
    ticketPerPage: [],
    assignee: [],
    commitId: [],
    modelNo: [],
  });
  const [filterCount, setFilterCount] = useState({
    issueType: 0,
    subIssueType: 0,
    RO: 0,
    DO: 0,
    time: 0,
    machineNumber: 0,
    hourlyStartDate: 0,
    hourlyEndDate: 0,
    DailyStartDate: 0,
    DailyEndDate: 0,
    MonthlyStartDate: 0,
    MonthlyEndDate: 0,
    crop_variation: 0,
    params_variation: 0,
    moreFilters: 0,
    searchBox: 0,
    ticketPerPage: 0,
    assignee: 0,
    commitId: 0,
    modelNo: 0,
  });
  // Function to update filters
  const updateFilters = (Filters, clickedPageNumber = 0, updateURL = true) => {
    // console.log("update filter 0 -->", Filters, clickedPageNumber);
    Filters = applyCoustomChecksFilter(Filters);
    setFilters(Filters);
    // setCurrentPage(clickedPageNumber);
    if (updateURL) {
      updateURLParams(Filters, clickedPageNumber);
      // console.log("Url Is Updating ");
    }
    // else {
    // // console.log("Url Is Not Updating ");
    // }
    updateFilterCount(Filters);
    if (getUserFilters?.issues) {
      applyIssueTypeFilters(Filters);
    }
    showAllFilters(Filters);
    fetchData(Filters);
  };

  const updateFilterCount = (farr) => {
    for (const key in farr) {
      const count = farr[key].length;
      filterCount[key] = count;
    }
    setFilterCount(filterCount);
  };

  const updateURLParams = (params) => {
    const urlSearchParams = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      // console.log(key, params[key]);
      if (params[key]) {
        urlSearchParams.set(key, params[key]);
      } else {
        urlSearchParams.delete(key);
      }
    });
    // console.log("url send to update : ", params);
    // urlSearchParams.set("currentPage", parseInt(page) + 1);
    // urlSearchParams.set("cardsPerPage", cardsPerPage);
    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;

    if (window.location.pathname === "/FCI/analytics" && !Data) {
      // Check if the state already exists
      // console.log("Replace state Wroking");
      const currentState = window.history.state;
      if (!currentState || !currentState.filters) {
        // Replace the initial state if no filters exist
        window.history.replaceState(
          {
            filters: params,
          },
          "",
          newUrl
        );
      }
    } else {
      // Push state for subsequent changes
      window.history.pushState(
        {
          filters: params,
        },
        "",
        newUrl
      );
    }
  };

  // this will populate our dashboard page
  const [Data, setData] = useState(null); // page data
  const fetchData = async (F, Page = 0) => {
    setData(null);
    // console.log("data set to empty List - 3");

    setLoading(true);
    showLoading(true);
    // data from backend
    // total number of cards
    // cards filter show how many pages perpage (cardsPerPage : 15)

    const payload = {};
    // payload["page_num"] = parseInt(Page) + 1;
    if (F["createdStartDate"] && F["createdStartDate"].length) {
      payload["creation_from"] = F["createdStartDate"][0];
    }
    if (F["createdEndDate"] && F["createdEndDate"].length) {
      payload["creation_to"] = F["createdEndDate"][0];
    }
    if (
      F["resolvedStartDate"] &&
      F["resolvedStartDate"].length &&
      F["status"].includes("Resolved")
    ) {
      payload["resolved_from"] = F["resolvedStartDate"][0];
    }
    if (
      F["resolvedEndDate"] &&
      F["resolvedEndDate"].length &&
      F["status"].includes("Resolved")
    ) {
      payload["resolved_to"] = F["resolvedEndDate"][0];
    }
    //  filters for dashboard
    if (F["time"]?.length !== 0) {
      payload["frequency"] = F["time"][0];
    }
    if (F["weeklyStartDate"]?.length && F["time"].includes("weekly")) {
      payload["start_date"] = F["weeklyStartDate"][0];
    }
    if (F["weeklyEndDate"]?.length && F["time"].includes("weekly")) {
      payload["end_date"] = F["weeklyEndDate"][0];
    }
    if (F["DailyStartDate"]?.length && F["time"].includes("daily")) {
      payload["start_date"] = F["DailyStartDate"][0];
    }
    if (F["DailyEndDate"]?.length && F["time"].includes("daily")) {
      payload["end_date"] = F["DailyEndDate"][0];
    }
    if (F["MonthlyStartDate"]?.length && F["time"].includes("monthly")) {
      payload["start_date"] = F["MonthlyStartDate"][0];
    }
    if (F["MonthlyEndDate"]?.length && F["time"].includes("monthly")) {
      payload["end_date"] = F["MonthlyEndDate"][0];
    }
    if (
      F["MonthlyStartDate"]?.length &&
      F["time"].includes("monthly") &&
      F["MonthlyEndDate"]?.length &&
      F["time"].includes("monthly")
    ) {
      payload["months_list"] = generateMonthsList(
        F["MonthlyStartDate"],
        F["MonthlyEndDate"]
      );
    }

    if (F["issueType"] && F["issueType"].length) {
      payload["issue"] = F["issueType"];
    }
    if (F["subIssueType"] && F["subIssueType"].length) {
      payload["sub_issue"] = F["subIssueType"];
    }
    if (F["status"] && F["status"].length) {
      payload["status"] = F["status"].map((e) => e.toLowerCase());
    }
    if (F["machineNumber"] && F["machineNumber"].length) {
      payload["machines"] = F["machineNumber"];
    }
    if (F["DO"] && F["DO"].length) {
      payload["districts"] = F["DO"];
    }
    if (F["RO"] && F["RO"].length) {
      payload["regions"] = F["RO"];
    }
    if (F["commitId"] && F["commitId"].length) {
      payload["commit_id"] = F["commitId"];
    }
    if (F["modelNo"] && F["modelNo"].length) {
      payload["model_no"] = F["modelNo"];
    }
    // if (F["sort"] && F["sort"].length) {
    //   const sortDictManual = {
    //     "Creation time- Ascending": "creation_time:ASC",
    //     "Creation time- Descending": "creation_time:DESC",
    //     "Updated time- Descending": "last_updated:DESC",
    //   };
    //   const sortArr =
    //     (sortDictManual[filtersOptions["sortDict"][0]] &&
    //       F["sort"].map((item) => filtersOptions["sortDict"][item])) ||
    //     sortDictManual[F["sort"][0]];
    //   payload["Sort"] = sortArr;
    // }
    if (F["crop_variation"] && F["crop_variation"].length) {
      payload["crop"] = F["crop_variation"][0];
    }
    if (F["params_variation"] && F["params_variation"].length) {
      payload["description"] = F["params_variation"][0];
    }
    if (F["assignee"] && F["assignee"].length) {
      payload["assignee"] = F["assignee"];
    }
    if (F["moreFilters"] && F["moreFilters"].length) {
      F["moreFilters"].forEach((item) => {
        payload[item] = true;
      });
    }
    if (F["searchBox"] && F["searchBox"].length && F["searchBox"][0] !== "") {
      payload["ticket_id"] = F["searchBox"][0];
    }
    if (
      F["ticketPerPage"] &&
      F["ticketPerPage"].length &&
      F["ticketPerPage"][0] !== ""
    ) {
      payload["tickets_limit"] = F["ticketPerPage"][0];
    }
    if (F["option"] && F["option"].length && F["option"][0] !== "") {
      const dict = {};
      for (let index = 0; index < F["option"].length; index++) {
        const select = F["option"][index].split(/-(.+)/);
        const selectCompare = select[0];
        const selectValue = select[1];
        dict[`option_${index + 1}`] = {
          compare: selectCompare,
          value:
            selectCompare === "machine" ? selectValue.slice(0, 4) : selectValue,
        };
      }
      payload["option"] = dict;
    }

    let is_all_ticket_data_Mounted = true;
    // console.log("data send : ", payload);

    const all_tickets_data = await fetchApi(
      "get_pram_id_info",
      "POST",
      payload,
      is_all_ticket_data_Mounted
    );
    // console.log("data send : ", payload, all_tickets_data?.data);

    if (is_all_ticket_data_Mounted) {
      if (all_tickets_data.statusCode === 200) {
        showErrorPage(null, null);
        setData(all_tickets_data?.data || null);
        // settotalCardsCount(all_tickets_data?.data?.total_count || 1);

        // if (all_tickets_data?.data?.tickets_limit === "All") {
        //   setCardsPerPage(all_tickets_data?.data?.total_count || 1);
        // } else {
        //   setCardsPerPage(all_tickets_data?.data?.tickets_limit || 1);
        // }
      } else if (
        all_tickets_data.statusCode === 500 ||
        all_tickets_data.statusCode === 401
      ) {
        setData(null);
        showErrorPage(all_tickets_data.statusCode, all_tickets_data.msg);
      } else {
        setData(null);
        // showErrorPage(all_tickets_data.statusCode, all_tickets_data.msg);
        localStorage.clear();
        window.location.href = "/login";
      }
    }

    setLoading(false);
    showLoading(false);
    // setTimeout(() => {
    // }, 100);

    return () => {
      is_all_ticket_data_Mounted = false;
    };
  };

  //   useEffect(() => {
  //     console.log("Data : ", Data.data);
  //   }, [Data]);

  const handleFilterChange = (filter, filterType) => {
    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };
      const filterList = currentFilters[filterType];

      if (
        filterList &&
        (filterType === "searchBox" ||
          filterType === "createdStartDate" ||
          filterType === "createdEndDate" ||
          filterType === "resolvedStartDate" ||
          filterType === "resolvedEndDate" ||
          filterType === "hourlyStartDate" ||
          filterType === "hourlyEndDate" ||
          filterType === "DailyStartDate" ||
          filterType === "DailyEndDate" ||
          filterType === "MonthlyStartDate" ||
          filterType === "MonthlyEndDate" ||
          filterType === "weeklyStartDate" ||
          filterType === "weeklyEndDate" ||
          filterType === "time")
      ) {
        if (filterType === "time") {
          currentFilters["DailyStartDate"] = [];
          currentFilters["DailyEndDate"] = [];
          currentFilters["MonthlyStartDate"] = [];
          currentFilters["MonthlyEndDate"] = [];
          currentFilters["hourlyStartDate"] = [];
          currentFilters["hourlyEndDate"] = [];
          currentFilters["weeklyStartDate"] = [];
          currentFilters["weeklyEndDate"] = [];
        }
        if (filter !== "") currentFilters[filterType] = [filter];
        else currentFilters[filterType] = [];
      } else if (filterList && filterType === "compare") {
        if (filter !== "") currentFilters[filterType] = [filter];
        else {
          currentFilters[filterType] = [];
        }
      } else if (
        (filterList && filterType === "sort") ||
        filterType === "ticketPerPage" ||
        filterType === "crop_variation" ||
        filterType === "params_variation"
      ) {
        if (filterType === "crop_variation") {
          currentFilters["params_variation"] = [];
        }
        currentFilters[filterType] = [filter];
      } else if (filterList && filterList.includes(filter)) {
        currentFilters[filterType] = filterList.filter(
          (prevFilter) => prevFilter !== filter
        );
      } else {
        currentFilters[filterType] = [...(filterList || []), filter];
      }
      if (filterType === "RO") {
        applyROFilters(currentFilters);
      } else if (filterType === "machineNumber") {
        applyMachineNumberFilters(currentFilters);
      } else if (filterType === "DO") {
        applyDOFilters(currentFilters);
      }
      applyIssueTypeFilters(currentFilters);
      updateFilterCount(currentFilters);

      if (filterType !== "params_variation") {
        applyCropVariationFilters(currentFilters);
      }
      // console.log(
      //   "update filter 1 -->",
      //   prevFilters,
      //   currentFilters,
      //   filter,
      //   filterType
      // );
      return currentFilters;
    });
  };

  const handleFilterReset = (filtername) => {
    const updatefilters = { ...filters };
    updatefilters[filtername] = [];
    if (filtername === "moreFilters") {
      updatefilters["searchBox"] = [];
      updateFilters["ticketPerPage"] = [];
    }
    if (filtername === "issueType") {
      updatefilters["subIssueType"] = [];
    }
    if (filtername === "time") {
      updatefilters["DailyStartDate"] = [];
      updatefilters["DailyEndDate"] = [];
      updatefilters["MonthlyStartDate"] = [];
      updatefilters["MonthlyEndDate"] = [];
      updatefilters["hourlyStartDate"] = [];
      updatefilters["hourlyEndDate"] = [];
    }
    updateFilterCount(updatefilters);
    setFilters(updatefilters);
    // console.log(filtername);
    if (filtername === "RO") {
      applyROFilters(updatefilters);
    } else if (filtername === "machineNumber") {
      applyMachineNumberFilters(updatefilters);
    } else if (filtername === "DO") {
      applyDOFilters(updatefilters);
    }
    // console.log(updatefilters, "updateFilterCount");
    // applyIssueTypeFilters(updatefilters);
    updateFilterCount(updatefilters);
  };

  const handleSaveChanges = (filtername) => {
    // console.log("sendFilters->  ", filtername, filters[filtername]);
    const filterAfterCoustomChange = applyCoustomChecksFilter(filters);
    updateFilters(filterAfterCoustomChange, 0);
    setshowAllFilters(filterAfterCoustomChange);
  };

  const handelResetAllBtn = () => {
    // console.log("working");
    for (const key in filters) {
      filters[key] = [];
    }
    // console.log("updated filter ", filters);
    updateFilters(filters, 0);

    setfiltersOptions((prevState) => ({
      ...prevState,
      //   DO: filtersOptionsCopy["DO"],
      //   machineNumber: filtersOptionsCopy["machineNumber"],
      //   RO: filtersOptionsCopy["RO"],
    }));
  };

  const handelDeleteFilterBtn = (filterType, subfilter) => {
    // console.log("working", filterType, subfilter);

    // Check if the filterKey exists in the filters state
    if (filters.hasOwnProperty(filterType)) {
      // Remove the specified filterValue from the filterKey array
      filters[filterType] = filters[filterType].filter(
        (filter) => filter !== subfilter
      );
    }
    if (filterType === "status" && subfilter === "Resolved") {
      filters["resolvedEndDate"] = [];
      filters["resolvedStartDate"] = [];
    }
    if (filterType === "issueType") {
      filters["subIssueType"] = [];
      filters["status"] = [];
      if (subfilter === "Data Variation") {
        filters["crop_variation"] = [];
        filters["params_variation"] = [];
        filters["modelNo"] = [];
        filters["commitId"] = [];
      }
    }
    if (filterType === "crop_variation") {
      filters["params_variation"] = [];
    }

    if (
      filterType === "hourlyStartDate" ||
      filterType === "hourlyEndDate" ||
      filterType === "DailyStartDate" ||
      filterType === "DailyEndDate" ||
      filterType === "MonthlyStartDate" ||
      filterType === "MonthlyEndDate" ||
      filterType === "time"
    ) {
      if (filterType !== "time") {
        filters["time"] = [];
      }
      filters["DailyStartDate"] = [];
      filters["DailyEndDate"] = [];
      filters["MonthlyStartDate"] = [];
      filters["MonthlyEndDate"] = [];
      filters["hourlyStartDate"] = [];
      filters["hourlyEndDate"] = [];
    }
    if (filterType === "RO") {
      applyROFilters(filters);
    } else if (filterType === "machineNumber") {
      applyMachineNumberFilters(filters);
    } else if (filterType === "DO") {
      applyDOFilters(filters);
    }
    updateFilters(filters, 0);

    // console.log("Deleted single filter --->", filters);
  };
  const handelTempRemoveFilterBtn = (filterType, subfilter) => {
    // console.log("working", filterType, subfilter);

    // Check if the filterKey exists in the filters state
    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };

      if (currentFilters.hasOwnProperty(filterType)) {
        // Remove the specified filterValue from the filterKey array
        currentFilters[filterType] = currentFilters[filterType].filter(
          (filter) => filter !== subfilter
        );
      }

      return currentFilters;
    });
  };
  const handelAutoSelectFilterBtn = (FilterName, FilterValue) => {
    // given machine number find its district and ro
    // and only ro if it is ro machine
    // console.log("Auto Select working : ", FilterName, FilterValue);
    const optionsSelected = FilterValue.split(/-(.+)/);
    const comparator = optionsSelected[0];
    const value = optionsSelected[1];
    // console.log("Auto Select working : ", comparator, value);
    const MachineValueArr = Array.from([value.slice(0, 4)]);
    // console.log(MachineValueArr, typeof MachineValueArr);
    let filteredRODO = Object.entries(getUserFilters?.ro_machines || {}).reduce(
      (acc, [ro, roMachine]) => {
        if (MachineValueArr.includes(roMachine)) {
          acc.push({ RO: ro });
        }
        return acc;
      },
      []
    );
    // console.log("Ro Machine Data ", filteredRODO);

    if (filteredRODO.length === 0) {
      filteredRODO = Object.entries(getUserFilters?.filters_data || {}).reduce(
        (acc, [ro, roData]) => {
          Object.entries(roData || {}).forEach(([doKey, machines]) => {
            const matchingMachines = machines.filter((machine) =>
              MachineValueArr.includes(machine)
            );
            if (matchingMachines.length > 0) {
              acc.push({ RO: ro, DO: doKey });
            }
          });
          return acc;
        },
        []
      );
    }

    // Extract unique RO and DO keys
    const uniqueRO = Array.from(new Set(filteredRODO.map((entry) => entry.RO)));
    const uniqueDO = Array.from(new Set(filteredRODO.map((entry) => entry.DO)));

    // console.log(uniqueRO, uniqueDO);

    const addOptions = [];
    for (const RO of uniqueRO) {
      if (RO) {
        addOptions.push(`region-${RO}`);
      }
    }
    for (const DO of uniqueDO) {
      if (DO) {
        addOptions.push(`district-${DO}`);
      }
    }

    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };

      currentFilters[FilterName] = [
        ...currentFilters[FilterName].filter((value) => value === FilterValue),
        ...addOptions,
      ];

      return currentFilters;
    });
  };

  function showAllFilters(FiltersDict) {
    for (const i in FiltersDict) {
      if (FiltersDict[i].length !== 0) {
        // console.log("show filter");
        setallFilterDisplay(true);
        return;
      }
    }
    setallFilterDisplay(false);
  }

  const [getUserFilters, setgetUserFilters] = useState(null);

  // filters to show

  const [filtersOptions, setfiltersOptions] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    commitId: [],
    modelNo: [],
    moreFilters: [],
    time: ["weekly", "daily", "monthly"],
    compare: ["Region", "District", "Machine"],
    ticketPerPage: [],
  });
  const [filtersOptionsCopy, setfiltersOptionsCopy] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    commitId: [],
    modelNo: [],
    moreFilters: [],
  });
  // filters sorting according to selected filter
  const getFilterResponse = async (response, initalFilters = null) => {
    // setLoading(true);
    // showLoading(true);
    try {
      // calling refresh token whenever loading the page
      // console.log("get_user_filters--->", response);
      if (response.statusCode === 200) {
        // console.log("get_user_filters Data Fetched Successfully");
        const data = response.data;
        // console.log("data", new Date().toLocaleString(), data);
        setgetUserFilters(data);

        // Extract RO# keys from  filters_data
        const roKeys =
          data && data.access_level === "institution"
            ? Object.keys(data?.filters_data || {})
            : [];
        const compareUniqueRoKeys = roKeys.map((keys) => ({
          label: keys,
        }));

        // Extract DO# keys from RO objects in filters_data
        const doKeys =
          data && data.access_level === "institution"
            ? Object.values(data?.filters_data || {}).flatMap((Do) =>
                Object.keys(Do)
              )
            : data && data.access_level === "region"
            ? Object.keys(data.filters_data)
            : [];

        // Populate machineNumber array with unique machine numbers
        const uniquedoKeys = Array.from(new Set(doKeys));
        const compareUniqueDoKeys = uniquedoKeys.map((keys) => ({
          label: keys,
        }));

        // Extract machine numbers from DO keys
        const machineNumbervalue =
          data && data.access_level === "institution"
            ? Object.values(data?.filters_data || {})
                .flatMap((ro) => Object.values(ro))
                .flatMap((doData) => doData)
                .flatMap((machine) => machine)
                .concat(Object.values(data?.ro_machines || {}))
            : data && data.access_level === "region"
            ? Object.values(data?.filters_data || {})
                .flatMap((doList) => {
                  return Object.values(doList);
                })
                .flat()
            : data && data.access_level === "district"
            ? data.filters_data
            : [];

        // Populate machineNumber array with unique machine numbers
        const uniqueMachineNumbers = Array.from(new Set(machineNumbervalue));
        const comapreUniqueMachineNumbers = uniqueMachineNumbers.map(
          (keys) => ({
            label: `${keys} (${data.machines_data[keys]})`,
          })
        );

        // ********** not required ************
        // populate subissuetype in filter option
        // const subIssueTypes = Object.values(data.issues[0]).flatMap((issue) =>
        //   issue.sub_issue.map((subIssue) => subIssue[1])
        // );
        let commitIdArr = data && Object.values(data.commit_id_list || {});

        commitIdArr = commitIdArr.map((commitIds, index) => {
          return commitIds.slice(0, 5);
        });
        const modelNoArr = data && Object.values(data.model_no_list || {});
        // console.log(
        //   "Sub issues : ",
        //   subIssueTypes,
        //   typeof subIssueTypes,
        //   subIssueTypes.length
        // );
        // populating sort in filters Option
        // const sortArr =
        //   (data && data.sort_list.map((item) => item.value)) || [];
        // const sortDictArr =
        //   (data &&
        //     data.sort_list.reduce((acc, item) => {
        //       acc[item.value] = item.id;
        //       return acc;
        //     }, {})) ||
        //   [];

        // const assigneeType =
        //   (data && data.assignee_list?.map((item) => item.name)) || [];
        // // console.log("assigneeType", assigneeType);
        const cropvariationTypes = data ? Object.keys(data.param_data) : [];

        let dataParamsTypes =
          Array.from(
            new Set(
              data &&
                Object.values(data.param_data).flatMap((item) =>
                  Object.keys(item.client_params)
                )
            )
          ) || [];

        const dataParamsTypesCopy = dataParamsTypes;

        if (initalFilters) {
          if (initalFilters?.crop_variation?.length !== 0) {
            const selectedCrops = initalFilters.crop_variation;

            // Filter client_params keys based on selectedCrops
            const clientParamsKeysArray = Object.entries(data?.param_data || {})
              .filter(([key]) => selectedCrops.includes(key)) // Filter based on selectedCrops
              .flatMap(([key, value]) => Object.keys(value.client_params));

            // Update filtersOptions.params_variation
            dataParamsTypes = Array.from(
              new Set([
                ...(filters.params_variation || []),
                ...clientParamsKeysArray,
              ])
            );
          }
        }

        // console.log("cropvariationTypes", cropvariationTypes, dataParamsTypes);
        // const statusTypes = Array.from(
        //   new Set(Object.values(data?.states_list || {}).flat())
        // );
        // const issueTypeArr = Object.keys(data?.states_list || {});
        // console.log("issueTypeArr : ", issueTypeArr)

        setfiltersOptions((eachFilterToShow) => ({
          ...eachFilterToShow,
          //   subIssueType: subIssueTypes,
          crop_variation: cropvariationTypes,
          //   assignee: assigneeType,
          params_variation: dataParamsTypes,
          //   issueType: issueTypeArr,
          //   modelNo: modelNoArr,
          //   commitId: commitIdArr,
          // status: statusTypes,
          Compare_RO: compareUniqueRoKeys,
          Compare_DO: compareUniqueDoKeys,
          Compare_machineNumber: comapreUniqueMachineNumbers,
        }));
        setfiltersOptionsCopy((eachFilterToShow) => ({
          ...eachFilterToShow,
          //   RO: roKeys,
          //   DO: uniquedoKeys,
          //   machineNumber: uniqueMachineNumbers,
          //   subIssueType: subIssueTypes,
          //   assignee: assigneeType,
          crop_variation: cropvariationTypes,
          params_variation: dataParamsTypesCopy,
          //   issueType: issueTypeArr,
          //   modelNo: modelNoArr,
          //   commitId: commitIdArr,
          // status: statusTypes,
        }));
      } else {
        // Handle non-successful responses
        console.error("Error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const applyROFilters = (filtersDict) => {
    const roFilters = filtersDict.RO;

    // Extract all DO keys from selected RO filters
    const filteredDO = roFilters.flatMap((ro) =>
      getUserFilters.filters_data[ro]
        ? Object.keys(getUserFilters?.filters_data[ro] || {})
        : []
    );

    // console.log("filteredDO", filteredDO, roFilters);

    // Extract all machine numbers from selected RO filters and DO keys
    const filteredMachineNumbers = roFilters.flatMap((ro) =>
      getUserFilters.filters_data[ro]
        ? Object.values(getUserFilters?.filters_data[ro] || {})
            .flatMap((doValues) => doValues)
            .concat(
              getUserFilters?.ro_machines[ro]
                ? getUserFilters?.ro_machines[ro]
                : []
            )
        : []
    );

    // Update filtersOptions with unique filtered DO values and machine numbers
    const uniqueDO = Array.from(new Set(filteredDO));
    const uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));

    if (roFilters.length === 0) {
      const newFilter = { ...filters };
      newFilter["DO"] = [];
      newFilter["RO"] = [];
      newFilter["machineNumber"] = [];
      // setFilters(newFilter);
      // updateFilterCount(newFilter);
      // showAllFilters(newFilter);
      setfiltersOptions((prevState) => ({
        ...prevState,
        DO: filtersOptionsCopy["DO"],
        machineNumber: filtersOptionsCopy["machineNumber"],
      }));
    } else {
      const newFilter = { ...filters };
      newFilter["DO"] = [];
      newFilter["machineNumber"] = [];
      // console.log("uniqueMachineNumber --> ", uniqueMachineNumbers);
      // setFilters(newFilter);
      // updateFilterCount(newFilter);
      // showAllFilters(newFilter);

      setfiltersOptions((prevState) => ({
        ...prevState,
        DO: uniqueDO,
        machineNumber: uniqueMachineNumbers,
      }));
    }
  };

  const applyDOFilters = (filtersDict) => {
    const doFilters = filtersDict.DO;
    const roFilters = filtersDict.RO;
    if (getUserFilters.access_level === "institution") {
      // Find corresponding RO keys for selected DO keys
      const roKeys = [];
      Object.entries(getUserFilters?.filters_data || {}).forEach(
        ([roKey, doValues]) => {
          Object.keys(doValues).forEach((doKey) => {
            if (doFilters.includes(doKey)) {
              roKeys.push(roKey);
            }
          });
        }
      );

      // Extract all machine numbers from selected DO keys
      const filteredMachineNumbers = doFilters.flatMap((doKey) =>
        Object.values(getUserFilters.filters_data)
          .filter((ro) => ro[doKey])
          .flatMap((ro) => ro[doKey])
      );
      // Update filtersOptions with unique filtered machine numbers and corresponding RO keys
      const uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));
      const uniqueRO = Array.from(new Set(roKeys));
      if (doFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: filtersOptionsCopy["machineNumber"],
          RO: filtersOptionsCopy["RO"],
        }));
      } else {
        //filters["machineNumber"] = uniqueMachineNumbers;
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: uniqueMachineNumbers,
          RO: roFilters.length === 0 ? uniqueRO : prevState.RO,
        }));
      }
    } else if (getUserFilters.access_level === "region") {
      // Extract machine numbers for selected DO keys
      const filteredMachineNumbers = doFilters.reduce((acc, doKey) => {
        if (getUserFilters.filters_data[doKey]) {
          acc.push(...getUserFilters.filters_data[doKey]);
        }
        return acc;
      }, []);

      if (doFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: filtersOptionsCopy["machineNumber"],
        }));
      } else {
        // Update filtersOptions machineNumber with unique filtered machine numbers
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: Array.from(new Set(filteredMachineNumbers)),
        }));
      }
    }
  };

  const applyMachineNumberFilters = (filtersDict) => {
    const roFilters = filtersDict.RO;
    const doFilters = filtersDict.DO;
    const machineNumberFilters = filtersDict.machineNumber;
    if (getUserFilters.access_level === "institution") {
      // Extract corresponding RO and DO keys for selected machine numbers
      const filteredRODO = Object.entries(
        getUserFilters?.filters_data || {}
      ).reduce((acc, [ro, roData]) => {
        Object.entries(roData || {}).forEach(([doKey, machines]) => {
          const matchingMachines = machines.filter((machine) =>
            machineNumberFilters.includes(machine)
          );
          if (matchingMachines.length > 0) {
            acc.push({ RO: ro, DO: doKey });
          }
        });
        return acc;
      }, []);

      // Extract unique RO and DO keys
      const uniqueRO = Array.from(
        new Set(filteredRODO.map((entry) => entry.RO))
      );
      const uniqueDO = Array.from(
        new Set(filteredRODO.map((entry) => entry.DO))
      );

      // Update filtersOptions with unique RO and DO keys
      if (machineNumberFilters.length === 0) {
        // console.log(
        //   "machineNumberFilters.length",
        //   machineNumberFilters.length,
        //   filtersOptions
        // );
        setfiltersOptions((prevState) => ({
          ...prevState,
          RO: filtersOptionsCopy["RO"],
          DO: filtersOptionsCopy["DO"],
        }));
      } else {
        setfiltersOptions((prevState) => ({
          ...prevState,
          RO: roFilters.length === 0 ? uniqueRO : prevState.RO,
          DO: doFilters.length === 0 ? uniqueDO : prevState.DO,
        }));
      }
    } else if (getUserFilters.access_level === "region") {
      // Extract DO keys for selected machine numbers
      const filteredDOKeys = Object.keys(getUserFilters.filters_data).filter(
        (doKey) => {
          const machineNumbers = getUserFilters.filters_data[doKey];
          return machineNumbers.some((number) =>
            machineNumberFilters.includes(number)
          );
        }
      );

      if (machineNumberFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          DO: filtersOptionsCopy["DO"],
        }));
      } else {
        // Update filtersOptions DO with unique filtered DO keys
        setfiltersOptions((prevState) => ({
          ...prevState,
          DO:
            doFilters.length === 0
              ? Array.from(new Set(filteredDOKeys))
              : prevState.RO,
        }));
      }
    }
  };
  // const applyIssueTypeFilters = (filterDict) => {
  // Function to update filtersOptions based on selected issueType
  const applyIssueTypeFilters = (filterDict) => {
    // Filter subIssueType values based on the selected issueType
    const filteredSubIssueType =
      getUserFilters?.issues?.reduce((acc, issue) => {
        const selectedIssueTypes = filterDict.issueType;
        for (const issueType of selectedIssueTypes) {
          if (issue[issueType]) {
            acc.push(
              ...issue[issueType].sub_issue?.map((subIssue) => subIssue[1])
            );
          }
        }
        return acc;
      }, []) || [];

    // Variable to store status options
    const statusOptions = [];

    // Iterate over statesList and update statusOptions
    // Object.keys(getUserFilters?.states_list || {}).forEach((key) => {
    //   if (filterDict.issueType.includes(key)) {
    //     statusOptions.push(...(getUserFilters?.states_list[key] || []));
    //   }
    // });

    // console.log(
    //   "statusOptions.push(...statesList[key]) --> ",
    //   new Set([...filters.status, ...statusOptions])
    // );

    if (filterDict.issueType.length) {
      // Update filtersOptions state with the filtered subIssueType values
      setfiltersOptions((prevState) => ({
        ...prevState,
        subIssueType: Array.from(
          new Set([...(filters.subIssueType || []), ...filteredSubIssueType])
        ),
        // status: Array.from(new Set([...filters.status, ...statusOptions])),
      }));
    } else {
      setfiltersOptions((prevState) => ({
        ...prevState,
        subIssueType: filtersOptionsCopy.subIssueType,
        // status: filtersOptionsCopy.status,
      }));
    }
  };

  const applyCropVariationFilters = (filterDict) => {
    // Get the list of param_data keys from filters.crop_variation
    const selectedCrops = filterDict.crop_variation;

    // Filter client_params keys based on selectedCrops
    const clientParamsKeysArray = Object.entries(
      getUserFilters?.param_data || {}
    )
      .filter(([key]) => selectedCrops.includes(key)) // Filter based on selectedCrops
      .flatMap(([key, value]) => Object.keys(value.client_params));

    // Update filtersOptions.params_variation
    const updatedParamsVariation = Array.from(
      new Set([...(filters.params_variation || []), ...clientParamsKeysArray])
    );

    // console.log("updatedParamsVariation", updatedParamsVariation);
    if (selectedCrops.length) {
      setfiltersOptions((prevState) => ({
        ...prevState,
        params_variation: updatedParamsVariation,
      }));
    } else {
      setfiltersOptions((prevState) => ({
        ...prevState,
        params_variation: filtersOptionsCopy["params_variation"],
      }));
    }
  };

  const applyCoustomChecksFilter = (filterDict) => {
    if (filterDict["status"] && !filterDict["status"].includes("Resolved")) {
      filterDict["resolvedEndDate"] = [];
      filterDict["resolvedStartDate"] = [];
    }

    // if (
    //   filterDict?.issueType &&
    //   !filterDict?.issueType?.includes("Data Variation")
    // ) {
    // filterDict["params_variation"] = [];
    // filterDict["crop_variation"] = [];
    // filterDict["modelNo"] = [];
    // filterDict["commitId"] = [];
    // }

    if (filterDict?.issueType && filterDict?.issueType?.length === 0) {
      filterDict.subIssueType = [];
      filterDict.status = [];
    }

    if (
      filterDict?.crop_variation &&
      filterDict?.crop_variation?.length === 0
    ) {
      filterDict.params_variation = [];
    }

    filterDict["compare"] = [];

    return filterDict;
  };
  useEffect(() => {
    document.title = "Parameter Trends - NEO";
    showLoading(true);
    setLoading(true);
    setData(null);
    // console.log("data set to empty List - 1");
    const params = new URLSearchParams(window.location.search);
    // console.log("prams -->", params);
    // const statusFilterData =
    //   params.get("status") !== "" && params.get("status") !== null
    //     ? params.get("status").split(",")
    //     : [];
    const issueTypeFilterData =
      params.get("issueType") !== "" && params.get("issueType") !== null
        ? params.get("issueType").split(",")
        : [];
    // const ROFilterData =
    //   params.get("RO") !== "" && params.get("RO") !== null
    //     ? params.get("RO").split(",")
    //     : [];
    // const DOFilterData =
    //   params.get("DO") !== "" && params.get("DO") !== null
    //     ? params.get("DO").split(",")
    //     : [];
    // const machineNumberFilterData =
    //   params.get("machineNumber") !== "" && params.get("machineNumber") !== null
    //     ? params.get("machineNumber").split(",")
    //     : [];
    const timeFilterData =
      params.get("time") !== "" && params.get("time") !== null
        ? params.get("time").split(",")
        : [];
    const weeklyStartDateFilterData =
      params.get("weeklyStartDate") !== "" &&
      params.get("weeklyStartDate") !== null
        ? params.get("weeklyStartDate").split(",")
        : [];
    const weeklyEndDateFilterData =
      params.get("weeklyEndDate") !== "" && params.get("weeklyEndDate") !== null
        ? params.get("weeklyEndDate").split(",")
        : [];
    const DailyStartDateFilterData =
      params.get("DailyStartDate") !== "" &&
      params.get("DailyStartDate") !== null
        ? params.get("DailyStartDate").split(",")
        : [];
    const DailyEndDateFilterData =
      params.get("DailyEndDate") !== "" && params.get("DailyEndDate") !== null
        ? params.get("DailyEndDate").split(",")
        : [];
    const MonthlyStartDateFilterData =
      params.get("MonthlyStartDate") !== "" &&
      params.get("MonthlyStartDate") !== null
        ? params.get("MonthlyStartDate").split(",")
        : [];
    const MonthlyEndDateFilterData =
      params.get("MonthlyEndDate") !== "" &&
      params.get("MonthlyEndDate") !== null
        ? params.get("MonthlyEndDate").split(",")
        : [];
    // const commitIdFilterData =
    //   params.get("commitId") !== "" && params.get("commitId") !== null
    //     ? params.get("commitId").split(",")
    //     : [];
    // const modelNoFilterData =
    //   params.get("modelNo") !== "" && params.get("modelNo") !== null
    //     ? params.get("modelNo").split(",")
    //     : [];
    // console.log("machineNumberFilterData", machineNumberFilterData);
    // const resolvedStartDateDateFilterData =
    //   params.get("resolvedStartDate") !== "" &&
    //   params.get("resolvedStartDate") !== null
    //     ? params.get("resolvedStartDate").split(",")
    //     : [];
    // const resolvedEndDateFilterData =
    //   params.get("resolvedEndDate") !== "" &&
    //   params.get("resolvedEndDate") !== null
    //     ? params.get("resolvedEndDate").split(",")
    //     : [];
    // const assigneeFilterData =
    //   params.get("assignee") !== "" && params.get("assignee") !== null
    //     ? params.get("assignee").split(",")
    //     : [];
    // const subIssueTypeFilterData =
    //   params.get("subIssueType") !== "" && params.get("subIssueType") !== null
    //     ? params.get("subIssueType").split(",")
    //     : [];
    const subIssueTypeFilterData =
      params.get("subIssueType") !== "" && params.get("subIssueType") !== null
        ? params.get("subIssueType").split(",")
        : [];
    // const sortFilterData =
    //   params.get("sort") !== "" && params.get("sort") !== null
    //     ? params.get("sort").split(",")
    //     : [];
    // const searchBoxFilterData =
    //   params.get("searchBox") !== "" && params.get("searchBox") !== null
    //     ? params.get("searchBox").split(",")
    //     : [];
    // const Page =
    //   params.get("currentPage") !== "" && params.get("currentPage") !== null
    //     ? params.get("currentPage")
    //     : 1;
    // const ticketPerPageFilterData =
    //   params.get("ticketPerPage") !== "" && params.get("ticketPerPage") !== null
    //     ? [params.get("ticketPerPage")]
    //     : [];
    // console.log("ticketPerPageFilterData", ticketPerPageFilterData);
    // if (
    //   ticketPerPageFilterData.length ||
    //   ticketPerPageFilterData[0] === "20" ||
    //   ticketPerPageFilterData[0] === "50" ||
    //   ticketPerPageFilterData[0] === "100"
    // ) {
    //   setCardsPerPage(parseInt(ticketPerPageFilterData[0]));
    // }
    // console.log("searchBoxFilterData: ", searchBoxFilterData);
    const cropVariationFilterData =
      params.get("crop_variation") !== "" &&
      params.get("crop_variation") !== null
        ? params.get("crop_variation").split(",")
        : [];
    const paramVariationFilterData =
      params.get("params_variation") !== "" &&
      params.get("params_variation") !== null
        ? params.get("params_variation").split(",")
        : [];
    const compareOptionFilterData =
      params.get("option") !== "" && params.get("option") !== null
        ? params.get("option").split(",")
        : [];
    // console.log("compareOptionFilterData : ", compareOptionFilterData);
    // const moreFiltersFilterData =
    //   params.get("moreFilters") !== "" && params.get("moreFilters") !== null
    //     ? params.get("moreFilters").split(",")
    //     : [];
    // console.log("moreFiltersFilterData", paramVariationFilterData);
    // machineNumber: [],
    // createdStartDate: [],
    // createdEndDate: [],
    // resolvedStartDate: [],
    // resolvedEndDate: [],
    // sort: [],
    // crop_variation: [],
    // params_variation: [],

    // console.log("------------->", Page);
    const initalFilters = {
      // status: statusFilterData,
      issueType: issueTypeFilterData,
      //   RO: ROFilterData,
      //   DO: DOFilterData,
      //   machineNumber: machineNumberFilterData,
      time: timeFilterData,
      //   hourlyStartDate: hourlyStartDateFilterData,
      //   hourlyEndDate: hourlyEndDateFilterData,
      weeklyStartDate: weeklyStartDateFilterData,
      weeklyEndDate: weeklyEndDateFilterData,
      DailyStartDate: DailyStartDateFilterData,
      DailyEndDate: DailyEndDateFilterData,
      MonthlyStartDate: MonthlyStartDateFilterData,
      MonthlyEndDate: MonthlyEndDateFilterData,
      // createdStartDate: createdStartDateFilterData,
      // createdEndDate: createdEndDateFilterData,
      // resolvedStartDate: resolvedStartDateDateFilterData,
      // resolvedEndDate: resolvedEndDateFilterData,
      crop_variation: cropVariationFilterData,
      params_variation: paramVariationFilterData,
      option: compareOptionFilterData,
      // moreFilters: moreFiltersFilterData,
      // searchBox: searchBoxFilterData,
      // ticketPerPage: ticketPerPageFilterData,
      //   assignee: assigneeFilterData,
      //   commitId: commitIdFilterData,
      //   modelNo: modelNoFilterData,
      //   subIssueType: subIssueTypeFilterData,
    };
    // console.log("insitial filter --->", initalFilters);

    let is_all_filter_data_Mounted = true;
    const fetchDataAndUpdateFilters = async (
      initalFilters,
      Page,
      updateURL = true
    ) => {
      const currentTime = Date.now();
      const lastDataFetchTimestamp = userFilters?.lastDataFetchTimestamp;

      if (
        !lastDataFetchTimestamp ||
        currentTime - lastDataFetchTimestamp >= 30 * 60 * 1000
      ) {
        const all_filter_data = await fetchApi(
          "get_user_filters",
          "POST",
          {},
          is_all_filter_data_Mounted
        );
        // console.log("all_filter_data in All Ticket : ", all_filter_data);

        if (all_filter_data.statusCode === 200) {
          dispatch(addFilters(all_filter_data));
          getFilterResponse(all_filter_data, initalFilters);
          showErrorPage(null, null);
        } else if (
          all_filter_data.statusCode === 500 ||
          all_filter_data.statusCode === 401
        ) {
          setData(null);
          showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
        } else {
          setData(null);
          // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
          // console.log("Some Error Occured", all_filter_data);
          localStorage.clear();
          window.location.href = "/login";
        }
      } else {
        // console.log("Data fetch from redux ************", userFilters?.data);
        getFilterResponse(userFilters?.data, initalFilters);
      }
      updateFilters(initalFilters, 0, updateURL);
      setshowAllFilters(initalFilters);
      // showLoading(false);
    };
    // console.log("initial filter from url : ", initalFilters);
    fetchDataAndUpdateFilters(initalFilters, 0);
    // console.log("data set to empty List - 2");

    // setTimeout(() => {
    //   setLoading(false);
    //   showLoading(false);
    // }, 2000);

    const handlePopState = () => {
      // Your logic to handle popstate event
      // console.log("mount", "User navigated back or forward");
      const previousPageURL = window.history.state || null;
      if (previousPageURL && previousPageURL.filters) {
        showLoading(true);
        fetchDataAndUpdateFilters(previousPageURL.filters, 0, false);
        // console.log("Previous page URL:  filters send ", previousPageURL);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      is_all_filter_data_Mounted = false;
      window.removeEventListener("popstate", handlePopState);
      // On unmount, abort the previous request for fetchApi api
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      // console.log("unmount");
    };
  }, []);

  const [mousePosition, setMousePosition] = useState({ x: "", y: "" });

  const handleMouseMove = (e) => {
    setMousePosition((prev) => ({
      ...prev,
      x: e.clientX,
      y: e.clientY,
    }));
  };

  // const itemValues = [Data['total_created'], Data['total_resolved']];
  const portalRoot = document.getElementById("portal-root");
  const CustomTooltip = ({ active, payload, label }) => {
    // console.log("test_custom_tooltip------------->", payload, active, label);
    const [position, setPosition] = useState("right");
    const [showTooltip, setShowTooltip] = useState(false);
    useEffect(() => {
      const windowWidth = window.innerWidth;

      let timer;
      if (active) {
        timer = setTimeout(() => {
          setShowTooltip(true);
        }, 100); // Delay of 100ms
      } else {
        setShowTooltip(false);
      }

      const currentPointer = isSideBarOpen
        ? mousePosition.x - 160
        : mousePosition.x - 60;

      if (currentPointer > windowWidth / 2 && active) {
        setPosition("left");
      } else {
        setPosition("right");
      }

      return () => {
        clearTimeout(timer);
      };
    }, [label]);

    return active && payload && payload.length && showTooltip
      ? createPortal(
          <div
            style={{
              background: "#ffffffcf",
              borderRadius: "5px",
              border: "1px solid",
              borderColor: "#e5e6e8",
              position: "absolute",
              [position]: 15,
              zIndex: 10000,
            }}
          >
            {/* label for tooltip */}
            {label?.length > 1 ? (
              <p
                style={{
                  margin: "0px 0px 0px 0px",
                  fontSize: "0.75rem",
                  borderBottom: "1px solid",
                  borderColor: "#e5e6e8",
                  padding: "4px 8px",
                }}
              >
                {label.length > 1 ? label.replace("w-", "Week-") : null}
              </p>
            ) : null}

            <div style={{ padding: "4px 0px" }}>
              <div style={{ padding: "4px 0" }}>
                {payload &&
                  payload?.map((entry, index) => {
                    const entryData = entry.payload;
                    const name = entry.name.replace(" ", "_");
                    // console.log(
                    //   "entry--> ",
                    //   entry,
                    //   entryData,
                    //   entryData[name + "_sd"],
                    //   entryData[name + "_OOLScans"]
                    // );
                    return (
                      <div
                        key={`CustomTooltip-item-${index}`}
                        className="d-flex align-items-start gap-2 px-2 py-1"
                      >
                        <div
                          style={{
                            backgroundColor:
                              entry?.color || entry?.payload?.fill,
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            marginTop: "6px",
                          }}
                        ></div>
                        <div className="d-flex flex-column">
                          {/* entry heading */}
                          <div
                            style={{ fontSize: "0.83rem", marginRight: "4px" }}
                          >
                            <span
                              style={{ fontSize: "0.93rem", fontWeight: 700 }}
                            >
                              {Data?.option &&
                                !["lower limit", "upper limit"].includes(
                                  entry.name.toLowerCase()
                                ) &&
                                Data?.option[name]?.compare +
                                  "-" +
                                  Data?.option[name]?.value +
                                  " "}
                            </span>
                            {entry.name}
                            {["lower limit", "upper limit"].includes(
                              entry.name.toLowerCase()
                            ) && (
                              <>
                                :{" "}
                                <p
                                  style={{
                                    fontWeight: 600,
                                    display: "inline-block",
                                    margin: "0px",
                                    padding: "0px",
                                  }}
                                >
                                  {entry.value}
                                </p>
                              </>
                            )}
                          </div>
                          {/* entry data */}
                          {!["lower limit", "upper limit"].includes(
                            entry.name.toLowerCase()
                          ) && (
                            <div
                              style={{ fontSize: "0.73rem", fontWeight: 600 }}
                            >
                              <p
                                style={{
                                  fontWeight: 600,
                                  display: "inline-block",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 500,
                                    fontSize: "0.83rem",
                                  }}
                                >
                                  value:{" "}
                                </span>
                                {entry.value}
                              </p>
                              {(entryData[name + "_sd"] ||
                                entryData[name + "_sd"] === 0) && (
                                <p
                                  style={{
                                    fontWeight: 600,
                                    display: "inline-block",
                                    margin: "0px",
                                    padding: "0px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "0.83rem",
                                    }}
                                  >
                                    , sd:{" "}
                                  </span>
                                  {entryData[name + "_sd"]}
                                </p>
                              )}
                              {(entryData[name + "_OOLScans"] ||
                                entryData[name + "_OOLScans"] === 0) && (
                                <p
                                  style={{
                                    fontWeight: 600,
                                    display: "inline-block",
                                    margin: "0px",
                                    padding: "0px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "0.83rem",
                                    }}
                                  >
                                    , OPLS:{" "}
                                  </span>
                                  {entryData[name + "_OOLScans"]}
                                </p>
                              )}
                              {(entryData[name + "_totalScans"] ||
                                entryData[name + "_totalScans"] === 0) && (
                                <p
                                  style={{
                                    fontWeight: 600,
                                    display: "inline-block",
                                    margin: "0px",
                                    padding: "0px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "0.83rem",
                                    }}
                                  >
                                    , TS:{" "}
                                  </span>
                                  {entryData[name + "_totalScans"]}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="px-2">
                <div className="card-Div" style={{ minHeight: "50px" }}>
                  <div className="row">
                    <div
                      className="col-3 text-nowrap bar-chart-heading"
                      style={{ fontSize: "0.75rem" }}
                    >
                      Value:
                    </div>
                    <div
                      className="col-9 text-nowrap bar-chart-heading"
                      style={{ fontSize: "0.75rem", fontWeight: 600 }}
                    >
                      Average Parameter Value
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-3 text-nowrap bar-chart-heading"
                      style={{ fontSize: "0.75rem" }}
                    >
                      sd:
                    </div>
                    <div
                      className="col-9 text-nowrap bar-chart-heading"
                      style={{ fontSize: "0.75rem", fontWeight: 600 }}
                    >
                      Standard Daviation
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-3 text-nowrap bar-chart-heading"
                      style={{ fontSize: "0.75rem" }}
                    >
                      OPLS:
                    </div>
                    <div
                      className="col-9 text-nowrap bar-chart-heading"
                      style={{ fontSize: "0.75rem", fontWeight: 600 }}
                    >
                      Out Of Param Limit Scans
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-3 text-nowrap bar-chart-heading"
                      style={{ fontSize: "0.75rem" }}
                    >
                      TS:
                    </div>
                    <div
                      className="col-9 text-nowrap bar-chart-heading"
                      style={{ fontSize: "0.75rem", fontWeight: 600 }}
                    >
                      Total Scans
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>,
          portalRoot
        )
      : null;
  };

  const [opacity, setOpacity] = useState({
    option_1: 1,
    option_2: 1,
    option_3: 1,
    option_4: 1,
    option_5: 1,
    option_6: 1,
    option_7: 1,
    option_8: 1,
    upper_limit: 1,
    lower_limit: 1,
  });

  const handleMouseEnterLegend = (o) => {
    // console.log("Mouse Enter ----->", o);
    const { dataKey } = o;

    setOpacity((prevOpacity) => {
      const updatedOpacity = {};

      for (const key in prevOpacity) {
        if (prevOpacity.hasOwnProperty(key)) {
          updatedOpacity[key] = key !== dataKey ? 0.1 : 1;
        }
      }

      return updatedOpacity;
    });
  };

  const handleMouseLeaveLegend = (o) => {
    // console.log("Mouse Leave ----->", o);
    const { dataKey } = o;
    // setOpacity((prevOpacity) => ({
    //   ...prevOpacity,
    //   [dataKey]: 1,
    // }));

    setOpacity((prevOpacity) => {
      const updatedOpacity = {};

      // Set all opacities to 1
      Object.keys(prevOpacity).forEach((key) => {
        updatedOpacity[key] = 1;
      });

      // Update the specific dataKey to 1
      updatedOpacity[dataKey] = 1;

      return updatedOpacity;
    });
  };
  const customLegend = (props) => {
    const { payload } = props;
    return (
      <div
        className="d-flex justify-content-center gap-3 "
        style={{ cursor: "pointer" }}
      >
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{
              listStyleType: "none",
              // marginTop: "4px",
              border: "1px solid",
              padding: "3px 10px",
              borderRadius: "10px",
              borderColor: "#e5e6e8",
              fontSize: "0.85rem",
            }}
            onMouseEnter={() => handleMouseEnterLegend(entry)}
            onMouseLeave={() => handleMouseLeaveLegend(entry)}
          >
            <span
              style={{
                display: "inline-block",
                backgroundColor: entry.color,
                width: "10px",
                height: "10px",
                marginRight: "8px",
                borderRadius: "50%",
              }}
            ></span>
            {([
              "option_1",
              "option_2",
              "option_3",
              "option_4",
              "option_5",
              "option_6",
              "option_7",
              "option_8",
            ].includes(entry.dataKey) &&
              Data.option[entry.dataKey].compare +
                "-" +
                Data.option[entry.dataKey].value) ||
              (entry.dataKey === "option_2" && entry.value + " Scans") ||
              entry.value}
          </div>
        ))}
      </div>
    );
  };

  const [ExportDataList, setExportDataList] = useState(null);

  const onExportLocal = (title) => {
    const chartData = Data?.line_chart_data || {};
    const line_chart_data_heading =
      chartData && chartData?.length !== 0
        ? Object.keys(chartData[0])
            .map((headerData, index) => {
              if (
                headerData === "lower_limit" ||
                headerData === "upper_limit"
              ) {
                return null;
              } else if (
                !headerData.includes("option") ||
                (headerData.includes("option") &&
                  ExportDataList[headerData.slice(0, 8)])
              ) {
                return headerData;
              }
              return null;
            })
            .filter((item) => item !== null)
        : [];

    // console.log("line_chart_data_heading :", line_chart_data_heading);

    let FinalLineChartData = [];

    FinalLineChartData.push(["Crop ", Data?.crop, "", "", "", "", ""]);
    FinalLineChartData.push([
      "Crop Parameter ",
      Data?.param_name,
      "",
      "",
      "",
      "",
      "",
    ]);
    FinalLineChartData.push([
      "Data Range ",
      Data?.data_range,
      "",
      "",
      "",
      "",
      "",
    ]);
    FinalLineChartData.push([
      "Lower Limit ",
      Data?.lower_limit,
      "",
      "",
      "",
      "",
      "",
    ]);
    FinalLineChartData.push([
      "Upper Limit ",
      Data?.upper_limit,
      "",
      "",
      "",
      "",
      "",
    ]);

    chartData?.forEach((each_date_item) => {
      const GraphRowData = each_date_item;
      let update_arr = [];
      for (const i of line_chart_data_heading) {
        update_arr.push(
          GraphRowData[i] || GraphRowData[i] === 0 ? GraphRowData[i] : "-"
        );
      }
      update_arr = update_arr.filter((item) => item !== null);

      const final_update_arr = [];
      const temp_empty_arr = [];

      const arr_len = 7;
      for (let i = 0; i < arr_len; i++) {
        temp_empty_arr.push("");
      }
      let temp_arr = [];
      for (let i = 0; i < arr_len; i++) {
        if (i == 0) {
          temp_arr.push("Date ");
        } else if (i == 1) {
          temp_arr.push(update_arr[0]);
        } else {
          temp_arr.push("");
        }
      }

      final_update_arr.push(temp_empty_arr);
      final_update_arr.push(temp_empty_arr);
      final_update_arr.push(temp_arr);
      final_update_arr.push(temp_empty_arr);
      final_update_arr.push([
        "Option",
        "Compare",
        "Compare_value",
        "Avg Value",
        "Standard Daviation",
        "Out of Limit Param Scans",
        "Total Scans",
      ]);

      // console.log("update_arr :", update_arr);

      Object.entries(ExportDataList)
        .filter(([key, value]) => value === true)
        .map(([key, value]) => key)
        .forEach((optionName) => {
          let count = 0;
          temp_arr = [];
          temp_arr.push(optionName);
          count++;
          temp_arr.push(Data?.option[optionName]["compare"]);
          count++;
          temp_arr.push(Data?.option[optionName]["value"]);
          count++;
          for (const i in update_arr) {
            // console.log(
            //   "---> ",
            //   line_chart_data_heading[i],
            //   optionName,
            //   line_chart_data_heading[i].includes(optionName)
            // );
            if (line_chart_data_heading[i].includes(optionName)) {
              temp_arr.push(update_arr[i]);
              count++;
            }
          }

          while (count < arr_len) {
            temp_arr.push("");
            count++;
          }
          final_update_arr.push(temp_arr);
        });
      // console.log("final_update_arr : ", final_update_arr);
      FinalLineChartData = [...FinalLineChartData, ...final_update_arr];
    });

    // console.log("FinalLineChartData : ", FinalLineChartData);
    // console.log("Export Data ---> ", headings, headingsData, wsData);

    // // Convert the data to a worksheet
    const ws = XLSX.utils.aoa_to_sheet(FinalLineChartData);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Area Chart Data");

    const CardData = Data?.card_data;

    const cardDataHeading =
      CardData && CardData.length !== 0
        ? [
            "option",
            "compare",
            "compare_value",
            "start_date",
            "end_date",
            ...Object.keys(CardData[0]).filter((key) => key !== "name"),
          ]
        : [];

    const TopDetails = [
      ["Crop Parameter ", Data?.param_name, "", "", "", "", "", "", "", ""],
      ["Data Range ", Data?.data_range, "", "", "", "", "", "", "", ""],
      ["Lower Limit ", Data?.lower_limit, "", "", "", "", "", "", "", ""],
      ["Upper Limit ", Data?.upper_limit, "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", "", ""],
    ];

    const cardDataRows = [];

    for (const optionData of CardData) {
      if (ExportDataList[optionData.name]) {
        const updateNewRow = [];
        for (const header of cardDataHeading) {
          const compare_option = Data?.option[optionData.name];
          const compare = compare_option["compare"];
          const compare_value = compare_option["value"];
          const start_date = Data?.data_range.split("-")[0];
          const end_date = Data?.data_range.split("-")[1];
          if (header === "option") {
            updateNewRow.push(optionData.name);
          } else if (header === "compare") {
            updateNewRow.push(compare);
          } else if (header === "compare_value") {
            updateNewRow.push(compare_value);
          } else if (header === "start_date") {
            updateNewRow.push(start_date);
          } else if (header === "end_date") {
            updateNewRow.push(end_date);
          } else {
            updateNewRow.push(optionData[header]);
          }
        }
        cardDataRows.push(updateNewRow);
      }
    }
    // console.log("new_data : ", [
    //   ...TopDetails,
    //   cardDataHeading,
    //   ...cardDataRows,
    // ]);
    const ws2 = XLSX.utils.aoa_to_sheet([
      ...TopDetails,
      cardDataHeading,
      ...cardDataRows,
    ]);
    XLSX.utils.book_append_sheet(wb, ws2, "OverAllData");
    // Generate and download the Excel file
    XLSX.writeFile(
      wb,
      `ParameterTrendsExportData(${title})_${new Date()
        .toString()
        .slice(0, 24)
        .replaceAll(" ", "_")}.xlsx`
    );
  };

  const handleExportDataListChange = (value) => {
    console.log("value : ", value);
    setExportDataList((prevFilter) => {
      const updateFilter = { ...prevFilter };
      updateFilter[value] = !updateFilter[value];
      // console.log("--> ", updateFilter, value);
      return updateFilter;
    });
  };

  useEffect(() => {
    // if (Data) {
    //   console.log("Export Data prefetch : ", Data);
    // }
    const headings =
      Data?.option_available?.map((each_item) => each_item) || [];

    setExportDataList(() => {
      const updateExportDataList = {};
      for (const i of headings) {
        updateExportDataList[i] = true;
      }
      return updateExportDataList;
    });
  }, [Data]);
  return (
    <>
      {(!showError.status && (
        <>
          <Dropdown
            blurTickets={handelPageBlur}
            filters={filters}
            filterCount={filterCount}
            handleFilterChange={handleFilterChange}
            handleSaveChanges={handleSaveChanges}
            handleFilterReset={handleFilterReset}
            getUserFilters={getUserFilters}
            getFilterResponse={getFilterResponse}
            filtersOptions={filtersOptions}
            updateTimestr={"Frequency"}
            showDataVariationFilters={true}
            handelDeleteFilterBtn={handelDeleteFilterBtn}
            handelTempRemoveFilterBtn={handelTempRemoveFilterBtn}
            handelAutoSelectFilterBtn={handelAutoSelectFilterBtn}
          />
          {allFiltersDisplay && (
            <AllFilters
              filters={showAllfilters}
              handelResetAllBtn={handelResetAllBtn}
              handelDeleteFilterBtn={handelDeleteFilterBtn}
              getUserFilters={getUserFilters}
            />
          )}

          <div
            className={`${blurTickets} all-ticket-content ${
              allFiltersDisplay ? "m-top-0 p-top-0" : " "
            }`}
            onMouseMove={handleMouseMove}
          >
            {(loading && (
              // {lodingVisiblity && (
              <div className="showSpinner all-ticket-spinner">
                <BeatLoader color="#2D97D4" />
              </div>
            )) ||
              (Data && !Data.msg && (
                <div className="container row ">
                  {/* Time Wise Ticket Analysis */}
                  <div className="col-md-12 mb-3 container-border-1 p-0">
                    <>
                      {/* {console.log(Object.keys(Data?.line_chart_data))} */}
                      <div className="upper-heading-container p-0 pt-2 align-items-center">
                        <div className="info-part me-auto">
                          <span className="px-2 mb-1 info-heading">
                            Parameter Trends
                          </span>
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <i
                              className="bi bi-info-circle icon-color px-0"
                              id="info-icon"
                              style={{ fontSize: "15px", lineHeight: "24px" }}
                            ></i>
                          </OverlayTrigger>
                        </div>
                        <div className="d-flex flex-row ms-auto">
                          <div className="show-range ms-auto">
                            {Data?.data_range}
                          </div>

                          {/* Document export data */}
                          <div
                            className="dropdow dropdown_export mx-2"
                            style={{ cursor: "pointer" }}
                          >
                            <MuiTooltip
                              data-html="true"
                              title={"Export Data"}
                              arrow={true}
                            >
                              <i
                                className="fa-solid fa-ellipsis-vertical  "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{
                                  padding: "0px 3px",
                                  color: color.taxtHeadingLight,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              ></i>
                            </MuiTooltip>
                            {/* Centered dropdown */}
                            {/* </button> */}
                            <ul
                              className="dropdown-menu dropdown-menu-end delete-ticket-btn-border"
                              style={{ padding: "4px 0px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <li>
                                <div className="row  m-2 mx-3 created-date-heading ">
                                  Select Data To Export
                                </div>
                              </li>
                              {ExportDataList &&
                                Object.keys(ExportDataList || {}).map(
                                  (exportListData, index) => (
                                    <li key={index}>
                                      <div
                                        className="dropdown-item py-0"
                                        href="#"
                                      >
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id={`ExportDataList${index}`}
                                            checked={
                                              ExportDataList[exportListData]
                                            }
                                            onChange={() => {
                                              handleExportDataListChange(
                                                exportListData
                                              );
                                              //applyFilterss();
                                            }}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`ExportDataList${index}`}
                                          >
                                            <div className="d-flex flex-column">
                                              <p
                                                className="p-0 m-0 d-flex flex-row"
                                                style={{
                                                  fontFamily:
                                                    "Open-Sans-Medium",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  {
                                                    Data?.option[
                                                      exportListData
                                                    ]["compare"]
                                                  }
                                                </span>
                                                -
                                                <span
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  {
                                                    Data?.option[
                                                      exportListData
                                                    ]["value"]
                                                  }
                                                </span>
                                              </p>
                                              <span
                                                style={{
                                                  textTransform: "capitalize",
                                                  marginTop: "-3px",
                                                }}
                                              >
                                                {exportListData.replace(
                                                  "_",
                                                  " "
                                                )}
                                              </span>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    </li>
                                  )
                                )}
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  // onExportLocal(Data?.count_heading || "");
                                }}
                              >
                                <a
                                  className="dropdown-item text-center edit-ticket-btn "
                                  href="#"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    onExportLocal(Data?.data_range);
                                  }}
                                >
                                  <span className="export_all_data_btn">
                                    <CiExport className="me-2 " />
                                  </span>
                                  Export Data
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div id="portal-root"></div>
                      {(Data?.line_chart_data &&
                        Data?.line_chart_data?.length !== 0 && (
                          // <div className="card-Div" ref={chartContainerRef}>
                          <div className="card-Div overflow-visible">
                            <div
                              className="issueLineChartContainer"
                              style={{
                                maxHeight: "1000px",
                                zIndex: 300,
                                overflow: "auto",
                              }}
                            >
                              <AreaChart
                                width={chartWidth}
                                height={450}
                                data={Data?.line_chart_data}
                                margin={{
                                  top: 30,
                                  right: 10,
                                  left: 0,
                                  bottom: 0,
                                }}
                                // style={{  minWidth:"700px"}}
                              >
                                <Legend
                                  layout="horizontal"
                                  verticalAlign="bottom"
                                  align="center"
                                  margin={{
                                    top: 40,
                                    left: 10,
                                    right: 10,
                                    bottom: 30,
                                  }}
                                  wrapperStyle={{
                                    position: "absolute",
                                    paddingTop: 30,
                                    paddingBottom: 10,
                                    bottom: "-10px",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                  }}
                                  iconSize={10}
                                  iconType="circle"
                                  // height={30}
                                  content={customLegend}
                                  // onMouseEnter={handleMouseEnterLegend}
                                  // onMouseLeave={handleMouseLeaveLegend}
                                />
                                <defs>
                                  {[
                                    "option_1",
                                    "option_2",
                                    "option_3",
                                    "option_4",
                                    "option_5",
                                    "option_6",
                                    "option_7",
                                    "option_8",
                                  ].map((chartItem) => (
                                    <linearGradient
                                      id={`customColor${chartItem}`}
                                      x1="0"
                                      y1="0"
                                      x2="0"
                                      y2="1"
                                    >
                                      <stop
                                        offset="5%"
                                        stopColor={color[chartItem]}
                                        stopOpacity={0.8}
                                      />
                                      <stop
                                        offset="95%"
                                        stopColor={color[chartItem]}
                                        stopOpacity={0}
                                      />
                                    </linearGradient>
                                  ))}
                                </defs>
                                {/* Conditionally render the XAxis based on the data */}

                                <XAxis
                                  dataKey="name"
                                  axisLine={{ stroke: "rgba(0, 0, 0, 0.15)" }}
                                  tick={{
                                    fill: "#4c4e6499",
                                    fontSize: "0.75rem",
                                  }}
                                  tickMargin={10}
                                  label={{
                                    value: Data?.frequency + " Time",
                                    // angle: -90,
                                    position: "center",
                                    dx: 10, // Move label to the left for padding
                                    dy: 30, // Move label down for padding
                                    style: { textAnchor: "middle" }, // Center the text
                                  }}
                                  // padding={{ left: 10, right: 10 }}
                                />

                                <YAxis
                                  axisLine={{ stroke: "rgba(0, 0, 0, 0.15)" }}
                                  tick={{ fill: "#4c4e6499" }}
                                  tickMargin={10}
                                  domain={[
                                    0,
                                    Math.max(
                                      Data?.upper_limit + 1,
                                      Data?.max_graph_limit
                                    ),
                                  ]}
                                  scale={"linear"}
                                  label={{
                                    value: Data?.param_value || "Value",
                                    angle: -90,
                                    position: "insideLeft",
                                    dx: 10, // Move label to the left for padding
                                    dy: 10, // Move label down for padding
                                    style: { textAnchor: "middle" }, // Center the text
                                  }}
                                />

                                <CartesianGrid
                                  strokeDasharray="0 0"
                                  vertical={false}
                                  stroke={color.primary}
                                />
                                <Tooltip content={<CustomTooltip />} />
                                {/* Use your custom linear gradients for filling the areas */}

                                {[
                                  "option_1",
                                  "option_2",
                                  "option_3",
                                  "option_4",
                                  "option_5",
                                  "option_6",
                                  "option_7",
                                  "option_8",
                                ].map((chartItem) => {
                                  return (
                                    <>
                                      {Data?.option_available?.includes(
                                        chartItem
                                      ) && (
                                        <Area
                                          type="monotone"
                                          dataKey={chartItem}
                                          stroke={color[chartItem]}
                                          strokeOpacity={opacity[chartItem]}
                                          fillOpacity={opacity[chartItem]}
                                          fill={`url(#customColor${chartItem})`}
                                          name={chartItem}
                                          // label={{value: "hello"}}
                                        />
                                      )}
                                    </>
                                  );
                                })}

                                <Area
                                  type="monotone"
                                  dataKey="upper_limit"
                                  stroke={color.upper_limit}
                                  fillOpacity={0}
                                  strokeOpacity={opacity.upper_limit}
                                  name={"Upper Limit"}
                                  strokeDasharray="5 5"
                                />
                                <Area
                                  type="monotone"
                                  dataKey="lower_limit"
                                  stroke={color.lower_limit}
                                  strokeOpacity={opacity.lower_limit}
                                  fillOpacity={0}
                                  name={"Lower Limit"}
                                  strokeDasharray="5 5"
                                />
                              </AreaChart>
                              <p className="linechart-xaxis-div"></p>
                            </div>
                          </div>
                        )) ||
                        ""}
                    </>
                  </div>

                  {/* Compare option chart analysis */}
                  <div className="col-md-12 p-0 mb-4">
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: islagelargeScreen
                          ? isMediumScreen
                            ? isSmallScreen
                              ? "1fr "
                              : "1fr 1fr"
                            : "1fr 1fr 1fr"
                          : "1fr 1fr 1fr 1fr",
                        gap: "16px ",
                      }}
                    >
                      {Data?.card_data?.map((option_data, index) => {
                        const compare_option = Data["option"];
                        const out_of_limit_scan_percent =
                          option_data.total_scans > 0
                            ? (
                                (option_data.out_of_limit_scans * 100) /
                                option_data.total_scans
                              ).toFixed(2)
                            : 0;
                        return (
                          <div className="card-Div">
                            <div className="mx-2">
                              {/* top heading div */}
                              <div className="d-flex flex-row justify-contents-between gap-3 my-2">
                                {/* heading  */}
                                <div
                                  className="top-div top-monthly-div d-flex flex-row align-item-center flex-grow-1"
                                  style={{ height: "70px", minWidth: "200px" }}
                                >
                                  <div className="card-div-icon-div top-div-circular-progress d-flex align-items-center justify-content-center">
                                    <div
                                      style={{
                                        backgroundColor:
                                          color.iconBackgroundBlue,
                                        borderRadius:
                                          pageStyling.imageBackgroundDivRadius,
                                        height:
                                          pageStyling.iconBackgroundHeight,
                                        width: pageStyling.iconBackgroundWidth,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {compare_option[option_data.name][
                                        "compare"
                                      ] === "region" && (
                                        <img
                                          src={TotalRegionImg}
                                          alt="All Assesment img"
                                          className=""
                                          style={{
                                            width: pageStyling.IconWidth,
                                          }}
                                        />
                                      )}
                                      {compare_option[option_data.name][
                                        "compare"
                                      ] === "district" && (
                                        <img
                                          src={TotalDistrictImg}
                                          alt="All Assesment img"
                                          className=""
                                          style={{
                                            width: pageStyling.IconWidth,
                                          }}
                                        />
                                      )}
                                      {compare_option[option_data.name][
                                        "compare"
                                      ] === "machine" && (
                                        <img
                                          src={TotalMachinesImg}
                                          alt="All Assesment img"
                                          className=""
                                          style={{
                                            width: pageStyling.IconWidth,
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {/* circular progress bar div desc */}
                                  <div
                                    className="top-div-circular-progress-desc flex-grow-1"
                                    style={{
                                      color: color.taxtHeading,
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: color.tableRowHeading,
                                        textWrap: "wrap",
                                        wordBreak: "break-word",
                                        display: "inline-block",
                                        width: "100%",
                                      }}
                                    >
                                      {
                                        compare_option[option_data.name][
                                          "value"
                                        ]
                                      }
                                      {compare_option[option_data.name][
                                        "compare"
                                      ] === "machine" &&
                                        userFilters?.data?.data
                                          ?.machines_data && (
                                          <span
                                            style={{
                                              display: "inline-block",
                                            }}
                                          >
                                            {
                                              userFilters?.data?.data
                                                ?.machines_data[
                                                compare_option[
                                                  option_data.name
                                                ]["value"]
                                              ]
                                            }
                                          </span>
                                        )}
                                    </p>
                                    {
                                      compare_option[option_data.name][
                                        "compare"
                                      ]
                                    }
                                  </div>
                                </div>
                                {/* option badge */}
                                <div>
                                  <span
                                    class="badge rounded-pill"
                                    style={{
                                      color: color[option_data?.name],
                                      backgroundColor:
                                        color[option_data?.name + "_bg"],
                                      boxShadow:
                                        "0px 0px 2px 0px " +
                                        color[option_data?.name + "_bg"],
                                      fontFamily: "Open-Sans-Medium",
                                      fontSize: "0.8rem",
                                      padding: "8px 16px",
                                    }}
                                  >
                                    {option_data?.name?.replace("_", " ") || ""}
                                  </span>
                                </div>
                              </div>
                              {/* total Scans */}
                              <div style={{ marginTop: "-20px " }}>
                                <div className="card-Div  shadow-none overflow-hidden">
                                  <p className="m-0 top-10-days-div">
                                    <div className=" last10days-desc-div w-100 ">
                                      {/* <img
                              src={AvgMostScansImg}
                              alt="TotalMostScansImg"
                              className="barChartGraphDesc"
                            /> */}
                                      <span
                                        className="bar-chart-num m-auto"
                                        style={{
                                          fontSize: "1.75rem",
                                        }}
                                      >
                                        {formatInIndianRupees(
                                          option_data.total_scans
                                        )}
                                      </span>
                                      <p
                                        className="bar-chart-heading m-auto p-0"
                                        style={{
                                          fontSize: "1rem",
                                          color: "#4c4e64e6",
                                        }}
                                      >
                                        Total Scans
                                      </p>
                                    </div>
                                  </p>
                                </div>
                              </div>
                              {/* average and STDaviation */}
                              <div className="d-flex flex-row gap-3">
                                <div className="flex-grow-1">
                                  <div
                                    className="overflow-hidden "
                                    style={{
                                      minHeight: "80px !important",
                                      boxShadow: "0px 0px 4px 1px #00000026",
                                      borderRadius: "6px",
                                      padding: "10px",
                                    }}
                                  >
                                    <p className="m-0 top-10-days-div">
                                      <div className=" last10days-desc-div w-100 ">
                                        {/* <img
                                  src={AvgMostScansImg}
                                  alt="TotalMostScansImg"
                                  className="barChartGraphDesc"
                                /> */}
                                        <span
                                          className="bar-chart-num m-0"
                                          style={{
                                            fontSize: "1.25rem",
                                          }}
                                        >
                                          {formatInIndianRupees(
                                            option_data?.avg_scans
                                          )}
                                        </span>
                                        <p
                                          className="bar-chart-heading m-0 p-0"
                                          style={{
                                            fontSize: "0.88rem",
                                            color: "#4c4e64e6",
                                            textWrap: "wrap",
                                          }}
                                        >
                                          Average Parameter Value
                                        </p>
                                      </div>
                                    </p>
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <div
                                    className="overflow-hidden"
                                    style={{
                                      minHeight: "80px !important",
                                      boxShadow: "0px 0px 4px 1px #00000026",
                                      borderRadius: "6px",
                                      padding: "10px",
                                    }}
                                  >
                                    <p className="m-0 top-10-days-div">
                                      <div className=" last10days-desc-div w-100 ">
                                        {/* <img
                          src={AvgMostScansImg}
                          alt="TotalMostScansImg"
                          className="barChartGraphDesc"
                        /> */}
                                        <span
                                          className="bar-chart-num m-0"
                                          style={{
                                            fontSize: "1.25rem",
                                          }}
                                        >
                                          {formatInIndianRupees(
                                            option_data?.standard_deviation
                                          )}
                                        </span>
                                        <p
                                          className="bar-chart-heading m-0 p-0"
                                          style={{
                                            fontSize: "0.88rem",
                                            color: "#4c4e64e6",
                                            textWrap: "wrap",
                                          }}
                                        >
                                          Standard Deviation
                                        </p>
                                      </div>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* progress bar */}
                              <div className="my-3 mb-2">
                                {/* out of limit scans */}
                                <div className="">
                                  <div>
                                    <p
                                      className="location_headings"
                                      style={{
                                        fontSize: "0.88rem",
                                        margin: "0px 0px 0px 0px",
                                        color: color.tableRowHeading,
                                      }}
                                    >
                                      Out Of Parameter Limit Scans
                                    </p>
                                  </div>
                                </div>
                                {/* out of limit scans Details */}
                                <div className="d-flex flex-row mb-1">
                                  <div className="d-inline-block me-auto">
                                    <span
                                      style={{
                                        // fontSize: "1rem",
                                        color: color.tableRowHeading,
                                      }}
                                      className="location_nums"
                                    >
                                      {formatInIndianRupees(
                                        option_data.out_of_limit_scans
                                      )}
                                    </span>
                                  </div>
                                  <div className="d-inline-block align-self-end">
                                    <p
                                      className="location_nums m-0"
                                      style={{
                                        color: color.taxtHeading,
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      {`${out_of_limit_scan_percent}%`}
                                    </p>
                                  </div>
                                </div>
                                {/* progress bar for the out of limit  */}
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={out_of_limit_scan_percent}
                                  colorAccept={color.red}
                                  colorReject={color.iconBackgroundred}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )) ||
              (Data && Data.msg && (
                <div className="container no-ticket-found-div text-center count_heading_div">
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <div className="upper-heading-container p-0 pt-2">
                      <div className="info-part" style={{ textAlign: "left" }}>
                        <span className="px-2 mb-1 info-heading">
                          Parameter Trends
                        </span>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <i
                            className="bi bi-info-circle icon-color px-0"
                            id="info-icon"
                            style={{ fontSize: "15px", lineHeight: "24px" }}
                          ></i>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
                    <p>{Data.msg || "No Data Found"}</p>
                  </div>
                </div>
              ))}
          </div>
        </>
      )) ||
        (showError.status && (
          <Error code={showError.status} msg={showError.msg} />
        )) || <Error code={500} />}
    </>
  );
};

export default LineChartAnalysis;

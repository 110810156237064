import LoadingButton from "@mui/lab/LoadingButton";
import { TextField, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ContextState from "../contextApi/ContextState";
import dayjs from "dayjs";
import "../../assets/styles/UpdateMachineLocation.css";
import SendIcon from "@mui/icons-material/Send";
import { addTicketDetail } from "../features/filtersSlice/filterSlice";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const filter = createFilterOptions();

const UpdateMachine = () => {
  const {
    fetchApi,
    showLoading,
    showError,
    showErrorPage,
    openSnackbar,
    controllerRef,
  } = useContext(ContextState);

  const dispatch = useDispatch();
  const createTicketDetail = useSelector((state) => state.createTicketDetail);

  const [loading, setloading] = useState(false);
  const [value, setValue] = useState(null);
  const [machineInfo, setMachineInfo] = useState({
    new_region: "",
    new_district: "",
    old_location: "",
    new_location: "",
    code: "",
    address: "",
    IsNewLocation: false,
    IsNewDistrict: false,
    IsNewRegion: false,
    date: "",
    remark: "",
    visit_id: "",
    shifted_by: "",
  });

  const [getcreateTicketDetail, setgetcreateTicketDetail] = useState(null);
  // console.log("filters data", getcreateTicketDetail);
  const submitForm = async () => {
    try {
      const payload = {
        new_region: machineInfo?.new_region,
        new_district: machineInfo?.new_district,
        old_location: machineInfo?.old_location,
        new_location: machineInfo?.new_location,
        code: machineInfo?.code,
        address: machineInfo?.address,
        IsNewLocation: machineInfo?.IsNewLocation,
        IsNewDistrict: machineInfo?.IsNewDistrict,
        IsNewRegion: machineInfo?.IsNewRegion,
        date: machineInfo?.date
          ? dayjs(machineInfo?.date).format("YYYY-MM-DD")
          : "",
        remark: machineInfo?.remark,
        shifted_by: machineInfo?.shifted_by,
      };

      if (machineInfo?.visit_id !== "") {
        payload["visit_id"] = machineInfo?.visit_id;
      }

      // console.log("---payload---", payload);

      setloading(true);

      // Send the payload to the backend route
      const response = await fetchApi(
        "update_machine_info",
        "POST",
        payload,
        true
      );
      if (response.statusCode === 200) {
        // console.log("comments added succesfully");
        setMachineInfo((prev) => ({
          ...prev,
          new_region: "",
          new_district: "",
          old_location: "",
          new_location: "",
          code: "",
          address: "",
          IsNewLocation: false,
          IsNewDistrict: false,
          IsNewRegion: false,
          date: "",
          remark: "",
          visit_id: "",
          shifted_by: "",
        }));
        openSnackbar(response.data.message, "success");
      } else {
        // console.error("Error editing ticket information:", response.statusCode);
        openSnackbar(response.msg, "error");
      }
      // console.log("addded new model details", modelDetails);
    } catch (error) {
      // console.error("Error editing ticket information:", error);
      openSnackbar("some error occured while saving details", "error");
    } finally {
      setloading(false);
    }
  };

  const getFilterResponse = async (response) => {
    try {
      if (response && response?.data) {
        const data = response.data;
        setgetcreateTicketDetail(data);
      } else {
        console.error("Error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchFiltersData = async () => {
    const currentTime = Date.now();
    const lastDataFetchTimestamp = createTicketDetail?.lastDataFetchTimestamp;

    let is_create_ticket_data_Mounted = true;
    if (
      !lastDataFetchTimestamp ||
      currentTime - lastDataFetchTimestamp >= 30 * 60 * 1000
    ) {
      // fetchFiltersDataApiCall
      const create_ticket_data = await fetchApi(
        "get_create_ticket_details",
        "POST",
        {},
        is_create_ticket_data_Mounted
      );
      // console.log("create_ticket_data in All Ticket : ", create_ticket_data);

      if (create_ticket_data.statusCode === 200) {
        dispatch(addTicketDetail(create_ticket_data?.data));
        getFilterResponse(create_ticket_data);
        showErrorPage(null, null);
      } else if (
        create_ticket_data.statusCode === 500 ||
        create_ticket_data.statusCode === 401
      ) {
        showErrorPage(create_ticket_data.statusCode, create_ticket_data.msg);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } else {
      // console.log("Data fetch from redux ************", createTicketDetail);
      getFilterResponse(createTicketDetail);
    }
  };

  const addPrevRoDo = () => {
    if (machineInfo.code !== "") {
      let found = false;
      for (const [region, districts] of Object.entries(
        getcreateTicketDetail?.filters_data
      )) {
        for (const [district, codes] of Object.entries(districts)) {
          if (codes.includes(machineInfo.code)) {
            setMachineInfo((prevInfo) => ({
              ...prevInfo,
              new_region: region,
              new_district: district,
            }));
            found = true;
            break;
          }
        }
        if (found) break;
      }
    }
  };

  // console.log("machine info", machineInfo);

  useEffect(() => {
    document.title = "Update Machine Locations- NEO";
    showLoading(true);
    fetchFiltersData();
    showLoading(false);
    return () => {
      // On unmount, abort the previous request for fetchApi api
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, []);

  // options for previous machine location list...
  const shifted_by_options =
    (getcreateTicketDetail &&
      getcreateTicketDetail?.assignee_list?.map(
        (each_assigne) => each_assigne.name
      )) ||
    [];

  shifted_by_options.push("Self Shifted by Fci");

  // function to set the automatic location data according to the machine code
  function get_location_data(code) {
    let location_name = createTicketDetail?.data?.machines_data.find(
      (machine_code) => {
        if (machine_code?.code === code) {
          setMachineInfo((prev) => ({
            ...prev,
            old_location: machine_code.location,
          }));
        }
      }
    );
  }

  return (
    <>
      <div
        className="mb-3 mt-4 ms-3"
        style={{
          color: "rgba(76,78,100,0.7)",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        <span className="fs-5">Update Machine Locations</span>
      </div>
      <div
        className=""
        style={{
          width: "92%",
          margin: "20px auto",
          maxWidth: "1200px",
          minHeight: "180px",
          backgroundColor: "#FFFFFF",
          padding: "25px",
          borderRadius: 12,
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 16px 0px",
        }}
      >
        <div
          className="update_machine_location "
          // style={{ width: "100%" }}
        >
          {/* Choose Machines  */}
          <Autocomplete
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                // padding: "5px",
                width: "100%",
              },
            }}
            disablePortal
            size="medium"
            id="Choose Machine"
            options={
              (getcreateTicketDetail &&
                getcreateTicketDetail?.machines_data?.map((item, index) => {
                  return item.code;
                })) ||
              []
            }
            value={machineInfo?.code}
            renderInput={(params) => (
              <TextField {...params} label="Choose Machine*" />
            )}
            onChange={(event, newValue) => {
              if (newValue) {
                setMachineInfo((prev) => ({
                  ...prev,
                  code: newValue,
                }));
                get_location_data(newValue);
              } else {
                // Handle clearing of the selection
                setMachineInfo((prev) => ({
                  ...prev,
                  code: "",
                  new_region: "",
                  new_district: "",
                  IsNewDistrict: false,
                  IsNewRegion: false,
                }));
              }
            }}
          />

          {/* Choose old location  */}
          <Autocomplete
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                // padding: "5px",
                width: "100%",
              },
            }}
            disablePortal
            size="medium"
            id="Choose Old Location"
            options={
              (getcreateTicketDetail &&
                getcreateTicketDetail?.complete_locations) ||
              []
            }
            value={machineInfo?.old_location}
            renderInput={(params) => (
              <TextField {...params} label="Choose Old Location*" />
            )}
            onChange={(event, newValue) => {
              if (newValue) {
                setMachineInfo((prev) => ({
                  ...prev,
                  old_location: newValue,
                }));
              } else {
                setMachineInfo((prev) => ({
                  ...prev,
                  old_location: "",
                }));
              }
            }}
          />

          {/* Choose new location */}
          <div className="position-relative">
            <Autocomplete
              value={machineInfo?.new_location}
              onChange={(event, newValue) => {
                if (newValue) {
                  if (
                    typeof newValue === "string" &&
                    newValue.startsWith('Add "')
                  ) {
                    setMachineInfo((prev) => ({
                      ...prev,
                      new_location: newValue.slice(5, -1),
                      IsNewLocation: true,
                    }));
                  } else {
                    setMachineInfo((prev) => ({
                      ...prev,
                      new_location: newValue,
                      IsNewLocation: false,
                    }));
                  }
                } else {
                  setMachineInfo((prev) => ({
                    ...prev,
                    new_location: "",
                    IsNewLocation: false,
                  }));
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;

                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push(`Add "${inputValue}"`);
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="Choose New Location"
              options={
                (getcreateTicketDetail &&
                  getcreateTicketDetail?.complete_locations) ||
                []
              }
              getOptionLabel={(option) => {
                // Regular option
                if (typeof option === "string") {
                  return option;
                }
                return option.toString();
              }}
              renderOption={(props, option) => <li {...props}>{option}</li>}
              sx={{ width: "100%" }}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Choose New Location*" />
              )}
            />
            {(machineInfo?.code !== "" && machineInfo?.new_location !== "" && (
              <Tooltip
                data-html="true"
                title={
                  "Click if new location has same DO & RO as the previous location."
                }
                arrow={true}
              >
                <span
                  className="position-absolute "
                  style={{
                    boxShadow: "0px 0px 2px 0px gray",
                    padding: "1px 8px",
                    fontFamily: "Open-Sans-regular",
                    fontSize: "0.65rem",
                    margin: "2px 4px",
                    marginRight: "30px",
                    right: -25,
                    bottom: -22,
                    borderRadius: "4px",
                    backgroundColor: "rgb(232 233 235 / 30%);",
                    color: "#0d6efd",
                    cursor: "pointer",
                  }}
                  onClick={addPrevRoDo}
                >
                  Select Prev RO & DO
                </span>
              </Tooltip>
            )) ||
              ""}
          </div>

          {/* Choose new District */}
          <Autocomplete
            value={machineInfo?.new_district}
            onChange={(event, newValue) => {
              if (newValue) {
                if (
                  typeof newValue === "string" &&
                  newValue.startsWith('Add "')
                ) {
                  setMachineInfo((prev) => ({
                    ...prev,
                    new_district: newValue.slice(5, -1),
                    IsNewDistrict: true,
                  }));
                } else {
                  setMachineInfo((prev) => ({
                    ...prev,
                    new_district: newValue,
                    IsNewDistrict: false,
                  }));
                }
              } else {
                setMachineInfo((prev) => ({
                  ...prev,
                  new_district: "",
                  IsNewDistrict: false,
                }));
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;

              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push(`Add "${inputValue}"`);
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="Choose New District"
            options={
              (getcreateTicketDetail &&
                Object.values(getcreateTicketDetail?.filters_data).flatMap(
                  Object.keys
                )) ||
              []
            }
            getOptionLabel={(option) => {
              // Regular option
              if (typeof option === "string") {
                return option;
              }
              return option.toString();
            }}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            sx={{ width: "100%" }}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Choose New District*" />
            )}
          />

          {/* Choose new Region */}
          <Autocomplete
            value={machineInfo?.new_region}
            onChange={(event, newValue) => {
              if (newValue) {
                if (
                  typeof newValue === "string" &&
                  newValue.startsWith('Add "')
                ) {
                  setMachineInfo((prev) => ({
                    ...prev,
                    new_region: newValue.slice(5, -1),
                    IsNewRegion: true,
                  }));
                } else {
                  setMachineInfo((prev) => ({
                    ...prev,
                    new_region: newValue,
                    IsNewRegion: false,
                  }));
                }
              } else {
                setMachineInfo((prev) => ({
                  ...prev,
                  new_region: "",
                  IsNewRegion: false,
                }));
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;

              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push(`Add "${inputValue}"`);
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="Choose New Region"
            options={
              (getcreateTicketDetail &&
                Object.keys(getcreateTicketDetail?.filters_data)) ||
              []
            }
            getOptionLabel={(option) => {
              // Regular option
              if (typeof option === "string") {
                return option;
              }
              return option.toString();
            }}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            sx={{ width: "100%" }}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Choose New Region*" />
            )}
          />

          {/* choose date  */}
          {/* <div className="date d-block"> */}
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            sx={
              {
                // " & .MuiInputBase-input":{
                //   padding:"15px"
                // }
              }
            }
          >
            <DatePicker
              // name="date"
              value={machineInfo?.date}
              onChange={(date) => {
                setMachineInfo((prev) => ({
                  ...prev,
                  date: date,
                }));
              }}
            />
          </LocalizationProvider>
          {/* </div> */}

          {/* Shift by */}
          <Autocomplete
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                // padding: "5px",
                width: "100%",
              },
            }}
            disablePortal
            size="medium"
            id="Shifted By"
            options={shifted_by_options || []}
            value={machineInfo?.shifted_by}
            renderInput={(params) => (
              <TextField {...params} label="Shifted By" />
            )}
            onChange={(event, newValue) => {
              if (newValue) {
                setMachineInfo((prev) => ({
                  ...prev,
                  shifted_by: newValue,
                }));
              } else {
                setMachineInfo((prev) => ({
                  ...prev,
                  shifted_by: "",
                }));
              }
            }}
          />

          {/* Add address  */}
          <TextField
            value={machineInfo?.address}
            fullWidth
            id="outlined-basic"
            label="Add Address"
            variant="outlined"
            onChange={(event) => {
              setMachineInfo((prev) => ({
                ...prev,
                address: event.target.value,
              }));
            }}
          />

          {/* Add remark  */}
          <TextField
            value={machineInfo?.remark}
            fullWidth
            id="outlined-basic"
            label="Add remark*"
            variant="outlined"
            onChange={(event) => {
              setMachineInfo((prev) => ({
                ...prev,
                remark: event.target.value,
              }));
            }}
          />

          {/* Add visit_id  */}
          <TextField
            value={machineInfo?.visit_id}
            fullWidth
            type="number"
            id="outlined-basic"
            label="Visit Id"
            variant="outlined"
            onChange={(event) => {
              setMachineInfo((prev) => ({
                ...prev,
                visit_id: event.target.value,
              }));
            }}
          />
        </div>
        <div className="mt-4">
          <LoadingButton
            size="small"
            onClick={submitForm}
            disabled={
              machineInfo?.code === "" ||
              machineInfo?.old_location === "" ||
              machineInfo?.new_location === "" ||
              machineInfo?.new_district === "" ||
              machineInfo?.new_region === "" ||
              machineInfo?.remark === ""
            }
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
            style={{ height: "36px" }}
          >
            <span>Submit</span>
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

export default UpdateMachine;

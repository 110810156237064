import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../../assets/styles/Signup.css";
import LoadingButton from "@mui/lab/LoadingButton";
import ContextState from "../contextApi/ContextState";

function Signup() {
  const { fetchloginApiCall, openSnackbar, key, controllerRef } = useContext(ContextState);
  // State for form
  const [sign_up_formData, setSign_up_formData] = useState({
    username: "",
    email: "",
    phone: "",
    password: "",
    accessCode: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  //  state for button spinner
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState(false);

  const handleSubmit = async (e, prevState) => {
    e.preventDefault();

    const validationErrors = {};

    if (!sign_up_formData.username.trim()) {
      validationErrors.username = "Username is required";
    }
    if (!sign_up_formData.email.trim()) {
      validationErrors.email = "Email is required";
    }
    if (!sign_up_formData.phone.trim()) {
      validationErrors.phone = "Phone Number is required";
    } else if (sign_up_formData.phone.length !== 10) {
      validationErrors.phone = "Phone number must be 10 digits";
    }

    if (!sign_up_formData.password.trim()) {
      validationErrors.password = "Password is required";
    }

    if (!sign_up_formData.accessCode.trim()) {
      validationErrors.accessCode = "Access Code is required";
    }
    //  else if (sign_up_formData.accessCode.length !== 6) {
    //   validationErrors.accessCode = "access must be 6 digits";
    // }
    // Update form errors state

    // If there are no validation errors, proceed with form submission
    if (Object.keys(validationErrors).length !== 0) {
      setFormErrors(validationErrors);

      return;
    }

    // for loading button
    setLoading(true);
    setLoading(true);

    // console.log("Submitting form...");

    const trimmed_sign_up_formData = {
      username: sign_up_formData.username.trim(),
      email: sign_up_formData.email.trim(),
      phone: sign_up_formData.phone.trim(),
      password: sign_up_formData.password,
      accessCode: sign_up_formData.accessCode.trim(),
    };

    // console.log(trimmed_sign_up_formData, "trimmed new data");

    const response = await fetchloginApiCall(
      "user_signup",
      "POST",
      trimmed_sign_up_formData
    );
    if (response.statusCode === 200) {
      // console.log("response.data.message", response.data.message);
      openSnackbar(response.data.message, "success");
      navigate("/login");
      // setLoading(false);
    } else if (response.statusCode === 409) {
      // console.log("rehdvd message", response.msg.message);
      openSnackbar(response.msg.message, "error");
      // setLoading(false);
    } else if (response.statusCode === 500) {
      // openSnackbar(response.msg.error, "error");
      openSnackbar("Internal sever error!!! Try after some time.", "error");
    } else {
      openSnackbar(response.msg.message, "error");
    }
    setLoading(false);

    //  form submission ...................
    // console.log("jweiojweniw", sign_up_formData);

    setState(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errors = { ...formErrors };

    // Check if the field being changed is the password field
    if (name === "password") {
      errors.password = ""; // Reset password error

      if (!value) {
        errors.password = "Password is required";
      } else if (value.length < 4) {
        errors.password = "At least 4 characters required.";
      } else {
        errors.password = "";
      }
    }

    // Update form data and errors state
    setSign_up_formData({
      ...sign_up_formData,
      [name]: value,
    });

    setFormErrors(errors);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // useEffect(() => {
  //   const time = setTimeout(() => {
  //     setLoading(false)

  //     setState(false)
  //   }, 2000)

  //   return () => {
  // clearTimeout(time);
  //     console.log("unmount")
  //   }
  // }, [loading]
  // )

  useEffect(() => {
    document.title = "Signup - NEO";
    if (localStorage.getItem(key)) {
      navigate("/");
    }
    // On unmount, abort the previous request for fetchApi api
    if (controllerRef.current) {
      controllerRef.current.abort(); 
    }
  }, []);

  return (
    <>
      <div className="d-flex p-4  mt-2 align-items-center justify-content-center">
        <div className="d-flex col-sm-8 col-md-8 col-lg-6 col-xxl-5  align-items-center justify-content-center ">
          <div className="signup_form_container signup_form col-sm-12 d-flex align-items-center justify-content-center">
            <div className="col">
              <div className="mb-4">
                <h3 className="title"> Create an account</h3>
                <p className="desp">Please Create an account!</p>
              </div>
              <form
                onSubmit={handleSubmit}
                className="d-flex flex-column justify-content-center h-100"
              >
                <div className="form-group py-2 form_float">
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    name="username"
                    value={sign_up_formData.username}
                    onChange={handleChange}
                  />
                  <label
                    className={`labels ${sign_up_formData ? "float" : ""}`}
                    htmlFor="username"
                  >
                    Username
                  </label>
                  {formErrors.username && (
                    <span className="error">{formErrors.username}</span>
                  )}
                </div>

                <div className="form-group py-2 form_float">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={sign_up_formData.email}
                    onChange={handleChange}
                  />
                  <label
                    className={`labels ${
                      sign_up_formData.email ? "float" : ""
                    }`}
                    htmlFor="email"
                  >
                    Email
                  </label>
                  {formErrors.email && (
                    <span className="error">{formErrors.email}</span>
                  )}
                </div>

                <div className="form-group py-2 form_float">
                  <input
                    type="number"
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={sign_up_formData.phone}
                    onChange={handleChange}
                  />
                  <label
                    className={`labels ${
                      sign_up_formData.phone ? "float" : ""
                    }`}
                    htmlFor="phone"
                  >
                    Phone
                  </label>
                  {formErrors.phone && (
                    <span className="error">{formErrors.phone}</span>
                  )}
                </div>

                <div className="form-group py-2 form_float">
                  <input
                    type="text"
                    className="form-control"
                    id="accessCode"
                    name="accessCode"
                    value={sign_up_formData.accessCode}
                    onChange={handleChange}
                  />
                  <label
                    className={`labels ${
                      sign_up_formData.accessCode ? "float" : ""
                    }`}
                    htmlFor="accessCode"
                  >
                    Access Code
                  </label>
                  {formErrors.accessCode && (
                    <span className="error">{formErrors.accessCode}</span>
                  )}
                </div>

                <div className="form-group py-2 form_float input-icon-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control input-with-icon"
                    id="password"
                    name="password"
                    value={sign_up_formData.password}
                    onChange={handleChange}
                  />
                  <span
                    className={`icon-inside-input ${
                      showPassword ? "visible" : ""
                    }`}
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye-slash password_icons"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                      </svg>
                    ) : null}
                  </span>
                  <span
                    className={`icon-inside-input ${
                      showPassword ? "" : "visible"
                    }`}
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? null : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye password_icons"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                      </svg>
                    )}
                  </span>
                  <label
                    className={`labels ${
                      sign_up_formData.password ? "float" : ""
                    }`}
                    htmlFor="password"
                  >
                    Password
                  </label>
                  {formErrors.password && (
                    <span className="error error_msg_password">
                      {formErrors.password}
                    </span>
                  )}
                </div>

                <div className="py-2 mt-2">
                  {/* <button type="submit" className="signup_button w-100 mt-auto">
                    Signup
                  </button> */}

                  <LoadingButton
                    // onClick={handleClick}
                    loading={loading}
                    variant="outlined"
                    type="submit"
                    className={`w-100 login_button ${
                      loading ? "while_submitting" : ""
                    }`}
                  >
                    <span>Signup</span>
                  </LoadingButton>
                </div>

                <div className="custom_media_small_screens d-flex flex-row  justify-content-center align-items-center  fs-6 py-3">
                  <p className="m-0 space_sigin">Already have an account?</p>
                  <NavLink to="/login">Sign in instead</NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;

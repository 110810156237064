import React, { useContext, useEffect, useState } from "react";
import "./ForgotPassword.css";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { NavLink, useNavigate } from "react-router-dom";
import ContextState from "../contextApi/ContextState";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { openSnackbar, key, generalApiCall, controllerRef } = useContext(ContextState);
  const [loading, setLoading] = useState(false);
  const [forgot_password_data, setForgot_password_data] = useState({
    email: "",
    newpassword: "",
    confirmPassword: "",
    code: "",
    user_id: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const handleOnchange = (e) => {
    const { value, name } = e.target;
    setForgot_password_data((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // console.log(forgot_password_data, "parent");

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      setErrorEmail(false);
      if (!validateEmail(forgot_password_data.email)) {
        setErrorEmail(true);
      } else {
        // Handle submitting the valid email
        // openModal();
        setLoading(true);
        setErrorEmail(false);

        let payload = {
          email: forgot_password_data.email,
        };

        const response = await generalApiCall(
          "reset_password_mail",
          "POST",
          payload,
          true
        );

        // const responsedata = await response.json();
        // const statusCode = responsedata.status_code;

        if (response?.statusCode === 200) {
          console.log("response?.data?.user_id", response?.data?.user_id)
          setForgot_password_data((prevState) => ({
            ...prevState,
            user_id: response?.data?.user_id,
          }));
          openModal();
          openSnackbar(response.data.message, "success");
          // console.log("email code sended successfully");
        } else if (response?.statusCode === 401) {
          openSnackbar(response.msg, "error");
          // console.log("some error occured", statusCode, responsedata);
          // alert("some error occured");
        } else {
          openSnackbar(
            "Internal server Error!, Please try again after some time",
            "error"
          );
          // console.log("some error occured", statusCode, responsedata);
          // alert("some error occured");
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      openSnackbar("some error occured!", "error");
      // console.log("some error occured in code");
    }

    // console.log(forgot_password_data);
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    document.title = "Reset Password - NEO";
    if (localStorage.getItem(key)) {
      navigate("/");
    }
    // On unmount, abort the previous request for fetchApi api
    if (controllerRef.current) {
      controllerRef.current.abort(); 
    }
  }, []);

  return (
    <>
      <div className="forgot_container_main p-4 d-flex flex-row justify-content-center  align-items-center vh-80 ">
        <div className="forgot_container d-flex flex-column">
          {/* <div className="text-center mb-3 forgot_img">
            <img src={logo} alt="" className="" />
          </div> */}
          <div className="mb-4">
            {" "}
            <h5 className="forgot_title">Forgot Password ?</h5>
            <p className="forgot_des m-0">
              Please enter your email address to reset the password.
            </p>
          </div>

          <div className="">
            {" "}
            <TextField
              sx={{
                "& .MuiInputLabel-root": {
                  zIndex: modalIsOpen ? 0 : 1,
                },
              }}
              error={errorEmail}
              id="outlined-basic"
              label="Email"
              variant="outlined"
              className="w-100"
              value={forgot_password_data.email}
              onChange={handleOnchange}
              name="email"
              helperText={errorEmail ? "Invalid email" : ""}
            />
          </div>

          <div className="mt-3">
            {" "}
            <LoadingButton
              // onClick={handleClick}
              loading={loading}
              variant="outlined"
              //   type="submit"
              //   size="medium"
              className={`w-100 forgot_btn ${
                loading ? "while_submitting" : ""
              }`}
              onClick={handleSubmit}
            >
              <span>Send Reset Code</span>
            </LoadingButton>
          </div>
          <div className="back_to_login accordion text-start mt-4">
            <NavLink to="/login">
              <span>
                <i className="bi bi-chevron-left mx-2"></i>
              </span>
              Back to Login
            </NavLink>
          </div>

          <ForgotPasswordModal
            isOpen={modalIsOpen}
            onClose={closeModal}
            data={forgot_password_data}
            onEdit={handleOnchange}
            setForgot_password_data={setForgot_password_data}
          />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

import React, { useContext, useEffect, useState } from "react";
import "../../assets/styles/UserDashboard.css";
import "../../assets/styles/LineChartAnalysis.css";
import ContextState from "../contextApi/ContextState";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Error from "../error/Error";
import "../../App.css";
import Accordion from "react-bootstrap/Accordion";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip as BootstrapTooltip } from "react-bootstrap";

import * as XLSX from "xlsx";
import {
  PieChart,
  Pie,
  Cell,
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AnimationBar,
} from "recharts";
import Dropdown from "../filters/Dropdown";
import AllFilters from "../filters/AllFilters";
import { BeatLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { addFilters } from "../features/filtersSlice/filterSlice";
import useMediaQuery from "@mui/material/useMediaQuery"; // conditional renderring of media query
import ShowTable from "./ShowTable";
import { CiExport } from "react-icons/ci";

const UserDashboard = () => {
  const {
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    closeSnackbar,
    lodingVisiblity,
    formatDate,
    controllerRef,
  } = useContext(ContextState);

  const CustomBar = (props) => {
    const { fill, x, y, width, height } = props;
    const radius = 7; // Adjust the radius to change the curvature

    return (
      <path
        d={`M${x},${y + height} 
            v${-height + radius} 
            a${radius},${radius} 0 0 1 ${radius},${-radius} 
            h${width - 2 * radius} 
            a${radius},${radius} 0 0 1 ${radius},${radius} 
            v${height - radius} 
            z`}
        fill={fill}
      />
    );
  };
  const issueTyepLogoBGcolor = ["#f04438", "#10b981", "#6366f1", "#f79009"];

  const shadesOfBlue = [
    "#001F3F",
    "#003366",
    "#004080",
    "#005099",
    "#0066B3",
    "#0077CC",
    "#0088E6",
    "#0099FF",
    "#1A8CFF",
    "#3399FF",
    "#4DABFF",
    "#66B8FF",
    "#80C6FF",
    "#99D4FF",
    "#B3E1FF",
    "#CCEFFF",
    "#E6F7FF",
    "#F0FAFF",
    "#FAFFFF", // (Lightest)
  ];

  const subissueComponentColors = {
    resolved: "#61bd4f", // light green
    validation: "#9575cd", // light purple
    ongoing: "#4fc3f7", // light blue
    pending: "#ef5350", // light red
  };

  const updatePiChartData = (issueData) => {
    // console.log("issueData:", issueData);
    const pieChartConvertedData01 = [];
    const pieChartConvertedData02 = [];
    const barGraphConvertedData01 = [];
    const avgSubIssueCompletionTime = [];

    if (!issueData) return pieChartConvertedData02;
    Object.keys(issueData?.graph_data || {}).forEach((subissue, index) => {
      const subissueValue = issueData?.graph_data[subissue];

      // for (const status in item) {
      //   if (
      //     status !== "subissue" &&
      //     status !== "total_count" &&
      //     status !== "total_tickets" &&
      //     status !== "avg_completion_time"
      //   ) {
      //     pieChartConvertedData02.push({
      //       name: subissue,
      //       status: status,
      //       value: item[status],
      //       color: subissueComponentColors[status],
      //     });
      //   } else if (status === "avg_completion_time") {
      //     avgSubIssueCompletionTime.push({
      //       name: item[status],
      //       uv: item[status],
      //       fill: shadesOfBlue[index % shadesOfBlue.length],
      //     });
      //   }
      // }
      // console.log("shadesOfBlue.length --> ", shadesOfBlue.length);
      pieChartConvertedData01.push({
        name: subissue,
        value: subissueValue,
        color: shadesOfBlue[index % shadesOfBlue.length],
      });
    });

    // Object.keys(issueData?.avg_duration || {}).forEach(
    //   (status, statusindex) => {
    //     const statusAvgValue = issueData?.avg_duration[status];
    //     barGraphConvertedData01.push({
    //       name: status.charAt(0).toUpperCase() + status.slice(1),
    //       avg_value: parseFloat((statusAvgValue / 60).toFixed(2)),
    //       fill: Object.keys(subissueComponentColors).includes(status)
    //         ? subissueComponentColors[status]
    //         : "#99D4FF",
    //     });
    //   }
    // );

    let totalDuration = Object.values(issueData?.avg_duration || {}).reduce(
      (total, duration) => total + duration,
      0
    );
    let MaxDuration = Math.max(...Object.values(issueData?.avg_duration || {}));
    let barGraphData = [];

    for (let [key, value] of Object.entries(issueData?.avg_duration || {})) {
      let percentage = (value / totalDuration) * 100;
      let name;
      let lastname;
      if (value >= 525600) {
        name = `${Math.floor(value / 525600)} year`;
        lastname = `${Math.floor((value % 525600) / 43200)} month`;
      } else if (value >= 43200) {
        name = `${Math.floor(value / 43200)} months`;
        lastname = `${Math.floor((value % 43200) / 1440)} day`;
      } else if (value >= 1440) {
        name = `${Math.floor(value / 1440)} days`;
        lastname = `${Math.floor((value % 1440) / 60)} hour`;
      } else if (value >= 60) {
        name = `${Math.floor(value / 60)} hours`;
        lastname = `${value % 60} minute`;
      } else {
        name = `${value} minutes`;
        lastname = "";
      }
      barGraphData.push({
        name: name,
        lastname: lastname,
        value: value,
        key: key,
        percentage: percentage,
        total: totalDuration,
        maximum: MaxDuration,
        color: Object.keys(subissueComponentColors).includes(key)
          ? subissueComponentColors[key]
          : "#99D4FF",
      });
    }
    // console.log("Bargraph : ", barGraphData);
    // console.log(pieChartConvertedData01, barGraphConvertedData01);
    // setdata02PieChart(pieChartConvertedData);
    // console.log("avgSubIssueCompletionTime", avgSubIssueCompletionTime);
    return {
      data01: pieChartConvertedData01,
      bardChartData: barGraphConvertedData01,
      bardChartData02: barGraphData,
    };
  };

  // ************* filters required data ***********
  const { fetchApi, showLoading, showError, showErrorPage } =
    useContext(ContextState);
  const dispatch = useDispatch();
  const userFilters = useSelector((state) => state.userFilters); // user filter from redux

  // blur all tickets
  const [blurTickets, setblurTickets] = useState("");

  const handelPageBlur = (blur_ticket) => {
    if (blur_ticket) {
      setblurTickets("all-ticket-content-blur");
    } else {
      setblurTickets("");
    }
  };

  // loding page when data to be fetched
  const [loading, setLoading] = useState(true);
  const [allFiltersDisplay, setallFilterDisplay] = useState(false);
  // filters
  const lastWeekDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
    .toISOString()
    .split("T")[0];
  const currentDate = new Date(Date.now()).toISOString().split("T")[0];
  const [filters, setFilters] = useState({
    // status: [],
    // issueType: [],
    // subIssueType: [],
    RO: [],
    DO: [],
    createdStartDate: [],
    createdEndDate: [],
    // time: [],
    // machineNumber: [],
    // hourlyDate: [],
    // DailyStartDate: [],
    // DailyEndDate: [],
    // MonthlyStartDate: [],
    // MonthlyEndDate: [],
    // crop_variation: [],
    // params_variation: [],
    // assignee: [],
    commitId: [],
    modelNo: [],
  });
  const [showAllfilters, setshowAllFilters] = useState({
    // status: [],
    // issueType: [],
    // subIssueType: [],
    RO: [],
    DO: [],
    createdStartDate: [],
    createdEndDate: [],
    // time: [],
    // machineNumber: [],
    // hourlyDate: [],
    // DailyStartDate: [],
    // DailyEndDate: [],
    // MonthlyStartDate: [],
    // MonthlyEndDate: [],
    // crop_variation: [],
    // params_variation: [],
    // assignee: [],
    commitId: [],
    modelNo: [],
  });
  const [filterCount, setFilterCount] = useState({
    status: 0,
    issueType: 0,
    subIssueType: 0,
    RO: 0,
    DO: 0,
    time: 0,
    createdStartDate: 0,
    createdEndDate: 0,
    // machineNumber: 0,
    // hourlyDate: 0,
    // DailyStartDate: 0,
    // DailyEndDate: 0,
    // MonthlyStartDate: 0,
    // MonthlyEndDate: 0,
    // crop_variation: 0,
    // params_variation: 0,
    // assignee: 0,
    commitId: 0,
    modelNo: 0,
  });
  // Function to update filters
  const updateFilters = (Filters, clickedPageNumber = 0, updateURL = true) => {
    // console.log("update filter 0 -->", Filters, clickedPageNumber);
    Filters = applyCoustomChecksFilter(Filters);
    setFilters(Filters);
    // setCurrentPage(clickedPageNumber);
    // if (updateURL) {
    updateURLParams(Filters, clickedPageNumber);
    // console.log("Url Is Updating ");
    // } else {
    // console.log("Url Is Not Updating ");
    // }
    updateFilterCount(Filters);
    // if (getUserFilters?.issues) {
    //   applyIssueTypeFilters(Filters);
    // }
    showAllFilters(Filters);
    fetchData(Filters);
  };

  const updateFilterCount = (farr) => {
    for (const key in farr) {
      const count = farr[key].length;
      filterCount[key] = count;
    }
    setFilterCount(filterCount);
  };

  const updateURLParams = (params) => {
    const urlSearchParams = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      // console.log(key, params[key]);
      if (params[key]) {
        urlSearchParams.set(key, params[key]);
      } else {
        urlSearchParams.delete(key);
      }
    });
    // console.log("url send to update : ", params);
    // urlSearchParams.set("currentPage", parseInt(page) + 1);
    // urlSearchParams.set("cardsPerPage", cardsPerPage);
    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;

    if (window.location.pathname === "/FCI/ticketsoverview" && !Data) {
      // Check if the state already exists
      // console.log("Replace state Wroking");
      const currentState = window.history.state;
      if (!currentState || !currentState.filters) {
        // Replace the initial state if no filters exist
        window.history.replaceState(
          {
            filters: params,
          },
          "",
          newUrl
        );
      }
    } else {
      // Push state for subsequent changes
      window.history.pushState(
        {
          filters: params,
        },
        "",
        newUrl
      );
    }
  };

  // this will populate our dashboard page
  const [Data, setData] = useState(null); // page data
  const fetchData = async (F, Page = 0) => {
    setLoading(true);
    showLoading(true);
    setData(null);
    // console.log("data set to empty List - 3");

    // data from backend
    // total number of cards
    // cards filter show how many pages perpage (cardsPerPage : 15)

    const payload = {};
    // payload["page_num"] = parseInt(Page) + 1;
    if (F["createdStartDate"] && F["createdStartDate"].length) {
      payload["creation_from"] = F["createdStartDate"][0];
    }
    if (F["createdEndDate"] && F["createdEndDate"].length) {
      payload["creation_to"] = F["createdEndDate"][0];
    }
    if (
      F["resolvedStartDate"] &&
      F["resolvedStartDate"].length &&
      F["status"].includes("Resolved")
    ) {
      payload["resolved_from"] = F["resolvedStartDate"][0];
    }
    if (
      F["resolvedEndDate"] &&
      F["resolvedEndDate"].length &&
      F["status"].includes("Resolved")
    ) {
      payload["resolved_to"] = F["resolvedEndDate"][0];
    }
    //  filters for dashboard
    // if (F["hourlyDate"]?.length && F["time"].includes("hourly")) {
    //   payload["hourlyDate"] = F["hourlyDate"][0];
    // }
    // if (F["DailyStartDate"]?.length && F["time"].includes("daily")) {
    //   payload["DailyStartDate"] = F["DailyStartDate"][0];
    // }
    // if (F["DailyEndDate"]?.length && F["time"].includes("daily")) {
    //   payload["DailyEndDate"] = F["DailyEndDate"][0];
    // }
    // if (F["MonthlyStartDate"]?.length && F["time"].includes("monthly")) {
    //   payload["MonthlyStartDate"] = F["MonthlyStartDate"][0];
    // }
    // if (F["MonthlyEndDate"]?.length && F["time"].includes("monthly")) {
    //   payload["MonthlyEndDate"] = F["MonthlyEndDate"][0];
    // }
    // if (F["issueType"] && F["issueType"].length) {
    //   payload["issue"] = F["issueType"];
    // }
    // if (F["subIssueType"] && F["subIssueType"].length) {
    //   payload["sub_issue"] = F["subIssueType"];
    // }
    // if (F["status"] && F["status"].length) {
    //   payload["status"] = F["status"].map((e) => e.toLowerCase());
    // }
    if (F["machineNumber"] && F["machineNumber"].length) {
      payload["machines"] = F["machineNumber"];
    }
    if (F["DO"] && F["DO"].length) {
      payload["districts"] = F["DO"];
    }
    if (F["RO"] && F["RO"].length) {
      payload["regions"] = F["RO"];
    }
    if (F["commitId"] && F["commitId"].length) {
      payload["commit_id"] = F["commitId"];
    }
    if (F["modelNo"] && F["modelNo"].length) {
      payload["model_no"] = F["modelNo"];
    }
    if (F["sort"] && F["sort"].length) {
      const sortDictManual = {
        "Creation time- Ascending": "creation_time:ASC",
        "Creation time- Descending": "creation_time:DESC",
        "Updated time- Descending": "last_updated:DESC",
      };
      const sortArr =
        (sortDictManual[filtersOptions["sortDict"][0]] &&
          F["sort"].map((item) => filtersOptions["sortDict"][item])) ||
        sortDictManual[F["sort"][0]];
      payload["Sort"] = sortArr;
    }
    if (F["crop_variation"] && F["crop_variation"].length) {
      payload["crop_variation"] = F["crop_variation"];
    }
    if (F["params_variation"] && F["params_variation"].length) {
      payload["params_variation"] = F["params_variation"];
    }
    if (F["assignee"] && F["assignee"].length) {
      payload["assignee"] = F["assignee"];
    }
    if (F["moreFilters"] && F["moreFilters"].length) {
      F["moreFilters"].forEach((item) => {
        payload[item] = true;
      });
    }
    if (F["searchBox"] && F["searchBox"].length && F["searchBox"][0] !== "") {
      payload["ticket_id"] = F["searchBox"][0];
    }
    if (
      F["ticketPerPage"] &&
      F["ticketPerPage"].length &&
      F["ticketPerPage"][0] !== ""
    ) {
      payload["tickets_limit"] = F["ticketPerPage"][0];
    }

    let is_all_ticket_data_Mounted = true;
    const getTicketAnalytics = await fetchApi(
      "get_ticket_analytics",
      "POST",
      payload,
      is_all_ticket_data_Mounted
    );
    // console.log("data send : ", payload);

    if (getTicketAnalytics.statusCode === 200) {
      // console.log(
      //   "getTicketAnalytics-->",
      //   getTicketAnalytics,
      //   getTicketAnalytics?.data
      // );
      showErrorPage(null, null);
      setData(getTicketAnalytics || null);
    } else if (
      getTicketAnalytics.statusCode === 500 ||
      getTicketAnalytics.statusCode === 401
    ) {
      setData(null);
      showErrorPage(getTicketAnalytics.statusCode, getTicketAnalytics.msg);
    } else {
      setData(null);
      localStorage.clear();
      window.location.href = "/login";
    }

    setLoading(false);
    showLoading(false);

    return () => {
      is_all_ticket_data_Mounted = false;
    };
  };

  const handleFilterChange = (filter, filterType) => {
    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };
      const filterList = currentFilters[filterType];

      if (
        filterList &&
        (filterType === "searchBox" ||
          filterType === "createdStartDate" ||
          filterType === "createdEndDate" ||
          filterType === "resolvedStartDate" ||
          filterType === "resolvedEndDate" ||
          filterType === "hourlyDate" ||
          filterType === "DailyStartDate" ||
          filterType === "DailyEndDate" ||
          filterType === "MonthlyStartDate" ||
          filterType === "MonthlyEndDate" ||
          filterType === "time")
      ) {
        if (filterType === "time") {
          currentFilters["DailyStartDate"] = [];
          currentFilters["DailyEndDate"] = [];
          currentFilters["MonthlyStartDate"] = [];
          currentFilters["MonthlyEndDate"] = [];
          currentFilters["hourlyDate"] = [];
        }
        if (filter !== "") currentFilters[filterType] = [filter];
        else currentFilters[filterType] = [];
      } else if (
        (filterList && filterType === "sort") ||
        filterType === "ticketPerPage"
      ) {
        currentFilters[filterType] = [filter];
      } else if (filterList && filterList.includes(filter)) {
        currentFilters[filterType] = filterList.filter(
          (prevFilter) => prevFilter !== filter
        );
      } else {
        currentFilters[filterType] = [...(filterList || []), filter];
      }
      if (filterType === "RO") {
        applyROFilters(currentFilters);
      } else if (filterType === "machineNumber") {
        applyMachineNumberFilters(currentFilters);
      } else if (filterType === "DO") {
        applyDOFilters(currentFilters);
      }
      // applyIssueTypeFilters(currentFilters);
      updateFilterCount(currentFilters);
      // applyCropVariationFilters(currentFilters);
      // console.log(
      //   "update filter 1 -->",
      //   prevFilters,
      //   currentFilters,
      //   filter,
      //   filterType
      // );
      return currentFilters;
    });
  };

  const handleFilterReset = (filtername) => {
    // const filters = { ...filters };
    if (Object.keys(filters || {}).includes(filtername)) {
      filters[filtername] = [];
    }
    if (filtername === "moreFilters") {
      filters["searchBox"] = [];
      updateFilters["ticketPerPage"] = [];
    }
    if (filtername === "issueType") {
      filters["subIssueType"] = [];
    }
    if (filtername === "dateSelector") {
      filters["resolvedStartDate"] = [];
      filters["resolvedEndDate"] = [];
      filters["createdStartDate"] = [];
      filters["createdEndDate"] = [];
    }
    if (filtername === "time") {
      filters["DailyStartDate"] = [];
      filters["DailyEndDate"] = [];
      filters["MonthlyStartDate"] = [];
      filters["MonthlyEndDate"] = [];
      filters["hourlyStartDate"] = [];
      filters["hourlyEndDate"] = [];
    }
    // console.log("working", filtername, filtername === "dateSelector", filters);
    setFilters(filters);
    // console.log(filtername);
    if (filtername === "RO") {
      applyROFilters(filters);
    } else if (filtername === "machineNumber") {
      applyMachineNumberFilters(filters);
    } else if (filtername === "DO") {
      applyDOFilters(filters);
    }
    // console.log(filters, "updateFilterCount");
    // applyIssueTypeFilters(filters);
    updateFilterCount(filters);
  };
  const handleSaveChanges = (filtername) => {
    // console.log("sendFilters->  ", filtername, filters[filtername]);
    const filterAfterCoustomChange = applyCoustomChecksFilter(filters);
    updateFilters(filterAfterCoustomChange, 0);
    setshowAllFilters(filterAfterCoustomChange);
  };

  const handelResetAllBtn = () => {
    // console.log("working");
    for (const key in filters) {
      filters[key] = [];
    }
    // console.log("updated filter ", filters);
    updateFilters(filters, 0);

    setfiltersOptions((prevState) => ({
      ...prevState,
      DO: filtersOptionsCopy["DO"],
      machineNumber: filtersOptionsCopy["machineNumber"],
      RO: filtersOptionsCopy["RO"],
    }));
  };

  const handelDeleteFilterBtn = (filterType, subfilter) => {
    // console.log("working", filterType, subfilter);

    // Check if the filterKey exists in the filters state
    if (filters.hasOwnProperty(filterType)) {
      // Remove the specified filterValue from the filterKey array
      filters[filterType] = filters[filterType].filter(
        (filter) => filter !== subfilter
      );
    }
    if (filterType === "status" && subfilter === "Resolved") {
      filters["resolvedEndDate"] = [];
      filters["resolvedStartDate"] = [];
    }
    if (filterType === "issueType") {
      filters["subIssueType"] = [];
      if (subfilter === "Data Variation") {
        filters["crop_variation"] = [];
        filters["params_variation"] = [];
      }
    }
    if (filterType === "crop_variation") {
      filters["params_variation"] = [];
    }

    if (
      filterType === "hourlyDate" ||
      filterType === "DailyStartDate" ||
      filterType === "DailyEndDate" ||
      filterType === "MonthlyStartDate" ||
      filterType === "MonthlyEndDate" ||
      filterType === "time"
    ) {
      if (filterType !== "time") {
        filters["time"] = [];
      }
      filters["DailyStartDate"] = [];
      filters["DailyEndDate"] = [];
      filters["MonthlyStartDate"] = [];
      filters["MonthlyEndDate"] = [];
      filters["hourlyDate"] = [];
    }
    if (filterType === "RO") {
      applyROFilters(filters);
    } else if (filterType === "machineNumber") {
      applyMachineNumberFilters(filters);
    } else if (filterType === "DO") {
      applyDOFilters(filters);
    }
    updateFilters(filters, 0);
    // console.log("Deleted single filter --->", filters);
  };

  function showAllFilters(FiltersDict) {
    for (const i in FiltersDict) {
      if (FiltersDict[i].length !== 0) {
        // console.log("show filter");
        setallFilterDisplay(true);
        return;
      }
    }
    setallFilterDisplay(false);
  }

  const [getUserFilters, setgetUserFilters] = useState(null);

  // filters to show

  const [filtersOptions, setfiltersOptions] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    DO: [],
    DO: [],
    machineNumber: [],
    commitId: [],
    modelNo: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    sort: [],
    sortDict: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    moreFilters: [],
    ticketPerPage: ["20", "50", "100", "All"],
  });
  const [filtersOptionsCopy, setfiltersOptionsCopy] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    commitId: [],
    modelNo: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    sort: [],
    sortDict: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    moreFilters: [],
  });
  // filters sorting according to selected filter
  const getFilterResponse = async (response) => {
    // setLoading(true);
    // showLoading(true);
    try {
      // calling refresh token whenever loading the page
      // console.log("get_user_filters--->", response);
      if (response.statusCode === 200) {
        // console.log("get_user_filters Data Fetched Successfully");
        const data = response.data;
        // console.log("data", new Date().toLocaleString(), data);
        setgetUserFilters(data);

        // Extract RO# keys from  filters_data
        const roKeys =
          data && data.access_level === "institution"
            ? Object.keys(data?.filters_data || {})
            : [];

        // Extract DO# keys from RO objects in filters_data
        const doKeys =
          data && data.access_level === "institution"
            ? Object.values(data?.filters_data || {}).flatMap((Do) =>
                Object.keys(Do)
              )
            : data && data.access_level === "region"
            ? Object.keys(data.filters_data)
            : [];

        // Populate machineNumber array with unique machine numbers
        const uniquedoKeys = Array.from(new Set(doKeys));

        // Extract machine numbers from DO keys
        const machineNumbervalue =
          data && data.access_level === "institution"
            ? Object.values(data?.filters_data || {})
                .flatMap((ro) => Object.values(ro))
                .flatMap((doData) => doData)
                .flatMap((machine) => machine)
                .concat(Object.values(data?.ro_machines || {}))
            : data && data.access_level === "region"
            ? Object.values(data?.filters_data || {})
                .flatMap((doList) => {
                  return Object.values(doList);
                })
                .flat()
            : data && data.access_level === "district"
            ? data.filters_data
            : [];

        // Populate machineNumber array with unique machine numbers
        const uniqueMachineNumbers = Array.from(new Set(machineNumbervalue));

        // ********** not required ************
        // populate issuetype in filter option
        // const issueTypes = Object.keys(data.issues[0]);
        // populate subissuetype in filter option
        // const subIssueTypes = Object.values(data.issues[0]).flatMap((issue) =>
        //   issue.sub_issue.map((subIssue) => subIssue[1])
        // );
        // console.log(
        //   "Sub issues : ",
        //   subIssueTypes,
        //   typeof subIssueTypes,
        //   subIssueTypes.length
        // );
        // populating sort in filters Option
        // const sortArr =
        //   (data && data.sort_list.map((item) => item.value)) || [];
        // const sortDictArr =
        //   (data &&
        //     data.sort_list.reduce((acc, item) => {
        //       acc[item.value] = item.id;
        //       return acc;
        //     }, {})) ||
        //   [];

        // const assigneeType =
        //   (data && data.assignee_list?.map((item) => item.name)) || [];
        // // console.log("assigneeType", assigneeType);
        // const cropvariationTypes = data ? Object.keys(data.param_data) : [];

        let commitIdArr = data && Object.values(data.commit_id_list || {});

        commitIdArr = commitIdArr.map((commitIds, index) => {
          return commitIds.slice(0, 5);
        });
        const modelNoArr = data && Object.values(data.model_no_list || {});

        // const dataParamsTypes =
        //   Array.from(
        //     new Set(
        //       data &&
        //         Object.values(data.param_data).flatMap((item) =>
        //           Object.keys(item.client_params)
        //         )
        //     )
        //   ) || [];

        // console.log("cropvariationTypes", cropvariationTypes, dataParamsTypes);
        // const statusTypes = data.status_list;

        setfiltersOptions((eachFilterToShow) => ({
          ...eachFilterToShow,
          RO: roKeys,
          DO: uniquedoKeys,
          machineNumber: uniqueMachineNumbers,
          modelNo: modelNoArr,
          commitId: commitIdArr,
        }));
        setfiltersOptionsCopy((eachFilterToShow) => ({
          ...eachFilterToShow,
          RO: roKeys,
          DO: uniquedoKeys,
          machineNumber: uniqueMachineNumbers,
          modelNo: modelNoArr,
          commitId: commitIdArr,
        }));
      } else {
        // Handle non-successful responses
        console.error("Error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const applyROFilters = (filtersDict) => {
    const roFilters = filtersDict.RO;

    // Extract all DO keys from selected RO filters
    const filteredDO = roFilters.flatMap((ro) =>
      getUserFilters.filters_data[ro]
        ? Object.keys(getUserFilters?.filters_data[ro] || {})
        : []
    );

    // console.log("filteredDO", filteredDO, roFilters);

    // Extract all machine numbers from selected RO filters and DO keys
    const filteredMachineNumbers = roFilters.flatMap((ro) =>
      getUserFilters.filters_data[ro]
        ? Object.values(getUserFilters?.filters_data[ro] || {})
            .flatMap((doValues) => doValues)
            .concat(
              getUserFilters?.ro_machines[ro]
                ? getUserFilters?.ro_machines[ro]
                : []
            )
        : []
    );

    // Update filtersOptions with unique filtered DO values and machine numbers
    const uniqueDO = Array.from(new Set(filteredDO));
    const uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));

    if (roFilters.length === 0) {
      const newFilter = { ...filters };
      newFilter["DO"] = [];
      newFilter["RO"] = [];
      newFilter["machineNumber"] = [];
      // setFilters(newFilter);
      // updateFilterCount(newFilter);
      // showAllFilters(newFilter);
      setfiltersOptions((prevState) => ({
        ...prevState,
        DO: filtersOptionsCopy["DO"],
        machineNumber: filtersOptionsCopy["machineNumber"],
      }));
    } else {
      const newFilter = { ...filters };
      newFilter["DO"] = [];
      newFilter["machineNumber"] = [];
      // console.log("uniqueMachineNumber --> ", uniqueMachineNumbers);
      // setFilters(newFilter);
      // updateFilterCount(newFilter);
      // showAllFilters(newFilter);

      setfiltersOptions((prevState) => ({
        ...prevState,
        DO: uniqueDO,
        machineNumber: uniqueMachineNumbers,
      }));
    }
  };

  const applyDOFilters = (filtersDict) => {
    const doFilters = filtersDict.DO;
    const roFilters = filtersDict.RO;
    if (getUserFilters.access_level === "institution") {
      // Find corresponding RO keys for selected DO keys
      const roKeys = [];
      Object.entries(getUserFilters?.filters_data || {}).forEach(
        ([roKey, doValues]) => {
          Object.keys(doValues).forEach((doKey) => {
            if (doFilters.includes(doKey)) {
              roKeys.push(roKey);
            }
          });
        }
      );

      // Extract all machine numbers from selected DO keys
      const filteredMachineNumbers = doFilters.flatMap((doKey) =>
        Object.values(getUserFilters.filters_data)
          .filter((ro) => ro[doKey])
          .flatMap((ro) => ro[doKey])
      );
      // Update filtersOptions with unique filtered machine numbers and corresponding RO keys
      const uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));
      const uniqueRO = Array.from(new Set(roKeys));
      if (doFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: filtersOptionsCopy["machineNumber"],
          RO: filtersOptionsCopy["RO"],
        }));
      } else {
        //filters["machineNumber"] = uniqueMachineNumbers;
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: uniqueMachineNumbers,
          RO: roFilters.length === 0 ? uniqueRO : prevState.RO,
        }));
      }
    } else if (getUserFilters.access_level === "region") {
      // Extract machine numbers for selected DO keys
      const filteredMachineNumbers = doFilters.reduce((acc, doKey) => {
        if (getUserFilters.filters_data[doKey]) {
          acc.push(...getUserFilters.filters_data[doKey]);
        }
        return acc;
      }, []);

      if (doFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: filtersOptionsCopy["machineNumber"],
        }));
      } else {
        // Update filtersOptions machineNumber with unique filtered machine numbers
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: Array.from(new Set(filteredMachineNumbers)),
        }));
      }
    }
  };

  const applyMachineNumberFilters = (filtersDict) => {
    const roFilters = filtersDict.RO;
    const doFilters = filtersDict.DO;
    const machineNumberFilters = filtersDict.machineNumber;
    if (getUserFilters.access_level === "institution") {
      // Extract corresponding RO and DO keys for selected machine numbers
      const filteredRODO = Object.entries(
        getUserFilters?.filters_data || {}
      ).reduce((acc, [ro, roData]) => {
        Object.entries(roData || {}).forEach(([doKey, machines]) => {
          const matchingMachines = machines.filter((machine) =>
            machineNumberFilters.includes(machine)
          );
          if (matchingMachines.length > 0) {
            acc.push({ RO: ro, DO: doKey });
          }
        });
        return acc;
      }, []);

      // Extract unique RO and DO keys
      const uniqueRO = Array.from(
        new Set(filteredRODO.map((entry) => entry.RO))
      );
      const uniqueDO = Array.from(
        new Set(filteredRODO.map((entry) => entry.DO))
      );

      // Update filtersOptions with unique RO and DO keys
      if (machineNumberFilters.length === 0) {
        // console.log(
        //   "machineNumberFilters.length",
        //   machineNumberFilters.length,
        //   filtersOptions
        // );
        setfiltersOptions((prevState) => ({
          ...prevState,
          RO: filtersOptionsCopy["RO"],
          DO: filtersOptionsCopy["DO"],
        }));
      } else {
        setfiltersOptions((prevState) => ({
          ...prevState,
          RO: roFilters.length === 0 ? uniqueRO : prevState.RO,
          DO: doFilters.length === 0 ? uniqueDO : prevState.DO,
        }));
      }
    } else if (getUserFilters.access_level === "region") {
      // Extract DO keys for selected machine numbers
      const filteredDOKeys = Object.keys(getUserFilters.filters_data).filter(
        (doKey) => {
          const machineNumbers = getUserFilters.filters_data[doKey];
          return machineNumbers.some((number) =>
            machineNumberFilters.includes(number)
          );
        }
      );

      if (machineNumberFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          DO: filtersOptionsCopy["DO"],
        }));
      } else {
        // Update filtersOptions DO with unique filtered DO keys
        setfiltersOptions((prevState) => ({
          ...prevState,
          DO:
            doFilters.length === 0
              ? Array.from(new Set(filteredDOKeys))
              : prevState.RO,
        }));
      }
    }
  };

  // const applyIssueTypeFilters = (filterDict) => {
  // Function to update filtersOptions based on selected issueType
  const applyIssueTypeFilters = (filterDict) => {
    // Filter subIssueType values based on the selected issueType
    if (filterDict?.issueType) {
      if (filterDict.issueType.length) {
        const filteredSubIssueType =
          getUserFilters?.issues?.reduce((acc, issue) => {
            const selectedIssueTypes = filterDict.issueType;
            for (const issueType of selectedIssueTypes) {
              if (issue[issueType]) {
                acc.push(
                  ...issue[issueType].sub_issue?.map((subIssue) => subIssue[1])
                );
              }
            }
            return acc;
          }, []) || [];
        // Update filtersOptions state with the filtered subIssueType values
        setfiltersOptions((prevState) => ({
          ...prevState,
          subIssueType: Array.from(
            new Set([...(filters.subIssueType || []), ...filteredSubIssueType])
          ),
        }));
      } else {
        setfiltersOptions((prevState) => ({
          ...prevState,
          subIssueType: filtersOptionsCopy.subIssueType,
        }));
      }
    }
  };

  const applyCropVariationFilters = (filterDict) => {
    // Get the list of param_data keys from filters.crop_variation
    const selectedCrops = filterDict.crop_variation;

    // Filter client_params keys based on selectedCrops
    const clientParamsKeysArray = Object.entries(
      getUserFilters?.param_data || {}
    )
      .filter(([key]) => selectedCrops.includes(key)) // Filter based on selectedCrops
      .flatMap(([key, value]) => Object.keys(value.client_params));

    // Update filtersOptions.params_variation
    const updatedParamsVariation = Array.from(
      new Set([...(filters.params_variation || []), ...clientParamsKeysArray])
    );

    // console.log("updatedParamsVariation", updatedParamsVariation);
    if (selectedCrops.length) {
      setfiltersOptions((prevState) => ({
        ...prevState,
        params_variation: updatedParamsVariation,
      }));
    } else {
      setfiltersOptions((prevState) => ({
        ...prevState,
        params_variation: filtersOptionsCopy["params_variation"],
      }));
    }
  };

  const applyCoustomChecksFilter = (filterDict) => {
    if (filterDict["status"] && !filterDict["status"].includes("Resolved")) {
      filterDict["resolvedEndDate"] = [];
      filterDict["resolvedStartDate"] = [];
    }

    if (
      filterDict?.issueType &&
      !filterDict?.issueType?.includes("Data Variation")
    ) {
      filterDict["params_variation"] = [];
      filterDict["crop_variation"] = [];
    }

    if (filterDict?.issueType && filterDict?.issueType?.length === 0) {
      filterDict.subIssueType = [];
      filterDict.status = [];
    }

    if (
      filterDict?.crop_variation &&
      filterDict?.crop_variation?.length === 0
    ) {
      filterDict.params_variation = [];
    }

    return filterDict;
  };

  // ************* filters required data done ***********
  const [animationActive, setAnimationActive] = useState(false);
  const [barChartAnimationActive, setBarChartAnimationActive] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    document.title = "User Dashboard - NEO";
    document.title = "User Dashboard - NEO";
    showLoading(true);
    setLoading(true);
    setData(null);
    // console.log("data set to empty List - 1");
    const params = new URLSearchParams(window.location.search);
    // console.log("prams -->", params);
    // const statusFilterData =
    //   params.get("status") !== "" && params.get("status") !== null
    //     ? params.get("status").split(",")
    //     : [];
    // const issueTypeFilterData =
    //   params.get("issueType") !== "" && params.get("issueType") !== null
    //     ? params.get("issueType").split(",")
    //     : [];
    const ROFilterData =
      params.get("RO") !== "" && params.get("RO") !== null
        ? params.get("RO").split(",")
        : [];
    const DOFilterData =
      params.get("DO") !== "" && params.get("DO") !== null
        ? params.get("DO").split(",")
        : [];
    const machineNumberFilterData =
      params.get("machineNumber") !== "" && params.get("machineNumber") !== null
        ? params.get("machineNumber").split(",")
        : [];
    // const timeFilterData =
    //   params.get("time") !== "" && params.get("time") !== null
    //     ? params.get("time").split(",")
    //     : [];
    // const hourlyDateFilterData =
    //   params.get("hourlyDate") !== "" && params.get("hourlyDate") !== null
    //     ? params.get("hourlyDate").split(",")
    //     : [];
    // const DailyStartDateFilterData =
    //   params.get("DailyStartDate") !== "" &&
    //   params.get("DailyStartDate") !== null
    //     ? params.get("DailyStartDate").split(",")
    //     : [];
    // const DailyEndDateFilterData =
    //   params.get("DailyEndDate") !== "" && params.get("DailyEndDate") !== null
    //     ? params.get("DailyEndDate").split(",")
    //     : [];
    // const MonthlyStartDateFilterData =
    //   params.get("MonthlyStartDate") !== "" &&
    //   params.get("MonthlyStartDate") !== null
    //     ? params.get("MonthlyStartDate").split(",")
    //     : [];
    // const MonthlyEndDateFilterData =
    //   params.get("MonthlyEndDate") !== "" &&
    //   params.get("MonthlyEndDate") !== null
    //     ? params.get("MonthlyEndDate").split(",")
    //     : [];
    // console.log("machineNumberFilterData", machineNumberFilterData);

    const createdStartDateFilterData =
      params.get("createdStartDate") !== "" &&
      params.get("createdStartDate") !== null
        ? params.get("createdStartDate").split(",")
        : [];
    const createdEndDateFilterData =
      params.get("createdEndDate") !== "" &&
      params.get("createdEndDate") !== null
        ? params.get("createdEndDate").split(",")
        : [];

    const commitIdFilterData =
      params.get("commitId") !== "" && params.get("commitId") !== null
        ? params.get("commitId").split(",")
        : [];
    const modelNoFilterData =
      params.get("modelNo") !== "" && params.get("modelNo") !== null
        ? params.get("modelNo").split(",")
        : [];

    // console.log("createdStartDate --> ", createdStartDateFilterData);
    // const resolvedStartDateDateFilterData =
    //   params.get("resolvedStartDate") !== "" &&
    //   params.get("resolvedStartDate") !== null
    //     ? params.get("resolvedStartDate").split(",")
    //     : [];
    // const resolvedEndDateFilterData =
    //   params.get("resolvedEndDate") !== "" &&
    //   params.get("resolvedEndDate") !== null
    //     ? params.get("resolvedEndDate").split(",")
    //     : [];
    // const assigneeFilterData =
    //   params.get("assignee") !== "" && params.get("assignee") !== null
    //     ? params.get("assignee").split(",")
    //     : [];
    // const subIssueTypeFilterData =
    //   params.get("subIssueType") !== "" && params.get("subIssueType") !== null
    //     ? params.get("subIssueType").split(",")
    //     : [];
    // const sortFilterData =
    //   params.get("sort") !== "" && params.get("sort") !== null
    //     ? params.get("sort").split(",")
    //     : [];
    // const searchBoxFilterData =
    //   params.get("searchBox") !== "" && params.get("searchBox") !== null
    //     ? params.get("searchBox").split(",")
    //     : [];
    // const Page =
    //   params.get("currentPage") !== "" && params.get("currentPage") !== null
    //     ? params.get("currentPage")
    //     : 1;
    // const ticketPerPageFilterData =
    //   params.get("ticketPerPage") !== "" && params.get("ticketPerPage") !== null
    //     ? [params.get("ticketPerPage")]
    //     : [];
    // console.log("ticketPerPageFilterData", ticketPerPageFilterData);
    // if (
    //   ticketPerPageFilterData.length ||
    //   ticketPerPageFilterData[0] === "20" ||
    //   ticketPerPageFilterData[0] === "50" ||
    //   ticketPerPageFilterData[0] === "100"
    // ) {
    //   setCardsPerPage(parseInt(ticketPerPageFilterData[0]));
    // }
    // console.log("searchBoxFilterData: ", searchBoxFilterData);
    // const cropVariationFilterData =
    //   params.get("crop_variation") !== "" &&
    //   params.get("crop_variation") !== null
    //     ? params.get("crop_variation").split(",")
    //     : [];
    // const paramVariationFilterData =
    //   params.get("params_variation") !== "" &&
    //   params.get("params_variation") !== null
    //     ? params.get("params_variation").split(",")
    //     : [];

    // const moreFiltersFilterData =
    //   params.get("moreFilters") !== "" && params.get("moreFilters") !== null
    //     ? params.get("moreFilters").split(",")
    //     : [];
    // console.log("moreFiltersFilterData", moreFiltersFilterData);
    // machineNumber: [],
    // createdStartDate: [],
    // createdEndDate: [],
    // resolvedStartDate: [],
    // resolvedEndDate: [],
    // sort: [],
    // crop_variation: [],
    // params_variation: [],

    // console.log("------------->", Page);
    const initalFilters = {
      // status: statusFilterData,
      // issueType: issueTypeFilterData,
      RO: ROFilterData,
      DO: DOFilterData,
      machineNumber: machineNumberFilterData,
      createdStartDate: createdStartDateFilterData,
      createdEndDate: createdEndDateFilterData,
      commitId: commitIdFilterData,
      modelNo: modelNoFilterData,
      // time: timeFilterData,
      // hourlyDate: hourlyDateFilterData,
      // DailyStartDate: DailyStartDateFilterData,
      // DailyEndDate: DailyEndDateFilterData,
      // MonthlyStartDate: MonthlyStartDateFilterData,
      // MonthlyEndDate: MonthlyEndDateFilterData,
      // createdStartDate: createdStartDateFilterData,
      // createdEndDate: createdEndDateFilterData,
      // resolvedStartDate: resolvedStartDateDateFilterData,
      // resolvedEndDate: resolvedEndDateFilterData,
      // sort: sortFilterData,
      // crop_variation: cropVariationFilterData,
      // params_variation: paramVariationFilterData,
      // moreFilters: moreFiltersFilterData,
      // searchBox: searchBoxFilterData,
      // ticketPerPage: ticketPerPageFilterData,
      // assignee: assigneeFilterData,
      // subIssueType: subIssueTypeFilterData,
    };
    // console.log("insitial filter --->", initalFilters);

    let is_all_filter_data_Mounted = true;
    const fetchDataAndUpdateFilters = async (
      initalFilters,
      Page,
      updateURL = true
    ) => {
      const currentTime = Date.now();
      const lastDataFetchTimestamp = userFilters?.lastDataFetchTimestamp;

      if (
        !lastDataFetchTimestamp ||
        currentTime - lastDataFetchTimestamp >= 30 * 60 * 1000
      ) {
        // fetchFiltersDataApiCall
        const all_filter_data = await fetchApi(
          "get_user_filters",
          "POST",
          {},
          is_all_filter_data_Mounted
        );
        // console.log("all_filter_data in All Ticket : ", all_filter_data);

        if (all_filter_data.statusCode === 200) {
          dispatch(addFilters(all_filter_data));
          getFilterResponse(all_filter_data);
          showErrorPage(null, null);
        } else if (
          all_filter_data.statusCode === 500 ||
          all_filter_data.statusCode === 401
        ) {
          setData(null);
          showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
        } else {
          setData(null);
          // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
          // console.log("Some Error Occured", all_filter_data);
          localStorage.clear();
          window.location.href = "/login";
        }
      } else {
        // console.log("Data fetch from redux ************", userFilters?.data);
        getFilterResponse(userFilters?.data);
      }
      updateFilters(initalFilters, 0, updateURL);
      setshowAllFilters(initalFilters);
      // showLoading(false);
    };
    fetchDataAndUpdateFilters(initalFilters, 0);
    // console.log("data set to empty List - 2");

    const handlePopState = () => {
      // Your logic to handle popstate event
      // console.log("mount", "User navigated back or forward");
      const previousPageURL = window.history.state || null;
      // console.log("Previous page URL:", previousPageURL);
      if (previousPageURL && previousPageURL.filters) {
        showLoading(true);
        fetchDataAndUpdateFilters(previousPageURL.filters, 0, false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    const animationTimer = setTimeout(() => {
      setAnimationActive(true);
    }, 200);

    return () => {
      is_all_filter_data_Mounted = false;
      // On unmount, abort the previous request for fetchApi api
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      clearTimeout(animationTimer);
      window.removeEventListener("popstate", handlePopState);
      // console.log("unmount");
    };
  }, []);

  // console.log(Data);

  const onExportLocal = (title) => {
    // all tickets data for software,hardware,data varation
    const excelData = Object.keys(Data?.data?.issue_data || {}).map(
      (issueType) => {
        return [
          issueType,
          Data?.data?.issue_data[issueType]?.total_count,
          ...Object.values(Data?.data?.issue_data[issueType]?.states),
        ];
      }
    );

    // All Issue Data Headings
    const all_issue_data_headings = Object.keys(
      Data?.data?.issue_data["All Tickets"]?.states
    );

    const final_all_issue_data_headings = [
      "",
      "Total Count",
      ...all_issue_data_headings,
    ];

    // Software Sub Issue
    const sub_issue_software_values =
      Data?.data?.sub_issue_data?.Software?.table_data.map(
        (each_item) => each_item
      );

    const sub_issue_software_headers = [
      "Software Sub Issues",
      ...(Data?.data?.sub_issue_data?.Software?.headers || []),
    ];
    // Average duration
    const software_avg_duration_keys = [
      "Status",
      ...Object.keys(Data?.data?.sub_issue_data?.Software?.avg_duration || {}),
    ];

    // console.log("softwareavd", software_avg_duration_keys);
    const software_avg_duration_values = [
      "Average Duration",
      ...Object.values(
        Data?.data?.sub_issue_data?.Software?.avg_duration || {}
      ),
    ];
    // top tickets source ro_list
    const top_tickets_raised_headings = ["Location", "Total Tickets"];

    const top_tickets_raised_software =
      Data?.data?.sub_issue_data?.Software?.top_ticket_sources?.ro_list.map(
        (each_ticket) => each_ticket
      );

    // top tickets do_list
    // const top_tickets_raised_headings = ["Location", "Total Tickets"];

    const top_tickets_raised_software_do_list =
      Data?.data?.sub_issue_data?.Software?.top_ticket_sources?.do_list.map(
        (each_ticket) => each_ticket
      );

    // machine_list

    const machine_list_headings = [
      "Machine Location",
      "Machine Code",
      "Total Tickets",
    ];

    const software_machine_list_details =
      Data?.data?.sub_issue_data?.Software?.top_ticket_sources?.machine_list?.map(
        (each_machine_details) => each_machine_details
      );

    // Hardware Sub Issues

    const sub_issue_hardware_values =
      Data?.data?.sub_issue_data?.Hardware?.table_data.map(
        (each_item) => each_item
      );

    const sub_issue_hardware_headers = [
      "Hardware Sub Issues",
      ...(Data?.data?.sub_issue_data?.Hardware?.headers || []),
    ];

    // Average duration

    const hardware_avg_duration_keys = [
      "Status",
      ...Object.keys(Data?.data?.sub_issue_data?.Hardware?.avg_duration || {}),
    ];

    const hardware_avg_duration_values = [
      "Average Duration",
      ...Object.values(
        Data?.data?.sub_issue_data?.Hardware?.avg_duration || {}
      ),
    ];

    // top tickets source

    const top_tickets_raised_hardware =
      Data?.data?.sub_issue_data?.Hardware?.top_ticket_sources?.ro_list.map(
        (each_ticket) => each_ticket
      );

    //top tickets do_list
    const top_tickets_raised_hardware_do_list =
      Data?.data?.sub_issue_data?.Hardware?.top_ticket_sources?.do_list.map(
        (each_ticket) => each_ticket
      );

    // machine_list
    const hardware_machine_list_details =
      Data?.data?.sub_issue_data?.Hardware?.top_ticket_sources?.machine_list?.map(
        (each_machine_details) => each_machine_details
      );

    // Data Variation Sub Issues

    const sub_issue_Data_Variation_values = Data?.data?.sub_issue_data?.[
      "Data Variation"
    ]?.table_data.map((each_item) => each_item);

    const sub_issue_Data_Variation_headers = [
      "Data Variation Sub Issues",
      ...(Data?.data?.sub_issue_data?.["Data Variation"]?.headers || []),
    ];

    // Average duration

    const data_variation_avg_duration_keys = [
      "Status",
      ...Object.keys(Data?.data?.sub_issue_data?.Software?.avg_duration || {}),
    ];

    const data_variation_avg_duration_values = [
      "Average Duration",
      ...Object.values(
        Data?.data?.sub_issue_data?.Software?.avg_duration || {}
      ),
    ];

    // top tickets raised
    const top_tickets_raised_data_variation =
      Data?.data?.sub_issue_data?.Hardware?.top_ticket_sources?.ro_list.map(
        (each_ticket) => each_ticket
      );

    //top tickets do_list
    const top_tickets_raised_data_variation_do_list =
      Data?.data?.sub_issue_data?.[
        "Data Variation"
      ]?.top_ticket_sources?.do_list.map((each_ticket) => each_ticket);

    // machine_list
    const data_variation_machine_list_details = Data?.data?.sub_issue_data?.[
      "Data Variation"
    ]?.top_ticket_sources?.machine_list?.map(
      (each_machine_details) => each_machine_details
    );

    // Rendering all details like all issues,subissues
    const all_issue_details = [final_all_issue_data_headings, ...excelData];
    const software_sub_issue = [
      ...(sub_issue_software_values || []),
      [],
      [],
      [],
      [],
      ["Average Duration (Minutes)"],
      software_avg_duration_keys || [],
      software_avg_duration_values,
      [],
      [],
      [],
      ["Ticket List by Region"],
      top_tickets_raised_headings,
      ...(top_tickets_raised_software || []),
      [],
      [],
      [],
      ["Ticket List by Disitrict"],
      top_tickets_raised_headings,
      ...(top_tickets_raised_software_do_list || []),
      [],
      [],
      [],
      ["Machine List by Location"],
      machine_list_headings,
      ...(software_machine_list_details || []),
    ];
    const hardware_sub_issue = [
      sub_issue_hardware_headers,
      ...(sub_issue_hardware_values || []),
      [],
      [],
      [],
      [],
      ["Average Duration (Minutes)"],
      hardware_avg_duration_keys,
      hardware_avg_duration_values,
      [],
      [],
      [],
      ["Ticket List by Region"],
      top_tickets_raised_headings,
      ...(top_tickets_raised_hardware || []),
      [],
      [],
      [],
      ["Ticket List by Disitrict"],
      top_tickets_raised_headings,
      ...(top_tickets_raised_hardware_do_list || []),
      [],
      [],
      [],
      ["Machine List by Location"],
      machine_list_headings,
      ...(hardware_machine_list_details || []),
    ];

    const data_variation_sub_issue = [
      sub_issue_Data_Variation_headers,
      ...(sub_issue_Data_Variation_values || []),
      [],
      [],
      [],
      [],
      ["Average Duration (Minutes)"],
      data_variation_avg_duration_keys,
      data_variation_avg_duration_values,
      [],
      [],
      [],
      ["Ticket List by Region"],
      top_tickets_raised_headings,
      ...(top_tickets_raised_data_variation || []),
      [],
      [],
      [],
      ["Ticket List by Disitrict"],
      top_tickets_raised_headings,
      ...(top_tickets_raised_data_variation_do_list || []),
      [],
      [],
      [],
      ["Machine List by Location"],
      machine_list_headings,
      ...(data_variation_machine_list_details || []),
    ];

    // console.log(top_tickets_raised);
    // console.log(top_tickets_raised_headings);

    const ws = XLSX.utils.aoa_to_sheet(all_issue_details);
    const ws1 = XLSX.utils.aoa_to_sheet(software_sub_issue);
    const ws2 = XLSX.utils.aoa_to_sheet(hardware_sub_issue);
    const ws3 = XLSX.utils.aoa_to_sheet(data_variation_sub_issue);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "All Data");
    XLSX.utils.book_append_sheet(wb, ws1, "Software Data");
    XLSX.utils.book_append_sheet(wb, ws2, "Hardware Data");
    XLSX.utils.book_append_sheet(wb, ws3, "DataVariation Data");

    // Generate and download the Excel file
    XLSX.writeFile(
      wb,
      `TicketsOverview(${title})_${new Date()
        .toString()
        .slice(0, 24)
        .replaceAll(" ", "_")}.xlsx`
    );
  };

  return (
    (!showError.status && (
      <>
        {/* snackbar showing in userDashboard */}
        <Box sx={{ width: 500 }}>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={closeSnackbar}
            message={snackbarMessage}
            severity={snackbarSeverity}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            <Alert
              onClose={closeSnackbar}
              severity={snackbarSeverity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
        {/* filters started */}
        <Dropdown
          blurTickets={handelPageBlur}
          filters={filters}
          filterCount={filterCount}
          handleFilterChange={handleFilterChange}
          handleSaveChanges={handleSaveChanges}
          handleFilterReset={handleFilterReset}
          getUserFilters={getUserFilters}
          getFilterResponse={getFilterResponse}
          filtersOptions={filtersOptions}
          updateDateFilterName={"Creation Time"}
        />
        {(allFiltersDisplay && (
          <AllFilters
            filters={showAllfilters}
            handelResetAllBtn={handelResetAllBtn}
            handelDeleteFilterBtn={handelDeleteFilterBtn}
            getUserFilters={getUserFilters}
          />
        )) || <></>}

        <div
          className={`${blurTickets} all-ticket-content ${
            allFiltersDisplay ? "m-top-0 p-top-0" : " "
          }`}
        >
          {(loading && (
            <div className="showSpinner all-ticket-spinner">
              <BeatLoader color="#2D97D4" />
            </div>
          )) ||
            (Data && (
              <div className="container user-dashboard-container">
                {/* Issue Type Desc */}

                <div className="upper-heading-container mt-2">
                  <div className="info-part">
                    <span className="px-2 mb-1 info-heading">
                      Tickets Overview
                    </span>

                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <BootstrapTooltip
                          id="tooltip-bottom-bottom "
                          className=""
                        >
                          Analytics for tickets <strong>Raised</strong> within
                          the selected time range.
                        </BootstrapTooltip>
                      }
                    >
                      <i
                        className="bi bi-info-circle icon-color px-0"
                        id="info-icon"
                        style={{ fontSize: "15px", lineHeight: "24px" }}
                      ></i>
                    </OverlayTrigger>
                  </div>
                  <div className="show-range ms-auto">
                    {Data?.data?.title}
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <BootstrapTooltip
                          id="tooltip-bottom-bottom "
                          className="mt-3 me-4"
                        >
                          Export Data
                        </BootstrapTooltip>
                      }
                    >
                      {/* <i className="fa-solid fa-file-arrow-down fa-xl export_all_data_btn"></i> */}
                      <span
                        className="export_all_data_btn"
                        onClick={() => onExportLocal(Data?.data?.title || "")}
                      >
                        <CiExport />
                      </span>
                    </OverlayTrigger>
                  </div>
                </div>

                {/* issue Type description */}
                <div className="issue-type-row-div overflow-visible">
                  {Data?.data &&
                    Object.keys(Data?.data?.issue_data || {}).map(
                      (issueType, index) => {
                        const issueTypeData =
                          Data.data.issue_data[issueType].states;
                        const issueTypeTotalCount =
                          Data.data.issue_data[issueType].total_count;
                        return (
                          <div key={`subissuelist-${index}`}>
                            {/* ALL Ticket */}
                            <div className="card-Div-issueType">
                              {/* all ticket icon & heading */}
                              <div className="d-flex align-items-center ">
                                <div className="ps-2 card-Div-heading me-auto">
                                  {/* All Tickets */}
                                  {issueType}
                                </div>
                                <div
                                  className="user-dashboard-circle-icon "
                                  style={{
                                    backgroundColor:
                                      issueTyepLogoBGcolor[index],
                                  }}
                                >
                                  <i
                                    className={`fa-solid ${
                                      issueType.toLowerCase() === "all tickets"
                                        ? "fa-ticket"
                                        : issueType.toLowerCase() === "software"
                                        ? "fa-laptop-code"
                                        : issueType.toLowerCase() === "hardware"
                                        ? "fa-microchip"
                                        : issueType.toLowerCase() ===
                                          "data variation"
                                        ? "fa-database"
                                        : ""
                                    } fa-2xl`}
                                  ></i>
                                </div>
                              </div>
                              {/* Total Counts of All Tickets */}
                              <div className="total-count-div d-flex flex-column align-items-start justify-content-center ps-2">
                                <span className="fs-2">
                                  {issueTypeTotalCount}
                                </span>
                                <p>Total Counts</p>
                              </div>
                              {/* progress bar for resolved*/}
                              <div className="resolved-div">
                                {/* progress bar */}
                                <div
                                  className="progress "
                                  style={{
                                    height: "10px",
                                    borderRadius: "10px",
                                  }}
                                >
                                  {Object.keys(issueTypeData).map(
                                    (status, issueTypeDataindex) => {
                                      return (
                                        <div
                                          className="progress-bar"
                                          key={`showprogress-${index}-${issueTypeDataindex}`}
                                          role="progressbar"
                                          style={{
                                            width:
                                              (issueTypeData[status] * 100) /
                                                issueTypeTotalCount +
                                              "%",
                                            borderRight: "1px solid #ffff",
                                            borderRadius: "10px",
                                            backgroundColor: Object.keys(
                                              subissueComponentColors
                                            ).includes(status)
                                              ? subissueComponentColors[status]
                                              : "#99D4FF",
                                          }}
                                        ></div>
                                      );
                                    }
                                  )}
                                </div>
                                {/* progress bar Discription */}
                                <ul className="grid-container">
                                  {Object.keys(issueTypeData).map(
                                    (status, statusindex) => {
                                      return (
                                        <li
                                          className="grid-item"
                                          key={`progress-desc-${issueType}-${statusindex}`}
                                        >
                                          <div
                                            style={{
                                              backgroundColor: Object.keys(
                                                subissueComponentColors
                                              ).includes(status)
                                                ? subissueComponentColors[
                                                    status
                                                  ]
                                                : "#99D4FF",
                                            }}
                                          ></div>
                                          <span
                                            style={{
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {status}
                                          </span>
                                          <span>{issueTypeData[status]}</span>
                                          <span>
                                            {(
                                              (issueTypeData[status] * 100) /
                                              issueTypeTotalCount
                                            ).toFixed(1) + "%"}
                                          </span>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
                {/* pie chart (Sub issue type)*/}

                <Accordion>
                  {Data?.data &&
                    Object.keys(Data?.data?.sub_issue_data || {}).map(
                      (issueType, index) => {
                        const issueTypeData =
                          Data.data.sub_issue_data[issueType];

                        const { data01, bardChartData, bardChartData02 } =
                          updatePiChartData(issueTypeData);
                        // console.log("value destructured :", issueTypeData);
                        // console.log("value destructured :", data03);
                        return (
                          <Accordion.Item
                            eventKey={`${index}`}
                            style={{ margin: "20px 0px" }}
                            key={`Accrodian-item-${index}`}
                          >
                            <Accordion.Header>
                              {/* {issueType} Sub Issues */}
                              <div className="ps-2 card-Div-heading me-auto text-wrap">
                                {issueType} Analytics
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div
                                className="col-lg-12 mb-3"
                                key={`pie-${index}`}
                              >
                                <div className="subissue-main-div">
                                  {/* heading */}
                                  <div className="d-flex align-items-center justify-content-around flex-wrap ">
                                    {/* main-div */}
                                    <div className="pie-chart-div">
                                      <PieChart width={400} height={400}>
                                        <Pie
                                          data={data01}
                                          dataKey="value"
                                          cx={200}
                                          cy={200}
                                          outerRadius={140}
                                          fill="#8884d8"
                                          startAngle={90} // Start at 90 degrees (12 o'clock position)
                                          endAngle={-270} // End at -270 degrees (90 degrees clockwise from start)
                                          clockWise={true} // Set to true for clockwise rotation
                                          animationBegin={200} // Delay the start of the animation
                                          isAnimationActive={animationActive} // Enable animation
                                        >
                                          {data01?.map((entry, index) => (
                                            <Cell
                                              key={`cell-${index}`}
                                              fill={entry.color}
                                            />
                                          ))}
                                        </Pie>
                                        <Tooltip />
                                      </PieChart>
                                      {/* pie chart lable */}
                                    </div>
                                    {/* circular bar chart */}
                                    <div className="avg-compeletion-time">
                                      {/* <ResponsiveContainer
                                          width="70%"
                                          height={300}
                                        >
                                          <div>
                                            <BarChart
                                              width={500}
                                              height={300}
                                              className="avg-compeletion-time-bar-chart"
                                              data={bardChartData}
                                              margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                              }}
                                            >
                                              <CartesianGrid
                                                strokeDasharray="1 1"
                                                // fill="#f3f7fd78"
                                              />
                                              <XAxis
                                                dataKey="name"
                                                axisLine={false} // Remove x-axis border
                                                tick={{ fill: "#4c4e6499" }} // Lighten x-axis tick label text
                                                // interval={0}
                                                type="category"
                                                domain={["auto", "auto"]}
                                              />
                                              <YAxis
                                                label={{
                                                  value: "Avg. Hours",
                                                  angle: -90,
                                                  position: "insideLeft",
                                                }}
                                                axisLine={false} // Remove y-axis border
                                                tick={{ fill: "#4c4e6499" }} // Lighten y-axis tick label text
                                              />
                                              <Tooltip />
                                              <Bar
                                                dataKey="avg_value"
                                                // fill="#8bc0d4"
                                                shape={<CustomBar />}
                                                barSize={15}
                                                stroke="red"
                                                // strokeWidth={50}
                                                // animationBegin={1500}
                                                animationEasing="ease-in-out"
                                                // isAnimationActive={true}
                                                // animationDuration={1000}
                                              ></Bar>
                                            </BarChart> */}
                                      {/* <AnimationBar /> */}
                                      {/* </div>
                                        </ResponsiveContainer> */}
                                      <div className="coustom_bar_chart_div">
                                        {bardChartData02?.map(
                                          (barData, index) => (
                                            <div
                                              className="coustom_bar_chart"
                                              key={`coustom_bar_chart-${index}`}
                                            >
                                              <span className="coustom_bar_chart_y_axis">
                                                {barData.key}
                                              </span>
                                              <div className="coustom_bar_chart_bar_div">
                                                <OverlayTrigger
                                                  placement="bottom"
                                                  delay={{
                                                    show: 250,
                                                    hide: 400,
                                                  }}
                                                  overlay={
                                                    <BootstrapTooltip
                                                      id="tooltip-bottom-bottom "
                                                      className=""
                                                    >
                                                      {barData.key}&nbsp;(
                                                      {barData.name}&nbsp;
                                                      {barData.lastname})
                                                    </BootstrapTooltip>
                                                  }
                                                >
                                                  <div
                                                    className="coustom_bar_chart_bar"
                                                    style={{
                                                      width: `${
                                                        (barData.value *
                                                          (isSmallScreen
                                                            ? 100
                                                            : 300)) /
                                                          barData.maximum +
                                                        5
                                                      }px`,
                                                      backgroundColor:
                                                        barData.color,
                                                    }}
                                                  ></div>
                                                </OverlayTrigger>
                                                <span className="coustom_bar_chart_bar_time_label">
                                                  &nbsp;&nbsp;({barData.name})
                                                </span>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <div className="avg-completion-time-label text-center ">
                                        Avg Duration In Each State
                                      </div>
                                    </div>
                                  </div>

                                  {/* Desc-div */}
                                  <ShowTable
                                    tableData={issueTypeData?.table_data}
                                    headers={issueTypeData?.headers || []}
                                    shadesOfBlue={shadesOfBlue}
                                  />
                                </div>
                                <div className="mt-3">
                                  {/* top ticket scorer */}
                                  <div className="card-Div top-ticket-sources-label">
                                    Top Ticket Sources
                                  </div>
                                  {/* top ticket scorer data*/}
                                  <div className="row">
                                    {Object.keys(
                                      issueTypeData?.top_ticket_sources || {}
                                    ).map((listKeys, listKeysIndex) => {
                                      let listData =
                                        issueTypeData?.top_ticket_sources[
                                          listKeys
                                        ];

                                      if (!Array.isArray(listData)) {
                                        listData = [];
                                      }
                                      // console.log("listData", listData);
                                      return (
                                        <div
                                          className="col-md-4"
                                          key={`top_ticket_sources-${listKeysIndex}`}
                                        >
                                          <div className="card-Div">
                                            <div className="row top-performer-div">
                                              {listData?.map(
                                                (
                                                  listDataitem,
                                                  listDataIndex
                                                ) => (
                                                  <React.Fragment
                                                    key={`top_ticket_sources_data-${listDataIndex}`}
                                                  >
                                                    {listDataIndex === 0 && (
                                                      <div className="row p-0 m-0">
                                                        {listKeys ===
                                                          "machine_list" && (
                                                          <div className="col-3 heading">
                                                            Machine No.
                                                          </div>
                                                        )}
                                                        <div
                                                          className={`${
                                                            listKeys ===
                                                            "machine_list"
                                                              ? "col-6"
                                                              : "col-6"
                                                          } heading`}
                                                        >
                                                          {listKeys ===
                                                          "ro_list"
                                                            ? "Region"
                                                            : listKeys ===
                                                              "do_list"
                                                            ? "District"
                                                            : listKeys ===
                                                              "machine_list"
                                                            ? "Location"
                                                            : ""}
                                                        </div>
                                                        <div
                                                          className={`${
                                                            listKeys ===
                                                            "machine_list"
                                                              ? "col-3"
                                                              : "col-6"
                                                          } heading`}
                                                        >
                                                          Counts
                                                        </div>
                                                      </div>
                                                    )}
                                                    {listKeys ===
                                                      "machine_list" && (
                                                      <div className="col-3">
                                                        {listDataitem[1]}
                                                      </div>
                                                    )}
                                                    <div
                                                      className={
                                                        listKeys ===
                                                        "machine_list"
                                                          ? "col-6"
                                                          : "col-6"
                                                      }
                                                    >
                                                      {
                                                        listDataitem[
                                                          listKeys ===
                                                          "machine_list"
                                                            ? 0
                                                            : 0
                                                        ]
                                                      }
                                                    </div>
                                                    <div
                                                      className={
                                                        listKeys ===
                                                        "machine_list"
                                                          ? "col-3"
                                                          : "col-6"
                                                      }
                                                    >
                                                      {
                                                        listDataitem[
                                                          listKeys ===
                                                          "machine_list"
                                                            ? 2
                                                            : 1
                                                        ]
                                                      }
                                                    </div>
                                                  </React.Fragment>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      }
                    )}
                </Accordion>
              </div>
            )) || (
              <div className="container no-ticket-found-div text-center count_heading_div">
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
                  <p>No Data Found</p>
                </div>
              </div>
            )}
        </div>
      </>
    )) ||
    (showError.status && <Error />) || <Error code={500} />
  );
};

export default UserDashboard;
